import React, {useState} from "react";
import SidebarItems from "../../templates/default/config/SidebarItems";
import {ProSidebar, Menu, SubMenu, SidebarHeader, SidebarContent, SidebarFooter} from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import ItemMenu from "./ItemMenu";
import MenuIcon from "./MenuIcon";
//import Avatar from '@material-ui/core/Avatar';
//import ReactFlagsSelect from 'react-flags-select';
import {IntlMessageValue} from "../component/const/IntlMessage";
import ChangeHeader from "./ChangeHeader";
import {
    //useDispatch,
    useSelector} from "react-redux";
/*import {AppDispatch} from "../../../store";
import * as actions from '../../templates/default/localization/store/Actions/Locale/LocaleAction'*/
import './sidebar.css'
import Gallery from "../component/const/galery";
import {replaceChar} from "../component/const/Tooltip";
import SecureCheck from "../RoleSecury";

const Sidebar = (props: {setValue:any,value:any} ) => {

    const [menuCollapse, setMenuCollapse] = useState(false)
    let Store = useSelector((state: any) => state)
    //create a custom function that will change menucollapse state from false to true and true to false
    const menuIconClick = () => {
        //condition checking to change state from true to false and vice versa

        let Header = document.getElementsByClassName('theme-header')[0] as HTMLDivElement;
        let Footer = document.getElementsByClassName("theme-footer")[0] as HTMLDivElement
        if(menuCollapse) {
            setMenuCollapse(false)
            Header.style.marginLeft = '-270px';
            Footer.style.marginLeft = '-270px';
            Footer.style.zIndex = '1008';
        } else {
            setMenuCollapse(true)
            Header.style.marginLeft = '-80px';
            Footer.style.marginLeft = '-80px';
            Footer.style.zIndex = '1009';
        }
    };
    /*const [selected, setSelected] = useState('DE');
    const dispatch : AppDispatch = useDispatch();
    const setter = (code: React.SetStateAction<string>) => {
        setSelected(code)
        props.setValue(code)

        dispatch(actions.getLocale(code as string) as any)
    }

    localStorage.setItem('locale',selected)*/

    return (
        <>
            <ProSidebar
                toggled={false}
                breakPoint="md"
                collapsed={menuCollapse}
            >
                <SidebarHeader className={'sidebarHeader'}>
                    <div className="logotext">
                        {/* small and big change using menucollapse state */}
                        <p className={'m-0'}>{menuCollapse ? <a href={(process.env.REACT_APP_LANDINGPAGES !== undefined ? process.env.REACT_APP_LANDINGPAGES : '/Blog/overview/1')}><img src={'/Real_erde_gerade_Ukleinersilber3d.png'} height={60} alt={'Logo'} className={'SidebarLogo'} /></a> : <a href={(process.env.REACT_APP_LANDINGPAGES !== undefined ? process.env.REACT_APP_LANDINGPAGES : '/Blog/overview/1')}><img src={process.env.REACT_APP_LOGO_IMG} height={60} alt={'Logo'} className={'SidebarLogo'} /></a>}</p>

                    </div>

                </SidebarHeader>
                <SidebarContent>

                    <div className="closemenu box" onClick={menuIconClick}>
                        <h3>
                            {/* changing menu collapse icon on click */}
                            {menuCollapse ? (
                                <svg className={'float-start ml-2'} stroke="currentColor" fill="white" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="10"></circle><polyline points="12 16 16 12 12 8"></polyline><line x1="8" y1="12" x2="16" y2="12"></line></svg>
                            ) : (
                                <svg className={'float-end mr-2'} stroke="currentColor" fill="white" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="10"></circle><polyline points="12 8 8 12 12 16"></polyline><line x1="16" y1="12" x2="8" y2="12"></line></svg>

                            )}
                            <br style={{clear:'both'}} />
                        </h3>
                    </div>

                {

                    SidebarItems(props.value).map((item, index)=> {
                        if(item.icon !== undefined && item.icon !== '' && SecureCheck(item.roles)) {
                            return (
                                <>
                                <Menu key={'Menu'+index} iconShape="square">

                                    <SubMenu key={'SubMenu'+index} title={ChangeHeader({header:item.header,value:props.value,Store:Store})} icon={<MenuIcon icon={(item.icon !== undefined ? item.icon : '')} />} className={'first'}>

                                        <ItemMenu header={item.header} key={'ItemMenu'+index} items={item.menuitem} value={props.value} Store={Store} />

                                    </SubMenu>
                                </Menu>

                                </>
                            );
                        } else {
                            if (item.icon !== undefined && item.icon !== '' && SecureCheck(item.roles)) {
                                return (
                                    <>
                                        <Menu key={'Menu' + index} iconShape="circle">
                                            <SubMenu key={'SubMenu' + index}
                                                     title={IntlMessageValue(props.value, item.header, Store)}>

                                                <ItemMenu header={item.header} key={'ItemMenu' + index}
                                                          items={item.menuitem} value={props.value} Store={Store}/>

                                            </SubMenu>
                                        </Menu>
                                    </>
                                );
                            }
                        }
                    })

                }

                </SidebarContent>
                <SidebarFooter className={'ta-center'}>
                    <div className="sidebar-btn-wrapper" >
                        <a
                            href={process.env.REACT_APP_LOGO_IMG_WUM_LINK !== undefined ? process.env.REACT_APP_LOGO_IMG_WUM_LINK : "https://wum-solution.de"}
                            target="_blank"
                            className="sidebar-btn"
                            rel="noopener noreferrer"
                        >
                            <img src={process.env.REACT_APP_LOGO_IMG_WUM} style={{maxHeight:'60px'}} alt={'Logo'}/>
                        </a>
                    </div>
                </SidebarFooter>
            </ProSidebar>
        </>
    );
}
export default Sidebar


export const ShowDialog = (Store:any) => {
    let eles =  document.getElementById('dialog') as HTMLDivElement;
    eles.style.display = 'block';
    Gallery('','','',Store,'',null,null,null,false)
}

