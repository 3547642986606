import React from 'react';
import Form from 'react-bootstrap/Form';
import legende from './helper/legende';
import buildOption from './helper/buildOption';
import {ValidateMessage} from '../../component/const/ValidateMessage';

function select(data: any,z:any,Store:any) {
  const row = [];
  for (let i = 0; i < data.options.length; i++) {
    if (data.hasOwnProperty('selected')) {
      row.push(buildOption(data.options[i], data.selected, i));
    } else {
      row.push(buildOption(data.options[i], 9999, i));
    }
  }


  let id = (data.id !== undefined ? data.id : "select" + z);
  let required = false;
  if (data.required !== undefined && data.required !== null && data.required !== false) {
    required = true;
  }

  let br;
  if (data.br !== undefined) {
    br = (<><br /></>)
  } else {
    br = '';
  }

  let hidden = 'block';
  if (data.type !== undefined && data.type === 'hidden') {
    hidden = 'none'
  }


  const stockShow = (e:any) => {
    let value = e.currentTarget.value;
    //console.log(value)
    let stock = document.getElementById('stockContainer')?.style;

    if(value === '1') {
      // @ts-ignore
      stock.display = 'block';
    } else if (value === '0'){
      // @ts-ignore
      stock.display = 'none';
    } else {
      // @ts-ignore
      stock.display = 'none';
    }

  }
  if (data.onclick === 'stock') {
    return (
      <>
        {br}
        <Form.Group controlId={id} key={"select" + z} className="mb-3" style={{display: hidden}}>
          <Form.Label>{data.label}</Form.Label>
          <Form.Select name={data.name} aria-label="Floating label select example" defaultValue={data.selected}
                       disabled={data.disabled} required={required} onChange={(e) => stockShow(e)}>
            {row}
          </Form.Select>
          <ValidateMessage valid={(data.valid !== undefined ? data.valid : undefined)} Store={Store} />
        </Form.Group>
        {legende(data)}
      </>
    );
  } else {
    return (
      <>
        {br}
        <Form.Group controlId={id} key={"select" + z} className="mb-3" style={{display: hidden}}>
          <Form.Label>{data.label}</Form.Label>
          <Form.Select name={data.name} aria-label="Floating label select example" defaultValue={data.selected}
                       disabled={data.disabled} required={required}>
            {row}
          </Form.Select>
          <ValidateMessage valid={(data.valid !== undefined ? data.valid : undefined)} Store={Store} />
        </Form.Group>
        {legende(data)}
      </>
    );
  }
}

export default select;



