import CreateMatomoIframe from "../../core/CreateMatomoIframe";
import WidgetURLMapping from "../../core/WidgetURLMapping";
import React from "react";


const EntryPages = () => {

    return(
        <>
            <CreateMatomoIframe
                headline={'Einstiegsseiten'}
                width={"100%"}
                height={"600"}
                url={WidgetURLMapping({name:'entry pages'})}
                scrolling={"yes"}
                id={'entry1'}
            />
        </>
    )
}

export default EntryPages;