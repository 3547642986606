import {useEffect, useState} from 'react';
import {error} from '../../../../../../@WUM/core/component/const/error.services';
import {invoice} from '../../request/invoice/invoice';
import {invoiceApiType} from '../../request/invoice/invoiceApi.interface';
import {invoiceDetailApiType} from '../../request/invoice/invoiceDetailApi.interface';
import {checkLogin} from "../../../../../../@WUM/templates/default/Auth/service/AuthService";


export const Response = (limit: any, searchValue: any) => {
  const [posts, setPosts] = useState<invoiceApiType[]>([]);
  const [isError, setIsError] = useState<boolean>(false);
  useEffect(() => {
    (async () => {
      await invoice.getPosts(limit,searchValue)
    .then((data) => {
      checkLogin(data);
      setPosts(data);
    })
    .catch((err) => {
      error(err);
      setIsError(true);
    });
    })()}, [limit,searchValue])


  return isError ? (posts ) : (posts);

}




export const ResponseDetail = (id: string | undefined) => {
  const [posts, setPosts] = useState<invoiceDetailApiType[]>([]);
  const [isError, setIsError] = useState<boolean>(false);
  useEffect(() => {
    (async () => {
      await invoice.getAPost(id)
        .then((data) => {
          checkLogin(data);
          setPosts(data);
        })
        .catch((err) => {
          error(err);
          setIsError(true);
        });
    })()}, [id])

  return isError ? (isError ) : (posts);

}
