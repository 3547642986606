import ListGroup from "react-bootstrap/ListGroup";
import Form from "react-bootstrap/Form";
import ReactDOM from "react-dom";
import React from "react";
import setForm from "./setForm";
import {closeandChange, deleted, show} from "./switchdisplay";
import open from "./open";
import Badge from "react-bootstrap/Badge";

const addform = (id:any,x:any,AllPages:any,settypes: (value: (((prevState: string) => string) | string)) => void,types:any) => {
    let l= $((document.getElementById(id) as HTMLElement)).find(".list-group-item").length

    let newElement = <>
        <p id={'p'+x+''+l}  style={{paddingLeft:'25px',display:'none'}}
        >
            <p className={'IconPlus float-end '} style={{left:'5px',top:'0px'}}>
                                <span className="material-icons text-white" onClick={(e)=>deleted('lg'+x+l,'group'+x,settypes)}>
                                delete
                            </span></p>
            <p id={'p'+x+''+l+'titel'} style={{maxWidth: '180px'}} onClick={(e) => open(x + '-' + l + '-form')}> </p>

            <p className={'float-end '} style={{right:'55px',top:'6px',position:'absolute'}}>
                {
                    (types === 'page' ?
                        <Badge pill  bg={'primary'}>
                            {types.toUpperCase()}
                        </Badge>
                        :
                        (types === 'link' ?
                            <Badge pill  bg={'secondary'}>
                                {types.toUpperCase()}
                            </Badge>
                            :
                            (types === 'html' ?
                                    <Badge pill  bg={'info'}>
                                        {types.toUpperCase()}
                                    </Badge>
                                    :
                                    <></>
                            )))
                }
            </p>
            <p className={'IconPlus float-end '} style={{right:'15px',top:'0px'}}>
                                <span className="material-icons text-white" onClick={(e)=>show('p'+x+''+l,'name'+x+''+l)}>
                                edit_note
                            </span></p></p>
        <div className="mb-3"  id={'name'+x+''+l}>

            <Form.Control type="hidden" name={"Menu["+x+"][Items]["+l+"][item]"} />
            <Form.Control type="text" name={"Menu["+x+"][Items]["+l+"][item][name]"} id={'name'+x+''+l+'input'}  />
            <Form.Control type="hidden" name={"Menu["+x+"][Items]["+l+"][menu_id]"} />
            <Form.Control type="hidden" name={"Menu["+x+"][Items]["+l+"][item_id]"} />
            <Form.Control type="hidden" name={"Menu["+x+"][Items]["+l+"][id]"} />
            <p className={'IconPlus float-end '} style={{right:'15px',top:'7px'}}>
                                <span className="material-icons text-white"  onClick={(e)=>closeandChange('p'+x+''+l,'name'+x+''+l)}>
                                    close
                                </span>
            </p>
        </div>

        <div id={x + '-' + l + '-form'} className={'hidden Form'}>
            {setForm(x, l,[],true,AllPages,settypes,types)}
        </div>
    </>;
    let temp = document.createElement('div');
    temp.id = 'lg'+x+l
    temp.className = 'list-group-item'
    ReactDOM.render(newElement,temp);
    let ele =  document.getElementById(id);
    if(ele !== null) {
        ele.appendChild(temp)
        settypes('addForm')
    }
}

export default addform;
