import React, {useState} from 'react';
import {ResponseDelete} from '../../../../CMS/Blog/API/response/blog/blog';
import {SpinnerJSX} from '../../../../../@WUM/core/component/const/Spinner';
import configuration from '../configuration/Page/overview';
import {useNavigate, useParams} from 'react-router-dom';
import {removeUseLocalStorage} from "../../../../../@WUM/core/component/const/useLocalStoreage";
import IntlMessage from '../../../../../@WUM/core/component/const/IntlMessage';
import ContentSwitch from "../../../../../@WUM/core/component/const/ContentSwitch";
import {useSelector} from 'react-redux'
import {ResponsePromo} from "../../API/response/promo";

/**
 * Give View Overview for Content Blog
 * @memberOf blog
 * @prop page
 * @prop deleteID
 * @author Marcel Ugurcu
 * @version 0.0.1
 */
const PromoOverview = (props:{value:any}) => {


    const {page,deleteID} = useParams<{page: string,deleteID: string}>();

    removeUseLocalStorage('seo_title');
    removeUseLocalStorage('seo_description');
    const Store = useSelector((state: any) => state)


    const history = useNavigate();
    /**
     * Neuladen der Seite
     */
    const routeChange = (paths:string) => {
        let path = paths;
        history(path);
    };
    let limit: any;
    if(deleteID) {
        ResponseDelete(deleteID);
        routeChange('/Promo/overview/1')
    }

    if (typeof page === "string") {
        limit = (parseFloat(page) * 10) - 10;
    }
    const [searchValue, setSearchValue] = useState('');
    const [search, setSearch] = useState<boolean>(false)
    const response = ResponsePromo(limit,searchValue);
    const [modalShow, setModalShow] = React.useState(false);
    const [modalData, setModalData] = React.useState([]);
    const Configuration = configuration('',response,page,Store);

    const [mode, setMode] = useState('cart');
    const destination = 'Promo';


    let link;
    if(process.env.REACT_APP_MODE === 'LIVE') {
        link = process.env.REACT_APP_PREVIEW_LINK_LIVE;
    } else if(process.env.REACT_APP_MODE === 'DEVELOPMENT'){
        link = process.env.REACT_APP_PREVIEW_LINK_DEVELOPMENT;
    } else {
        link = process.env.REACT_APP_PREVIEW_LINK_LOCAL;
    }
    const CardsConfiguration = {
        'data': {
            'ressource': response,
            'indiBild': true,
            'img': 'preview_media.cdn_url',
            'status': 'status',
            'statusActive': <IntlMessage Store={Store} messageId="common.activated" />,
            'statusDeactive': <IntlMessage Store={Store} messageId="common.deactivated" />,
            'link': 'translation.de.slug',
            'prelink': link+'/magazin/',
            'prelinksearch': 'translation.de.slug',
            'start':0,
            'descriptionShow':true,
            'title': 'userName',
            'description': 'accessToken',
            'router': 'id',
            'preroute': '/Promo/Detail/',
            'edit': <IntlMessage Store={Store} messageId={'blog.overview.button'} />,
            'delete': true,
            'deleteLink': '/Promo/Delete/'+page+'/[ID]'
        }
    }


    let NewPage = (page === undefined ? 1:page);
    if (response.length > 0) {
        return (
            <>
                { <ContentSwitch pagenew={'new'} destinationnew={'Promo'} tonew={'new'} text={<IntlMessage Store={Store} locale={'de'} messageId={'blog.new.post'} />}
                                 response={response} CardsConfiguration={CardsConfiguration} mode={mode}
                                 Configuration={Configuration} setMode={setMode}  destination={destination} page={NewPage}
                                 to={'overview'} setSearchValues={setSearchValue} setSearchs={setSearch} search={search}
                                 modalShow={modalShow} setModalShow={setModalShow} modalData={modalData} setModalData={setModalData}
                                 searchfield={[]} allTaxon={[]} _handleKeyFilters={[]} _handleEnter={[]} filter={true} stop={false} Store={Store}/>}
            </>

        )
    } else {
        localStorage.setItem('searchEnterInput','');
        return (
            <><SpinnerJSX messageId={'common.loading'} Store={Store} /></>
        );
    }

};


export default PromoOverview;



