
import React from "react";

import AnalyticsConfig from "../configuration/analytics/AnalyticsConfig";
import SwitchSearch from "../core/SwitchSearch";
import {useSelector} from "react-redux";

const Anayltics = (props:{value:any}) => {

    const [Period, setPeriod] = React.useState([localStorage.getItem('period')]);
    const [Date, setDate] = React.useState([localStorage.getItem('sdate')+','+localStorage.getItem('edate')]);
    const Store = useSelector((state: any) => state)
    //console.log(Period);
    //console.log(Date);
    return (
        <>
            <SwitchSearch setDate={setDate} setPeriod={setPeriod} Store={Store} />
            <br />
            <AnalyticsConfig />
        </>
    )
}



export default Anayltics;
