interface WidgetURLMappingProps {
    name: string;
}

const SetPeriod = () => {
    if(localStorage.getItem('sdate') !== '' && localStorage.getItem('sdate') !== undefined && localStorage.getItem('sdate') !== null
        && localStorage.getItem('edate') !== '' && localStorage.getItem('edate') !== undefined && localStorage.getItem('edate') !== null){
        return (localStorage.getItem('period') !== '' && localStorage.getItem('period') !== undefined ? localStorage.getItem('period') : 'day')
    } else {
        if(localStorage.getItem('period') !== '' && localStorage.getItem('period') !== 'range' && localStorage.getItem('period') !== undefined && localStorage.getItem('period') !== null){
            return localStorage.getItem('period')
        } else {
            return 'day'
        }
    }

}

const SetDate = () => {
    if(localStorage.getItem('sdate') !== '' && localStorage.getItem('sdate') !== undefined && localStorage.getItem('sdate') !== null
        && localStorage.getItem('edate') !== '' && localStorage.getItem('edate') !== undefined && localStorage.getItem('edate') !== null){
        return(localStorage.getItem('sdate')+','+localStorage.getItem('edate'))
    } else {
        return 'yesterday'
    }
}


const WidgetURLMapping = (props: WidgetURLMappingProps) => {

    switch (props.name) {
        case "KPI Metrik":
            return "/index.php?module=Widgetize&action=iframe&column=&disableLink=0&widget=1&moduleToWidgetize=CoreVisualizations&actionToWidgetize=singleMetricView&idSite=1&period="+SetPeriod()+"&date="+SetDate()+"&disableLink=1&widget=1" as string;
        case 'Graph of recent visits':
            return "/index.php?module=Widgetize&action=iframe&forceView=1&viewDataTable=graphEvolution&disableLink=0&widget=1&moduleToWidgetize=VisitsSummary&actionToWidgetize=getEvolutionGraph&idSite=1&period="+SetPeriod()+"&date="+SetDate()+"&disableLink=1&widget=1" as string;
        case 'Visitor overview':
            return "/index.php?module=Widgetize&action=iframe&forceView=1&viewDataTable=sparklines&disableLink=0&widget=1&moduleToWidgetize=VisitsSummary&actionToWidgetize=get&idSite=1&period="+SetPeriod()+"&date="+SetDate()+"&disableLink=1&widget=1" as string;
        case 'Visitor log':
            return "/index.php?module=Widgetize&action=iframe&forceView=1&viewDataTable=VisitorLog&small=1&disableLink=0&widget=1&moduleToWidgetize=Live&actionToWidgetize=getLastVisitsDetails&idSite=1&period="+SetPeriod()+"&date="+SetDate()+"&disableLink=1&widget=1" as string;
        case 'Visits in real time':
            return "/index.php?module=Widgetize&action=iframe&disableLink=0&widget=1&moduleToWidgetize=Live&actionToWidgetize=widget&idSite=1&period="+SetPeriod()+"&date="+SetDate()+"&disableLink=1&widget=1" as string;
        case 'Visitor map in real time':
            return "/index.php?module=Widgetize&action=iframe&disableLink=0&widget=1&moduleToWidgetize=UserCountryMap&actionToWidgetize=realtimeMap&idSite=1&period="+SetPeriod()+"&date="+SetDate()+"&disableLink=1&widget=1" as string;
        case 'Unique Visitors':
            return "/index.php?module=Widgetize&action=iframe&disableLink=0&widget=1&moduleToWidgetize=UserCountryMap&actionToWidgetize=visitorMap&idSite=1&period="+SetPeriod()+"&date="+SetDate()+"&disableLink=1&widget=1" as string;
        case 'continent':
            return "/index.php?module=Widgetize&action=iframe&disableLink=0&widget=1&moduleToWidgetize=UserCountry&actionToWidgetize=getContinent&idSite=1&period="+SetPeriod()+"&date="+SetDate()+"&disableLink=1&widget=1" as string;
        case 'different countries':
            return "/index.php?module=Widgetize&action=iframe&disableLink=0&widget=1&moduleToWidgetize=UserLanguage&actionToWidgetize=getLanguageCode&idSite=1&period="+SetPeriod()+"&date="+SetDate()+"&disableLink=1&widget=1" as string;
        case 'browser language':
            return "/index.php?module=Widgetize&action=iframe&disableLink=0&widget=1&moduleToWidgetize=UserLanguage&actionToWidgetize=getLanguage&idSite=1&period="+SetPeriod()+"&date="+SetDate()+"&disableLink=1&widget=1" as string;
        case 'country':
            return "/index.php?module=Widgetize&action=iframe&disableLink=0&widget=1&moduleToWidgetize=UserCountry&actionToWidgetize=getCountry&idSite=1&period="+SetPeriod()+"&date="+SetDate()+"&disableLink=1&widget=1" as string;
        case 'region':
            return "/index.php?module=Widgetize&action=iframe&disableLink=0&widget=1&moduleToWidgetize=UserCountry&actionToWidgetize=getRegion&idSite=1&period="+SetPeriod()+"&date="+SetDate()+"&disableLink=1&widget=1" as string;
        case 'city':
            return "/index.php?module=Widgetize&action=iframe&disableLink=0&widget=1&moduleToWidgetize=UserCountry&actionToWidgetize=getCity&idSite=1&period="+SetPeriod()+"&date="+SetDate()+"&disableLink=1&widget=1" as string;
        case 'device type':
            return "/index.php?module=Widgetize&action=iframe&disableLink=0&widget=1&moduleToWidgetize=DevicesDetection&actionToWidgetize=getType&idSite=1&period="+SetPeriod()+"&date="+SetDate()+"&disableLink=1&widget=1" as string;
        case 'device brand':
            return "/index.php?module=Widgetize&action=iframe&disableLink=0&widget=1&moduleToWidgetize=DevicesDetection&actionToWidgetize=getBrand&idSite=1&period="+SetPeriod()+"&date="+SetDate()+"&disableLink=1&widget=1" as string;
        case 'device model':
            return "/index.php?module=Widgetize&action=iframe&disableLink=0&widget=1&moduleToWidgetize=DevicesDetection&actionToWidgetize=getModel&idSite=1&period="+SetPeriod()+"&date="+SetDate()+"&disableLink=1&widget=1" as string;
        case 'screen resolutions':
            return "/index.php?module=Widgetize&action=iframe&disableLink=0&widget=1&moduleToWidgetize=Resolution&actionToWidgetize=getResolution&idSite=1&period="+SetPeriod()+"&date="+SetDate()+"&disableLink=1&widget=1" as string;
        case 'Operating System Versions':
            return "/index.php?module=Widgetize&action=iframe&disableLink=0&widget=1&moduleToWidgetize=DevicesDetection&actionToWidgetize=getOsVersions&idSite=1&period="+SetPeriod()+"&date="+SetDate()+"&disableLink=1&widget=1" as string;
        case 'configurations':
            return "/index.php?module=Widgetize&action=iframe&disableLink=0&widget=1&moduleToWidgetize=Resolution&actionToWidgetize=getConfiguration&idSite=1&period="+SetPeriod()+"&date="+SetDate()+"&disableLink=1&widget=1" as string;
        case 'browser plugins':
            return "/index.php?module=Widgetize&action=iframe&disableLink=0&widget=1&moduleToWidgetize=DevicePlugins&actionToWidgetize=getPlugin&idSite=1&period="+SetPeriod()+"&date="+SetDate()+"&disableLink=1&widget=1" as string;
        case 'browsers':
            return "/index.php?module=Widgetize&action=iframe&disableLink=0&widget=1&moduleToWidgetize=DevicesDetection&actionToWidgetize=getBrowsers&idSite=1&period="+SetPeriod()+"&date="+SetDate()+"&disableLink=1&widget=1" as string;
        case 'browser engines':
            return "/index.php?module=Widgetize&action=iframe&disableLink=0&widget=1&moduleToWidgetize=DevicesDetection&actionToWidgetize=getBrowserEngines&idSite=1&period="+SetPeriod()+"&date="+SetDate()+"&disableLink=1&widget=1" as string;
        case 'Visits according to local time':
            return "/index.php?module=Widgetize&action=iframe&disableLink=0&widget=1&moduleToWidgetize=VisitTime&actionToWidgetize=getVisitInformationPerLocalTime&idSite=1&period="+SetPeriod()+"&date="+SetDate()+"&disableLink=1&widget=1" as string;
        case 'Visits by server time':
            return "/index.php?module=Widgetize&action=iframe&disableLink=0&widget=1&moduleToWidgetize=VisitTime&actionToWidgetize=getVisitInformationPerServerTime&idSite=1&period="+SetPeriod()+"&date="+SetDate()+"&disableLink=1&widget=1" as string;
        case 'user IDs':
            return "/index.php?module=Widgetize&action=iframe&disableLink=0&widget=1&moduleToWidgetize=UserId&actionToWidgetize=getUsers&idSite=1&period="+SetPeriod()+"&date="+SetDate()+"&disableLink=1&widget=1" as string;
        case 'sites':
            return "/index.php?module=Widgetize&action=iframe&disableLink=0&widget=1&moduleToWidgetize=Actions&actionToWidgetize=getPageUrls&idSite=1&period="+SetPeriod()+"&date="+SetDate()+"&disableLink=1&widget=1" as string;
        case 'entry pages':
            return "/index.php?module=Widgetize&action=iframe&disableLink=0&widget=1&moduleToWidgetize=Actions&actionToWidgetize=getEntryPageUrls&idSite=1&period="+SetPeriod()+"&date="+SetDate()+"&disableLink=1&widget=1" as string;
        case 'exit pages':
            return "/index.php?module=Widgetize&action=iframe&disableLink=0&widget=1&moduleToWidgetize=Actions&actionToWidgetize=getExitPageUrls&idSite=1&period="+SetPeriod()+"&date="+SetDate()+"&disableLink=1&widget=1" as string;
        case "page title":
            return "/index.php?module=Widgetize&action=iframe&disableLink=0&widget=1&moduleToWidgetize=Actions&actionToWidgetize=getPageTitles&idSite=1&period="+SetPeriod()+"&date="+SetDate()+"&disableLink=1&widget=1" as string;
        case "internal search":
            return "/index.php?module=Widgetize&action=iframe&disableLink=0&widget=1&moduleToWidgetize=Actions&actionToWidgetize=getSiteSearchKeywords&idSite=1&period="+SetPeriod()+"&date="+SetDate()+"&disableLink=1&widget=1" as string;
        case "Search terms with no results":
            return "/index.php?module=Widgetize&action=iframe&disableLink=0&widget=1&moduleToWidgetize=Actions&actionToWidgetize=getSiteSearchNoResultKeywords&idSite=1&period="+SetPeriod()+"&date="+SetDate()+"&disableLink=1&widget=1" as string;
        case "Pages visited after an internal search":
            return "/index.php?module=Widgetize&action=iframe&disableLink=0&widget=1&moduleToWidgetize=Actions&actionToWidgetize=getPageUrlsFollowingSiteSearch&idSite=1&period="+SetPeriod()+"&date="+SetDate()+"&disableLink=1&widget=1" as string;
        case "search categories":
            return "/index.php?module=Widgetize&action=iframe&disableLink=0&widget=1&moduleToWidgetize=Actions&actionToWidgetize=getSiteSearchCategories&idSite=1&period="+SetPeriod()+"&date="+SetDate()+"&disableLink=1&widget=1" as string;
        case "Outgoing referrals":
            return "/index.php?module=Widgetize&action=iframe&disableLink=0&widget=1&moduleToWidgetize=Actions&actionToWidgetize=getOutlinks&idSite=1&period="+SetPeriod()+"&date="+SetDate()+"&disableLink=1&widget=1" as string;
        case "Downloads":
            return "/index.php?module=Widgetize&action=iframe&disableLink=0&widget=1&moduleToWidgetize=Actions&actionToWidgetize=getDownloads&idSite=1&period="+SetPeriod()+"&date="+SetDate()+"&disableLink=1&widget=1" as string;
        case "event categories":
            return "/index.php?module=Widgetize&action=iframe&secondaryDimension=eventAction&disableLink=0&widget=1&moduleToWidgetize=Events&actionToWidgetize=getCategory&idSite=1&period="+SetPeriod()+"&date="+SetDate()+"&disableLink=1&widget=1" as string;
        case "event actions":
            return "/index.php?module=Widgetize&action=iframe&secondaryDimension=eventName&disableLink=0&widget=1&moduleToWidgetize=Events&actionToWidgetize=getAction&idSite=1&period="+SetPeriod()+"&date="+SetDate()+"&disableLink=1&widget=1" as string;
        case "event names":
            return "/index.php?module=Widgetize&action=iframe&secondaryDimension=eventAction&disableLink=0&widget=1&moduleToWidgetize=Events&actionToWidgetize=getName&idSite=1&period="+SetPeriod()+"&date="+SetDate()+"&disableLink=1&widget=1" as string;
        case "content name":
            return "/index.php?module=Widgetize&action=iframe&disableLink=0&widget=1&moduleToWidgetize=Contents&actionToWidgetize=getContentNames&idSite=1&period="+SetPeriod()+"&date="+SetDate()+"&disableLink=1&widget=1" as string;
        case "content part":
            return "/index.php?module=Widgetize&action=iframe&disableLink=0&widget=1&moduleToWidgetize=Contents&actionToWidgetize=getContentPieces&idSite=1&period="+SetPeriod()+"&date="+SetDate()+"&disableLink=1&widget=1" as string;
        case "Frequency overview":
            return "/index.php?module=Widgetize&action=iframe&forceView=1&viewDataTable=sparklines&disableLink=0&widget=1&moduleToWidgetize=VisitFrequency&actionToWidgetize=get&idSite=1&period="+SetPeriod()+"&date="+SetDate()+"&disableLink=1&widget=1" as string;
        case "Visits by visit duration":
            return "/index.php?module=Widgetize&action=iframe&disableLink=0&widget=1&moduleToWidgetize=VisitorInterest&actionToWidgetize=getNumberOfVisitsPerVisitDuration&idSite=1&period="+SetPeriod()+"&date="+SetDate()+"&disableLink=1&widget=1" as string;
        case "Visits by number of visits":
            return "/index.php?module=Widgetize&action=iframe&disableLink=0&widget=1&moduleToWidgetize=VisitorInterest&actionToWidgetize=getNumberOfVisitsByVisitCount&idSite=1&period="+SetPeriod()+"&date="+SetDate()+"&disableLink=1&widget=1" as string;
        case "Visits by page count":
            return "/index.php?module=Widgetize&action=iframe&disableLink=0&widget=1&moduleToWidgetize=VisitorInterest&actionToWidgetize=getNumberOfVisitsPerPage&idSite=1&period="+SetPeriod()+"&date="+SetDate()+"&disableLink=1&widget=1" as string;
        case "Visits by days since last visit":
            return "/index.php?module=Widgetize&action=iframe&disableLink=0&widget=1&moduleToWidgetize=VisitorInterest&actionToWidgetize=getNumberOfVisitsByDaysSinceLast&idSite=1&period="+SetPeriod()+"&date="+SetDate()+"&disableLink=1&widget=1" as string;
        case "Graph of recent visits engagements":
            return "/index.php?module=Widgetize&action=iframe&forceView=1&viewDataTable=graphEvolution&disableLink=0&widget=1&moduleToWidgetize=VisitFrequency&actionToWidgetize=getEvolutionGraph&idSite=1&period="+SetPeriod()+"&date="+SetDate()+"&disableLink=1&widget=1" as string;
        case "transitions":
            return "/index.php?module=Widgetize&action=iframe&disableLink=0&widget=1&moduleToWidgetize=Transitions&actionToWidgetize=getTransitions&idSite=1&period="+SetPeriod()+"&date="+SetDate()+"&disableLink=1&widget=1" as string;
        case "Change in page performance metrics":
            return "/index.php?module=Widgetize&action=iframe&forceView=1&viewDataTable=graphStackedBarEvolution&disableLink=0&widget=1&moduleToWidgetize=PagePerformance&actionToWidgetize=getEvolutionGraph&idSite=1&period="+SetPeriod()+"&date="+SetDate()+"&disableLink=1&widget=1" as string;
        case "channel type":
            return "/index.php?module=Widgetize&action=iframe&disableLink=0&widget=1&moduleToWidgetize=Referrers&actionToWidgetize=getReferrerType&idSite=1&period="+SetPeriod()+"&date="+SetDate()+"&disableLink=1&widget=1" as string;
        case "search terms":
            return "/index.php?module=Widgetize&action=iframe&disableLink=0&widget=1&moduleToWidgetize=Referrers&actionToWidgetize=getKeywords&idSite=1&period="+SetPeriod()+"&date="+SetDate()+"&disableLink=1&widget=1" as string;
        case "search engines":
            return "/index.php?module=Widgetize&action=iframe&disableLink=0&widget=1&moduleToWidgetize=Referrers&actionToWidgetize=getSearchEngines&idSite=1&period="+SetPeriod()+"&date="+SetDate()+"&disableLink=1&widget=1" as string;
        case "Web pages":
            return "/index.php?module=Widgetize&action=iframe&disableLink=0&widget=1&moduleToWidgetize=Referrers&actionToWidgetize=getWebsites&idSite=1&period="+SetPeriod()+"&date="+SetDate()+"&disableLink=1&widget=1" as string;
        case "Social networks":
            return "/index.php?module=Widgetize&action=iframe&disableLink=0&widget=1&moduleToWidgetize=Referrers&actionToWidgetize=getSocials&idSite=1&period="+SetPeriod()+"&date="+SetDate()+"&disableLink=1&widget=1" as string;
        case "campaigns":
            return "/index.php?module=Widgetize&action=iframe&disableLink=0&widget=1&moduleToWidgetize=Referrers&actionToWidgetize=getCampaigns&idSite=1&period="+SetPeriod()+"&date="+SetDate()+"&disableLink=1&widget=1" as string;
        case "Campaign URL Generator":
            return "/index.php?module=Widgetize&action=iframe&disableLink=0&widget=1&moduleToWidgetize=Referrers&actionToWidgetize=getCampaignUrlBuilder&idSite=1&period="+SetPeriod()+"&date="+SetDate()+"&disableLink=1&widget=1" as string;
        case "development over the period":
            return "/index.php?module=Widgetize&action=iframe&containerId=GoalsOverview&disableLink=0&widget=1&moduleToWidgetize=CoreHome&actionToWidgetize=renderWidgetContainer&idSite=1&period="+SetPeriod()+"&date="+SetDate()+"&disableLink=1&widget=1" as string;
        case "SEO rating":
            return "/index.php?module=Widgetize&action=iframe&disableLink=0&widget=1&moduleToWidgetize=SEO&actionToWidgetize=getRank&idSite=1&period="+SetPeriod()+"&date="+SetDate()+"&disableLink=1&widget=1" as string;
        case "Insights overview":
            return "/index.php?module=Widgetize&action=iframe&disableLink=0&widget=1&moduleToWidgetize=Insights&actionToWidgetize=getInsightsOverview&idSite=1&period="+SetPeriod()+"&date="+SetDate()+"&disableLink=1&widget=1" as string;
        case "Shift and Scatter":
            return "/index.php?module=Widgetize&action=iframe&disableLink=0&widget=1&moduleToWidgetize=Insights&actionToWidgetize=getOverallMoversAndShakers&idSite=1&period="+SetPeriod()+"&date="+SetDate()+"&disableLink=1&widget=1" as string;
    }
    return "" as string;

}


export default WidgetURLMapping;