import deMessages from './de_DE.json';
import enMessages from './en_EN.json';
import {RegisterLanguageDE, RegisterLanguageEN} from "../../../../Register/RegisterLanguage";
const deLang = {
    messages: {
        ...deMessages,
        ...RegisterLanguageDE
    },
    locale: 'de',
};
const EnLang = {
    messages: {
        ...enMessages,
        ...RegisterLanguageEN
    },
    locale: 'us',
};

const AppLocale: any = {
    de: deLang,
    us: EnLang
};

export default AppLocale;