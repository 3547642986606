import CreateMatomoIframe from "../../core/CreateMatomoIframe";
import WidgetURLMapping from "../../core/WidgetURLMapping";
import React from "react";


const ExitPages = () => {
    return(
        <>
            <CreateMatomoIframe
                headline={'Ausstiegsseiten'}
                width={"100%"}
                height={"600"}
                url={WidgetURLMapping({name:'exit pages'})}
                scrolling={"yes"}
                id={'exit1'}
            />
        </>
    )
}

export default ExitPages;