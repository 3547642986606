import React, {useState
   } from 'react';
import { useParams} from 'react-router-dom';
import {useSelector} from "react-redux";
import {notify_save} from "../../../@WUM/core/component/const/notification.service";
import {ResponseCalenderDelete} from "../API/response/calender/calender";




const CalenderDeleted = (value:any) => {
    const Store = useSelector((state: any) => state)
    const {page, deleteID} = useParams<{ page: string, deleteID: string }>();
    ResponseCalenderDelete(deleteID);
    let landingpages = '/Calender/overview/1'
    /*notify_save(Store);*/
    setTimeout(() => window.location.href = landingpages, 3000);
    return (<></>);

}
export default CalenderDeleted;



