import Button from 'react-bootstrap/Button';
import React from 'react';
import LanguageSwitcher from './LanguageSwitcher';
import '../../css/btn_back.css';
import BtnBackProps from '../../Interface/btn_Back.interface';



/**
 * Globaler zurück Button
 * @param props
 */
const BtnBack = (props:BtnBackProps) =>{

  let Lang = (props.langKey === undefined ? "common.abort" : props.langKey);
  let addClass = (props.addedClass === undefined ? "" : props.addedClass);
  return (
    <>
      <Button variant='light'  className={addClass+" floatmarleft" } onClick={() => props.history.goBack()}>{<LanguageSwitcher messageId={Lang} />}</Button>
    </>
  );
}

export default BtnBack;