import PielersserializeElementsTranslations from '../helper/translations';
import excape from '../helper/excape';

const serializeElementTranslations = (elementsdataTranslation:any,Data:any,form:any) => {

  if(elementsdataTranslation.length > 0) {
    let zahl: number = 0;
    let elementstmps: string = '';
    elementstmps += '{';
    for (let i = 0; i < elementsdataTranslation.length; i++) {

      let end = elementsdataTranslation.length - 1;

      for (let z = 0; z < elementsdataTranslation[i].length; z++) {

        let type = elementsdataTranslation[i][z].split('[');
        let typeident = type[1].split(']');
        let head: number = parseFloat(typeident[0]);
        zahl = head;
        if (i === end) {
          elementstmps += excape(PielersserializeElementsTranslations(elementsdataTranslation[i][z], form, z));
        } else {
          elementstmps += excape(PielersserializeElementsTranslations(elementsdataTranslation[i][z], form, z));
        }
        if (elementsdataTranslation[i].length - 1 === z) {
          elementstmps += '}';

          if (Data['elements'][zahl] !== undefined) {
            Data['elements'][zahl].translation = JSON.parse(elementstmps);
            elementstmps = '{';
          }
        } else {
          //elementstmps += ',';

        }
      }



    }

  }

  return Data;

};

export default serializeElementTranslations;