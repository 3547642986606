import React from 'react';
import eye from './eye';
import pencil from './pencil';
import deleteIcon from './delete';


function initIcon(icon:string) {

  switch(icon) {
    case 'eye':
      return (<>{eye()}</>)
    case 'pencil':
      return (<>{pencil()}</>)
    case 'Delete':
      return (<>{deleteIcon()}</>)
    default:
      return;
  }


}

export default initIcon;