

const sortElement = (elements:any) => {



  if(elements.hasOwnProperty('elements')) {
    for(let x=0;x < elements.elements.length; x++) {
      elements.elements[x].position = x;
    }

    return elements;
  } else {
    return elements;
  }

}

export default sortElement;