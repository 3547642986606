
import {useEffect, useState} from 'react';
import {customizer} from "../../request/Type/customizer";
import {customizerApiType} from "../../request/Type/customizerApi.interface";
import {customizerApiDetailType} from "../../request/Type/customizerApiDetail.interface";
import {checkLogin} from "../../../../../../@WUM/templates/default/Auth/service/AuthService";

/**
 * Sends response to Rest API for example
 * @memberOf customizer
 * @prop limit
 * @prop searchValue
 * @see {@link exampleApiType}
 * @author Marcel
 * Ugurcu
 * @version 0.0.1
 */
export const ResponseOverview = (limit:any,searchValue:any,state:any) => {
    const [posts, setPosts] = useState<customizerApiType[]>([]);
    const [isError, setIsError] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            await customizer.getPosts(limit,searchValue)
                .then((data:customizerApiType[]) => {
                    checkLogin(data);
                    setPosts(data);
                })
                .catch((err:any) => {
                    setIsError(true);
                });
        })()}, [limit,searchValue,state])


    return isError ? (posts ) : (posts);

}

/**
 * Sends response to Rest API for delete a example Post
 * @memberOf customizer
 * @prop id
 * @author Marcel Ugurcu
 * @version 0.0.1
 */
export const ResponseDelete = (id: string) => {
    const [isError, setIsError] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            await customizer.deletePost(id)
                .then((data:void) => {
                    return data;
                })
                .catch((err:any) => {
                    setIsError(true);
                });
        })()}, [id])

    return isError ? (isError ) : (isError);

}

/**
 * Sends response to Rest API for example Detail PAge
 * @memberOf customizer
 * @prop id
 * @see {@link exampleApiType}
 * @author Marcel Ugurcu
 * @version 0.0.1
 */
export const ResponseDetail = (id: any) => {
    const [posts, setPosts] = useState<customizerApiDetailType[]>([]);
    const [isError, setIsError] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            await customizer.getAPost(id)
                .then((data:customizerApiDetailType[]) => {
                    localStorage.setItem('css',data[0].css);
                    return setPosts(data);
                })
                .catch((err:any) => {
                    setIsError(true);
                });
        })()}, [id])

    return isError ? (posts ) : (posts);

}
export const ResponseCountry = () => {
    const [posts, setPosts] = useState<customizerApiType[]>([]);
    const [isError, setIsError] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            await customizer.getPostsCountry()
                .then((data:customizerApiType[]) => {
                    checkLogin(data);
                    setPosts(data);
                })
                .catch((err:any) => {
                    setIsError(true);
                });
        })()}, [])


    return isError ? (posts ) : (posts);

}
