import {footer} from "./tabsConfig/Footer/tabsfooter";
import {footerNavi} from "./tabsConfig/FotterNavi/tabsfooterNavi";
import {TabsHeadline} from "./tabsConfig/Headline/tabsHeadline";
import {TabsLinks} from "./tabsConfig/Links/tabsLinks";
import {TabsLogo} from "./tabsConfig/Logo/tabsLogo";
import {TabsNavbar} from "./tabsConfig/Navbar/tabsNavbar";
import {TabsModule} from "./tabsConfig/Module/tabsModule";
import {TabsTopbar} from "./tabsConfig/Topbar/tabsTopbar";
import {TabsBlog} from "./tabsConfig/Blog/tabsBlog";

export const tabs = [
    footer,
    footerNavi,
    TabsHeadline,
    TabsLinks,
    TabsLogo,
    TabsNavbar,
    TabsModule,
    TabsTopbar,
    TabsBlog
]
