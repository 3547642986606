import DynamikOverview from "../../dynamikModul/Modules/Pages/overview";
import React from "react";
import {ResponseOverview,ResponseDelete,ResponseDetail} from "./API/response/Type/customizer";
import DynamikDeleted from "../../dynamikModul/Modules/Pages/deleted";
import DynamikDetail from "../../dynamikModul/Modules/Pages/detail";
import DynamikNew from "../../dynamikModul/Modules/Pages/new";
import CardJSX from "./configuration/card";
import SearchConfig from "./configuration/SearchConfig";
import {destination, link} from "./helper/const";
import table from "./configuration/table";
import Forms from "./configuration/Form"



export const EwivPagesConfig = (value:any) =>{
    return [
        {
            path: '/ewiv/overview/:page',
            elements: <DynamikOverview value={value} table={table} destination={destination} state={'ewiv'} SearchConfig={SearchConfig(destination)}
                                       CardsConfiguration={CardJSX(link,destination)} response={ResponseOverview} buttonText={'EWIV '} />,
            roles: ['superadmin','admin']
        },
        {
            path: '/ewiv/Delete/:page/:deleteID',
            elements: <DynamikDeleted value={value} reponse={ResponseDelete} namespace={destination}  />,
            roles: ['superadmin','admin']
        },
        {
            path: '/ewiv/new/new',
            elements: <DynamikNew value={value} forms={Forms} namespace={'/'+destination+'/overview/1'} saveRoute={'company/ewiv/0/'}/>,
            roles: ['superadmin','admin']
        },
        {
            path: '/ewiv/new/:page',
            elements: <DynamikNew value={value} forms={Forms} namespace={'/'+destination+'/overview/1'} saveRoute={'company/ewiv/0/'}/>,
            roles: ['superadmin','admin']
        },
        {
            path: '/ewiv/Detail/:id',
            elements: <DynamikDetail value={value} forms={Forms} response={ResponseDetail} saveRoute={'company/ewiv/'}/>,
            roles: ['superadmin','admin']
        }
    ];
}

export const EwivMenuConfig = (value:any) =>{
    return [
        {
            name: 'EWIV',
            route: '/ewiv/overview/1',
            icon: 'corporate_fare',
            roles: ['superadmin','admin']
        },
    ];
}

export const EwivDasboard = () => [
    {
        title: 'EWIV',
        href: '/ewiv/overview/1',
        undertitle:'Backoffice',
        icon: 'corporate_fare',
        text: 'EWIV',
        roles: ['superadmin','admin']
    }
]
