import {requests} from '../../../../../../@WUM/core/RestFullApi/api';
import {ConfigApiType} from './ConfigApi.interface.';


export const config = {
  getPosts: (limit:string,search:any): Promise<ConfigApiType[]> => requests.get(`template/css/${limit}/`),
  createPost: (post: ConfigApiType): Promise<ConfigApiType> =>
    requests.post('template/css/Single/0/', post),
};



