import React, {useState} from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Button from 'react-bootstrap/Button';
import {
  useNavigate,
} from 'react-router-dom';
import home from './elements/home';
import {init2} from "../../../../core/form-serilizer/seriliaze/Formserialize";
import {configFAQ, outputFAQ} from "../configuration/mapping";
import {notify_save} from '../../../../core/component/const/notification.service';
import {error} from "../../../../core/component/const/error.services";
import {handleSubmitForm} from "../../../../core/component/const/handleSubmitForm";
import {Form} from 'react-bootstrap';
import IntlMessage from "../../../../core/component/const/IntlMessage";
import FAQNewInterface from "../../../interface/FAQNew.interface";
import {useSelector} from "react-redux";
import {faqApiType} from "../../../api/request/faqApi.interface";
import {faq} from "../../../api/request/faq";



const FAQNew = (props:FAQNewInterface) => {


  const history = useNavigate();
  const Store = useSelector((state: any) => state)
  const [currentTutorial, setCurrentTutorial] = useState<faqApiType[]>([]);
  const [validated, setValidated] = useState(false);
  /**
   * Sendet Formular Daten an die API
   * @param status
   * @param id
   */
  const updateData = (status: boolean, id: any = 0,form:any) => {

    /*
     *  Wichtig zum Rausholen der Datan aus Formular und senden
     *  Das Interface muss immer angepasst werden
     */
    let Data = init2(form);

    let reauestData: faqApiType[] &
        configFAQ
      = Object.assign({}, currentTutorial,
        outputFAQ(Data));


    /*
     *  Senden der Erhaltenen Daten an das Frontend
     */
    faq.createPostFAQ(reauestData, id)
      .then(response => {
        setCurrentTutorial({...currentTutorial});
        notify_save(Store);
        setTimeout(() => window.location.href = '/helpdesk/faq/overview/1', 3000);
      })
      .catch((e: any) => {
        error(e);
      });

  };


  return (
    <div>
      <Form  id={'form001'} noValidate validated={validated} onSubmit={(e) => handleSubmitForm(e, updateData, setValidated, 0)}>
        <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3" key={'tabindex'}>
          <Tab eventKey="home" title={'FAQ'} key={'allgemein'} id={'allgemein'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
          {home({response:'new',Store:Store})}
          </Tab>

        </Tabs>
        <div style={{backgroundColor: 'white', padding: '15px'}}>
          <Button variant='light' style={{float: 'left', marginLeft: '15px'}} onClick={() => history(-1)}><IntlMessage Store={Store} messageId="common.back"/></Button>
          <Button variant='success'
                  style={{float: 'right', marginRight: '15px'}}
                  type={'submit'}
          >
            <IntlMessage Store={Store} messageId="common.save" />
          </Button>
          <br style={{clear: 'both'}} />
        </div>
      </Form>
    </div>
  );
}


export default FAQNew;

