import React from "react";
import Site from "../../elements/behave/Site";
import EntryPages from "../../elements/behave/EntryPages";
import ExitPages from "../../elements/behave/ExitPages";
import InternalSearch from "../../elements/behave/InternalSearch";
import OutgoingReferrals from "../../elements/behave/OutgoingReferrals";
import Downloads from "../../elements/behave/Downloads";
import Events from "../../elements/behave/Events";
import Content from "../../elements/behave/Content";
import Engagements from "../../elements/behave/Engagements";
import Transitions from "../../elements/behave/Transitions";
import Performance from "../../elements/behave/Performance";
import { LanguageSwitcherValue } from "../../components/LanguageSwitcher";


const BehaveConfig = () => {

    let config = [{
        tab: {defaultActiveKey:'home',id:'uncontrolled-tab-example',className:'mb-3 tabMatomo',key:'tabindex'},
        tabs:[
            {eventKey:'home',title:LanguageSwitcherValue('BehaveConfig.home.title'),key:'home',id:'home',class:'tabsMatomo',content:<Site />},
            {eventKey:'entry_pages',title:LanguageSwitcherValue('BehaveConfig.entry_pages.title'),key:'entry_pages',id:'entry_pages',class:'tabsMatomo',content:<EntryPages />},
            {eventKey:'exit_pages',title:LanguageSwitcherValue('BehaveConfig.exit_pages.title'),key:'exit_pages',id:'exit_pages',class:'tabsMatomo',content:<ExitPages />},
            {eventKey:'page_title',title:LanguageSwitcherValue('BehaveConfig.page_title.title'),key:'page_title',id:'page_title',class:'tabsMatomo',content:<ExitPages />},
            {eventKey:'internal_search',title:LanguageSwitcherValue('BehaveConfig.internal_search.title'),key:'internal_search',id:'internal_search',class:'tabsMatomo',content:<InternalSearch />},
            {eventKey:'Outgoing_referrals',title:LanguageSwitcherValue('BehaveConfig.Outgoing_referrals.title'),key:'Outgoing_referrals',id:'Outgoing_referrals',class:'tabsMatomo',content:<OutgoingReferrals />},
            {eventKey:'Downloads',title:LanguageSwitcherValue('BehaveConfig.Downloads.title'),key:'Downloads',id:'Downloads',class:'tabsMatomo',content:<Downloads />},
            {eventKey:'events',title:LanguageSwitcherValue('BehaveConfig.events.title'),key:'events',id:'events',class:'tabsMatomo',content:<Events />},
            {eventKey:'content',title:LanguageSwitcherValue('BehaveConfig.content.title'),key:'content',id:'content',class:'tabsMatomo',content:<Content />},
            {eventKey:'engagements',title:LanguageSwitcherValue('BehaveConfig.engagements.title'),key:'engagements',id:'engagements',class:'tabsMatomo',content:<Engagements />},
            {eventKey:'transitions',title:LanguageSwitcherValue('BehaveConfig.transitions.title'),key:'transitions',id:'transitions',class:'tabsMatomo',content:<Transitions />},
            {eventKey:'performance',title:LanguageSwitcherValue('BehaveConfig.performance.title'),key:'performance',id:'performance',class:'tabsMatomo',content:<Performance />},
        ]
    }]


    return config;

}

export default BehaveConfig;
