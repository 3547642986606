import getToken from '../modules/components/Token';
import ApiURL from '../modules/components/ApiURL';
import ReactDOM from 'react-dom';
import React from 'react';
import GetAvatarView from '../modules/components/getAvatarView';
import {notify_del_element, notify_save} from "../../../@WUM/core/component/const/notification.service";
export const saveTech = (e: any, issueID: any,Store:any) => {
  e.preventDefault();
  let data = e.currentTarget.value
  let setMethod:string = e.target[e.target.selectedIndex].getAttribute('data-param') as string
  var myHeaders = new Headers();

  e.target.value = ''
  myHeaders.append("Authorization", 'Bearer ' + getToken());
  var formdata = new FormData();
  formdata.append("developer", data);
  var raw = JSON.stringify({
    "developer": formdata.get('developer'),
    "issue_id": issueID
  });
  let requestOptions = {
    method: setMethod,
    headers: myHeaders,
    body: raw
  };

  fetch(ApiURL() + "developer-on-issue/"+(setMethod === 'Post' ? "update" : "delete" )+"/" + issueID + "/", requestOptions)
    .then(response => response.text())
    .then(result => (setMethod === 'Post' ? newComment(result) : DeleteLabel(data)))
    .catch(error => console.log('error', error));

  let optionsfield = (document.getElementById('optionTech'+data.replaceAll(' ','_')) as HTMLOptionElement)

  if(setMethod === 'Post') {
    optionsfield.setAttribute('data-param','Delete');
    optionsfield.innerHTML = optionsfield.innerHTML.replace(optionsfield.innerHTML,'☑ '+optionsfield.innerHTML)
  } else {
    optionsfield.setAttribute('data-param','Post');
    optionsfield.innerHTML = optionsfield.innerHTML.replace('☑','')
  }

  const DeleteLabel = (data:any) => {

    let element = document.getElementById('Tech'+data.replaceAll(' ','_')) as HTMLDivElement;
    element.innerHTML = ''
    element.removeAttribute('class');
    element.removeAttribute('style');

    notify_del_element(Store);

  }

  const createComment = (data:any) => {

    let Result = JSON.parse(data.item);

    if (Result !== '') {
      let developer = Result.developer;

      return (
        <>
          <div id={'Tech'+developer.replaceAll(' ','_')}>
          <GetAvatarView name={developer} color={'blue'} class={'ticketAvatarFloatLeft'}  />
          <p className={'pAvatar'}>{developer}</p>
          </div>
        </>
      )
    }
  }

  const newComment = (result:any) => {
    let newElement = createComment({'item': result});
    let temp = document.createElement('span');
    // @ts-ignore
    ReactDOM.render(newElement, temp);
    let ele = document.getElementById('TechHolder');
    if (ele !== null) {
      ele.appendChild(temp)
      notify_save(Store);
    }
  }
}
