import React, {useState} from "react";
import Article from "../article/article";
import ReactDOM from "react-dom";
import {Response} from "../../../api/response/faq";


const FAQ = (props:{Store:any}) => {

   const [searchValue, setSearchValue] = useState('');

   let response = Response(searchValue)

    const makeFAQ = (response:any) =>{

        let faqHolder = [];
        let Holders: any = [];
        let tmp = [];
        if(response !== undefined && response !== null && response.length > 0) {

            for (let t = 0; t < response.length; t++) {
                tmp[response[t].id] = response[t]
            }


            for (let x = 0; x < tmp.length; x++) {
                if (tmp[x] !== undefined && tmp[x] !== null) {
                    if (tmp[x].parent !== null && tmp[x].hasOwnProperty('parent')) {

                        if(x < tmp[x].parent) {
                            tmp[tmp[x].parent].child = [];
                            tmp[tmp[x].parent].child.push(tmp[x])
                        } else {
                            if(!tmp[x].hasOwnProperty('child')) {
                                Holders[tmp[x].parent].child = [];
                                Holders[tmp[x].parent].child.push(tmp[x])
                            }
                        }

                    } else {
                        Holders[tmp[x].id] = tmp[x]
                    }
                }
            }
        }

        let Hold = Holders.sort()

        let Holder: any = [];

        for(let h=0;h< Hold.length; h++) {

            if(Hold[h] !== undefined)  {
                Holder.push(Hold[h])
            }
        }




        if(Holder !== undefined && Holder !== null && Holder.length > 0) {
            for (let x = 0; x < Holder.length; x++) {

                let underpoint = [];
                let faqHolders = [];
                let underpointChild = [];
                if(Holder[x].hasOwnProperty('posts')) {
                    for (let p = 0; p < Holder[x].posts.length; p++) {
                        underpoint.push(
                            <>
                                <div
                                    id={Holder[x].posts[p].id + '_' + Holder[x].posts[p].title.toLowerCase().replaceAll(' ', '')}
                                    className="help-desk__faq-items">
                                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                    <a href="#" className="help-desk__article-title"
                                       onClick={() => showArticle(Holder[x].posts[p].id)}>{Holder[x].posts[p].title}</a>
                                </div>
                            </>
                        )
                    }
                }

                if(Holder[x].hasOwnProperty('child')) {
                    for(let c=0;c<Holder[x].child.length;c++){
                        for (let pc = 0; pc < Holder[x].child[c].posts.length; pc++) {
                            underpointChild.push(
                                <>
                                    <div
                                        id={Holder[x].child[c].posts[pc].id + '_' + Holder[x].child[c].posts[pc].title.toLowerCase().replaceAll(' ', '')}
                                        className="help-desk__faq-items">
                                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                        <a href="#" className="help-desk__article-title"
                                           onClick={() => showArticle(Holder[x].child[c].posts[pc].id)}>{Holder[x].child[c].posts[pc].title}</a>
                                    </div>
                                </>
                            )
                        }
                        faqHolders.push(<>
                            <div id={Holder[x].child[c].id + '_' + Holder[x].child[c].name.toLowerCase().replaceAll(' ', '')}
                                 className="help-desk__category">
                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                <a href="#" className="help-desk__category-title"
                                   onClick={() => showFaq(Holder[x].child[c].id + '_' + Holder[x].child[c].name.toLowerCase().replaceAll(' ', ''))}>
                                    <svg viewBox="49 529 5 5"
                                         id={Holder[x].child[c].id + '_' + Holder[x].child[c].name.toLowerCase().replaceAll(' ', '') + '_svg'}>
                                        <path
                                            d="M51,531 L49,531 L49,532 L51,532 L51,534 L52,534 L52,532 L54,532 L54,531 L52,531 L52,529 L51,529 L51,531 Z"/>
                                    </svg>
                                    {Holder[x].child[c].name}</a>
                                {underpointChild}
                            </div>
                            <div id={'article'}/>
                        </>)

                    }


                }

                if(Holder[x].hasOwnProperty('name')) {
                    faqHolder.push(<>
                            <div id={Holder[x].id + '_' + Holder[x].name.toLowerCase().replaceAll(' ', '')}
                                 className="help-desk__category">
                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                <a href="#" className="help-desk__category-title"
                                   onClick={() => showFaq(Holder[x].id + '_' + Holder[x].name.toLowerCase().replaceAll(' ', ''))}>
                                    <svg viewBox="49 529 5 5"
                                         id={Holder[x].id + '_' + Holder[x].name.toLowerCase().replaceAll(' ', '') + '_svg'}>
                                        <path
                                            d="M51,531 L49,531 L49,532 L51,532 L51,534 L52,534 L52,532 L54,532 L54,531 L52,531 L52,529 L51,529 L51,531 Z"/>
                                    </svg>
                                    {Holder[x].name}</a>
                                <div
                                    className="help-desk__faq-items">
                                {underpoint}
                                {faqHolders}
                                </div>
                            </div>
                            <div id={'article'}/>
                        </>
                    )
                }else {
                    faqHolder.push(
                        <>
                            <div
                                id={Holder[x].id + '_' + Holder[x].title.toLowerCase().replaceAll(' ', '')}
                                className="help-desk__faq-items">
                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                <a href="#" className="help-desk__article-title"
                                   onClick={() => showArticle(Holder[x].id)}>{Holder[x].title}</a>
                            </div>
                            <div id={'article'}/>
                        </>
                    )
                }




            }
        }



        return faqHolder

    }

    const showFaq = (id:any) =>{
        let element = document.getElementById(id) as HTMLDivElement;

        let svg = document.getElementById(id+'_svg');

        let i = 0;
        for(let x=0;x<element.classList.length;x++) {
            if(element.classList[x] === 'expanded') {
                i++;
            }
        }

        if(i === 0) {
            element.classList.add("expanded");
            if(svg !== null) {
                svg.innerHTML = '<rect x="0" y="0" width="5" height="1"></rect>';
                svg.removeAttribute('viewBox')
                svg.setAttribute('viewBox','0 0 5 1')
            }

        } else {
            element.classList.remove("expanded");
            if(svg !== null) {
                svg.innerHTML = ' <path d="M51,531 L49,531 L49,532 L51,532 L51,534 L52,534 L52,532 L54,532 L54,531 L52,531 L52,529 L51,529 L51,531 Z"/>';
                svg.removeAttribute('viewBox')
                svg.setAttribute('viewBox','49 529 5 5')
            }
        }
    }


    const showArticle = (id:any) => {
        let temp = document.createElement('div');
        ReactDOM.render(<Article id={id} Store={props.Store} />, temp);
        temp.id = 'articleHolder';
        let element =  document.getElementById('article') as HTMLDivElement;
        element.innerHTML = '';
        element.appendChild(temp)
    }

    return (
        <>
            <div id={'faq'} className="help-desk__faq">
                <div className="help-desk__search"><label htmlFor="help-desk__faq-search"
                                                          className="help-desk__label">Search for
                    articles</label><input type="text" id="help-desk__faq-search"
                                           className="help-desk__input"
                                           placeholder="Search for articles"
                                           onChange={(e) => setSearchValue(e.target.value)}
                                           aria-describedby="help-desk__faq-search"/></div>
                <div className="help-desk__faq-content scrollable">
                    {makeFAQ(response)}
                </div>
            </div>
        </>
    )

}

export default FAQ;




export const destroyArticle = () => {
    let element = document.getElementById('articleHolder') as HTMLDivElement;
    // @ts-ignore
    element.parentNode.removeChild(element);
    localStorage.removeItem('articleHelpDesk')

}
