import Button from "react-bootstrap/Button";
import IntlMessage from "../../../../@WUM/core/component/const/IntlMessage";
import React from "react";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {Form} from 'react-bootstrap';
import {ApiURL} from "../../../../@WUM/core/component/const/ApiURL";
// @ts-ignore
import {Notification} from "create-notifications/notfication";

const DynamikNew = (props:{value:any,forms?:any,saveRoute?:any,namespace?:any,addedForm?:any}) => {
    const history = useNavigate();
    const Store = useSelector((state: any) => state)
    const addedF = (props.addedForm !== undefined && props.addedForm !== null ? props.addedForm() : '');
    const send = (e:any) => {
        e.preventDefault();
        let data = new FormData(e.target);
        let value = Object.fromEntries(data.entries());
        let raw = JSON.stringify(value);
        let root = ApiURL();
        let requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token') as string)
            },
            body: raw,
        };
        fetch(root+""+props.saveRoute, requestOptions)
            .then(response => response.text())
            .then(result => afterSend('success'))
            .catch(error => afterSend('error'));
    }

    const afterSend = (state:any) => {
        if(state === 'success') {
            Notification({rewrite:true,landingpages:props.namespace,type:'success', mode:'classic', title:{text:'Success'}, text:{text: 'Saved successfully'}, insert:'center right',duration:4})
        } else if(state === 'error'){
            Notification({rewrite:true,landingpages:props.namespace,type:'danger', mode:'classic', title:{text:'Error'}, text:{text: 'Sorry, an error has occurred'}, insert:'center right',duration:4})
        }
    }

    return (
        <>
            <div style={{backgroundColor: 'white', padding: '15px'}}>
                <Form id={'form001'} onSubmit={(e)=> send(e)}>
                    {(props.addedForm !== undefined && props.addedForm !== null ? props.forms([],'new',addedF) : props.forms())}
                    <div style={{backgroundColor: 'white', padding: '15px'}}>
                        <Button variant='light' style={{float: 'left', marginLeft: '15px'}} onClick={() => history(-1)}>
                            <IntlMessage Store={Store} messageId="common.back"/></Button>
                        <Button variant='success'
                                style={{float: 'right', marginRight: '15px'}}
                                type={'submit'}
                                id={'sendButton'}
                        >
                            <IntlMessage Store={Store} messageId="common.save" />
                        </Button>
                        <br style={{clear: 'both'}} />
                    </div>
                </Form>
            </div>
        </>
    )
}

export default DynamikNew;

