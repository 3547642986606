
const createFields = (data: any, template: any) => {

    let Holder = [];

    for (let x = 0; x < data.length; x++) {
        Holder.push(
            {
                "css": fields(data[x].identifier, data[x].type),
                "templateName": template,
                "key": data[x].field
            }
        )
    }


    return JSON.stringify(Holder);

}

export default createFields;

const fields = (id: any, type: any) => {


    if (type === 'select') {
        return ((document.getElementById(id) as HTMLSelectElement) !== undefined && (document.getElementById(id) as HTMLSelectElement) !== null ? (document.getElementById(id) as HTMLSelectElement).value : null)
    } else if (type === 'input') {
        return ((document.getElementById(id) as HTMLInputElement) !== undefined && (document.getElementById(id) as HTMLInputElement) !== null ? (document.getElementById(id) as HTMLInputElement)?.value : null)
    }
}



