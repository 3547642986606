import {GeneralFields} from "./tabsConfig/General/GernalFields";
import {FooterFields} from "./tabsConfig/Footer/FooterFields";
import {FooterNaviFields} from "./tabsConfig/FotterNavi/FooterNaviFields";
import {HeaderFields} from "./tabsConfig/Headline/Tabs/Header/HeaderFields";
import {GeneralHFields} from "./tabsConfig/Headline/Tabs/GeneralH/GeneralHFields";
import {H1Fields} from "./tabsConfig/Headline/Tabs/H1/H1Fields";
import {H2Fields} from "./tabsConfig/Headline/Tabs/H2/H2Fields";
import {H3Fields} from "./tabsConfig/Headline/Tabs/H3/H3Fields";
import {H4Fields} from "./tabsConfig/Headline/Tabs/H4/H4Fields";
import {H5Fields} from "./tabsConfig/Headline/Tabs/H5/H5Fields";
import {H6Fields} from "./tabsConfig/Headline/Tabs/H6/H6Fields";
import {LinksFields} from "./tabsConfig/Links/LinksFields";
import {LogoFields} from "./tabsConfig/Logo/LogoFields";
import {NavbarFields} from "./tabsConfig/Navbar/NavbarFields";
import {SliderFields} from "./tabsConfig/Module/Slider/SliderFields";
import {m22Fields} from "./tabsConfig/Module/m22/m22Fields";
import {m02Fields} from "./tabsConfig/Module/m02/m02Fields";
import {HeadlineFields} from "./tabsConfig/Module/Headline/HeadlineFields";
import {TopbarFields} from "./tabsConfig/Topbar/TopbarFields";
import {BlogFields} from "./tabsConfig/Blog/BlogFields";

export const FieldsMap = [
    //General
    ...GeneralFields,
    //Footer
    ...FooterFields,
    //FooterNavi
    ...FooterNaviFields,
    //HeadlineHeader
    ...HeaderFields,
    //Headline GeneralH
    ...GeneralHFields,
    //Headline H1
    ...H1Fields,
    //Headline H2
    ...H2Fields,
    //Headline H3
    ...H3Fields,
    //Headline H4
    ...H4Fields,
    //Headline H5
    ...H5Fields,
    //Headline H6
    ...H6Fields,
    //Links
    ...LinksFields,
    //Logo
    ...LogoFields,
    //Navbar
    ...NavbarFields,
    //Module Slider
    ...SliderFields,
    //Module m22 Text with Image
    ...m22Fields,
    //Module m02 Three Steps Module
    ...m02Fields,
    //Module Headline
    ...HeadlineFields,
    //Topbar
    ...TopbarFields,
    //Blog
    ...BlogFields
]
