export const show = (none:any,block:any) => {
    (document.getElementById(none) as HTMLDivElement).style.display = 'none';
    (document.getElementById(block) as HTMLDivElement).style.display = 'block';
}

export const close = (block:any,none:any) => {
    (document.getElementById(block) as HTMLDivElement).style.display = 'block';

    (document.getElementById(none) as HTMLDivElement).style.display = 'none';
}

export const closeandChange = (block:any,none:any) => {
    (document.getElementById(block) as HTMLDivElement).style.display = 'block';
    setTitel(block,none);

    (document.getElementById(none) as HTMLDivElement).style.display = 'none';
}

export const setTitel = (block:any,none:any) => {
    (document.getElementById(block+'titel') as HTMLDivElement).innerHTML = (document.getElementById(none+'input') as HTMLInputElement).value;
}

export const deletedMenu = (id:any,settypes:any) => {
    const element = document.getElementById(id) as HTMLDivElement;
    element.remove();
    settypes();
}

export const deleted = (id:any,menuButton:any,settypes:any) => {
    const element = document.getElementById(id) as HTMLDivElement;
    element.remove();
    settypes();
    /*let Childs2 = $(document.getElementById(menuButton) as HTMLDivElement).find(".list-group-item")
    for(let c=0;c<Childs2.length;c++){
        let Childs =  $(Childs2[c] as HTMLDivElement).find("input")

        for(let i=0;i<Childs.length;i++) {
            let Name = (Childs[i] as HTMLDivElement).getAttribute('name') as string;

            let Identifier = Name.split(']');


            let ListID = '[' + c

            let NewIdentifier = Name.replace(Identifier[2], ListID);

            (Childs[i] as HTMLDivElement).setAttribute('name', NewIdentifier)
        }
    }*/
}
