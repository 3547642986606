
const Tutorials = () => {

    return(
        <>
            <div id={'tutorials'} className="help-desk__tutorials d-none"><p className="help-desk__tutorials-help">Learn how to use the app by
                playing an audio or text-based tutorial that will walk you through our system.</p>
                <button className="help-desk__tutorial" title="Using the Dashboard">
                    <div className="help-desk__tutorial-thumbnail"><img  alt={'Using the Dashboard'} src="https://i.imgur.com/ItIdshs.png" />
                        <svg className="help-desk__tutorial-play" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                             fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                             strokeLinejoin="round">
                            <polygon points="5 3 19 12 5 21 5 3"/>
                        </svg></div>
                    <p>Using the Dashboard</p></button>
                <button className="help-desk__tutorial" title="Upload to the gallery">
                    <div className="help-desk__tutorial-thumbnail"><img  alt={'Upload to the gallery'} src="https://i.imgur.com/T8DZKF7.png" />
                        <svg className="help-desk__tutorial-play" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                             fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                             strokeLinejoin="round">
                            <polygon points="5 3 19 12 5 21 5 3"/>
                        </svg></div>
                    <p>Upload to the gallery</p></button>
                <button className="help-desk__tutorial" title="Embedding video">
                    <div className="help-desk__tutorial-thumbnail"><img  alt={'Embedding video'} src="https://i.imgur.com/OUm6qKz.png" />
                        <svg className="help-desk__tutorial-play" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                             fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                             strokeLinejoin="round">
                            <polygon points="5 3 19 12 5 21 5 3"/>
                        </svg></div>
                    <p>Embedding video</p></button>
                <button className="help-desk__tutorial" title="Learn how to chat">
                    <div className="help-desk__tutorial-thumbnail"><img alt={'Learn how to chat'} src="https://i.imgur.com/Osp9Xjq.png" />
                        <svg className="help-desk__tutorial-play" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                             fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                             strokeLinejoin="round">
                            <polygon points="5 3 19 12 5 21 5 3"/>
                        </svg></div>
                    <p>Learn how to chat</p></button>
            </div>
        </>
    )
}

export default Tutorials;
