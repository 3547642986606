import CreateMatomoIframe from "../../core/CreateMatomoIframe";
import WidgetURLMapping from "../../core/WidgetURLMapping";
import React from "react";
import IntlMessage from "../../../../../@WUM/core/component/const/IntlMessage";


const VisitorHome = (props:{Store:any}) => {

    return (
        <>
            <CreateMatomoIframe
                headline={<IntlMessage Store={props.Store} messageId='Visitor.headline.Graph_of_recent_visits' />}
                width={"100%"}
                height={"300"}
                url={WidgetURLMapping({name:'Graph of recent visits'})}
                scrolling={"no"}
                id={'vh1'}
                Store={props.Store}
            />
            <CreateMatomoIframe
                headline={<IntlMessage Store={props.Store} messageId='Visitor.headline.visitor_overview' />}
                width={"100%"}
                height={"500"}
                url={WidgetURLMapping({name:'Visitor overview'})}
                scrolling={"no"}
                id={'vh2'}
                Store={props.Store}
            />
        </>
    )

}

export default VisitorHome;
