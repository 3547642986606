import CreateMatomoIframe from "../../core/CreateMatomoIframe";
import WidgetURLMapping from "../../core/WidgetURLMapping";
import React from "react";
import {Row} from "react-bootstrap";
import Col from "react-bootstrap/Col";

const SearchEnginesAndSearchTerms = () => {
    return(
        <>
            <Row>
                <Col><CreateMatomoIframe
                    headline={'Suchbegriffe'}
                    width={"100%"}
                    height={"300"}
                    url={WidgetURLMapping({name:'search terms'})}
                    scrolling={"yes"}
                    id={'SeasT1'}
                /></Col>
                <Col><CreateMatomoIframe
                    headline={'Suchmaschinen'}
                    width={"100%"}
                    height={"300"}
                    url={WidgetURLMapping({name:'search engines'})}
                    scrolling={"yes"}
                    id={'SeasT2'}
                /></Col>
            </Row>

        </>
    )
}

export default SearchEnginesAndSearchTerms;