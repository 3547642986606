import {notify_save} from "./notification.service";
import {errorFetch} from "./error.services";
import {configAxios, httpClient} from "../../RestFullApi/api";



export const saveDataIndividualPut = (link: string, data: any) => {
    /* console.log('url, payload', url, payload);*/
    return new Promise((resolve, reject) => {
        httpClient
            .put(link,data,configAxios)
            .then(({data}) => {
                notify_save();
                return resolve(data);
            })
            .catch(function (error: any) {
                errorFetch(error);
                return reject(error);
            });
        return Promise.resolve();
    });
};

export const saveDataIndividualGet = (link: string) => {
    /* console.log('url, payload', url, payload);*/
    return new Promise((resolve, reject) => {
        httpClient
            .get(link,configAxios)
            .then(({data}) => {
                return resolve(data);
            })
            .catch(function (error: any) {
                errorFetch(error);
                return reject(error);
            });
        return Promise.resolve();
    });
};