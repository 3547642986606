const ApiURL = () => {
  let api_URL;
  if (process.env.REACT_APP_MODE === 'LOCAL') {
    api_URL = process.env.REACT_APP_API_URL_LOCAL;
  } else if (process.env.REACT_APP_MODE === 'DEVELOPMENT') {
    api_URL = process.env.REACT_APP_API_URL_DEVELOPMENT;
  } else if (process.env.REACT_APP_MODE === 'LIVE') {
    api_URL = process.env.REACT_APP_API_URL_LIVE;
  } else if (process.env.REACT_APP_MODE === 'KLIMABAUHELD') {
    api_URL = process.env.REACT_APP_API_URL_KLIMABAUHELD;
  }

  return api_URL;
}

export default ApiURL