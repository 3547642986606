import CreateMatomoIframe from "../../core/CreateMatomoIframe";
import WidgetURLMapping from "../../core/WidgetURLMapping";
import React from "react";

const SEO = () =>{
    return(
        <>
            <CreateMatomoIframe
                headline={'SEO Bewertung'}
                width={"100%"}
                height={"600"}
                url={WidgetURLMapping({name:'SEO rating'})}
                scrolling={"yes"}
                id={'seoB1'}
            />
        </>
    )
}

export default SEO;