import MergeTwoObjecttoArray from "../../../../@WUM/core/component/const/MergeTwoObjecttoArray";
import {cms} from "../../index";
import {product} from "../../../shop/products/modules/Product";
import {settings} from "../../../settings";
import {monitoring} from "../../../monitoring/modules";
import {matomo} from "../../../matomo/modules";
import {itSupport} from "../../../ticketsystem/modules";
import {ShowDialog} from "../../../../@WUM/core/sidebar/sidebar";
import components from "../../../../Register/RegisterComponents";
import {ResponseDashboard} from "../../../../API/response/dynmaikModuls";
import SecureCheck from "../../../../@WUM/core/RoleSecury";

const configruation = (Store: any) => {

    let config: any = [];
    let adminIndividual:any [];
    config = MergeTwoObjecttoArray(config, cms())

    switch (process.env.REACT_APP_MODE_PLATTFORM) {
        case 'ProductCatalog':
        case 'SHOP':
        case 'SHOPwithMatomo':
        case 'ProductCatalogwithMatomo':
            config = MergeTwoObjecttoArray(config, product())

    }
    config = MergeTwoObjecttoArray(config, settings(Store))
    config = MergeTwoObjecttoArray(config, monitoring())
    switch (process.env.REACT_APP_MODE_PLATTFORM) {
        case 'CMSwithMatomo':
        case 'SHOPwithMatomo':
        case 'ProductCatalogwithMatomo':
            config = MergeTwoObjecttoArray(config, matomo())

    }

    config = MergeTwoObjecttoArray(config, itSupport())
    config = MergeTwoObjecttoArray(config, galerie(Store))


    adminIndividual = []
    if(process.env.REACT_APP_DB_MODULS !== undefined  && Store.dynamikModuls.Dashboard.length  > 0 && !Store.dynamikModuls.Dashboard[0].hasOwnProperty('msg')) {
        for (let x = 0; x < Store.dynamikModuls.Dashboard.length; x++) {
            let search = Store.dynamikModuls.Dashboard[x].namespace
            // @ts-ignore
            adminIndividual = MergeTwoObjecttoArray(adminIndividual, components[search]())
        }
    }

    let newConfig = [];
    let newAdminIndividual = [];
    for(let x =0;x<config.length; x++) {
        if(SecureCheck(config[x].roles)) {
            newConfig.push(config[x])
        }
    }

    for(let x =0;x<adminIndividual.length; x++) {
        if(SecureCheck(adminIndividual[x].roles)) {
            newAdminIndividual.push(adminIndividual[x])
        }
    }

    if (process.env.REACT_APP_DB_MODULS === undefined) {
        return newConfig;
    } else {
        if (localStorage.getItem('userRole') === 'superadmin') {
            return [...newConfig,...newAdminIndividual];
        } else {
            return newAdminIndividual;
        }
    }
}

export default configruation

const galerie = (Store: any) => [
    {
        title: 'menu.image.galery',
        href: '',
        onClick: ShowDialog,
        undertitle: 'Media',
        icon: 'collections',
        text: 'media.galerie.tooltip.content',
        roles: ['superadmin','admin']
    }
]
