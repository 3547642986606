import React from 'react';
import PielersForm from '../../index';
import {base, basesettings} from './base';
import IntlMessage from "../../../component/const/IntlMessage";
import CopyElements from "../../../component/const/CopyElements";
import Col from "react-bootstrap/Col";
import WebBuilderElementsHead from "../../../component/const/WebBuilderElementsHead";
import Row from "react-bootstrap/Row";
import Box from '@mui/material/Box';

function containerendWebBuilder(data: any, element: any, i: any, newElement: any = false, newelementIndex: any = 0, modalShow: boolean,
                 setModalShow: (value: (((prevState: boolean) => boolean) | boolean)) => void, modalData: any[],
                 setModalData: (value: (((prevState: any[]) => any[]) | any[])) => void,
                 content:any = [],setElements:any = [],Store:any,overStart?:any,col?:any,row?:any) {


  let rows = (newElement === false ? element.row : row);
  let Cols = (newElement === false ? element.col : col);
  const index = (newElement === false ? i : (newelementIndex+1))

  let fix = (newElement === false ? i + 1 : newelementIndex);

  let appendFix = 'Injectcontainerend'+fix


  const configuration = [
    {
      'formType': 'input',
      'name': 'elements[' + index + '][row]',
      'type': 'hidden',
      'value': rows,
    },
    {
      'formType': 'input',
      'name': 'elements[' + index + '][col]',
      'type': 'hidden',
      'value': Cols,
    },
    {
      'formType': 'input',
      'name': 'elements[' + index + '][template]',
      'type': 'hidden',
      'value': 'Elements/container_end.html',
    }
  ];
  let newConfiguration;

  if(newElement === false) {
    newConfiguration = configuration.concat(base(element,index,newElement,index,overStart));
  } else {
    newConfiguration = configuration.concat(base(element,index,newElement,index,overStart,(newElement === false && element.appendto !== null && element.appendto !== undefined && element.appendto !== '' ? element.appendto : appendFix)));
  }

  let Status;
  if(element.visible === 1) {
    Status = 'elementActive';
  } if(element.visible === 0){
    Status = 'elementDeactive';
  } else if(newElement === true) {
    Status = 'elementActive';
  }
  return (
    <>
    <Box id={'containerend'+index}  data-appendcontainer={(newElement === false && element.appendto !== null && element.appendto !== undefined && element.appendto !== '' ? element.appendto : appendFix)}>

        
      <Row   className={'boxShadowCard card ElementBorder'}   style={{paddingBottom: '15px',paddingTop:'15px',width: '100%',margin:'0px 0px 10px 0px'}}>
        <div id={'Teaser'}>Row Start</div>
        <Col>containerend</Col>
          <Col className={'accordion-body element-body'}>
            {PielersForm(newConfiguration)}

          </Col>
        <div id={'settingstext'+index+'containerend'} className={'settingsWebBuilderModul '} style={{display:'none'}}>
          {PielersForm(basesettings(element,index,newElement,newelementIndex,Store))}
        </div>
          {(newElement === false ? CopyElements(element.id,'containerend',Store) : <></>)}

        </Row>
        </Box>
    </>
  )


}

export default containerendWebBuilder;
