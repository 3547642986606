import React, {useState} from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Button from 'react-bootstrap/Button';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import {
  /*
   */
  ResponseDataSheets,
  ResponseDimension,
  ResponseAllShipingTypes,
  ResponseDetail,
  ResponseVariants,
  ResponseArcticle, ResponseImages, ResponseAllUnit,
} from '../../../API/response/product/Product';
import Home from './elements/home';
import Content from './elements/content';
import {isEmptyArray} from 'formik';
import Spinner from 'react-bootstrap/Spinner';
import {ProductVariantsDetailApiType} from '../../../API/request/Product/ProductVariantsApiDetail.interface';
import {ShippingApiType} from '../../../API/request/Product/ShippingApi.interface';
import {DimensionApiType} from '../../../API/request/Product/DimensionApi.interface';
import {DataSheetApiType} from '../../../API/request/Product/DataSheetApi.interface';
import {DatenblattDetailApiType} from '../../../API/request/Product/DatenblattApi.interface';
import {ImagesType} from '../../../API/request/Product/ImagesApi.interface';
import {allUnitApiType} from '../../../API/request/unit/allUnitApi.interface';
import IntlMessage from '../../../../../../@WUM/core/component/const/IntlMessage';
import {DummyUser} from "../../../../../../@WUM/core/dummy/user";
import {ResponseallTaxon} from "../../../../../CMS/Blog/API/response/product/Product";
import {useSelector} from "react-redux";
import {allTaxonApiType} from "../../../API/request/Product/allTacxonApi.interface";




const ProductDetail = (value: any) => {


  const user = DummyUser();
  const forbidden = (search:any) => {
    if (user !== null) {
      for (let u = 0; u < user.role.length; u++) {
        if (user.role[u] === search) {
          return true;
        } else {

        }
      }
    }
  }
  const {id} = useParams<{id: string}>();
  const Arcticle = ResponseArcticle(id);
  const [loadVariantsValue, saveLoadVariantsValue] = useState<ProductVariantsDetailApiType[]>([])
  const [loadVariants, saveLoadVariants] = useState(false);
  const variants = ResponseVariants(id,saveLoadVariants,saveLoadVariantsValue);
  const Shipping =  ResponseAllShipingTypes();
  const Unit =  ResponseAllUnit();
  const debug = (Shipping: ShippingApiType[], variants: ProductVariantsDetailApiType[] | boolean,
                 Dimension: DimensionApiType[] | boolean, Arcticle: DataSheetApiType[] | boolean,
                 Image: ImagesType[] | boolean, Unit: allUnitApiType[], taxon: allTaxonApiType[] | boolean) => {
    return;
  }
  const Dimension = ResponseDimension(id);

  const Image = ResponseImages(id);
  //const DataSheets = ResponseDataSheets(id);

  const response2 = ResponseDetail(id);
  const taxon = ResponseallTaxon();


  debug(Shipping,variants,Dimension,Arcticle,Image,Unit,taxon)

  const history = useNavigate();
  const Store = useSelector((state: any) => state)




  const [ImgValue, setImgValue] = useState('');
  const [modalImgShow, setModalImgShow] = React.useState(false);

  const [materialsValue, setMaterialsValue] = useState((response2 !== true ? response2[0]?.translation.allergy : ''));
  const [DimensionsValue, setDimensionsValue] = useState((response2 !== true ? response2[0]?.translation.nutrient : null));
  const [modalMaterialsShow, setModalMaterialsShow] = React.useState(false);
  const [modalDimensionsShow, setModalDimensionsShow] = React.useState(false);
  const [modalDataSheetShow, setModalDataSheet] = React.useState(false);
  const [getDataSheetValue, saveGetDataSheetValue] = useState('');
  const [modalarcticleShow, setModalarcticle] = React.useState(false);
  const [arcticleValue, savearcticleValue] = useState(false);
  const [allTaxonValue, saveallTaxonValue] = useState(false);
  const [setattribute, saveattribute] = React.useState(false);
  const [modalvariantsShow, setModalvariantsShow] = React.useState(false);
  const [variantsValue, savevariantsValue] = useState('');
  const [variantstype, savevariantstype] = useState();
  if(isEmptyArray(response2) === true) {
    return (
      <><Spinner animation="border" role="status">
        <span className="visually-hidden"><IntlMessage Store={Store} messageId="common.loading" /></span>
      </Spinner></>
    )
  } else {
    if(user !== null && user !== undefined && forbidden('merchant') === true) {
      if (user.merchant_id !== null) {
        if(response2 !== true && parseInt(user?.merchant_id) !==  response2[0].merchant_id){
          return (<><IntlMessage Store={Store} messageId="product.forbidden" /></>)
        }
      }
    }


    if(isEmptyArray(variants) === true) {
      return (
        <><Spinner animation="border" role="status">
          <span className="visually-hidden"><IntlMessage Store={Store} messageId="common.loading" />.</span>
        </Spinner></>
      )
    } else {
      if(!localStorage.getItem('Shipping') && !localStorage.getItem('allTaxon') && !localStorage.getItem('arcticle')
      && !localStorage.getItem('Dimensions') && !localStorage.getItem('datasheet')) {
        return (
          <><Spinner animation="border" role="status">
            <span className="visually-hidden"><IntlMessage Store={Store} messageId="common.loading" /></span>
          </Spinner></>
        )
      } else {
        return (
          <div>
            <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3" key={'tabindex'}>
              <Tab eventKey="home" title={<IntlMessage Store={Store} messageId="article.main" />} key={'allgemein'} id={'allgemein'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
            {Home(response2,modalMaterialsShow,setModalMaterialsShow,materialsValue,
                  setMaterialsValue,DimensionsValue, setDimensionsValue,modalDimensionsShow,
                  setModalDimensionsShow,getDataSheetValue, saveGetDataSheetValue,
                  modalDataSheetShow, setModalDataSheet,modalarcticleShow, setModalarcticle,
                  arcticleValue, savearcticleValue,allTaxonValue, saveallTaxonValue,
                  ImgValue, setImgValue,modalImgShow, setModalImgShow,setattribute, saveattribute,user,Store)}
              </Tab>
              <Tab eventKey="content" title={<IntlMessage Store={Store} messageId="article.variants" />} key={'Inhalt'} id={'Inhalt'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
                {Content(response2,loadVariants, saveLoadVariants,loadVariantsValue,
                  saveLoadVariantsValue,modalvariantsShow,setModalvariantsShow,variantsValue,
                  savevariantsValue,variantstype, savevariantstype,Store)}
              </Tab>
            </Tabs>
            <div className={'footerBox'} style={{backgroundColor: 'white', padding: '15px'}}>
              <Button variant='light' style={{float: 'left', marginLeft: '15px'}} onClick={() => history(-1)}><IntlMessage Store={Store} messageId="common.cancel" /></Button>

              <br style={{clear: 'both'}} />
            </div>

          </div>
        );
      }

    }
  }


}


export default ProductDetail;
