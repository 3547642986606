import React, { ChangeEvent } from "react";
import {Form, Row,FormControl} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import InputGroup from 'react-bootstrap/InputGroup'
import {IntlMessageValue} from "../../../../@WUM/core/component/const/IntlMessage";

interface SwitchSearchProps {
    setPeriod:any;
    setDate:any;
    Store:any
}

const SwitchSearch = (props:SwitchSearchProps) => {

    const SetSearch = (e: ChangeEvent<HTMLSelectElement>) => {

        localStorage.setItem('period',e.target.value)
        props.setPeriod(localStorage.getItem('period'))
        localStorage.setItem('sdate','')
        // @ts-ignore
        document.getElementById('start').value = '';
        localStorage.setItem('edate','')
        // @ts-ignore
        document.getElementById('end').value = '';
    }

    const setStartDate = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        localStorage.setItem('period','range')
        localStorage.setItem('sdate',e.target.value)
        Setter();
    }

    const setendDate = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        localStorage.setItem('period','range')
        localStorage.setItem('edate',e.target.value)
        Setter();
    }

    const Setter = () => {
        if(localStorage.getItem('sdate') !== '' && localStorage.getItem('sdate') !== undefined && localStorage.getItem('sdate') !== null
            && localStorage.getItem('edate') !== '' && localStorage.getItem('edate') !== undefined && localStorage.getItem('edate') !== null){
            props.setDate(localStorage.getItem('sdate')+','+localStorage.getItem('edate'));
            localStorage.setItem('period','range')
            props.setPeriod(localStorage.getItem('period'))
        }
    }

    return(
        <>

                <Row className={'searchWrapper'}>
                    <Col>
                        <Form.Select  aria-label="Default select example" onChange={(e)=>SetSearch(e)}>
                        <option value="day" selected={(localStorage.getItem('period') === 'day')}>
                            {IntlMessageValue('de',"show.day",props.Store)}
                        </option>
                        <option value="week" selected={(localStorage.getItem('period') === 'week')}>
                            {IntlMessageValue('de',"show.week",props.Store)}
                        </option>
                        <option value="month" selected={(localStorage.getItem('period') === 'month')}>
                            {IntlMessageValue('de',"show.month",props.Store)}
                        </option>
                    </Form.Select></Col>
                    <Col>
                          <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1"> {IntlMessageValue('de',"show.start.date",props.Store)}</InputGroup.Text>
                                <FormControl
                                    placeholder="Username"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    type={'Date'}
                                    id={'start'}
                                    defaultValue={localStorage.getItem('sdate') as string}
                                    onChange={(e)=> setStartDate(e)}
                                />
                            </InputGroup></Col>
                            <Col><InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1"> {IntlMessageValue('de',"show.end.date",props.Store)}</InputGroup.Text>
                                <FormControl
                                    placeholder="Username"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    type={'Date'}
                                    id={'end'}
                                    defaultValue={localStorage.getItem('edate') as string}
                                    onChange={(e)=> setendDate(e)}
                                />
                            </InputGroup></Col>
                </Row>
        </>
    )
}

export default SwitchSearch;
