import React from 'react';
import PielersForm from '../../index'
import { Tabs } from 'react-bootstrap';
import { base, basesettings } from './base';
import IntlMessage from '../../../component/const/IntlMessage';
import Tab from "react-bootstrap/Tab";
import CopyElements from "../../../component/const/CopyElements";
import WebBuilderElementsHead from "../../../component/const/WebBuilderElementsHead";
import Col from 'react-bootstrap/Col';
import Row from "react-bootstrap/Row";
import "leaflet/dist/leaflet.css";
import Box from '@mui/material/Box';

function mapWebBuilder(data: any, element: any, i: any, newElement: any = false, newelementIndex: any = 0, modalShow: boolean,
    setModalShow: (value: (((prevState: boolean) => boolean) | boolean)) => void, modalData: any[],
    setModalData: (value: (((prevState: any[]) => any[]) | any[])) => void,
    content: any = [], setElements: any = [], Store: any) {

    const index = (newElement === false ? i : newelementIndex)

    const configuration = [

        {
            'formType': 'input',
            'label': 'street',
            'required': true,
            'name': 'elements[' + index + '][translation][street]',
            'value': (newElement === false ? (element.translation !== undefined ? element.translation.de.street : '') : ''),
        },
        {
            'formType': 'input',
            'label': 'number',
            'required': true,
            'name': 'elements[' + index + '][translation][number]',
            'value': (newElement === false ? (element.translation !== undefined ? element.translation.de.number : '') : ''),
        },


        {
            'formType': 'input',
            'label': 'city',
            'required': true,
            'name': 'elements[' + index + '][translation][city]',
            'value': (newElement === false ? (element.translation !== undefined ? element.translation.de.city : '') : ''),
        },
        {
            'formType': 'input',
            'label': 'zip',
            'required': true,
            'name': 'elements[' + index + '][translation][zip]',
            'value': (newElement === false ? (element.translation !== undefined ? element.translation.de.zip : '') : ''),
        },
        {
            'formType': 'input',
            'label': 'Ausgabe Text übern PIN',
            'name': 'elements[' + index + '][translation][content]',
            'value': (newElement === false ? (element.translation !== undefined ? element.translation.de.content : '') : ''),
        },
        {
            'formType': 'input',
            'name': 'elements[' + index + '][country]',
            'type': 'hidden',
            'value': 'DE',
        },
        {
            'formType': 'input',
            'name': 'elements[' + index + '][template]',
            'type': 'hidden',
            'value': 'Elements/maps.html',
        }]

    let newConfiguration = configuration;

    let Status;
    if (element.visible === 1) {
        Status = 'elementActive';
    }
    if (element.visible === 0) {
        Status = 'elementDeactive';
    } else if (newElement === true) {
        Status = 'elementActive';
    }
    const position = [(newElement === false ? (element.translation !== undefined ? element.translation.de.lang : 53.60835) : 53.60835),
    (newElement === false ? (element.translation !== undefined ? element.translation.de.long : 8.53635) : 8.53635)]



    return (
        <>
            <Box id={'map' + index} data-appendcontainer={(newElement === false && element.appendto !== null && element.appendto !== undefined && element.appendto !== '' ?  element.appendto : 'false')} >
                {WebBuilderElementsHead('Map', 'map', '', Status, index, 'elementButton', <IntlMessage Store={Store}
                    messageId="module.anchor" />,
                    modalShow, setModalShow, modalData, setModalData, newElement, element, content, setElements, Store, '', '', element.id)}
                <div id={'preview-container_map' + index} className={'preview-container '+Status} style={{display: (newElement === false ? 'block' : 'none')}}>
                    {(newElement === false ?

                        <span dangerouslySetInnerHTML={{ __html: element.previews }} />

                        : <></>)}
                </div>
                <Row id={'mein_map'+index} className={'boxShadowCard card ElementBorder'} style={{width: '100%',margin:'0px 0px 10px 0px',border: '0',boxShadow: 'unset' }}>
                     <Col className={'element-body'}>
                            <div id={'edit_map' + index} className={'boxShadowCard card '+Status} style={{display:(newElement === false ? 'none' : 'block'),padding:'15px',width: '100%',marginBottom: '20px'}}>
                                <Col style={{marginBottom: '10px',marginTop:'10px'}}><IntlMessage Store={Store} messageId="module.anchor" /></Col>
                                <div id={'Teaser'}>image_title</div>
                                {PielersForm(base(element,i,newElement,newelementIndex))}
                            </div>
                    </Col>
                    <div id={'settingstext' + index + 'map'} className={'settingsWebBuilderModul '}
                        style={{ display: 'none' }}>
                        {PielersForm(basesettings(element, i, newElement, newelementIndex, Store))}
                        <h3>Modul</h3>
                        {PielersForm(newConfiguration)}
                    </div>
                    {(newElement === false ? CopyElements(element.id, 'Map', Store) : <></>)}

                </Row>
            </Box>

        </>
    )


}

export default mapWebBuilder;
