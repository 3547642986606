import React from 'react';
import PielersForm from '../../index'
import {base, basesettings} from './base';
import IntlMessage from '../../../component/const/IntlMessage';
import CopyElements from "../../../component/const/CopyElements";
import Col from 'react-bootstrap/Col';
import Row from "react-bootstrap/Row";
import WebBuilderElementsHead from "../../../component/const/WebBuilderElementsHead";
import Box from '@mui/material/Box';

function module_social_mediaWebBuilder(data: any, element:any,i:any, newElement:any = false,newelementIndex:any = 0,modalShow: boolean,
              setModalShow: (value: (((prevState: boolean) => boolean) | boolean)) => void, modalData: any[],
              setModalData: (value: (((prevState: any[]) => any[]) | any[])) => void,
              content:any = [],setElements:any = [],Store:any) {

  const index = (newElement === false ? i : newelementIndex)


  const plattform = [
    {label: "Facebook",value: "Facebook"  },
    {label: "Instagram",value: "Instagram"  },
    {label: "Youtube",value: "Youtube"  },
    {label: "Vimeo",value: "Vimeo"  },
    {label: "Linked",value: "Linked"  },
    {label: "Snapshot",value: "Snapshot" },
  ];

  const column = [
    {label: "2 column display", value: "2" },
    {label: "3 column display" , value: "3" },
  ]

  const configuration = [
    {
      'formType': 'select',
      'label': <IntlMessage Store={Store} messageId="comment.Plattform" />,
      'name': 'elements[' + index + '][translation][text]',
      'selected': (newElement === false ? element.translation.de.text : ''),
      'options': plattform,
    }, {
      'formType': 'textarea',
      'label': <IntlMessage Store={Store} messageId="comment.social.link" />,
      'type': 'textarea',
      'row': 3,
      'name': 'elements[' + index + '][translation][url]',
      'value': (newElement === false ?element.translation.de.url : ''),
    },
    {
      'formType': 'select',
      'label': <IntlMessage Store={Store} messageId="comment.change.column" />,
      'name': 'elements[' + index + '][translation][sm_column]',
      'selected': (newElement === false ? element.translation.de.sm_column : ''),
      'options': column,
    },
    {
      'formType': 'input',
      'name': 'elements[' + index + '][template]',
      'type': 'hidden',
      'value': 'Elements/social_media.html',
    }
    ];

  let newConfiguration = configuration;

  let Status;
  if(element.visible === 1) {
    Status = 'elementActive';
  } if(element.visible === 0){
    Status = 'elementDeactive';
  } else if(newElement === true) {
    Status = 'elementActive';
  }
  return (
    <>
    <Box id={'module_social_media'+index} data-appendcontainer={(newElement === false && element.appendto !== null && element.appendto !== undefined && element.appendto !== '' ?  element.appendto : 'false')} >
    {WebBuilderElementsHead(<IntlMessage Store={Store} messageId="content.element.module_social_media.type" />,'module_social_media',(newElement === false ? element.translation.de.text : ''),Status,index,'elementButton',<IntlMessage Store={Store} messageId="content.element.module_social_media.type" />,
            modalShow,setModalShow,modalData, setModalData,newElement,element,content,setElements,Store,'','',element.id)}
        
      <Row   className={'boxShadowCard card ElementBorder'}   style={{paddingBottom: '15px',paddingTop:'15px',width: '100%',margin:'0px 0px 10px 0px'}}>
        <div id={'Teaser'}>module_social_media</div>
        <Col><IntlMessage Store={Store} messageId="content.element.module_social_media.type" /></Col>
         <Col  className={'accordion-body element-body'}>
           {PielersForm(base(element,i,newElement,newelementIndex))}
            {/*<Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3" key={'tabindex'}>
              <Tab eventKey="home" title={<IntlMessage Store={Store} messageId="common.general" />} key={'allgemein'} id={'allgemein'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
                {PielersForm(newConfiguration)}
              </Tab>
              <Tab eventKey="Einstellungen" title={<IntlMessage Store={Store} messageId="settings.settings.header" locale={'de'} />} key={'Einstellungen'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
                {PielersForm(basesettings(element,i,newElement,newelementIndex,Store))}
              </Tab>
            </Tabs>*/}

          </Col>
        <div id={'settingstext'+index+'module_social_media'} className={'settingsWebBuilderModul '} style={{display:'none'}}>
          {PielersForm(basesettings(element,i,newElement,newelementIndex,Store))}
          <h3>Modul</h3>
          {PielersForm(newConfiguration)}
        </div>
          {(newElement === false ? CopyElements(element.id,<IntlMessage messageId="content.element.module_social_media.type"  Store={Store}/>,Store) : <></>)}

        </Row>
        </Box>
    </>
  )


}

export default module_social_mediaWebBuilder;
