import {PagesConfig, PagesMenuConfig} from "./Pages/modules";
import {BlogMenuConfig, BlogPagesConfig} from "./Blog/modules";
import {CustomizerMenuConfig, CustomizerPagesConfig} from "./customize/modules";
import {TextBlocksMenuConfig, TextBlocksPagesConfig} from "./TextBlocks/modules";
import {DashbaordMenuConfig, DashbaordPagesConfig} from "./dashboard/modules";
import {FormularConfig, FormularMenuConfig} from "./Formular/modules";
import {CustomizerJSMenuConfig, CustomizerJSPagesConfig} from "./customizeJS/modules";
import {EmailMenuConfig, EmailPagesConfig} from "./Email/modules";
import {MenuConfig, MenuMenuConfig} from "./menu";


export const CMSRouteConfig = (value:any) => {
    return [
        ...CustomizerPagesConfig(value),
        ...CustomizerJSPagesConfig(value),
        ...BlogPagesConfig(value),
        ...PagesConfig(value),
        ...MenuConfig(value),
        ...EmailPagesConfig(value),
        //...SnippetPagesConfig(value),
        ...TextBlocksPagesConfig(value),
        ...DashbaordPagesConfig(value),
        ...FormularConfig(value)
    ]
}

export const CMSMenuConfig = (value:any) => {
        return [
            {
                header: 'CMS',
                icon: 'home',
                roles: ['superadmin','admin','author','contentcreator','operator','merchant','merchant_ma'],
                menuitem: [
                    ...DashbaordMenuConfig(value),
                    ...BlogMenuConfig(value),
                    ...PagesMenuConfig(value),
                    ...MenuMenuConfig(value),
                    ...EmailMenuConfig(value),
                    //...SnippetMenuConfig(value),
                    ...TextBlocksMenuConfig(value),
                    ...FormularMenuConfig(value),
                    ...CustomizerMenuConfig(value),
                    ...CustomizerJSMenuConfig(value),
                ]
            },
        ];
    }

export const cms = () => [
    {
        title: 'cms.blog.header',
        href: '/Blog/1',
        undertitle:'CMS',
        icon: 'post_add',
        roles: ['superadmin','admin','author','contentcreator','operator'],
        text: 'cms.blog.description'
    },
    {
        title: 'cms.page.header',
        href: '/Pages/overview/1',
        undertitle:'CMS',
        icon: 'account_tree',
        roles: ['superadmin','admin','contentcreator','operator'],
        text: 'cms.page.description'
    },
    {
        title: 'Menu',
        href: '/Menu/1',
        undertitle:'Menu',
        icon: 'menu',
        roles: ['superadmin','admin','contentcreator','operator'],
        text: 'Menu'
    },
    {
        title: 'cms.textblocks.header',
        href: '/TextBlocks/overview/1',
        undertitle:'CMS',
        icon: 'text_snippet',
        roles: ['superadmin','admin','operator'],
        text: 'cms.textblocks.description'
    },
    {
        title: 'Formular',
        href: '/formular/1',
        undertitle:'CMS',
        icon: 'contact_mail',
        roles: ['superadmin','admin','contentcreator','operator'],
        text: 'cms.formular.tooltip.content'
    },
    {
        title: 'cms.customize.header',
        href: '/customizer/1',
        undertitle:'CMS',
        roles: ['superadmin','admin','contentcreator','operator'],
        icon: 'css',
        text: 'cms.customize.description'
    },
    {
        title: 'customizerJS',
        href: '/customizerJS/1',
        undertitle:'CMS',
        roles: ['superadmin','admin','contentcreator','operator'],
        icon: 'javascript',
        text: 'cms.customize.description'
    }
]
