export const mapping = (key: any, response: any) => {


    for (let x = 0; x < response.length; x++) {
        if (response[x].key === key) {
            return response[x].css
        }
    }

}
