import React from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
/**
 * Create Header of Table
 * @prop {any} heads Gibt Spalten an
 * @prop {any} width Gibt Breite an
 * @memberOf PielersTable
 * @author Marcel Ugurcu
 * @version 0.0.1
 */
function TableHeader (heads:any,width:any,position:any){
  const row = [];

  for (let i = 0; i < heads.length;i++){
    if(position !== 'false') {
      row.push(<TableCell style={{width: width[i],textAlign: position[i]}} key={'head'+i} id={'head'+i}>{heads[i]}</TableCell>);
    } else {
      row.push(<TableCell style={{width: width[i]}} key={'head'+i} id={'head'+i}>{heads[i]}</TableCell>);
    }

  }
return (
  <><TableHead key={'thead0'} id={'thead0'}>
  <TableRow key={'tr_head'+0} id={'tr_head'+0}>
    {row}
  </TableRow>
  </TableHead>
  </>
)

}

export default TableHeader;
