import React, {useState} from 'react';
import {Response} from '../../../API/response/merchant/merchant';
import {SpinnerJSX} from '../../../../../../@WUM/core/component/const/Spinner';
import configuration from './configuration/Page/overview';
import {useParams} from 'react-router-dom';
import ContentSwitch from '../../../../../../@WUM/core/component/const/ContentSwitch';
import {ResponseallTaxon} from '../../../../products/API/response/product/Product';
import {allTaxonApiType} from '../../../../products/API/request/Product/allTacxonApi.interface';
import IntlMessage from '../../../../../../@WUM/core/component/const/IntlMessage';
import {useSelector} from "react-redux";


const MerchantOverview = (value:any) => {
  localStorage.removeItem('merchant')
  const {page} = useParams<{page: string}>();
  let limit: any;
  const debug = (allTaxon: allTaxonApiType[] | boolean) => {
    return;
  }
  if (typeof page === "string") {
    limit = (parseFloat(page) * 10) - 10;
  }
  const Store = useSelector((state: any) => state)
  const [searchValue, setSearchValue] = useState('');
  const [search, setSearch] = useState<boolean>(false)
  const response = Response(limit,searchValue);
  const allTaxon = ResponseallTaxon();
  debug(allTaxon)
  const Configuration = configuration('',response,Store);
  const [mode, setMode] = useState('cart');
  const destination = 'merchant';

  let link;
    link = (process.env.REACT_APP_API_URL_DEVELOPMENT as string).replace('/api/','');

  const CardsConfiguration = {
    'data': {
      'ressource': response,
      'img': 'images.3.path',
      'indiBild':true,
      'cdnlink':true,
      'status': 'enabled',
      'approved': 'approval_state',
      'isapproved': true,
      'statusActive': <IntlMessage Store={Store} messageId="common.activated" />,
      'statusDeactive': <IntlMessage Store={Store} messageId="common.deactivated" />,
      'statusWaiting': <IntlMessage Store={Store} messageId="common.waiting" />,
      'statusWaitingColor': 'text-white',
      'link': 'slug',
      'prelink': link+'/anbieter/[param]/',
      'Dontvisited': true,
      'prelinksearch':'slug',
      'title': 'company',
      'description': 'slug',
      'router': 'id',
      'preroute': '/merchant/Detail/',
      'edit': <IntlMessage Store={Store} messageId="common.edit_provider" />,
      'delete': false,
      'deleteLink': ''
    }
  }


  if (response.length > 0) {
    return (
      <>
        <ContentSwitch pagenew={''} destinationnew={''} tonew={''} text={''}
        response={response} CardsConfiguration={CardsConfiguration} mode={mode}
        Configuration={Configuration} setMode={setMode} destination={destination} page={page}
        to={'overview'} setSearchValues={setSearchValue} setSearchs={setSearch} search={search}
        modalShow={''} setModalShow={''} modalData={''} setModalData={''}
        searchfield={[]} allTaxon={[]} _handleKeyFilters={[]} _handleEnter={[]} filter={false} Store={Store}/>
      </>
    )
  } else {
    localStorage.setItem('searchEnterInput','');
    return (
      <><SpinnerJSX messageId={'common.loading'} Store={Store} /></>
    );
  }

};


export default MerchantOverview;

