import React from "react";
import IntlMessage from "../../../../../../core/component/const/IntlMessage";
import home from "../../../faq/elements/home";
import post from "../../../faq/elements/post";
import FAQDetailTabsConfigProps from "../../../../../interface/FAQDetailTabsConfigProps.interface";

const FAQDetailTabsConfig = (props:FAQDetailTabsConfigProps) => {

    let config = [{
        tab: {defaultActiveKey:'home',id:'uncontrolled-tab-example',className:'mb-3',key:'tabindex'},
        tabs:[
            {eventKey:'home',title:<IntlMessage Store={props.Store} messageId="common.general" />,key:'home',id:'home',class:'TabPanel',content:home(
                {response:props.response2,Store:props.Store})},
            {eventKey:'post',title:'Article',key:'post',id:'post',class:'TabPanel',content:post(
                    {response:props.response2,Store:props.Store,elements: props.element,
        setElements: props.setElement,Index: props.Index,setIndex: props.setIndex,})},
        ]
    }]

    return config;

}

export default FAQDetailTabsConfig;
