import React from 'react';
import PielersForm from '../../index'
import {base, basesettings} from './base';
import IntlMessage from '../../../component/const/IntlMessage';
import CopyElements from "../../../component/const/CopyElements";
import WebBuilderElementsHead from "../../../component/const/WebBuilderElementsHead";
import Col from 'react-bootstrap/Col';
import Row from "react-bootstrap/Row";
import Box from '@mui/material/Box';
import {clearCol} from "../../../component/core/elements/content";

function anchorWebBuilder(data: any, element:any,i:any, newElement:any = false,newelementIndex:any = 0,modalShow: boolean,
              setModalShow: (value: (((prevState: boolean) => boolean) | boolean)) => void, modalData: any[],
              setModalData: (value: (((prevState: any[]) => any[]) | any[])) => void,
              content:any = [],setElements:any = [],Store:any) {

  const index = (newElement === false ? i : newelementIndex)

  const configuration = [
    {
      'formType': 'input',
      'data': data,
      'label': <IntlMessage Store={Store} messageId="module.anchor.label" />,
      'name': 'elements['+index+'][translation][anchor]',
      'value': (newElement === false ? (element.translation !== undefined ? element.translation.de.anchor : '') : ''),
      'legende': <IntlMessage Store={Store} messageId="module.anchor.legende" />
    },
    {
      'formType': 'input',
      'name': 'elements[' + index + '][template]',
      'type': 'hidden',
      'value': 'Elements/anchor.html',
    }]

  let newConfiguration = configuration.concat(base(element,i,newElement,newelementIndex));

  let Status;
  if(element.visible === 1) {
    Status = 'elementActive';
  } if(element.visible === 0){
    Status = 'elementDeactive';
  } else if(newElement === true) {
    Status = 'elementActive';
  }
  return (
    <>
    <Box id={'anchor'+index} data-appendcontainer={(newElement === false && element.appendto !== null && element.appendto !== undefined && element.appendto !== '' ?  element.appendto : 'false')} >
    {WebBuilderElementsHead(<IntlMessage Store={Store} messageId="module.anchor" />,'anchor',(newElement === false ? (element.translation !== undefined ? element.translation.de.anchor : '') : ''),Status,index,'elementButton',<IntlMessage Store={Store} messageId="module.anchor" />,
            modalShow,setModalShow,modalData, setModalData,newElement,element,content,setElements,Store,'','',element.id)}
      <div id={'preview-container_anchor'+index}  className={'preview-container '+Status} style={{display: (newElement === false ? 'block' : 'none')}}>
        {(newElement === false ?
            <span dangerouslySetInnerHTML={{ __html: element.previews }} /> : <></>)}
      </div>
      <Row id={'mein_anchor'+index} className={'boxShadowCard card ElementBorder'}   style={{width: '100%',margin:'0px 0px 10px 0px',border: '0',boxShadow: 'unset'}}>

           <Col  className={'element-body'}>

            <div id={'edit_anchor'+index} className={'boxShadowCard card '+Status} style={{display:(newElement === false ? 'none' : 'block'),padding:'15px',width: '100%',marginBottom: '20px'}}>
              <div id={'Teaser'}>anchor</div>
              <Col style={{marginBottom: '10px',marginTop:'10px'}}><IntlMessage Store={Store} messageId="module.anchor" /></Col>
            {PielersForm(newConfiguration)}

            </div>
            </Col>
        <div id={'settingstext'+index+'anchor'} className={'settingsWebBuilderModul '} style={{display:'none'}}>

          {PielersForm(basesettings(element,i,newElement,newelementIndex,Store))}
        </div>
            {(newElement === false ? CopyElements(element.id,<IntlMessage messageId="module.anchor"  Store={Store}/>,Store) : <></>)}

          </Row>
          </Box>
    </>
  )


}

export default anchorWebBuilder;
