import CreateMatomoIframe from "../../core/CreateMatomoIframe";
import WidgetURLMapping from "../../core/WidgetURLMapping";
import React from "react";
import IntlMessage from "../../../../../@WUM/core/component/const/IntlMessage";


const VisitorLog = (props:{Store:any}) => {
    return (
        <>
            <CreateMatomoIframe
                headline={<IntlMessage Store={props.Store} messageId='Visitor.user.log' />}
                width={"100%"}
                height={"650"}
                url={WidgetURLMapping({name:'Visitor log'})}
                scrolling={"yes"}
                id={'vL1'}
                Store={props.Store}
            />
        </>
    )

}

export default VisitorLog;
