
export const Gernalh = {
    eventkey: 'Generelh',
    key: 'Generelh',
    title: 'Generelh',
    content: [
        {
            label: "headline.font-family-headline",
            discr: 'font-family-headline',
            size: [
                3,
                3,
                3
            ],
            type: 'text'
        }
    ]
}
