import IntlMessage, {IntlMessageValue} from '../../../../../../../@WUM/core/component/const/IntlMessage';
import React from "react";


function configuration(conf: string,response:any,Store:any) {

  const information = [
    {
      'formType': 'header',
      'value': <IntlMessage Store={Store} messageId="common.billing_address" />,
    },
    {
    'formType': 'input',
    'label': <IntlMessage Store={Store} messageId="billing.email" />,
    'type': 'text',
    'placeholder': IntlMessageValue("de","common.email_address",Store),
    'name': 'merchant[billingEmail]',
    'value': response.merchant.billingEmail,
  },{
      'formType': 'input',
      'label': <IntlMessage Store={Store} messageId="billing.company.name"/>,
      'type': 'text',
      'placeholder': IntlMessageValue("de","common.company.names",Store),
      'name': 'address[company]',
      'value': response.address.company,
  },{
      'formType': 'multiply',
      'cols': [
        { 'formType': 'input',
          'label': <IntlMessage Store={Store} messageId="common.name.first" />,
          'type': 'text',
          'placeholder': IntlMessageValue("de","common.name.first",Store),
          'name': 'address[first_name]',
          'value': response.address.first_name,
        },
        { 'formType': 'input',
          'label': <IntlMessage Store={Store} messageId="common.name.last" />,
          'type': 'text',
          'placeholder': IntlMessageValue("de","common.name.last",Store),
          'name': 'address[last_name]',
          'value': response.address.last_name,
        }
      ]
    },{
      'formType': 'input',
      'label': <IntlMessage Store={Store} messageId="billing.street_and_number" />,
      'type': 'text',
      'placeholder': IntlMessageValue("de","common.company.names",Store),
      'name': 'address[street]',
      'value': response.address.street,
    },{
      'formType': 'multiply',
      'cols': [
        { 'formType': 'input',
          'label': <IntlMessage Store={Store} messageId="common.zip_code" />,
          'type': 'text',
          'placeholder': IntlMessageValue("de","common.zip_code",Store),
          'name': 'address[postcode]',
          'value': response.address.postcode,
        },
        { 'formType': 'input',
          'label': <IntlMessage Store={Store} messageId="common.city" />,
          'type': 'text',
          'placeholder': IntlMessageValue("de","common.city",Store),
          'name': 'address[city]',
          'value': response.address.city,
        }
        ,
        { 'formType': 'input',
          'label': <IntlMessage Store={Store} messageId="common.country_code" />,
          'type': 'text',
          'placeholder': IntlMessageValue("de","common.country_code",Store),
          'name': 'address[country_code]',
          'value': response.address.country_code,
        },{
          'formType': 'input',
          'type': 'hidden',
          'name': 'address[id]',
          'value': response.address.id,
        },
      ]
    },
    {
      'formType': 'header',
      'value': <IntlMessage Store={Store} messageId="common.account_details" />,
    },
    {
      'formType': 'multiply',
      'cols': [
        { 'formType': 'input',
          'label': <IntlMessage Store={Store} messageId="common.local_court" />,
          'type': 'text',
          'placeholder': IntlMessageValue("de","common.local_court",Store),
          'name': 'merchant[districCourt]',
          'value': response.merchant.districCourt,
        },
        { 'formType': 'input',
          'label': <IntlMessage Store={Store} messageId="common.tax_number" />,
          'type': 'text',
          'placeholder': IntlMessageValue("de","common.tax_number",Store),
          'name': 'merchant[taxNumber]',
          'value': response.merchant.taxNumber,
        }
      ]
    },
    {
      'formType': 'multiply',
      'cols': [
        { 'formType': 'input',
          'label': <IntlMessage Store={Store} messageId="common.trade_register" />,
          'type': 'text',
          'placeholder': IntlMessageValue("de","common.trade_register",Store),
          'name': 'merchant[commercialRegisterIdNr]',
          'value': response.merchant.commercialRegisterIdNr,
        },
        { 'formType': 'input',
          'label': <IntlMessage Store={Store} messageId="common.vat_no" />,
          'type': 'text',
          'placeholder': IntlMessageValue("de","common.vat_no",Store),
          'name': 'merchant[vatIdNr]',
          'value': response.merchant.vatIdNr,
        }
      ]
    },{
      'formType': 'checkbox',
      'label': <IntlMessage Store={Store} messageId="common.small_business_owner" />,
      'name': 'merchant[smallBusinessman]',
      'visible': response.merchant.smallBusinessman,
    },{
      'formType': 'header',
      'value': <IntlMessage Store={Store} messageId="common.vacation_mode" />,
    },{
      'formType': 'select',
      'label': <IntlMessage Store={Store} messageId="common.vacation_mode" />,
      'disabled': (response.merchant.state === 'hidden' ? true : false),
      'name': 'merchant[state]',
      'selected': response.merchant.state,
      'options': [{
        'label': '',
        'value': ''
      },
        {
          'label': IntlMessageValue("de","common.vacation_mode.deactivate",Store),
          'value': 'visible'
        },
        {
          'label': IntlMessageValue("de","common.vacation_mode.activate",Store),
          'value': 'vacation'
        }]

    }
  ];



  if(conf === 'information') {
    return information;
  }

}

export default configuration;
