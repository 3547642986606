import {
  Address,
  Merchant, MerchantShippingType,
  Translation,
  taxons,
} from '../../../../API/request/merchant/merchantApiDetail.interface';

export interface config {
  merchant_id: string,
  translation: Translation,
  merchant: Merchant,
  address: Address[],
  MerchantShippingType: MerchantShippingType[]
  taxons: taxons[]
}

export function output(Data: any) {
  let back = {
    merchant_id: Data.merchant_id,
    translation: Data.translation,
    merchant: Data.merchants,
    address: Data.address,
    MerchantShippingType: Data.MerchantShippingType,
    taxons: Data.taxons


  }

  return back;
}