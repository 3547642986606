
export const TabsLinks =  {
    eventkey: 'Links',
    key: 'Links',
    title: 'Links',
    content: [
        {
            label: "links.link-color",
            discr: 'link-color',
            size: [
                3,
                3,
                3
            ],
            type: 'color'
        },
        {
            label: "links.link-color-hover",
            discr: 'link-color-hover',
            size: [
                3,
                3,
                3
            ],
            type: 'color'
        },
        {
            label: "links.link-font-weight",
            discr: 'link-font-weight',
            size: [
                3,
                3,
                3
            ],
            type: 'text'
        }
    ]
}
