import {MemberMenuConfig, MemberPagesConfig} from "./Member";
import {ShippingMenuConfig, ShippingPagesConfig} from "./Shipping";
import {TaxMenuConfig, TaxPagesConfig} from "./Tax";
import {UnitMenuConfig, UnitPagesConfig} from "./Unit";
import {TaxonMenuConfig, TaxonPagesConfig} from "./Taxon";
import {PromoMenuConfig, PromoPagesConfig} from "./promo";
import {LanguageSwitcherValue} from "../shop/core/components/LanguageSwitcher";
import {ConfigMenuConfig, ConfigPagesConfig} from "./config";
import {RewriteagesConfig, RewriteMenuConfig} from "./rewrite";
import {CustomTypesMenuConfig, CustomTypesPagesConfig} from "./CustomTypes";
import {TemplateConfig, TemplateMenuConfig} from "./Template/modules";
// @ts-ignore
import {IntlMessage} from 'multilanguagers/index'


export const SettingsRouteConfig = (value: any) => {

    switch (process.env.REACT_APP_MODE_PLATTFORM) {
        case 'CMS':
            return [
                ...ConfigPagesConfig(value),
                ...MemberPagesConfig(value),
                ...RewriteagesConfig(value),
                ...CustomTypesPagesConfig(value),
                ...TemplateConfig(value)
            ]
        case 'CMSwithMatomo':
            return [
                ...ConfigPagesConfig(value),
                ...MemberPagesConfig(value),
                ...RewriteagesConfig(value),
                ...CustomTypesPagesConfig(value),
                ...TemplateConfig(value)

            ]
        case 'ProductCatalog':
        case 'ProductCatalogwithMatomo':
            return [
                ...ConfigPagesConfig(value),
                ...MemberPagesConfig(value),
                ...TaxonPagesConfig(value),
                ...RewriteagesConfig(value),
                ...CustomTypesPagesConfig(value),
                ...TemplateConfig(value)

            ]
        case 'SHOP':
            if (process.env.REACT_APP_PROMOTIOM === 'True') {
                return [
                    ...ConfigPagesConfig(value),
                    ...PromoPagesConfig(value),
                    ...MemberPagesConfig(value),
                    ...ShippingPagesConfig(value),
                    ...TaxPagesConfig(value),
                    ...UnitPagesConfig(value),
                    ...TaxonPagesConfig(value),
                    ...RewriteagesConfig(value),
                    ...CustomTypesPagesConfig(value),
                    ...TemplateConfig(value)

                ]
            } else {
                return [
                    ...ConfigPagesConfig(value),
                    ...MemberPagesConfig(value),
                    ...ShippingPagesConfig(value),
                    ...TaxPagesConfig(value),
                    ...UnitPagesConfig(value),
                    ...TaxonPagesConfig(value),
                    ...RewriteagesConfig(value),
                    ...CustomTypesPagesConfig(value),
                    ...TemplateConfig(value)

                ]
            }

        case 'SHOPwithMatomo':
            if (process.env.REACT_APP_PROMOTIOM === 'True') {
                return [
                    ...ConfigPagesConfig(value),
                    ...PromoPagesConfig(value),
                    ...MemberPagesConfig(value),
                    ...ShippingPagesConfig(value),
                    ...TaxPagesConfig(value),
                    ...UnitPagesConfig(value),
                    ...TaxonPagesConfig(value),
                    ...RewriteagesConfig(value),
                    ...CustomTypesPagesConfig(value),
                    ...TemplateConfig(value)

                ]
            } else {
                return [
                    ...ConfigPagesConfig(value),
                    ...MemberPagesConfig(value),
                    ...ShippingPagesConfig(value),
                    ...TaxPagesConfig(value),
                    ...UnitPagesConfig(value),
                    ...TaxonPagesConfig(value),
                    ...RewriteagesConfig(value),
                    ...CustomTypesPagesConfig(value),
                    ...TemplateConfig(value)

                ]
            }

        default:
            return [
                ...ConfigPagesConfig(value),
                ...PromoPagesConfig(value),
                ...MemberPagesConfig(value),
                ...ShippingPagesConfig(value),
                ...TaxPagesConfig(value),
                ...UnitPagesConfig(value),
                ...TaxonPagesConfig(value),
                ...RewriteagesConfig(value),
                ...CustomTypesPagesConfig(value),
                ...TemplateConfig(value)

            ]
    }
}

export const SettingsMenuConfig = (value: any) => {

    switch (process.env.REACT_APP_MODE_PLATTFORM) {
        case 'CMS':
            return [
                {
                    header: LanguageSwitcherValue('common.settings'),
                    icon: 'settings',
                    roles: ['superadmin','admin','operator','merchant'],
                    menuitem: [
                        ...MemberMenuConfig(value),
                        ...ConfigMenuConfig(value),
                        ...RewriteMenuConfig(value),
                        ...CustomTypesMenuConfig(value),
                        ...TemplateMenuConfig(value)
                    ]
                },
            ]
        case 'CMSwithMatomo':
            return [
                {
                    header: LanguageSwitcherValue('common.settings'),
                    icon: 'settings',
                    roles: ['superadmin','admin','operator','merchant'],
                    menuitem: [
                        ...MemberMenuConfig(value),
                        ...ConfigMenuConfig(value),
                        ...RewriteMenuConfig(value),
                        ...CustomTypesMenuConfig(value),
                        ...TemplateMenuConfig(value)
                    ]
                },
            ]
        case 'ProductCatalog':
        case 'ProductCatalogwithMatomo':
            return [
                {
                    header: LanguageSwitcherValue('common.settings'),
                    icon: 'settings',
                    roles: ['superadmin','admin','operator','merchant'],
                    menuitem: [
                        ...MemberMenuConfig(value),
                        ...ConfigMenuConfig(value),
                        ...TaxonMenuConfig(value),
                        ...RewriteMenuConfig(value),
                        ...CustomTypesMenuConfig(value),
                        ...TemplateMenuConfig(value)

                    ]
                },
            ]
        case 'SHOP':
            if (process.env.REACT_APP_PROMOTIOM === 'True') {
                return [
                    {
                        header: LanguageSwitcherValue('common.settings'),
                        icon: 'settings',
                        roles: ['superadmin','admin','operator','merchant'],
                        menuitem: [
                            ...PromoMenuConfig(value),
                            ...MemberMenuConfig(value),
                            ...ShippingMenuConfig(value),
                            ...TaxMenuConfig(value),
                            ...UnitMenuConfig(value),
                            ...TaxonMenuConfig(value),
                            ...ConfigMenuConfig(value),
                            ...RewriteMenuConfig(value),
                            ...CustomTypesMenuConfig(value),
                            ...TemplateMenuConfig(value)
                        ]
                    },
                ]
            } else {
                return [
                    {
                        header: LanguageSwitcherValue('common.settings'),
                        icon: 'settings',
                        roles: ['superadmin','admin','operator','merchant'],
                        menuitem: [
                            ...MemberMenuConfig(value),
                            ...ShippingMenuConfig(value),
                            ...TaxMenuConfig(value),
                            ...UnitMenuConfig(value),
                            ...TaxonMenuConfig(value),
                            ...ConfigMenuConfig(value),
                            ...RewriteMenuConfig(value),
                            ...CustomTypesMenuConfig(value),
                            ...TemplateMenuConfig(value)
                        ]
                    },
                ]
            }

        case 'SHOPwithMatomo':
            if (process.env.REACT_APP_PROMOTIOM === 'True') {
                return [
                    {
                        header: LanguageSwitcherValue('common.settings'),
                        icon: 'settings',
                        roles: ['superadmin','admin','operator','merchant'],
                        menuitem: [
                            ...PromoMenuConfig(value),
                            ...MemberMenuConfig(value),
                            ...ShippingMenuConfig(value),
                            ...TaxMenuConfig(value),
                            ...UnitMenuConfig(value),
                            ...TaxonMenuConfig(value),
                            ...ConfigMenuConfig(value),
                            ...RewriteMenuConfig(value),
                            ...CustomTypesMenuConfig(value),
                            ...TemplateMenuConfig(value)
                        ]
                    },
                ]
            } else {
                return [
                    {
                        header: LanguageSwitcherValue('common.settings'),
                        icon: 'settings',
                        roles: ['superadmin','admin','operator','merchant'],
                        menuitem: [
                            ...MemberMenuConfig(value),
                            ...ShippingMenuConfig(value),
                            ...TaxMenuConfig(value),
                            ...UnitMenuConfig(value),
                            ...TaxonMenuConfig(value),
                            ...ConfigMenuConfig(value),
                            ...RewriteMenuConfig(value),
                            ...CustomTypesMenuConfig(value),
                            ...TemplateMenuConfig(value)
                        ]
                    },
                ]
            }
        default:
            return [
                {
                    header: LanguageSwitcherValue('common.settings'),
                    icon: 'settings',
                    roles: ['superadmin','admin','operator','merchant'],
                    menuitem: [
                        ...PromoMenuConfig(value),
                        //...CustomerMenuConfig(value),
                        ...MemberMenuConfig(value),
                        //...MemberMerchantMenuConfig(value),
                        //...SettingMenuConfig(value),
                        //...SettingsMerchantMenuConfig(value),
                        ...ShippingMenuConfig(value),
                        ...TaxMenuConfig(value),
                        ...UnitMenuConfig(value),
                        ...TaxonMenuConfig(value),
                        ...ConfigMenuConfig(value),
                        ...RewriteMenuConfig(value),
                        ...CustomTypesMenuConfig(value),
                        ...TemplateMenuConfig(value)
                    ]
                },
            ]
    }
}
export const settings = (Store:any) => {
    const Locales = (Store !== undefined && Store !== null && Store.locale !== undefined && Store.locale !== null && Store.locale.locale !== '' && Store.locale.locale !== undefined && Store.locale.locale !== null  ? Store.locale.locale.toLowerCase() :  localStorage.getItem('locale') )
    let Locale = (Locales !== undefined && Locales !== null ? Locales.toLowerCase() :  'de');
    switch (process.env.REACT_APP_MODE_PLATTFORM) {
        case 'SHOP':
        case 'SHOPwithMatomo':
            if(process.env.REACT_APP_PROMOTIOM === 'True') {
                return [
                    {
                        title: 'common.settings.promo',
                        href: '/Promo/1',
                        undertitle:<IntlMessage locale={Locale} messageId={'common.settings'}/>,
                        icon: 'savings',
                        text: 'einstellungen.promotion.tooltip.content',
                        roles: ['superadmin','admin','operator']
                    },
                    {
                        title: 'settings.Member.header',
                        href: '/Member/overview/1',
                        undertitle:<IntlMessage locale={Locale} messageId={'common.settings'}/>,
                        icon: 'manage_accounts',
                        text: 'settings.member.description',
                        roles: ['superadmin','admin','merchant']
                    },
                    {
                        title: 'settings.shipping.header',
                        href: '/Shipping/overview/1',
                        undertitle:<IntlMessage locale={Locale} messageId={'common.settings'}/>,
                        icon: 'local_shipping',
                        text: 'einstellungen.versandmethoden.tooltip.content',
                        roles: ['superadmin','admin','operator']
                    },
                    {
                        title: 'settings.tax.header',
                        href: '/Tax/overview/1',
                        undertitle:<IntlMessage locale={Locale} messageId={'common.settings'}/>,
                        icon: 'account_balance',
                        text: 'einstellungen.steuer.tooltip.content',
                        roles: ['superadmin','admin','operator']
                    },
                    {
                        title: 'settings.unit.header',
                        href: '/Unit/overview/1',
                        undertitle:<IntlMessage locale={Locale} messageId={'common.settings'}/>,
                        icon: 'scale',
                        text: 'einstellungen.einheiten.tooltip.content',
                        roles: ['superadmin','admin','operator']
                    },
                    {
                        title: 'Template Settings',
                        href: '/Config/overview/1',
                        undertitle:<IntlMessage locale={Locale} messageId={'common.settings'}/>,
                        icon: 'description',
                        text: 'einstellungen.template_settings.tooltip.content',
                        roles: ['superadmin','admin','operator']
                    },
                    {
                        title: 'settings.taxon.header',
                        href: '/Taxon/overview/1',
                        undertitle:<IntlMessage locale={Locale} messageId={'common.settings'}/>,
                        icon: 'category',
                        text: 'einstellungen.kategorien.tooltip.content',
                        roles: ['superadmin','admin','operator']
                    }
                ]
            } else {
                return [
                    {
                        title: 'settings.Member.header',
                        href: '/Member/overview/1',
                        undertitle:<IntlMessage locale={Locale} messageId={'common.settings'}/>,
                        icon: 'manage_accounts',
                        text: 'settings.member.description',
                        roles: ['superadmin','admin','merchant']
                    },
                    {
                        title: 'settings.shipping.header',
                        href: '/Shipping/overview/1',
                        undertitle:<IntlMessage locale={Locale} messageId={'common.settings'}/>,
                        icon: 'local_shipping',
                        text: 'einstellungen.versandmethoden.tooltip.content',
                        roles: ['superadmin','admin','operator']
                    },
                    {
                        title: 'settings.tax.header',
                        href: '/Tax/overview/1',
                        undertitle:<IntlMessage locale={Locale} messageId={'common.settings'}/>,
                        icon: 'account_balance',
                        text: 'einstellungen.steuer.tooltip.content',
                        roles: ['superadmin','admin','operator']
                    },
                    {
                        title: 'settings.unit.header',
                        href: '/Unit/overview/1',
                        undertitle:<IntlMessage locale={Locale} messageId={'common.settings'}/>,
                        icon: 'scale',
                        text: 'einstellungen.einheiten.tooltip.content',
                        roles: ['superadmin','admin','operator']
                    },
                    {
                        title: 'Template Settings',
                        href: '/Config/overview/1',
                        undertitle:<IntlMessage locale={Locale} messageId={'common.settings'}/>,
                        icon: 'description',
                        text: 'einstellungen.template_settings.tooltip.content',
                        roles: ['superadmin','admin','operator']
                    },
                    {
                        title: 'settings.taxon.header',
                        href: '/Taxon/overview/1',
                        undertitle:<IntlMessage locale={Locale} messageId={'common.settings'}/>,
                        icon: 'category',
                        text: 'einstellungen.kategorien.tooltip.content',
                        roles: ['superadmin','admin','operator']
                    }
                ]
            }

        case 'ProductCatalog':
        case 'ProductCatalogwithMatomo':
            return [
                {
                    title: 'settings.Member.header',
                    href: '/Member/overview/1',
                    undertitle:<IntlMessage locale={Locale} messageId={'common.settings'}/>,
                    icon: 'manage_accounts',
                    text: 'settings.member.description',
                    roles: ['superadmin','admin','merchant']
                },
                {
                    title: 'Template Settings',
                    href: '/Config/overview/1',
                    undertitle:<IntlMessage locale={Locale} messageId={'common.settings'}/>,
                    icon: 'description',
                    text: 'einstellungen.template_settings.tooltip.content',
                    roles: ['superadmin','admin','operator']
                },
                {
                    title: 'settings.taxon.header',
                    href: '/Taxon/overview/1',
                    undertitle:<IntlMessage locale={Locale} messageId={'common.settings'}/>,
                    icon: 'category',
                    text: 'einstellungen.kategorien.tooltip.content',
                    roles: ['superadmin','admin','operator']
                }
            ]
        default:
            return[
                {
                    title: 'Template Settings',
                    href: '/Config/overview/1',
                    undertitle:<IntlMessage locale={Locale} messageId={'common.settings'}/>,
                    icon: 'description',
                    text: 'einstellungen.template_settings.tooltip.content',
                    roles: ['superadmin','admin','operator']
                },
                {
                    title: 'settings.Member.header',
                    href: '/Member/overview/1',
                    undertitle:<IntlMessage locale={Locale} messageId={'common.settings'}/>,
                    icon: 'manage_accounts',
                    text: 'settings.member.description',
                    roles: ['superadmin','admin','merchant']
                }
            ]
    }
}
