import React from 'react';
import {MenuItem} from 'react-contextmenu';
import {useNavigate } from 'react-router-dom';
import initIcon from './elements';

function Items(heads: string | any[], route: string, icons: string, index:number ) {

  const row = [];
  const history = useNavigate ();

  const routeChangeZero = () =>{
    let path;
    if(heads === 'Anzeigen') {
      path = route.replace('[param]',localStorage.getItem('Param') as string)+'/';
      const win = window.open(path, "_blank");
      win?.focus();
    } else if(heads === 'Löschen') {
      path = route.replace('[ID]',localStorage.getItem('PagesID') as string)+'/';
      return history(path);
    }
    else {
      path = route+'/'+ localStorage.getItem('PagesID');
      return history(path);
    }

  }

  if(heads === 'Anzeigen') {
    //console.log(localStorage.getItem('Param'))
  }

  {/* @ts-ignore */}
  row.push(<MenuItem
    onClick={routeChangeZero}
    data={{item: 'Home'}}
    className='menuItem tableMenu'
    key={index+'_'+heads}
    data-id={index+'_'+heads}
    >
    {initIcon(icons)}
    {heads}
  </MenuItem>);

  return (
    <>{row}
    </>
  );
}

export default Items;

