/**
 * Escape strings
 * @param data
 */
function excape(data:string) {

 let convert;

 if (typeof data === 'string') {
   convert = normalSpaces(data)

 }else{
  convert = data;
 }

 return convert;
}

export default excape;

function normalSpaces(s:any) {
 var Zs_and_friends = new RegExp('[\u0009\u000B\u000C\u00A0\u1680\u180E' +
   '\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200A' +
   '\u202F\u205F\u3000\uFEFF]', 'g');

 return s ? s.toString().replace(Zs_and_friends, ' ') : s;
}