import {LanguageSwitcherValue} from "../../core/component/core/components/LanguageSwitcher";
import React from "react";
import FAQOverview from "./Pages/faq/overview";
import FAQNew from "./Pages/faq/new";
import FAQDetail from "./Pages/faq/FAQDetail";

export const HelpDeskPagesConfig = (value: any) => {
    return [
        {
            path: '/helpdesk/faq/overview/:page',
            elements: <FAQOverview value={value}/>,
            roles: ['superadmin']
        },
        {
            path: '/helpdesk/faq/new/:page',
            elements: <FAQNew value={value}/>,
            roles: ['superadmin']
        },
        {
            path: '/helpdesk/faq/Detail/:id',
            elements: <FAQDetail value={value}/>,
            roles: ['superadmin']
        }
    ]

}


export const HelpDeskMenuConfig = (value: any) => {
    return [
        {
            header: 'HelpDesk',
            icon: 'help_center',
            roles: ['superadmin'],
            menuitem: [
        {
            name: 'FAQ',
            route: '/helpdesk/faq/overview/1',
            icon: 'quiz'
        },
            ]
        },
    ];
}
