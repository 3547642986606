/**
 * currencyFormat give out currency string
 * @param {float} price float of Price
 * @param {string} currency string of currency
 * @example
 * currencyFormat(netto,currency)
 */
export const  currencyFormat = (price:any,currency:string) => {
    let tmp = Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 2,
    }).format(price);
    return tmp;
}
