import Button from 'react-bootstrap/Button';
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import {notify_del_element} from '../../../component/const/notification.service';
import {error} from '../../../component/const/error.services';
import IntlMessage from '../../../component/const/IntlMessage';
import CloseIcon from '@mui/icons-material/Close';
const deleteElementsWebBuilder = (id:any,className:any = false,type:any=false,modalShow: boolean, setModalShow: (value: (((prevState: boolean) => boolean) | boolean)) => void, modalData: any[],
                        setModalData: (value: (((prevState: any[]) => any[]) | any[])) => void,typeid:any=false,content:any = false,
                                  setElements:any = [],Store:any,parentID?:any) => {
  let setClass = '';
  if(className !== false) {
    setClass = className;
  }
  let parentType = ''
  if(parentID !== undefined && parentID !== null) {
    //console.log(parentID)
    let mainChilds = document.querySelector('#' + parentID)
    if(mainChilds !== null) {
      let mainChildss = mainChilds.querySelectorAll("textarea[name='elements'],select[name='elements'] ,input[name*='elements']")

    for (var mcs = 0; mcs < mainChildss.length; mcs++) {
      var namess = $(mainChildss[mcs]).attr("name");
      if (namess !== undefined && namess.includes('[translation][id]')) {
        let Type = mainChildss[mcs] as HTMLInputElement;
        parentType = Type.value;
      }
    }
    }
    //console.log(parentType)
  }


  if(type !== false && typeid !== 0) {
    return(<>
      {/*<Button className={setClass} type={'button'} onClick={() => modalDel(setModalShow,setModalData,type,id,typeid)}>*/}
      <p className={''+setClass} onClick={() => modalDel(setModalShow,setModalData,type,id,typeid,parentID,parentType)}
       style={{height:'100%',width:'30px',textAlign:'left',float:'right',padding: '5px',background: '#3870F7',cursor:"pointer",color:"white"}}>
        <span className="material-icons">
          clear
        </span>
  </p>
      {/* </Button>*/}
      {modal(modalShow,setModalShow,modalData,Store)}
    </>)
  }  else {
    return(<>
      {/*  <Button className={setClass} type={'button'} onClick={() => deleteElement(id,false,false,content,setElements)}>*/}
      <p className={''+setClass} onClick={() => deleteElement(id,false,false,content,setElements,null)}
      style={{height:'100%',width:'30px',cursor:"pointer", textAlign:'left',float:'right',padding: '5px',background: '#3870F7', color:"white" }}>
      <span className="material-icons">
          clear
        </span>
  </p>
      {/*</Button>*/}

    </>)
  }



}
export default deleteElementsWebBuilder;


const deleteElement = (id: any, onHide:any =false,typeid:any=false,setDeleteElements:any = false,elements:any = [],parentID?:any,parentType?:any) => {
  //console.log('parentID='+parentID)
  //console.log('parentType='+parentType)
  //console.log('id='+id)
  var element = document.getElementById(id);
  if(onHide !== false && typeid !== 0){
  let api_URL;
  if (process.env.REACT_APP_MODE === 'LOCAL') {
    api_URL = process.env.REACT_APP_API_URL_LOCAL;
  } else if (process.env.REACT_APP_MODE === 'DEVELOPMENT') {
    api_URL = process.env.REACT_APP_API_URL_DEVELOPMENT;
  } else if (process.env.REACT_APP_MODE === 'LIVE') {
    api_URL = process.env.REACT_APP_API_URL_LIVE;
  }  else if (process.env.REACT_APP_MODE === 'KLIMABAUHELD') {
    api_URL = process.env.REACT_APP_API_URL_KLIMABAUHELD;
  }

  let token = localStorage.getItem('token');
  // Identifizieren des Kindknotens


// Aufruf des Elternknotens, um dann dessen Kindknoten zu löschen
    if(element !== null && element !== undefined) {
      element.parentNode?.removeChild(element);
    }

    fetch(api_URL + 'element/delete/'+typeid+'/', {
      method: "delete",
      headers: {
        'Authorization': 'Bearer ' + token
      },

    })
      .then(response => response.json())
      .then(data => {
        notify_del_element();
      })
      .catch(e => {
        error(e);
      });
    onHide();
  } else if(typeid !== 0) {


   let allElements = elements;


  /* for(let e=0;e < allElements.length;e++){

      if (allElements[e].props.children[0].props.children !== undefined && allElements[e].props.children[0].props.children.props.id === element) {
        setDeleteElements(e);
      }
    }*/

    if(element !== null && element !== undefined) {
      element.parentNode?.removeChild(element);
    }
  }
  if(parentID !== undefined && parentID !== null && parentType !== undefined && parentType !== null && parentType !== '') {

    deleteElement(parentID,onHide,parentType,setDeleteElements,elements,null,null)
  }
}

function modalDel(setModalShow:any,setModalData:any,titel:any,id:any,typeid:any,parentID:any,parentType:any){
  setModalShow(true)

  setModalData([titel,id,typeid,parentID,parentType])
}


function modal(modalShow: boolean, setModalShow: (value: (((prevState: boolean) => boolean) | boolean)) => void,modalData:any,Store:any){

  return(<>
      <MyVerticallyCenteredModal
        show={modalShow}
        titel={modalData[0]}
        id={modalData[1]}
        typeid={modalData[2]}
        parentID={modalData[3]}
        parentType={modalData[4]}
        onHide={() => setModalShow(false)}
        Store={Store}
      />
    </>
  )
}

function MyVerticallyCenteredModal(props:any) {

  let identifier = 'Element:'+props.titel+' ID:'+props.typeid +' Position: '+ props.id?.replace(/\D/g, "")  ;

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Position: {props.id?.replace(/\D/g, "")}-{props.titel}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4><IntlMessage Store={props.Store} messageId="common.confirm_delete"/></h4>
        <p>
          <IntlMessage Store={props.Store} messageId="common.assure_delete"  preperator="replace" param="[title]" replace={identifier}  />
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}><IntlMessage Store={props.Store} messageId="common.close" /></Button>
        <Button type={'button'} variant="danger"  onClick={() => deleteElement(props.id,props.onHide,props.typeid,false,[],props.parentID,props.parentType)}><IntlMessage Store={props.Store} messageId="common.delete"/></Button>
      </Modal.Footer>
    </Modal>
  );
}
