import React from 'react';
import Table from 'react-bootstrap/Table';
import BuildBoardwithIssue from './buildBoardwithIssue';
import {HelperOverviewth} from '../helper/HelperOverviewth';
import BoardProps from '../../Interface/board.interface';

const Board = (props:BoardProps) => {

  return (<>
      <Table bordered>
        <thead>
        <tr>
          <HelperOverviewth row={props.config[2]} />
        </tr>
        </thead>
        <tbody>
        <BuildBoardwithIssue config={props.config} archiv={props.archiv}/>
        </tbody>
      </Table>
    </>
  );

};

export default Board;

