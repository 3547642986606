import React from 'react';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Spinner from 'react-bootstrap/Spinner'
import PielersForm from '../../../../../../../@WUM/core/form-serilizer'
import configuration from '../configuration/profil';
import IntlMessage from '../../../../../../../@WUM/core/component/const/IntlMessage';

function profil(response: any,Store:any){

  if(response !== true && response.hasOwnProperty('address')) {
    return (
      <>


        <Row className="g-2" key={'spalte'}>
          <Col md key={'colone'}>
            {PielersForm(configuration('information',response,Store))}
          </Col>
        </Row>

      </>
    )
  } else {
    return (
      <><Spinner animation="border" role="status">
        <span className="visually-hidden"><IntlMessage Store={Store} messageId="common.loading" /></span>
      </Spinner></>
    )
  }
}

export default profil;
