import React from 'react';
import Anayltics from "./Pages/anayltics";
import Visitor from "./Pages/visitor";
import Behave from "./Pages/behave";
import Acquisition from "./Pages/acquisition";
import Targets from "./Pages/targets";
import {LanguageSwitcherValue} from "./components/LanguageSwitcher";

export const MatomoPagesConfig = (value: any) => {

    return [
        {
            path: 'analytics',
            elements: <Anayltics value={value}/>,
            roles: ['superadmin','admin']
        },
        {
            path: '/analytics/visitor',
            elements: <Visitor value={value}/>,
            roles: ['superadmin','admin']
        },
        {
            path: '/analytics/behave',
            elements: <Behave value={value}/>,
            roles: ['superadmin','admin']
        },
        {
            path: '/analytics/acquisition',
            elements:<Acquisition value={value}/>,
            roles: ['superadmin','admin']
        },
        {
            path: '/analytics/targets',
            elements: <Targets value={value}/>,
            roles: ['superadmin','admin']
        }
    ];
}

export const MatomoMenuConfig = (value:any) => {
    return [{
        header: LanguageSwitcherValue("matomo.menu.header"),
        icon: 'stacked_line_chart',
        roles: ['superadmin','admin'],
        menuitem: [{
            name: LanguageSwitcherValue("matomo.menu.item.dashboard"),
            route: "/analytics",
            icon:"equalizer",
            roles: ['superadmin','admin']
        },{
            name: LanguageSwitcherValue("matomo.menu.item.visitor"),
            route: "/analytics/visitor",
            icon:"visibility",
            roles: ['superadmin','admin']
        },{
            name: LanguageSwitcherValue("matomo.menu.item.behave"),
            route: "/analytics/behave",
            icon:"psychology",
            roles: ['superadmin','admin']
        },{
            name: LanguageSwitcherValue("matomo.menu.item.acquisition"),
            route: "/analytics/acquisition",
            icon:"report",
            roles: ['superadmin','admin']
        },{
            name: LanguageSwitcherValue("matomo.menu.item.targets"),
            route: "/analytics/targets",
            icon:"track_changes",
            roles: ['superadmin','admin']
        }
        ]
    }]
}

export const matomo = () => [
    {
        title: 'matomo.menu.item.dashboard',
        href: '/analytics',
        undertitle:'Analytics',
        icon: 'equalizer',
        text: 'matomo.menu.item.dashboard.description',
        roles: ['superadmin','admin']
    },
    {
        title: 'matomo.menu.item.visitor',
        href: '/analytics/visitor',
        undertitle:'Analytics',
        icon: 'visibility',
        text: 'matomo.menu.item.visitor.description',
        roles: ['superadmin','admin']
    },
    {
        title: 'matomo.menu.item.behave',
        href: '/analytics/behave',
        undertitle:'Analytics',
        icon: 'psychology',
        text: 'matomo.menu.item.behave.description',
        roles: ['superadmin','admin']
    },
    {
        title: 'matomo.menu.item.acquisition',
        href: '/analytics/acquisition',
        undertitle:'Analytics',
        icon: 'report',
        text: 'matomo.menu.item.acquisition.description',
        roles: ['superadmin','admin']
    }
]
