import React from 'react';
import PielersForm from '../../index'
import { base, basesettings } from './base';
import IntlMessage, { IntlMessageValue } from '../../../component/const/IntlMessage';
import SunEditorBig from '../../../component/const/SunEditorBig';
import CopyElements from "../../../component/const/CopyElements";
import WebBuilderElementsHead from "../../../component/const/WebBuilderElementsHead";
import Col from 'react-bootstrap/Col';
import Row from "react-bootstrap/Row";
import Box from '@mui/material/Box';

function text_teaserWebBuilder(data: any, element: any, i: any, newElement: any = false, newelementIndex: any = 0, modalShow: boolean,
  setModalShow: (value: (((prevState: boolean) => boolean) | boolean)) => void, modalData: any[],
  setModalData: (value: (((prevState: any[]) => any[]) | any[])) => void,
  content: any = [], setElements: any = [], Store: any) {

  const index = (newElement === false ? i : newelementIndex)

  const configuration = [
    {
      'formType': 'input',
      'label': <IntlMessage Store={Store} messageId="common.headline" />,
      'type': 'text',
      'placeholder': IntlMessageValue("de", "common.headline", Store),
      'name': 'elements[' + index + '][translation][subline]',
      'value': (newElement === false ? (element.translation !== undefined ? element.translation.de.subline : '') : ''),
    },
    SunEditorBig(element.translation.de, 'teaser', newElement, data, 'elements[' + index + '][translation][teaser]', 'Teaser'),
    SunEditorBig(element.translation.de, 'text', newElement, data, 'elements[' + index + '][translation][text]', IntlMessageValue("de", 'common.edit_text', Store)),
  ]

  let newConfiguration = configuration.concat(base(element, i, newElement, newelementIndex));
  let Status;
  if (element.visible === 1) {
    Status = 'elementActive';
  } if (element.visible === 0) {
    Status = 'elementDeactive';
  } else if (newElement === true) {
    Status = 'elementActive';
  }
  return (
    <>
      <Box id={'text_teaser' + index} data-appendcontainer={(newElement === false && element.appendto !== null && element.appendto !== undefined && element.appendto !== '' ?  element.appendto : 'false')} >
        {WebBuilderElementsHead(<IntlMessage Store={Store} messageId="module.text_with_teaser" />, 'text_teaser', (newElement === false && element.translation !== undefined ? element.translation.de.text : ''), Status, index, 'elementButton', <IntlMessage Store={Store} messageId="module.text_with_teaser" />,
          modalShow, setModalShow, modalData, setModalData, newElement, element, content, setElements, Store, '', '', element.id)}


        <Row className={'boxShadowCard card ElementBorder'} style={{ paddingBottom: '15px', paddingTop: '15px', width: '100%', margin: '0px 0px 10px 0px' }}>
          <div id={'Teaser'}>text_teaser</div>
          <Col><IntlMessage Store={Store} messageId="module.text_with_teaser" /></Col>
          <Col className={'accordion-body element-body'}>
            {PielersForm(newConfiguration)}
            {/*<Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3" key={'tabindex'}>
              <Tab eventKey="home" title={<IntlMessage Store={Store} messageId="common.general" />} key={'allgemein'} id={'allgemein'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
                {PielersForm(newConfiguration)}
              </Tab>
              <Tab eventKey="Einstellungen" title={<IntlMessage Store={Store} messageId="settings.settings.header" locale={'de'} />} key={'Einstellungen'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
                {PielersForm(basesettings(element,i,newElement,newelementIndex,Store))}
              </Tab>
            </Tabs>*/}

          </Col>
          <div id={'settingstext' + index + 'text_teaser'} className={'settingsWebBuilderModul '} style={{ display: 'none' }}>

            {PielersForm(basesettings(element, i, newElement, newelementIndex, Store))}
          </div>
          {(newElement === false ? CopyElements(element.id, <IntlMessage messageId="module.text_with_teaser" Store={Store} />, Store) : <></>)}

        </Row>
      </Box>
    </>
  )


}

export default text_teaserWebBuilder;
