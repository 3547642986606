import React from 'react';
import PielersForm from '../../index';
import { base, basesettings } from './base';
import MergeTwoObjecttoArray from '../../../component/const/MergeTwoObjecttoArray';
import IntlMessage, { IntlMessageValue } from '../../../component/const/IntlMessage';
import SunEditorBig from "../../../component/const/SunEditorBig";
import CopyElements from "../../../component/const/CopyElements";
import Col from 'react-bootstrap/Col';
import Row from "react-bootstrap/Row";
import WebBuilderElementsHead, {Settingsprepare} from "../../../component/const/WebBuilderElementsHead";
import Box from '@mui/material/Box';
import {clearCol} from "../../../component/core/elements/content";

function image_titleWebBuilder(element: any = [], i: any, newElement: any = false, newelementIndex: any = 0, modalShow: boolean,
  setModalShow: (value: (((prevState: boolean) => boolean) | boolean)) => void, modalData: any[],
  setModalData: (value: (((prevState: any[]) => any[]) | any[])) => void, content: any = false, setElements: any = [], Store: any, galerie?: any,
  galerieItems?: any) {

  const index = (newElement === false ? i : newelementIndex);

  const fake_headlines = [1, 2, 3, 4, 5, 6].map(n => ({
    'label': IntlMessageValue('de', "module.fake_headline_n", Store, "replace", "[n]", n),
    'value': n,
  }));

  const headlines = [1, 2, 3, 4, 5, 6].map(n => ({
    'label': IntlMessageValue('de', "module.headline_n", Store, "replace", "[n]", n),
    'value': n + 6,
  }));

  let options = MergeTwoObjecttoArray(fake_headlines, headlines)

  const configuration = [
    {
      'formType': 'select',
      'label': <IntlMessage Store={Store} messageId="module.headline_type" />,
      'name': 'elements[' + index + '][headline_type]',
      'legende': <IntlMessage Store={Store} messageId="common.status" />,
      'selected': (newElement === false ? element.headline_type : ''),
      'options': options,
    }, {
      'formType': 'input',
      'label': <IntlMessage Store={Store} messageId="common.headline" />,
      'name': 'elements[' + index + '][translation][title]',
      'type': 'text',
      'placeholder': "lang[common.insert_headline]",
      'value': (newElement === false ? (element.translation !== undefined ? element.translation.de.title : '') : ''),
    },
    SunEditorBig((newElement === false ? (element.translation !== undefined ? element.translation.de : '') : ''), 'text', newElement, element, 'elements[' + index + '][translation][text]', <IntlMessage Store={Store} messageId="module.text" />),
    {
      'formType': 'multiply',
      'cols': [
        {
          'formType': 'image',
          'altLink': true,
          'id': 'image_title_image_' + index,
          'path': (newElement === false ? (element.translation !== undefined ? (element.translation.de.media !== null ? element.translation.de.media.cdn_url.replace('/templates', 'http://localhost/templates') : '') : '') : ''),
          'width': '44',
          'height': '44',
          'class': 'elementsIMg',
          'size': {
            'xl': 2,
            'md': 2,
            'sm': 2,
          }
        },
        {
          'formType': 'formFile',
          'onclick': true,
          'id': 'image_titleFormField' + index,
          'onclick_context': (newElement === false ? element.context : 'pielers_element_title_image'),
          'Gallery': galerie,
          'galerieItems': galerieItems,
          'expandfile': index + 'ImageTitle',
          'append': 'elements[' + index + '][translation][image_id]',
          'src': 'image_title_image_' + index,
          'experiment': true,
          'size': {
            'xl': 10,
            'md': 10,
            'sm': 10,
          }
        },
      ],
    },
    {
      'formType': 'input',
      'name': 'elements[' + index + '][translation][image_id]',
      'id': 'elements[' + index + '][translation][image_id]',
      'visible': true,
      'label': 'imageID',
      'type': 'text',
      'placeholder': 'imageID',
      'value': (newElement === false ? (element.translation !== undefined ? element.translation.de.image_id : '') : ''),
    },
    {
      'formType': 'input',
      'name': 'elements[' + index + '][template]',
      'type': 'hidden',
      'value': 'Elements/image_title.html',
    }


  ];
  let newConfiguration = configuration.concat(base(element, i, newElement, newelementIndex));
  const configSettings = [
    {
      'formType': 'select',
      'label': 'Parrallax',
      'name': 'elements[' + index + '][translation][parrallax]',
      'selected': (newElement === false ? (element.translation !== undefined ? element.translation.de.parrallax : '') : ''),
      'options': [
        {
          'label': IntlMessageValue('de', 'common.yes', Store),
          'value': '1',
        },
        {
          'label': IntlMessageValue('de', 'common.no', Store),
          'value': '0',
        },
      ]

    }
  ]
  let newconfigSettings = configSettings.concat(basesettings(element, i, newElement, newelementIndex, Store));

  let Status;
  if (element.visible === 1) {
    Status = 'elementActive';
  } if (element.visible === 0) {
    Status = 'elementDeactive';
  } else if (newElement === true) {
    Status = 'elementActive';
  }

  const outHeadline = (x: any) => {

    switch (x - 6) {
      case 1:
        return (<><h1> {element.translation.de.title}</h1></>)
      case 2:
        return (<><h2> {element.translation.de.title}</h2></>)
      case 3:
        return (<><h3> {element.translation.de.title}</h3></>)
      case 4:
        return (<><h4> {element.translation.de.title}</h4></>)
      case 5:
        return (<><h5> {element.translation.de.title}</h5></>)
      case 6:
        return (<><h6> {element.translation.de.title}</h6></>)
    }
  }
  return (
    <>
      <Box id={'image_title' + index}
           style={{border:'4px solid transparent'}}
           data-appendcontainer={(newElement === false && element.appendto !== null && element.appendto !== undefined && element.appendto !== '' ?  element.appendto : 'false')} >
        {WebBuilderElementsHead("", 'image_title', (newElement === false ? (element.translation !== undefined ? element.translation.de.title : '') : ''), Status, index, 'elementButton', <IntlMessage Store={Store} messageId="module.hero_image" />,
          modalShow, setModalShow, modalData, setModalData, newElement, element, content, setElements, Store, '', '', element.id)}
        <div id={'preview-container_image_title' + index} className={'preview-container '+Status} style={{display: (newElement === false ? 'block' : 'none')}}>
          {(newElement === false ?

                <span dangerouslySetInnerHTML={{ __html: element.previews }} />

               : <></>)}
        </div>
        <Row id={'mein_image_title'+index} className={'boxShadowCard card ElementBorder'} style={{width: '100%',margin:'0px 0px 10px 0px',border: '0',boxShadow: 'unset' }}>


          <Col className={' element-body'}>

            <div id={'edit_image_title' + index} className={'boxShadowCard card '+Status} style={{display:(newElement === false ? 'none' : 'block'),padding:'15px',width: '100%',marginBottom: '20px'}}>
              <Col style={{marginBottom: '10px',marginTop:'10px'}}><IntlMessage Store={Store} messageId="module.hero_image" /></Col>
              <div id={'Teaser'}>image_title</div>
              {PielersForm(newConfiguration)}

            </div>
          </Col>
          <div id={'settingstext' + index + 'image_title'} className={'settingsWebBuilderModul '} style={{ display: 'none' }}>

            {PielersForm(newconfigSettings)}
          </div>
          {(newElement === false ? CopyElements(element.id, <IntlMessage messageId="module.hero_image" Store={Store} />, Store) : <></>)}

        </Row>
      </Box>
    </>
  );


}

export default image_titleWebBuilder;

