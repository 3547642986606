import { ProductDataAction } from '../../Actions/ActionType'
const initialState = {
  loading: false,
  data: null,
  error: null,
  variant: null,
  datasheet: null,
  shipping: null,
  tax: null,
  allTaxon: null,
  allProduct: null,
  Images: null,
  article: null,
  dimensions: null,
}
type action = {
  type: string
  data?: any
  error?: any
  variant?: any
  datasheet?: any
  shipping?: any
  tax?: any
  allTaxon?: any
  allProduct?: any
  Images?: any
  article?: any
  dimensions?: any

}
const ProductReducer = (state = initialState, action: action) => {
  switch (action.type) {
    case ProductDataAction.PRODUCT_INIT:
      return {
        ...state,
        loading: true,
      }
    case ProductDataAction.PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      }
    case ProductDataAction.PRODUCT_VARIANT:
      return {
        ...state,
        variant: action.variant,
        loading: false,
      }
    case ProductDataAction.PRODUCT_DATASHEET:
      return {
        ...state,
        datasheet: action.datasheet,
        loading: false,
      }
    case ProductDataAction.PRODUCT_SHIPPING:
      return {
        ...state,
        shipping: action.shipping,
        loading: false,
      }
    case ProductDataAction.PRODUCT_PRODUCT_TAX:
      return {
        ...state,
        tax: action.tax,
        loading: false,
      }
    case ProductDataAction.PRODUCT_ALL_TAXON:
      return {
        ...state,
        allProduct: action.allProduct,
        loading: false,
      }
    case ProductDataAction.PRODUCT_ALL_PRODUCT:
      return {
        ...state,
        allProduct: action.allProduct,
        loading: false,
      }
    case ProductDataAction.PRODUCT_IMAGES:
      return {
        ...state,
        image: action.shipping,
        loading: false,
      }
    case ProductDataAction.PRODUCT_ARTICLE:
      return {
        ...state,
        article: action.article,
        loading: false,
      }
    case ProductDataAction.PRODUCT_DIMENSIONS:
      return {
        ...state,
        image: action.shipping,
        loading: false,
      }

    case ProductDataAction.PRODUCT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    default:
      return state
  }
}

export default ProductReducer
