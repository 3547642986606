const SearchConfig = (destination:any) => {

    return [{
        namespace: destination+"_searchValue",
        destination: destination,
        input:[
            {id:'search_name',translation: 'Nach Usernamen suchen',param: 'username',method: 'like'},
          /*  {id:'search_name',translation: 'Nach Namen suchen',param: 'customer.name',method: 'like'}*/
        ],
        button: ['common.search','common.del.search','Neuen User anlegen']
    }]
}

export default SearchConfig;
