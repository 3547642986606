
const CardJSX = (link:any,destination:any) => {
  return {
    'data': {
      'ressource': '',
      'dontStatus': true,
      'img': '',
      'status': 'visible',
      'statusActive': '',
      'statusDeactive': '',
      'link': 'translation.de.slug',
      'prelink': link+'/magazin/[param]/',
      'prelinksearch': 'translation.de.slug',
      'title': 'username',
      'descriptionShow': true,
      'description': 'customer.name',
      'router': 'id',
      'preroute': '/'+destination+'/Detail/',
      'edit': '',
      'delete': true,
      'Dontvisited': true,
      'AddressShow': true,
      'AddressDontShowGF': true,
      'Address': [{
        'street': 'address.0.street',
        'plz': 'address.0.postcode',
        'city': 'address.0.city',
        'HRB': 'hrb',
        'gffirstname':'customer.name',
        'gflastname':'last_name'
      }],
      'showauthor': true,
      'author_firstname': 'customer.firstName',
      'author_lastname': 'customer.last_name',
      'deleteLink': ''
    }
  }
}

export default CardJSX;
