import React from 'react';
import RewriteOverview from "./Pages/overview";
import RewriteDetail from "./Pages/Detail";
import RewriteNew from "./Pages/new";
import RewriteDeleted from "./Pages/deleted";

export const RewriteagesConfig = (value:any) => {

  return [
      {
        path: '/rewrite/overview/:page',
        elements: <RewriteOverview value={value} />,
          roles: ['superadmin','admin','operator']
      },
      {
        path: '/rewrite/Detail/:id',
        elements: <RewriteDetail value={value} />,
          roles: ['superadmin','admin','operator']
      },
      {
        path: '/rewrite/Delete/:page/:deleteID',
        elements: <RewriteDeleted value={value} />,
          roles: ['superadmin','admin','operator']
      },
      {
        path: '/rewrite/new/',
        elements: <RewriteNew value={value} />,
          roles: ['superadmin','admin','operator']
      },
      {
          path: '/rewrite/new/:page',
          elements: <RewriteNew value={value} />,
          roles: ['superadmin','admin','operator']
      },
];
}

export const  RewriteMenuConfig = (value:any) => {
  return [
    {
      name: 'Rewrite Rules',
      route: '/rewrite/overview/1',
      icon: 'rule',
        roles: ['superadmin','admin','operator']
    }
  ]
}
