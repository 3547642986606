import React from 'react';
import LabelMakerProps from '../../Interface/labelMaker.interface';
import IssueBadge from './badge';



const LabelMaker = (props:LabelMakerProps) => {

  let labelHolder = [];

  if (props.label !== undefined  && props.label !== null) {
    for (let x = 0; x < props.label.length; x++) {
      labelHolder.push(<IssueBadge bg={props.label[x].bg} tg={props.label[x].tg} text={props.label[x].text} id={props.label[x].id} />);
    }
  } else {
    labelHolder.push(<></>);
  }

  return (<>{labelHolder}</>);
};

export default LabelMaker;