import React from "react";
import VisitorHome from "../../elements/visitor/home";
import VisitorLog from "../../elements/visitor/VisitorLog";
import VisitsInRealTime from "../../elements/visitor/VisitsInRealTime";
import VisitorMapInRealTime from "../../elements/visitor/VisitorMapInRealTime";
import Places from "../../elements/visitor/Places";
import Device from "../../elements/visitor/Device";
import Software from "../../elements/visitor/Software";
import Time from "../../elements/visitor/Time";
import CustomerId from "../../elements/visitor/CustomerId";
import { LanguageSwitcherValue } from "../../components/LanguageSwitcher";


const VisitorConfig = (Store:any) => {

    let config = [{
        tab: {defaultActiveKey:'home',id:'uncontrolled-tab-example',className:'mb-3 tabMatomo',key:'tabindex'},
        tabs:[
            {eventKey:'home',title:LanguageSwitcherValue ('VisitorConfig.home.title'),key:'home',id:'home',class:'tabsMatomo',content:<VisitorHome Store={Store}  />},
            {eventKey:'Besucher-Log',title:LanguageSwitcherValue ('VisitorConfig.Besucher-Log.title'),key:'Besucher-Log',id:'Besucher-Log',class:'tabsMatomo',content:<VisitorLog Store={Store} />},
            {eventKey:'Echtzeit',title:LanguageSwitcherValue ('VisitorConfig.Echtzeit.title'),key:'Echtzeit',id:'Echtzeit',class:'tabsMatomo',content:<VisitsInRealTime Store={Store} />},
            {eventKey:'map',title:LanguageSwitcherValue ('VisitorConfig.map.title'),key:'map',id:'map',class:'tabsMatomo',content:<VisitorMapInRealTime Store={Store} />},
            {eventKey:'Orte',title:LanguageSwitcherValue ('VisitroConfig.Orte.title'),key:'Orte',id:'Orte',class:'tabsMatomo',content:<Places Store={Store} />},
            {eventKey:'Geräte',title:LanguageSwitcherValue ('VisitorConfig.Geräte.title'),key:'Geräte',id:'Geräte',class:'tabsMatomo',content:<Device Store={Store} />},
            {eventKey:'Software',title:LanguageSwitcherValue ('VisitorConfig.Software.title'),key:'Software',id:'Software',class:'tabsMatomo',content:<Software Store={Store} />},
            {eventKey:'Zeiten',title:LanguageSwitcherValue ('VisitorConfig.Zeiten.title'),key:'Zeiten',id:'Zeiten',class:'tabsMatomo',content: <Time Store={Store} />},
            {eventKey:'ids',title:LanguageSwitcherValue ('VisitorConfig.ids.title'),key:'ids',id:'ids',class:'tabsMatomo',content:<CustomerId Store={Store} />},
        ]
    }]


    return config;

}

export default VisitorConfig;
