import {Post} from "../../../api/request/faqApi.interface";
/**
 *
 * @memberOf blog
 * @typedef config
 * @prop {number} visible ID des Language Key
 * @prop {string} identifier Preperator Function name
 * @prop {number} discr Param for Preperator Function
 * @prop {string} layout Replace value for Preperator
 * @prop {string} position Replace value for Preperator
 * @prop {number} image_id Replace value for Preperator
 * @prop {number} author_id Param for Preperator Function
 * @prop {string} share_title Replace value for Preperator
 * @prop {string} share_description Replace value for Preperator
 * @prop {Element} elements Replace value for Preperator
 * @prop {Translations} translations Replace value for Preperator
 */
export interface configFAQ {
  id: number;
  cat_id: number;
  name: string;
  description?: any;
  locale: string;
  parent?: number;
  type: string;
  status: string;
  posts: Post[];
}

/**
 *
 * @memberOf blog
 * @prop Data
 */
export function outputFAQ(Data: any) {
  let back = {
    id: Data.id,
    cat_id:Data.cat_id,
    name: Data.name,
    description: Data.description,
    locale: Data.locale,
    parent:Data.parent,
    type:Data.type,
    status: Data.status,
    posts: Data.posts


  }

  return back;
}
