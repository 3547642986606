import React, {useEffect} from "react";
import {useNavigate, useParams} from 'react-router-dom';
import {useLocalStorage} from "../../../../core/component/const/useLocalStoreage";
import {SpinnerJSX} from "../../../../core/component/const/Spinner";
import updateData from "../../../../core/component/core/components/updateData";
import FormCMS from "../../../../core/component/core/components/Form.cms";
import * as actions from '../../../../../store/Actions/'
import { useSelector,useDispatch  } from 'react-redux'
import FAQNewInterface from "../../../interface/FAQNew.interface";
import {faq} from "../../../api/request/faq";
import {faqApiType} from "../../../api/request/faqApi.interface";
import FAQDetailTabsConfig from "../configuration/Page/faq/FAQDetailTabs.config";
import {ResponseFAQDetail} from "../../../api/response/faq";

const FAQDetail = (props: FAQNewInterface) => {
    const dispatch = useDispatch()
    const Store = useSelector((state: any) => state)
  
    const {id} = useParams<{ id: string }>();
    // redux call new code

    //

    let response2 = ResponseFAQDetail(id); // old data callmeins

    const [currentTutorial, setCurrentTutorial] = React.useState<faqApiType[]>(response2);
    const [element, setElement] = React.useState([]);
    const [modalShow, setModalShow] = React.useState(false);
    const [modalData, setModalData] = React.useState([]);
    const [seo_title, setseo_title] = useLocalStorage("seo_title", "");
    const [seo_description, setseo_description] = useLocalStorage("seo_description", "");
    const [deleteElement, setdeleteElement] = React.useState(0);
    const history = useNavigate();
    const [Index, setIndex] = React.useState(0);
    const [validated, setValidated] = React.useState(false);


    const debug = (deleteElement: number) => {
        return;
    }

    debug(deleteElement);

    console.log(response2)

    if (response2.length > 0 ) {
        return (
            <>
                <FormCMS history={history} element={element} setElement={setElement} id={id} Index={Index} setIndex={setIndex} currentTutorial={currentTutorial}
                         modalData={modalData} modalShow={modalShow} setModalShow={setModalShow} setModalData={setModalData} seo_title={seo_title}
                         setseo_title={setseo_title} setseo_description={setseo_description} seo_description={seo_description} response2={response2}
                         part={'faq'} setdeleteElement={setdeleteElement} setValidated={setValidated} validated={validated} NowRoute={faq}
                         setCurrentTutorial={setCurrentTutorial} updateData={updateData} config={FAQDetailTabsConfig} Store={Store} nonPreview={true}
                           />

            </>
        )
    } else {
        return (
            <><SpinnerJSX messageId={'common.loading'} Store={Store} /></>
        );
    }
}

export default FAQDetail;

