import CreateMatomoIframe from "../../core/CreateMatomoIframe";
import WidgetURLMapping from "../../core/WidgetURLMapping";
import React from "react";


const Insights = () => {
    return(
        <>
            <CreateMatomoIframe
                headline={'Insights Übersicht'}
                width={"100%"}
                height={"400"}
                url={WidgetURLMapping({name:'Insights overview'})}
                scrolling={"yes"}
                id={'insights1'}
            />

            <CreateMatomoIframe
                headline={'Verschiebung und Streuungen'}
                width={"100%"}
                height={"600"}
                url={WidgetURLMapping({name:'Shift and Scatter'})}
                scrolling={"yes"}
                id={'insights2'}
            />
        </>
    )
}

export default Insights;