
export const GeneralFields = [
    {identifier: 'border-color',type:'input',field:'border-color'},
    {identifier: 'border-width',type:'input',field:'border-width'},
    {identifier: 'border-style',type:'select',field:'border-style'},
    {identifier: 'border-radius',type:'input',field:'border-radius'},
    {identifier: 'box-shadow-type',type:'select',field:'box-shadow-type'},
    {identifier: 'box-shadow-x-offset',type:'input',field:'box-shadow-x-offset'},
    {identifier: 'box-shadow-y-offset',type:'input',field:'default-box-y-offset'},
    {identifier: 'default-box-blur',type:'input',field:'default-box-blur'},
    {identifier: 'default-box-spread',type:'input',field:'default-box-spread'},
    {identifier: 'default-box-color',type:'input',field:'default-box-color'},
    {identifier: 'primary-color',type:'input',field:'primary-color'},
    {identifier: 'secondary-color',type:'input',field:'secondary-color'},
    {identifier: 'tertiary-color',type:'input',field:'tertiary-color'},
    {identifier: 'background-color',type:'input',field:'background-color'},
    {identifier: 'font-color',type:'input',field:'font-color'},
    {identifier: 'font-family',type:'input',field:'font-family'},
    {identifier: 'font-size',type:'input',field:'font-size'},
    {identifier: 'font-weight',type:'input',field:'font-weight'},
    {identifier: 'letter-spacing',type:'input',field:'letter-spacing'},
    {identifier: 'white-space',type:'input',field:'white-space'},
    {identifier: 'logo_src',type:'input',field:'logo'},
    {identifier: 'favicon_src',type:'input',field:'favicon'}
    ]
