import IntlMessage from '../../../../../../@WUM/core/component/const/IntlMessage';
import React from 'react';
import Badge from 'react-bootstrap/Badge'

const pdfs = [
  {
  'name': 'MYHOMICE',
  'file':[
  {
  'url': '/assets/files/myHomice_B2B_AGB_Marktplatz_Oktober_2021.pdf',
  'name': 'myHomice B2B AGB Marktplatz Oktober 2021',
}]
},{
  'name': 'KLIMABAUHELD',
    'file': null,
}
];

const CreateHolderPDF = (Store:any) => {
    const holderPdf:any = [];

for (let pdf = 0; pdf < pdfs.length; pdf++) {
    if (pdfs[pdf].name === process.env.REACT_APP_CHANNEL) {
        if (pdfs[pdf].file !== null) {
            // @ts-ignore
            for (let file = 0; file < pdfs[pdf].file.length; file++) {
                // @ts-ignore

                holderPdf.push(<><a href={pdfs[pdf].file[file].url} download>
           <span className="material-icons" style={{verticalAlign: '-20%', color: 'red'}}>
                picture_as_pdf
            </span> {
                    // @ts-ignore
                    pdfs[pdf].file[file].name}
                </a></>)
            }
        } else {
            holderPdf.push(<><Badge pill bg="light" text="dark"><IntlMessage Store={Store} messageId="info.pdfs_soon"/></Badge></>)
        }
    }
}
return holderPdf
}


const DashbboardOverview = (value:any,Store:any) => {

    return (
      <>
        <div className={'dashboard'}>
        <h3><IntlMessage Store={Store} messageId="common.info_material" /></h3>
          {CreateHolderPDF(Store)}
        </div>
      </>
    )


};


export default DashbboardOverview;

