import React, {useState} from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Button from 'react-bootstrap/Button';
import {
  useNavigate,
} from 'react-router-dom';
import home from '../../../../../@WUM/core/component/core/elements/home';
import Content from '../../../../../@WUM/core/component/core/elements/content';
import seo from '../../../../../@WUM/core/component/core/elements/seo';
import {init2} from "../../../../../@WUM/core/form-serilizer/seriliaze/Formserialize";
import {config, output} from "../configuration/mapping";
import {notify_save} from '../../../../../@WUM/core/component/const/notification.service';
import {error} from "../../../../../@WUM/core/component/const/error.services";
import {handleSubmitForm} from "../../../../../@WUM/core/component/const/handleSubmitForm";
import {Form} from 'react-bootstrap';
import {useLocalStorage} from "../../../../../@WUM/core/component/const/useLocalStoreage";
import IntlMessage from "../../../../../@WUM/core/component/const/IntlMessage";
import {template} from "../../API/request/site/template";
import {templateApiDetailType} from "../../API/request/site/templateApiDetail.interface";
import TemplateNewInterface from "../../interface/TemplateNew.interface";
import {useSelector} from "react-redux";
import share from '../../../../../@WUM/core/component/core/elements/share';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


const TemplateNew = (props:TemplateNewInterface) => {


  const history = useNavigate();
  const Store = useSelector((state: any) => state)
  const [currentTutorial, setCurrentTutorial] = useState<templateApiDetailType[]>([]);
  const [element,setElement] = useState([]);
  const [modalShow, setModalShow] = React.useState(false);
  const [modalData, setModalData] = React.useState([]);
  const [Index,setIndex] = React.useState(0);
  const [validated, setValidated] = useState(false);
  const [seo_title, setseo_title] = useLocalStorage("seo_title", "");
  const [seo_description, setseo_description] = useLocalStorage("seo_description", "");
  /**
   * Sendet Formular Daten an die API
   * @param status
   * @param id
   */
  const updateData = (status: boolean, id: any = 0,form:any) => {

    /*
     *  Wichtig zum Rausholen der Datan aus Formular und senden
     *  Das Interface muss immer angepasst werden
     */
    let Data = init2(form);

    let reauestData: templateApiDetailType[] &
      config
      = Object.assign({}, currentTutorial,
      output(Data));


    /*
     *  Senden der Erhaltenen Daten an das Frontend
     */
    template.createPost(reauestData, id)
      .then(response => {
        setCurrentTutorial({...currentTutorial});
        notify_save(Store);
        setTimeout(() => window.location.href = '/Template/overview/1', 3000);
      })
      .catch((e: any) => {
        error(e);
      });

  };


  return (
    <div>
      <Form  id={'form001'} className={'formControl'} noValidate validated={validated} onSubmit={(e) => handleSubmitForm(e, updateData, setValidated, 0)}>
        <Row>


          <Col  style={{display:'block',minWidth:'260px',maxWidth:'260px'}}>

            <div className={'settingsContent'}>
              <div id={'home'} className={'pl-3 pr-3'}>
                {home({response:'new',Store:Store})}
              </div>
              <div id={'seo'} className={'pl-3 pr-3'}>
                {seo({response:'new',seo_title:seo_title,setseo_title:setseo_title,seo_description:seo_description,setseo_description:setseo_description,Store:Store})}
              </div>
              <div id={'share'} className={'pl-3 pr-3'}>
                {share({response:'new',Store:Store})}
              </div>
            </div></Col>
          <Col >
            {Content({response:'new', elements:element, setElements:setElement, modalShow:modalShow,
              setModalShow:setModalShow, modalData:modalData, setModalData:setModalData,setdeleteElement:false,Index:Index,setIndex:setIndex,Store:Store})}</Col>
        </Row>
        <Row className={'ContentButton'}>
          <Row className={'m-0'} style={{height: '100%'}}>
            <Col className={'mt-2 text-center'}>
              <Button variant='light' style={{float: 'left', marginLeft: '15px'}} onClick={() => history(-1)}><IntlMessage Store={Store} messageId="common.back"/></Button>
            </Col>
            <Col className={'mt-2  text-center'} >
              <Button variant='success'
                      style={{float: 'right', marginRight: '15px'}}
                      type={'submit'}
              >
                <IntlMessage Store={Store} messageId="common.save" />
              </Button>
            </Col>
          </Row>
        </Row>

        <div style={{backgroundColor: 'white', padding: '15px'}}>

          <br style={{clear: 'both'}} />
        </div>
      </Form>
    </div>
  );
}


export default TemplateNew;

/*
3800 = 2785 netto 975 = 3760 = 750 = 4510 + 450 = 4960 von 01.05 - 31.07
5500 = 3750 netto 975 = 4725 = 750 = 5475 + 450 = 5925 von 01.08 - 31.10
7500 = 5000 netto 975 = 6975 = 750 = 7725 + 450 = 8175 von 01.11 - ever
*/
