import {MenuApiDetail} from "./formularApiDetail.interface";


export interface configMenu {
  Menu:     MenuApiDetail[];
}

/**
 *
 * @memberOf blog
 * @prop Data
 */
export function outputMenu(Data: any) {
  let back = {
    Menu: Data.Menu


  }

  return back;
}
