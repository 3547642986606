import {IntlMessageValue} from "./IntlMessage";
import React from "react";


const TooltipModal = () => {

    return(
        <>
            <div id="tooltip" className="modal-tooltip">


                <div className="modal-content-tooltip">
                    <div className="modal-header-tooltip">
                        <span className="close" onClick={() => closeTooltip()}>&times;</span>
                        <h2 id={'TooltipHeader'}>...Loading</h2>
                    </div>
                    <div id={'TooltipContent'} className="modal-body-tooltip">
                        ..Loading
                    </div>
                    <div className="modal-footer-tooltip">
                        <p style={{float: 'right'}}>©2022 WUM Solution UG </p>
                        <br style={{clear:'both'}} />
                    </div>
                </div>
            </div>
        </>
    )

}

export default TooltipModal;

export const TooltipIcon = (props:{name:any,Store:any,locale:any,content:any,Setclass?:any,mode?:any}) => {

    let makeClass = props.Setclass;

    let loadMode = props.mode;

    if(props.mode === undefined) {
        loadMode = false;
    }

    if(props.Setclass === undefined) {
        makeClass = '';
    }

    return(
        <>
            <div className={'tooltips ' + makeClass}
                 onClick={() => Dialog(props.name, props.content, props.Store,props.locale,loadMode)}><span
                className="material-icons ">info </span></div>
        </>
    )
}


export const closeTooltip = () => {
    var modal = document.getElementById("tooltip") as HTMLDivElement;
    modal.style.display = "none";
}

export const replaceChar = (key: any) => {
    key = key.toLowerCase();
    key = key.replace(/ä/g, 'ae');
    key = key.replace(/ö/g, 'oe');
    key = key.replace(/ü/g, 'ue');
    key = key.replace(/ /g, '_');
    key = key.replace(/\[+([^\][]+)]+/g, '#');
    key = key.replace(/br/g, '#');
    key = key.replace(/#/g, '');
    return key;
}

export const Dialog = (headline: any,content:any, Store: any, locale: any,loadMode:any) => {


    // Get the modal
    var modal = document.getElementById("tooltip") as HTMLDivElement;

    let header = document.getElementById('TooltipHeader') as HTMLHeadElement

    let contents = document.getElementById('TooltipContent') as HTMLDivElement

    header.innerHTML = headline;

    if(loadMode === false) {
        contents.innerHTML = '<br/>'+IntlMessageValue(locale, replaceChar(content)+'.'+replaceChar(headline) + '.tooltip.content', Store)+'<br/><br/>';
    } else {
        contents.innerHTML = '<br/>'+IntlMessageValue(locale, replaceChar(content), Store)+'<br/><br/>';
    }


    modal.style.display = "block";

// When the user clicks anywhere outside of the modal, close it
    window.onclick = function (event) {
        if (event.target == modal) {
            modal.style.display = "none";
        }
    }
}
