/**
 * Erstellt den Header
 * @param name
 * @param nameID
 * @param Status
 * @param index
 * @param type
 * @param typeName
 * @param modalShow
 * @param setModalShow
 * @param modalData
 * @param setModalData
 * @param newElement
 * @param element
 * @param content
 * @param setElements
 * @constructor
 */
import {Accordion, Col, Row} from "react-bootstrap";
import deleteElements from "../../form-serilizer/elements/helper/deleteElements";
import deleteForms from "../../form-serilizer/elements/helper/deleteForms";

export const FormsHead  = (name:any,nameID:any,preview:any,Status:any,index:any,type:any,
                              typeName:any,modalShow:any,setModalShow:any,modalData:any,
                              setModalData:any,newElement:any,element:any,content:any = false,setElements:any = [],Store:any) => {
    let Margin;
    if(newElement === false) {
        Margin = '-6px';
    } else {
        Margin = '6px';
    }
    return (<><Row>
        <Col style={{width: '60px', maxWidth: '60px'}}>
            <h3 className={Status} style={{height:'100%',marginTop: '0px',padding:'0.08em 0em',width:'60px',textAlign:'center'}}>

        <span className="material-icons" style={{fontSize: 'xxx-large',marginTop: Margin,color:'black'}}>
     open_with
</span></h3></Col><Col>
        <Accordion.Button style={{background:'#f9fafb',border:'1px solid black'}}>
            <h3>{name}</h3>
            <span style={{marginLeft:'10px'}}>  ({preview.substr(0, 50)})</span>
            {deleteForms(nameID.toLowerCase()+''+index,type,typeName,modalShow, setModalShow,modalData, setModalData,(newElement === false ? element.id : 0 ),content,setElements,Store)}
        </Accordion.Button>

    </Col>
        {/* <Col style={{width: '50px', maxWidth: '50px',marginTop: '10px'}}>
      {deleteElements(nameID.toLowerCase()+''+index,type,typeName,modalShow, setModalShow,modalData, setModalData,(newElement === false ? element.id : 0 ),content,setElements)}
    </Col>*/}
    </Row></>)
}

export default FormsHead;
