import {Row,Col} from "react-bootstrap";
import React from "react";
import Form from "react-bootstrap/Form";
import SunEditor from "suneditor-react";
import {SunEditorConfig} from "../../component/const/SunEditorConfig";


const IconHolder = (data: any) => {


    let row = [];
    let newother;
    let other = 10;
    let length;
    if(data.value !== undefined) {
        for (let x = 0; x < data.value.length; x++) {
            row.push(<Row>
                <Row>
                    <Col>
                        <Form.Group controlId={"formFile" + x + 'Icon'} key={"formFile" + x + 'Icon'}>
                            <Form.Label key={"formFileLabel" + x + 'IconLabel'}>Icon</Form.Label>
                            <Form.Control name={'elements[' + data.index + '][items][' + x + '][icon]'}
                                          defaultValue={(data.value[x].icon === null ? '' : data.value[x].icon)}/>
                            <Form.Control name={'elements[' + data.index + '][items][' + x + '][id]'} type={'hidden'}
                                          defaultValue={(data.value[x].id === null ? 0 : data.value[x].id)}/>
                            <Form.Control name={'elements[' + data.index + '][items][' + x + '][parent]'}
                                          type={'hidden'} defaultValue={(data.parent === null ? 0 : data.parent)}/>
                        </Form.Group>
                    </Col><Col>
                    <Form.Group controlId={"formFile" + x + 'headline'} key={"formFile" + x + 'headline'}>
                        <Form.Label key={"formFileLabel" + x + 'headlineLabel'}>headline</Form.Label>
                        <Form.Control name={'elements[' + data.index + '][items][' + x + '][iconHeadline]'}
                                      defaultValue={data.value[x].iconHeadline}/>
                    </Form.Group>
                </Col>
                </Row><Row>
                <Col><label>Text Bearbeiten</label>
                    <SunEditor name={'elements[' + data.index + '][items][' + x + '][text]'} lang={'de'}
                               defaultValue={data.value[x].text} key={'Sun' + x}
                               height={'250px'}
                               setOptions={{
                                   buttonList: SunEditorConfig(),
                                   attributesWhitelist:
                                       {
                                           div: 'id',
                                           p: 'id',
                                           h: 'id'
                                       }
                               }}/></Col>
            </Row>
                <br/>
                <hr style={{marginTop: '10px'}}/>
                <br/>
            </Row>)
        }
        newother = other - data.value.length;
        length = data.value.length;
    } else {
        newother = other - 0;
        length = 0;
    }






    if(newother >=0) {

    for(let xs=0;xs<newother;xs++) {
        let index = xs + length
        row.push(<Row>
            <Row>
                <Col>
                    <Form.Group controlId={"formFile" + index + 'Icon'} key={"formFile" + index + 'Icon'}>
                    <Form.Label key={"formFileLabel" + index + 'IconLabel'}>Icon</Form.Label>
                    <Form.Control name={'elements[' + data.index + '][items]['+index+'][icon]'} defaultValue={''}/>
                    <Form.Control name={'elements[' + data.index + '][items]['+index+'][id]'} type={'hidden'} defaultValue={0}/>
                    <Form.Control name={'elements[' + data.index + '][items]['+index+'][parent]'} type={'hidden'} defaultValue={(data.parent === null ? 0 : data.parent)}/>
                    </Form.Group>
                </Col><Col>
                    <Form.Group controlId={"formFile" + index + 'headline'} key={"formFile" + index + 'headline'}>
                    <Form.Label key={"formFileLabel" + index + 'headlineLabel'}>headline</Form.Label>
                    <Form.Control name={'elements[' + data.index + '][items]['+index+'][iconHeadline]'} defaultValue={''}/>
                    </Form.Group>
                </Col>
            </Row><Row>
                <Col><label>Text Bearbeiten</label>
                    <SunEditor name={'elements[' + data.index + '][items]['+index+'][text]'} lang={'de'} defaultValue={''} key={'Sun' + index}
                               height={'250px'}
                               setOptions={{
                                   buttonList: SunEditorConfig(),
                                   attributesWhitelist:
                                       {
                                           div: 'id',
                                           p: 'id',
                                           h: 'id'
                                       }
                               }}/></Col>
            </Row>
            <br />
            <hr style={{marginTop: '10px'}}/>
            <br/>
        </Row>)
    }

    }
    return (
        <>
            <Row>
                <br />
                <hr />
                <br />
                {row}

            </Row>
        </>)
}

export default IconHolder;
