import React from "react";
import Headline from "./components/Header/headline";
import IconMenu from "./components/Header/IconMenu";
import FAQ from "./components/faq/faq";
import '../css/helpdesk.css';
import Tutorials from "./components/tutorials/tutorials";
import {useSelector} from "react-redux";
const HelpDesk = () => {

    const Store = useSelector((state: any) => state)


    return (
        <>
            <div id={'helpdesk'} className="help-desk">
                <div>
                    <button className="help-desk__menu" onClick={() => helpexpand()}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
                            <path d="M9.09,9a3,3,0,0,1,5.83,1c0,2-3,3-3,3" fill="none" stroke="currentColor"
                                  strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                            <circle cx="12" cy="12" r="10" fill="none" stroke="currentColor" strokeLinecap="round"
                                    strokeLinejoin="round" strokeWidth="2"/>
                            <line x1="12" y1="17" x2="12" y2="17" fill="none" stroke="currentColor"
                                  strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                        </svg>
                    </button>
                    <div className="help-desk__content">
                        <div>
                    <Headline />
                    <IconMenu />
                    <Tutorials />
                    <FAQ Store={Store}/>
                </div>
                    </div>
                </div>
            </div>
        </>
    )

}
export default HelpDesk;


export const helpexpand = () => {

    let element = document.getElementById('helpdesk') as HTMLDivElement;


    let i = 0;
    for(let x=0;x<element.classList.length;x++) {
        if(element.classList[x] === 'help-desk--expanded') {
            i++;
        }
    }

    if(i === 0) {
        element.classList.add("help-desk--expanded");
    } else {
        element.classList.remove("help-desk--expanded");
    }
}

export const switcher = (target:any,id:any) => {

    let elementTarget = document.getElementById(target) as HTMLDivElement;

    let element = document.getElementById(id) as HTMLDivElement;

    elementTarget.classList.add('d-block');
    elementTarget.classList.remove('d-none')

    element.classList.add('d-none')
}
