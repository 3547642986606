import {LocaleActionType} from "../../Actions/ActionType";

const initialState = {
    loading: false,
    locale: ''
}

type action = {
    type: string
    locale?: any
    error?: any
}

const LocaleReducer = (state = initialState,
                        action: action) => {
    switch (action.type) {
        case LocaleActionType.LOCALE_INIT:
            return {
                ...state,
                locale: action.locale,
                loading: true,
            }
        default:
            return state
    }
}

export default LocaleReducer;
