import VisibilityIcon from '@material-ui/icons/Visibility';
import React from 'react';
import ListItemIcon from '@material-ui/core/ListItemIcon';

function eye() {
return (
  <>
<ListItemIcon>
<VisibilityIcon fontSize="small" />
  </ListItemIcon>
</>
);
}

export default eye;