import React from 'react';
import Spinner from 'react-bootstrap/Spinner'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav'
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import {saveDataIndividualPut} from '../../../../../../../@WUM/core/RestFullApi/ApiHook';
import {modalMaterials, setMaterials, setterMaterials} from './materials';
import {modalDimensions, setDimensions, setterDimensions} from './dimensions';
import {modalDataSheet} from './datasheet';
import {modalarcticle, setarcticle} from './article';
import {notify_save} from '../../../../../../../@WUM/core/component/const/notification.service';
import {error} from '../../../../../../../@WUM/core/component/const/error.services';
import {modalImg, setImg} from './Img';
import IntlMessage, {IntlMessageValue} from '../../../../../../../@WUM/core/component/const/IntlMessage';



function Home(response: any, modalMaterialsShow: boolean, setModalMaterialsShow: (value: (((prevState: boolean) => boolean) | boolean)) => void,
              materialsValue: any, setMaterialsValue:any,DimensionsValue: string | undefined,
              setDimensionsValue: { (value: any): void; (arg0: any): void; },modalDimensionsShow: boolean,
              setModalDimensionsShow: { (value: React.SetStateAction<boolean>): void; (value: boolean | ((prevState: boolean) => boolean)): void; },
              getDataSheetValue:any, saveGetDataSheetValue:any,modalDataSheetShow:any, setModalDataSheet:any,modalarcticleShow:any, setModalarcticle:any,
              arcticleValue:any, savearcticleValue:any,allTaxonValue:any, saveallTaxonValue:any,
              ImgValue:any, setImgValue:any,modalImgShow:any, setModalImgShow:any,setattribute:any, saveattribute:any,user:any,Store:any){

  if(response.length > 0 ) {

    /* Beginn Materials */
    setterMaterials(materialsValue,setMaterialsValue,response);

    const saveMaterials = () => {
      let bodyData = {'materialien': localStorage.getItem('materials')}
      let setter;
      if(localStorage.getItem('materials') !== null) {
        setter = localStorage.getItem('materials') as string;
      } else {
        setter = '';
      }
      let link = response[0].id;
        saveDataIndividualPut('/Product/Material/' + link + '/', bodyData)
          .then((data: any) => {
            notify_save(Store);
          })
          .catch(e => {
            error(e);
          });
      setMaterialsValue(setter);
      setModalMaterialsShow(false)
    }
    /* End Materials */

    /* Beginn Dimensions */
    setterDimensions(DimensionsValue,setDimensionsValue,response);

    const saveDimensions = () => {
      let bodyData = {'nutrient': localStorage.getItem('Dimensions')}
      let setter;
      if(localStorage.getItem('Dimensions') !== null) {
        setter = localStorage.getItem('Dimensions') as string;
      } else {
        setter = '';
      }
      let link = response[0].id;
      saveDataIndividualPut('/Product/Abmessungen/' + link + '/', bodyData)
        .then((data: any) => {
          notify_save(Store);
        })
        .catch(e => {
          error(e);
        });
      setDimensionsValue(setter);
      setModalDimensionsShow(false)
    }
    /* End Materials */

    let owner;

    if(response[0].merchant_is_grower === 0) {
      owner = (<><br /><p><IntlMessage Store={Store} messageId="is_not_grower" /></p><br /></>);
    } else {
      owner = '';
    }

    let endabled:string = '';
    let enabledType:string = ''
    if(response[0].enabled === 1){
      endabled = IntlMessageValue("de","available",Store);
      enabledType = 'success'
    } else {
      endabled = IntlMessageValue("de","available.not",Store);
      enabledType = 'danger'
    }

    let Kategorien='';
    let Hauptkategorie = '';
    if(localStorage.getItem('arcticle') !== null && JSON.parse(localStorage.getItem('arcticle') as string)?.Artikelkategorie !== null) {
      for (let a = 0; a < JSON.parse(localStorage.getItem('arcticle') as string)?.Artikelkategorie.length; a++) {
        if (a === (JSON.parse(localStorage.getItem('arcticle') as string)?.Artikelkategorie.length - 1)) {
          Kategorien += JSON.parse(localStorage.getItem('arcticle') as string)?.Artikelkategorie[a].name + ' ';
        } else {
          Kategorien += JSON.parse(localStorage.getItem('arcticle') as string)?.Artikelkategorie[a].name + ', ';

        }

      }
      Hauptkategorie = (JSON.parse(localStorage.getItem('arcticle') as string).Hauptkategorie[0] !== undefined ? JSON.parse(localStorage.getItem('arcticle') as string).Hauptkategorie[0].name : '')
    }

    let nutrient;
    let ingredients;
    let nutrien_icon;
    let ingredients_icon;
    if(process.env.REACT_APP_AGRICA === 'True') {
      nutrient = 'Nährwerte bearbeiten';
      nutrien_icon = 'bar_chart';
      ingredients = 'Zutaten bearbeiten';
      ingredients_icon= 'shopping_cart';
    } else {
      nutrient =  <IntlMessage Store={Store} messageId="measurements.edit" />;
      nutrien_icon = 'square_foot';
      ingredients = <IntlMessage Store={Store} messageId="materials.edit" />;
      ingredients_icon= 'precision_manufacturing';
    }

    return (
      <>
        <Navbar bg="none" expand="lg">
          <Container>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">

                  <Button variant="light" style={{ marginRight: '15px', marginBottom: '10px',marginTop: '5px'}} onClick={() => setarcticle(setModalarcticle)}>
                  <span className="material-icons" style={{ verticalAlign: '-20%' }}>create</span>
                    <span><IntlMessage Store={Store} messageId="article.main.edit" /></span>
                  </Button>

                {/*  <Button variant="light" style={{ marginRight: '15px', marginBottom: '10px',marginTop: '5px'  }} onClick={() => setDataSheet(setModalDataSheet)}>
                  <span className="material-icons" style={{ verticalAlign: '-20%' }}>content_paste</span>
                    <IntlMessage Store={Store} messageId="datasheet.edit" />
                  </Button>*/}
                  <Button variant="light" style={{ marginRight: '15px', marginBottom: '10px',marginTop: '5px'  }} onClick={() => setDimensions(setModalDimensionsShow)}>
                  <span className="material-icons" style={{ verticalAlign: '-20%' }}>{nutrien_icon}</span>
                    {nutrient}
                  </Button>
                  <Button variant="light" style={{ marginRight: '15px', marginBottom: '10px',marginTop: '5px'  }} onClick={() => setMaterials(setModalMaterialsShow)}>
                  <span className="material-icons" style={{ verticalAlign: '-20%' }}>{ingredients_icon}</span>
                    <span > {ingredients}</span>
                  </Button>
                  <Button variant="light" style={{ marginRight: '15px', marginBottom: '10px',marginTop: '5px'  }} onClick={() => setImg(setModalImgShow)}>
                  <span className="material-icons" style={{ verticalAlign: '-20%' }}>collections</span>
                    <IntlMessage Store={Store} messageId="article.images.edit" />
                  </Button>

              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <Row>
          <Col md={3}><img id={'productBild'} alt={'BILD'} style={{ width: '100%' }} src={(response[0].image !== null ? (process.env.REACT_APP_MODE === 'LOCAL' ? process.env.REACT_APP_CDN_URL_LOCAL : process.env.REACT_APP_CDN_URL)+''+(response[0].image.path !== undefined ? response[0].image.path : '') : '')} /><br />
            <Button className={'float-end'} variant={enabledType} disabled>{endabled }</Button><div className={'clearfix'}></div></Col>
          <Col md="7">
            {owner}
             <hr />
            <h2>{response[0].translation.name}</h2><hr />
            <Row><Col sm={2} md={5}><strong><IntlMessage Store={Store} messageId="common.short_description" /></strong></Col><Col sm={10} md={7}><p dangerouslySetInnerHTML={ {__html: response[0].translation.short_description} } /></Col><hr /></Row>
            <Row><Col sm={2} md={5}><strong><IntlMessage Store={Store} messageId="common.description" /></strong></Col><Col sm={10} md={7}><p dangerouslySetInnerHTML={ {__html: response[0].translation.description} } /></Col><br /><hr /></Row>
            <Row><Col sm={2} md={5}><strong><IntlMessage Store={Store} messageId="common.article_category" /></strong></Col><Col sm={10} md={7}><p>{Hauptkategorie}</p></Col><br /><hr /></Row>
            <Row><Col sm={2} md={5}><strong><IntlMessage Store={Store} messageId="category.subs" /></strong></Col><Col sm={10} md={7}><p>{Kategorien}</p> </Col><br /><hr /></Row>
          </Col>
        </Row>
        {modalMaterials(modalMaterialsShow,setModalMaterialsShow,saveMaterials,materialsValue,Store)}
        {modalImg(modalImgShow,setModalImgShow,saveMaterials,ImgValue,response[0].id,Store)}
        {modalDimensions(modalDimensionsShow,setModalDimensionsShow,saveDimensions,DimensionsValue,Store)}
        {modalDataSheet(modalDataSheetShow,setModalDataSheet,saveDimensions,getDataSheetValue,response[0].id,Store)}
        {modalarcticle(modalarcticleShow,setModalarcticle,saveDimensions,arcticleValue,response[0].id,saveattribute,user,Store)}
      </>
    )
  } else {
    return (
      <><Spinner animation="border" role="status">
        <span className="visually-hidden"><IntlMessage Store={Store} messageId="loading" /></span>
      </Spinner></>
    )
  }
}
export default Home;
