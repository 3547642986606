import {requests} from '../../../../../../@WUM/core/RestFullApi/api';
import {invoiceApiType} from './invoiceApi.interface';
import {invoiceDetailApiType} from './invoiceDetailApi.interface';


export const invoice = {
  getPosts: (limit: any, searchValue: any): Promise<invoiceApiType[]> => requests.get(`Invoice/AllInvoice/${limit}/${searchValue}`),
  getAPost: (id: string | undefined): Promise<invoiceDetailApiType[]> => requests.get(`Invoice/${id}/`),
  createPost: (post: invoiceApiType): Promise<invoiceApiType> =>
    requests.post('posts', post),
  updatePost: (post: any, id: number): Promise<invoiceApiType> =>
    requests.put(`/Content/Blog/Single/${id}/`, post),
  deletePost: (id: string): Promise<void> => requests.delete(`Content/Blog/Single/${id}/`),
};



