import PagesDetailTabsConfigProps from "../../../Pages/interface/PagesDetailTabsConfigProps.interface";
import IntlMessage from "../../../../../@WUM/core/component/const/IntlMessage";
import home from '../../../../../@WUM/core/component/core/elements/homeEmail';
import Content from '../../../../../@WUM/core/component/core/elements/content';
import React from "react";
import homeCategory from "../../../../../@WUM/core/component/core/elements/homeCategory";

const BlogDetailTabsConfig = (props:PagesDetailTabsConfigProps) => {

    let Store = props.Store;
    let config;

    if(props.response2 !== undefined) {
        config = [{
            tab: {defaultActiveKey: 'home', id: 'uncontrolled-tab-example', className: 'mb-3', key: 'tabindex'},
            tabs: [
                {
                    eventKey: 'home',
                    title: <IntlMessage Store={Store} messageId="common.general"/>,
                    key: 'home',
                    id: 'home',
                    class: 'TabPanel',
                    content: home(
                        {
                            response: props.response2,
                            Store: Store,
                            galerie: props.galerie,
                            galerieItems: props.galerieItems
                        })
                },
                {
                    eventKey: 'content',
                    title: <IntlMessage Store={Store} messageId="common.content"/>,
                    key: 'content',
                    id: 'content',
                    class: 'TabPanel',
                    content: Content(
                        {
                            response: props.response2,
                            elements: props.element,
                            setElements: props.setElement,
                            modalShow: props.modalShow,
                            setModalShow: props.setModalShow,
                            modalData: props.modalData,
                            setModalData: props.setModalData,
                            setdeleteElement: props.setdeleteElement,
                            Index: props.Index,
                            setIndex: props.setIndex,
                            Store: props.Store,
                            galerie: props.galerie,
                            galerieItems: props.galerieItems,
                            mode: 'email'
                        })
                }
            ]
        }]
    } else {
        config = [{
            tab: {defaultActiveKey: 'home', id: 'uncontrolled-tab-example', className: 'mb-3', key: 'tabindex'},
            tabs: [
                {
                    eventKey: 'home',
                    title: <IntlMessage Store={Store} messageId="common.general"/>,
                    key: 'home',
                    id: 'home',
                    class: 'TabPanel',
                    content: homeCategory(
                        {
                            response: props.response2,
                            Store: Store,
                            galerie: props.galerie,
                            galerieItems: props.galerieItems
                        })
                },
                {
                    eventKey: 'content',
                    title: <IntlMessage Store={Store} messageId="common.content"/>,
                    key: 'content',
                    id: 'content',
                    class: 'TabPanel',
                    content: Content(
                        {
                            response: props.response2,
                            elements: props.element,
                            setElements: props.setElement,
                            modalShow: props.modalShow,
                            setModalShow: props.setModalShow,
                            modalData: props.modalData,
                            setModalData: props.setModalData,
                            setdeleteElement: props.setdeleteElement,
                            Index: props.Index,
                            setIndex: props.setIndex,
                            Store: props.Store,
                            galerie: props.galerie,
                            galerieItems: props.galerieItems,
                            mode: 'email'
                        })
                }
            ]
        }]
    }

    return config;

}

export default BlogDetailTabsConfig;
