import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import '../css/dashboard.css'
import {useSelector} from "react-redux";
import Cards from "../../../../../@WUM/core/component/const/dashboardCards";
import configruation from "../configuration";

const DashbaordOverview = (props: { value: any }) => {
    const Store = useSelector((state: any) => state)


    let dashboard = [];
    let conf = configruation(Store);
    for (let x = 0; x < conf.length; x++) {
        if (conf[x].onClick !== undefined) {
            dashboard.push(
                <>
                    <Col xs={12} sm={6} md={6}  lg={4} xl={3} xxl={3} xxxl={2} ><Cards title={conf[x].title}
                                       href={conf[x].href}
                                       icon={conf[x].icon}
                                       undertitle={conf[x].undertitle}
                                       text={conf[x].text} Store={Store}
                                       onClick={conf[x].onClick}/></Col>
                </>
            )
        } else {
            dashboard.push(
                <>
                    <Col xs={12} sm={6} md={6}  lg={4} xl={3} xxl={3} xxxl={2} ><Cards title={conf[x].title}
                                       href={conf[x].href}
                                       icon={conf[x].icon}
                                       undertitle={conf[x].undertitle}
                                       text={conf[x].text} Store={Store}/></Col>
                </>
            )
        }
    }

    return (<>
        <Row className={'DashbaordRow text-center'}>
            {dashboard}
        </Row>
    </>)
}

export default DashbaordOverview;




