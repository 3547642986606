import getToken from '../modules/components/Token';
import ApiURL from '../modules/components/ApiURL';
import ReactDOM from 'react-dom';
import React from 'react';
import GetAvatarView from '../modules/components/getAvatarView';
import {
  notify_del_element,
  notify_save,
  notify_save_intvall_reload
} from "../../../@WUM/core/component/const/notification.service";
export const saveClose= (e: any,close:any, issueID: any,Store:any,state:any) => {
  e.preventDefault();
  let data = close
  var myHeaders = new Headers();


  myHeaders.append("Authorization", 'Bearer ' + getToken());
  var formdata = new FormData();
  formdata.append("close", data);
  formdata.append("state", state);
  var raw = JSON.stringify({
    "close": formdata.get('close'),
    "state": formdata.get('state'),
    "issue_id": issueID
  });
  let requestOptions = {
    method: 'PUT',
    headers: myHeaders,
    body: raw
  };

  fetch(ApiURL() + "setissue/close/" + issueID + "/", requestOptions)
    .then(response => response.text())
    .then(result => (newComment(result)))
    .catch(error => console.log('error', error));


  const newComment = (result:any) => {

    notify_save_intvall_reload(Store);
    setTimeout(() => window.location.replace('/ticket/issue/'+issueID), 3000);
    }
}
