
export const TabsSlider = {
    eventkey: 'Slider',
    key: 'Slider',
    title: 'Slider',
    content: [
        {
            label: "slider.slider-background",
            discr: 'slider-background',
            size: [
                3,
                3,
                3
            ],
            type: 'text'
        },
        {
            label: "slider.slider-previous",
            discr: 'slider-previous',
            size: [
                3,
                3,
                3
            ],
            type: 'text'
        },
        {
            label: "slider.slider-next",
            discr: 'slider-next',
            size: [
                3,
                3,
                3
            ],
            type: 'text'
        }
    ]
}
