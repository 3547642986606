import CreateMatomoIframe from "../../core/CreateMatomoIframe";
import WidgetURLMapping from "../../core/WidgetURLMapping";
import React from "react";


const Downloads = () => {
    return(
        <>
            <CreateMatomoIframe
                headline={'Downloads'}
                width={"100%"}
                height={"600"}
                url={WidgetURLMapping({name:'Downloads'})}
                scrolling={"yes"}
                id={'download1'}
            />
        </>
    )
}

export default Downloads;