import CreateMatomoIframe from "../../core/CreateMatomoIframe";
import WidgetURLMapping from "../../core/WidgetURLMapping";
import React from "react";

const WebPages = () => {
    return(
        <>
            <CreateMatomoIframe
                headline={'Webseiten'}
                width={"100%"}
                height={"600"}
                url={WidgetURLMapping({name:'Web pages'})}
                scrolling={"yes"}
                id={'wp1'}
            />
        </>
    )
}

export default WebPages;