import { AxiosResponse} from 'axios';
import httpClient from './httpClient';

export const responseBody = (response: AxiosResponse) => response.data;

export const responseBodyArray = (response: AxiosResponse) => [response.data];

const Key = 'Z7gR1LLCtP5bJ0zks9Fpyg'

export const requests = {
  get: (url: string) => httpClient.get(url+'&key='+Key).then(responseBody),
  getarray: (url: string) => httpClient.get(url+'&key='+Key).then(responseBodyArray),
  post: (url: string, body: {}) => httpClient.post(url+'&key='+Key, body).then(responseBody),
  put: (url: string, body: {}) => httpClient.put(url+'&key='+Key, body).then(responseBody),
  delete: (url: string) => httpClient.delete(url+'&key='+Key).then(responseBody),
};