import React, {useState
   } from 'react';
import {SpinnerJSX} from '../../../../@WUM/core/component/const/Spinner';
import {useNavigate,
  useParams
} from 'react-router-dom';
import IntlMessage, {IntlMessageValue} from '../../../../@WUM/core/component/const/IntlMessage';
import {useSelector} from "react-redux";
import {ResponseCustomType, ResponseCustomTypes} from "../API/response/customTypes";
import PielersTable from "../../../../@WUM/core/tablemaker";
import PaginationJSX from "../../../../@WUM/core/form-serilizer/elements/pagination";
import {handleSucces} from "../../../../@WUM/core/component/const/HandleSucces";
import SearchJSX from "../../../../@WUM/core/component/const/SearchJSX";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";



const CustomTypesOverview = (value:any) => {

  const {page,deleteID} = useParams<{page: string,deleteID: string}>();
  let limit: any;
    if (typeof page === "string") {
        limit = (parseFloat(page) * 10) - 10;
    }

    const Store = useSelector((state: any) => state)
  const history = useNavigate();



  const debug = (page: string | undefined, modalShow: boolean, modalData: any[]) => {
    return;
  }
  const destination = 'customTypes';
  const namespace_search = "customTypes_searchValue";
  const [searchValue, setSearchValue] = useState((localStorage.getItem(namespace_search) !== null ? localStorage.getItem(namespace_search) : ''));
  const [search, setSearch] = useState<boolean>(false)
  const response = ResponseCustomTypes(limit,searchValue);
  const responseType = ResponseCustomType();
  const [modalShow, setModalShow] = React.useState(false);
  const [modalData, setModalData] = React.useState([]);
  debug(page,modalShow,modalData)



  const Configuration = {
    'tableBody': {
      'data': response,
      'dataID': 'id',
      'underdata': true,
      'keys': ['id','blueprint', 'type', 'template','slug'],
      'type': ['string', 'string', 'string', 'string', 'string'],
    },
    'tableHeader': ['ID','blueprint','type','template','slug'],
    'tableHeaderWidth': ['10%', '20%', '20%','20%','20%'],
    'tablecolor': ['light'],
    'menu': {
      'data': response,
      'menuDisable': false,
      'menuItem': [<IntlMessage Store={Store} messageId="common.edit" />, <IntlMessage Store={Store} messageId="common.delete" />],
      'menuroute': ['/customTypes/Detail', '/customTypes/Delete/1'],
      'menuParams': ['id', 'id'],
      'menuIcons': ['pencil','Delete'],
    },

  };


  const SearchConfig = [{
    namespace:namespace_search,
    destination: destination,
    input:[
      {id:'search_name',translation: 'blueprint',param: 'blueprint',method: 'like'},
      {id:'search_title',translation: 'type',param: 'type',method: 'like'}
    ],
    button: ['common.search','common.del.search','Create new Custom Type']
  }]

  const switchURL = (e:any) => {
  let type = document.getElementById('indisubmit') as HTMLLinkElement;
    if(e.target.value !== '0') {
      type.classList.remove('disabled');
      type.href = '/' + destination + '/new/' + e.target.value;
    } else {
      type.classList.add('disabled')
    }
    return;
  }


  if (typeof response !== "boolean" && response?.length > 0) {
    let TypeOption = [];
    if(responseType !== true && responseType?.length > 0) {
      for(let t=0;t<responseType.length;t++){
        TypeOption.push(
            <>
              <option value={responseType[t].id}>{ucFirst(responseType[t].type)}</option>
            </>
        )
      }
    }


    return (
      <>
        <SearchJSX setSearchValue={setSearchValue} Store={Store} SearchConfig={SearchConfig} destinationField={<>
          <Form.Group as={Col} controlId={'typeid'} title={'Auswählen um neuen CustomType zu erstellen'}
                      name={'typeid'} onChange={(e) => switchURL(e)}>
            <Form.Label>CustomType</Form.Label>
            <Form.Select  aria-label="Filter" defaultValue={''}>
              <option value={'0'} />
              {TypeOption}
            </Form.Select> </Form.Group>
        </>} destinationindivudal={'typeid'} />
          <PielersTable Configuration={Configuration} />
          <PaginationJSX response={response} history={history} to={'overview'} handleSuccess={handleSucces} destination={destination} />

  </>

    )
  } else {
    localStorage.setItem('searchEnterInput','');
        return (
          <><SpinnerJSX messageId={'common.loading'} Store={Store} /></>
        );
      }

};


export default CustomTypesOverview;


const ucFirst = (string:any) => {
  return string.substring(0, 1).toUpperCase() + string.substring(1);
}
