import Button from "react-bootstrap/Button";
import IntlMessage from "../../../../@WUM/core/component/const/IntlMessage";
import React from "react";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {ResponseConfigAll} from "../API/response/config/config";
import {SpinnerJSX} from "../../../../@WUM/core/component/const/Spinner";
import {Form} from "react-bootstrap";
import {tabs} from "../configuration/tabs";
import {dynamikTabs} from "../Helper/dynamikTabs";
import {save} from "../Helper/save";

const Configdetail = (value: any) => {
    const Store = useSelector((state: any) => state)
    const history = useNavigate();
    const response = ResponseConfigAll('0', '')
    /**
     * Neuladen der Seite
     */
    const routeChange = () => {
        let path = `/Config/overview/1`;
        history(path);
    };

    if (typeof response !== "boolean" && response?.length > 0) {
        return (
            <>
                <div key={'head'}>
                    <div className={'MainBackground'} style={{backgroundColor: 'white', padding: '15px'}}
                         key={'button'}>
                        <Form>
                            <h2><IntlMessage Store={Store} messageId="common.template.settings.header"/></h2>
                            <hr/>

                                {dynamikTabs(tabs, Store, response)}

                                <br/>
                            <Button variant='light' style={{float: 'left', marginLeft: '15px'}}><IntlMessage
                                Store={Store} messageId="common.cancel"/></Button>
                            <Button variant='success'
                                    style={{float: 'right', marginRight: '15px'}}
                                    type={'button'}
                                    id={'btn_save'}
                                    onClick={() => save(response[1].templateName,Store,routeChange)}
                            >
                                <IntlMessage Store={Store} messageId="common.save"/>
                            </Button>
                            <br style={{clear: 'both'}}/>
                        </Form>
                    </div>
                </div>
            </>
        )
    } else {
        return (
            <><SpinnerJSX messageId={'common.loading'} Store={Store}/></>
        );
    }
}

export default Configdetail;






