import IntlMessage from "../../../../@WUM/core/component/const/IntlMessage";
import {mapping} from "../configuration/mapping";
import React from "react";

export const dynamikForm = (data:any,Store:any,response:any) => {

    let Holder = [];

    if(data === undefined) {
        return;
    }
    for(let x=0;x<data.length;x++){
        if(data[x] === undefined || data[x].label === undefined ) {

        } else {
            Holder.push(
                {
                    'formType': 'input',
                    'label': <IntlMessage Store={Store} messageId={'common.template.settings.'+data[x].label}/>,
                    'type': data[x].type,
                    'id': data[x].discr,
                    'placeholder': data[x].discr,
                    'legende': 'var(--' + data[x].discr + ')',
                    'SyntaxHighlighter': true,
                    'SyntaxHighlighterLanguage': 'css',
                    'value': mapping(data[x].discr, response),
                    'size': {
                        'xl': data[x].size[0],
                        'md': data[x].size[0],
                        'sm': data[x].size[0]
                    }
                }
            )
        }
    }
    //console.log(Holder)
    let Row = [
        {
            'formType': 'multiply',
            'cols':  Holder
        }
    ]
    //console.log(Row)
    return Row;

}
