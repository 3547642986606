import {Header} from "./Tabs/Header/header";
import {Gernalh} from "./Tabs/GeneralH/gernalh";
import {H1} from "./Tabs/H1/H1";
import {H2} from "./Tabs/H2/H2";
import {H3} from "./Tabs/H3/H3";
import {H4} from "./Tabs/H4/H4";
import {H5} from "./Tabs/H5/H5";
import {H6} from "./Tabs/H6/H6";

export const TabsHeadline = {
    tabs: {
        defaultActiveKey: 'general',
        eventkey: 'Headline',
        key: 'Headline',
        title: 'Headline',
        tab: [Header,Gernalh,H1,H2,H3,H4,H5,H6]
    }
}
