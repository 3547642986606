/**
 * Merge two Objects into Array
 * @param {any} obj1
 * @param {any} obj2
 * @author Marcel Ugurcu
 * @version 0.0.1
 */
const MergeTwoObjecttoArray = (obj1:any,obj2:any) => {
  let row:any =[];
  for(let option = 0; option<obj1.length; option++){
    row.push(obj1[option])
  }
  for(let options = 0; options<obj2.length; options++){
    row.push(obj2[options])
  }

  return row;
}

export default MergeTwoObjecttoArray;