
import {useEffect, useState} from 'react';
import {exampleApiType} from "../../request/example/exampleApi.interface";
import {example} from "../../request/example/example";
import {site} from "../../request/site/site";
import {siteApiType} from "../../request/site/siteApi.interface";
import {siteApiDetailType} from "../../request/site/siteApiDetail.interface";
import {checkLogin} from "../../../../../../@WUM/templates/default/Auth/service/AuthService";

/**
 * Sends response to Rest API for example
 * @memberOf example
 * @prop limit
 * @prop searchValue
 * @see {@link exampleApiType}
 * @author Marcel
 * Ugurcu
 * @version 0.0.1
 */
export const ResponseSite = (limit:any,searchValue:any) => {
    const [posts, setPosts] = useState<siteApiType[]>([]);
    const [isError, setIsError] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            await site.getPosts(limit,searchValue)
                .then((data) => {
                    checkLogin(data);
                    setPosts(data);
                })
                .catch((err) => {
                    setIsError(true);
                });
        })()}, [limit,searchValue])


    return isError ? (posts ) : (posts);

}

/**
 * Sends response to Rest API for delete a example Post
 * @memberOf example
 * @prop id
 * @author Marcel Ugurcu
 * @version 0.0.1
 */
export const ResponseDelete = (id: string) => {
    const [isError, setIsError] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            await site.deletePost(id)
                .then((data) => {
                    checkLogin(data);
                    return data;
                })
                .catch((err) => {
                    setIsError(true);
                });
        })()}, [id])

    return isError ? (isError ) : (isError);

}

/**
 * Sends response to Rest API for example Detail PAge
 * @memberOf example
 * @prop id
 * @see {@link exampleApiType}
 * @author Marcel Ugurcu
 * @version 0.0.1
 */
export const ResponseDetailSite = (id: any) => {
    const [posts, setPosts] = useState<siteApiDetailType[]>([]);
    const [isError, setIsError] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            await site.getAPost(id)
                .then((data) => {
                    checkLogin(data);
                    return setPosts(data);
                })
                .catch((err) => {
                    setIsError(true);
                });
        })()}, [id])

    return isError ? (posts ) : (posts);

}

/**
 * Sends response to Rest API for example Author information/List
 * @memberOf example
 * @see {@link exampleApiType}
 * @author Marcel Ugurcu
 * @version 0.0.1
 */
export const ResponseAuthor = () => {
    const [posts, setPosts] = useState<exampleApiType[]>([]);
    const [isError, setIsError] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            await example.getPostsAuhtor()
                .then((data) => {
                    checkLogin(data);
                    setPosts(data);
                })
                .catch((err) => {
                    setIsError(true);
                });
        })()}, [])

    return isError ? (posts ) : (posts);

}
