import {useEffect, useState} from 'react';
import {error} from '../../../../../@WUM/core/component/const/error.services';
import {checkLogin} from "../../../../../@WUM/templates/default/Auth/service/AuthService";
import {customTypesApiType} from '../request/customTypesAPI.interface.';
import {customTypes} from "../request/customTypes";
import CustomTypesOverview from "../../Pages/overview";

export const ResponseCustomTypes = (limit: any, searchValue: any) => {
  const [posts, setPosts] = useState<customTypesApiType[]>([]);
  const [isError, setIsError] = useState<boolean>(false);
  useEffect(() => {
    (async () => {
      await customTypes.getPosts(limit,searchValue)
    .then((data) => {
      checkLogin(data);
      setPosts(data);
    })
    .catch((err) => {
      error(err);
      setIsError(true);
    });
    })()}, [limit,searchValue])


  return isError ? (isError ) : (posts);

}

export const ResponseCustomType = () => {
  const [posts, setPosts] = useState<customTypesApiType[]>([]);
  const [isError, setIsError] = useState<boolean>(false);
  useEffect(() => {
    (async () => {
      await customTypes.getPost()
          .then((data) => {
            checkLogin(data);
            setPosts(data);
          })
          .catch((err) => {
            error(err);
            setIsError(true);
          });
    })()}, [])


  return isError ? (isError ) : (posts);

}

export const ResponseCustomTypesDelete = (id: any) => {
  const [isError, setIsError] = useState<boolean>(false);
  useEffect(() => {
    (async () => {
      await customTypes.deletePost(id)
        .then((data) => {
          return data;
        })
        .catch((err) => {
          error(err);
          setIsError(true);
        });
    })()}, [id])

  return isError ? (isError ) : (isError);

}


export const ResponseCustomTypesDetail = (id: string | undefined) => {
  const [posts, setPosts] = useState<customTypesApiType[]>([]);
  const [isError, setIsError] = useState<boolean>(false);
  useEffect(() => {
    (async () => {
      await customTypes.getAPost(id)
        .then((data) => {
          setPosts(data);
          //console.log(data);

        })
        .catch((err) => {
          error(err);
          setIsError(true);
        });
    })()}, [id])

  return isError ? (isError ) : (posts);

}

export const ResponseCustomTypeDetail = (id: string | undefined) => {
  const [posts, setPosts] = useState<customTypesApiType[]>([]);
  const [isError, setIsError] = useState<boolean>(false);
  useEffect(() => {
    (async () => {
      await customTypes.getAPostType(id)
          .then((data) => {
            setPosts(data);
            //console.log(data);

          })
          .catch((err) => {
            error(err);
            setIsError(true);
          });
    })()}, [id])

  return isError ? (isError ) : (posts);

}

export const ResponseCustomTypesFormDetail = (id: string | undefined,add?:any) => {
  const [posts, setPosts] = useState<customTypesApiType[]>([]);
  const [isError, setIsError] = useState<boolean>(false);
  useEffect(() => {
    (async () => {
      await customTypes.getAFormPosts(id,(add !== undefined ? add : ''))
          .then((data) => {
            setPosts(data);
            //console.log(data);

          })
          .catch((err) => {
            error(err);
            setIsError(true);
          });
    })()}, [id,add])

  return isError ? (isError ) : (posts);

}
