

import React from 'react';
import FormularDetail from "./Pages/Detail";
import FormularOverview from "./Pages/overview";
import FormluarNew from "./Pages/new";
import FormDetail from "./Pages/FormDetail";


export const FormularConfig = (value:any) =>{
    return [
        {
            path: '/formular/:page',
            elements: <FormularOverview value={value}/>,
            roles: ['superadmin','admin','contentcreator','operator']
        },
        {
            path: '/formular/Detail/:id',
            elements: <FormularDetail value={value}/>,
            roles: ['superadmin','admin','contentcreator','operator']
        },
        {
            path: '/formular/new/:id',
            elements: <FormluarNew value={value}/>,
            roles: ['superadmin','admin','contentcreator','operator']
        },
        {
            path: '/formular/Deteil/Settings/:id',
            elements: <FormDetail value={value}/>,
            roles: ['superadmin','admin','contentcreator','operator']
        }
    ];
}

export const FormularMenuConfig = (value:any) =>{
    return [
        {
            name: 'Formular',
            route: '/formular/1',
            icon: 'contact_mail',
            roles: ['superadmin','admin','contentcreator','operator']
        },
    ];
}
