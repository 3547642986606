import PielersserializeElementsTranslations from '../helper/translations';
import excape from '../helper/excape';

const serializeTranslation = (elementsdataTranslation:any,Data:any,form:any) => {



  let elementstmps: string = '{';

  if(elementsdataTranslation.length > 0) {
    for (let i = 0; i < elementsdataTranslation.length; i++) {
      let end = elementsdataTranslation.length - 1;

      for (let z = 0; z < elementsdataTranslation[i].length; z++) {

        if (i === end) {
          elementstmps += excape(PielersserializeElementsTranslations(elementsdataTranslation[i][z], form, z));
        } else {
          elementstmps += excape(PielersserializeElementsTranslations(elementsdataTranslation[i][z], form, z));
        }

        if (elementsdataTranslation.length - 1 === i) {
        } else {
          elementstmps += ',';
        }


      }
    }
    elementstmps += '}';

    Data.translation = JSON.parse(elementstmps);


  }
  return Data;

};

export default serializeTranslation;