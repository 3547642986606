/**
 * Formatiert Datum
 * @param dateString
 */
export const formatDate = (dateString:any,withClock:any=false) =>
{
    if(dateString?.includes('T')) {
        dateString = dateString.split('T');
        if(withClock !== false) {
            return dateString[0] +' '+ dateString[1]+' Uhr';
        } else {
            return dateString[0]
        }

    } else {
        return dateString;
    }
}
