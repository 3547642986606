import {useEffect, useState} from 'react';
import {formularApiType} from '../../request/formular/formularApi.interface';
import {formular} from '../../request/formular/formular';
import {formularApiDetail} from '../../request/formular/formularApiDetail.interface';
import {error} from "../../../../../../@WUM/core/component/const/error.services";
import {checkLogin} from "../../../../../../@WUM/templates/default/Auth/service/AuthService";

/**
 * Sends response to Rest API for Blog Overview
 * @memberOf blog
 * @prop limit
 * @prop searchValue
 * @see {@link blogApiType}
 * @author Marcel
 * Ugurcu
 * @version 0.0.1
 */
export const Responseformular = (limit: any, searchValue: any) => {
  const [posts, setPosts] = useState<formularApiType[]>([]);
  const [isError, setIsError] = useState<boolean>(false);
  useEffect(() => {
    (async () => {
      await formular.getPosts(limit,searchValue)
    .then((data) => {
      setPosts(data);
    })
    .catch((err) => {
      error(err);
      setIsError(true);
    });
    })()}, [limit,searchValue])


  return isError ? (posts ) : (posts);

}

/**
 * Sends response to Rest API for Blog Overview
 * @memberOf blog
 * @prop limit
 * @prop searchValue
 * @see {@link blogApiType}
 * @author Marcel
 * Ugurcu
 * @version 0.0.1
 */
export const ResponseformularSettings = (id:any) => {
  const [posts, setPosts] = useState<formularApiDetail[]>([]);
  const [isError, setIsError] = useState<boolean>(false);
  useEffect(() => {
    (async () => {
      await formular.getAPostSettings(id)
          .then((data) => {
            setPosts(data);
          })
          .catch((err) => {
            error(err);
            setIsError(true);
          });
    })()}, [id])


  return isError ? (posts ) : (posts);

}

/**
 * Sends response to Rest API for Blog Overview
 * @memberOf blog
 * @prop limit
 * @prop searchValue
 * @see {@link blogApiType}
 * @author Marcel
 * Ugurcu
 * @version 0.0.1
 */
export const ResponseformularALL = () => {
  const [posts, setPosts] = useState<formularApiType[]>([]);
  const [isError, setIsError] = useState<boolean>(false);
  useEffect(() => {
    (async () => {
      await formular.getPostsALL()
          .then((data) => {
            setPosts(data);
            let form = data;
            localStorage.setItem('formular', JSON.stringify(form))
          })
          .catch((err) => {
            error(err);
            setIsError(true);
          });
    })()}, [])


  return isError ? (posts ) : (posts);

}

/**
 * Sends response to Rest API for delete a Blog Post
 * @memberOf blog
 * @prop id
 * @author Marcel Ugurcu
 * @version 0.0.1
 */
export const ResponseformularDelete = (id: string) => {
  const [isError, setIsError] = useState<boolean>(false);
  useEffect(() => {
    (async () => {
      await formular.deletePost(id)
        .then((data) => {
          return data;
        })
        .catch((err) => {
          error(err);
          setIsError(true);
        });
    })()}, [id])

  return isError ? (isError ) : (isError);

}

/**
 * Sends response to Rest API for Blog Detail PAge
 * @memberOf blog
 * @prop id
 * @see {@link blogApiDetail}
 * @author Marcel Ugurcu
 * @version 0.0.1
 */
export const ResponseformularDetail = (id: string | undefined) => {
  const [posts, setPosts] = useState<formularApiDetail[]>([]);
  const [isError, setIsError] = useState<boolean>(false);
  useEffect(() => {
    (async () => {
      await formular.getAPost(id)
        .then((data) => {
          checkLogin(data);
          return setPosts(data);
        })
        .catch((err) => {
          error(err);
          setIsError(true);
        });
    })()}, [id])

  return isError ? (posts ) : (posts);

}
