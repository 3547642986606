const SecureCheck = (role: any) => {

    if (role === undefined) {
        return false
    }

    for (let x = 0; x < role.length; x++) {
        if (localStorage.getItem('userRole') !== null) {

            if (role[x] === (localStorage.getItem('userRole') as string)?.toLowerCase()) {

                return true;
            }
        }
    }
    return false

}

export default SecureCheck;
