import React from 'react';
import TableCell from '@material-ui/core/TableCell';
function email(text: string, id: any, index: number, VisitData: any, VisitDataDel: any, Store: any, tableBodyPositionElement: any){


  return (
    <><TableCell style={{textAlign:tableBodyPositionElement}}  key={"td"+id+ "_" +index} data-param={VisitData} data-param-del={VisitDataDel}
                 data-id={id} id={"td"+id+ "_" +index}><a href={"mailto:"+text} style={{zIndex: '99999999999',position: 'absolute'}}>{text}</a></TableCell>
    </>
  );

}

export default email;
