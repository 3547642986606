import React from 'react';
import PielersForm from '../../index';
import {base, basesettings} from './base';
import CopyElements from "../../../component/const/CopyElements";

import Col from 'react-bootstrap/Col';
import Row from "react-bootstrap/Row";
import WebBuilderElementsHead from "../../../component/const/WebBuilderElementsHead";
import Box from '@mui/material/Box';

function SitemapWebBuilder(data: any, element: any, i: any, newElement: any = false, newelementIndex: any = 0, modalShow: boolean,
                 setModalShow: (value: (((prevState: boolean) => boolean) | boolean)) => void, modalData: any[],
                 setModalData: (value: (((prevState: any[]) => any[]) | any[])) => void,
                 content:any = [],setElements:any = [],Store:any) {

  const index = (newElement === false ? i : newelementIndex)

  const configuration = [
    {
      'formType': 'sitemap'
    },
    {
      'formType': 'input',
      'name': 'elements[' + index + '][template]',
      'type': 'hidden',
      'value': 'Elements/sitemap.html',
    }
    ]

  let newConfiguration = configuration;

  let Status;
  if(element.visible === 1) {
    Status = 'elementActive';
  } if(element.visible === 0){
    Status = 'elementDeactive';
  } else if(newElement === true) {
    Status = 'elementActive';
  }
  return (
    <>
    <Box  id={'sitemap'+index} data-appendcontainer={(newElement === false && element.appendto !== null && element.appendto !== undefined && element.appendto !== '' ?  element.appendto : 'false')} >
    {WebBuilderElementsHead('Sitemap','sitemap','',Status,index,'elementButton','Text',
            modalShow,setModalShow,modalData, setModalData,newElement,element,content,setElements,Store,'','',element.id)}
        <div id={'preview-container_sitemap'+index}  className={'preview-container '+Status} style={{display: (newElement === false ? 'block' : 'none')}} >
            {(newElement === false ?
                <span dangerouslySetInnerHTML={{ __html: element.previews }} />
                : <></>)}
        </div>
        <Row  className={'boxShadowCard card bbunset'}   style={{width: '100%',background:'transparent',height:'1px'}}>

         <Col  className={'element-body'}>
             <div id={'edit_sitemap'+index} className={'boxShadowCard card '+Status} style={{display:(newElement === false ? 'none' : 'block'),padding:'15px',width: '100%',marginBottom: '20px'}}>
                 <div id={'Teaser'}>carouselItem_end</div>
                 <Col style={{marginBottom: '10px',marginTop:'10px'}}>Sitemap</Col>
                 {PielersForm(base(element,i,newElement,newelementIndex))}
             </div>
         </Col>
        <div id={'settingstext'+index+'sitemap'} className={'settingsWebBuilderModul '} style={{display:'none'}}>
          {PielersForm(newConfiguration)}
          {PielersForm(basesettings(element,i,newElement,newelementIndex,Store))}
        </div>
        {(newElement === false ? CopyElements(element.id,'Sitemap',Store) : <></>)}

    </Row>
    </Box>
    </>
  )


}

export default SitemapWebBuilder;
