import React from 'react';
import PielersForm from '../../index';
import { base, basesettings } from './base';
import CopyElements from "../../../component/const/CopyElements";
import WebBuilderElementsHead from "../../../component/const/WebBuilderElementsHead";
import Col from 'react-bootstrap/Col';
import Row from "react-bootstrap/Row";
import Box from '@mui/material/Box';
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

function slider_startWebBuilder(data: any, element: any, i: any, newElement: any = false, newelementIndex: any = 0, modalShow: boolean,
  setModalShow: (value: (((prevState: boolean) => boolean) | boolean)) => void, modalData: any[],
  setModalData: (value: (((prevState: any[]) => any[]) | any[])) => void,
  content: any = [], setElements: any = [], Store: any) {

  const index = (newElement === false ? i : newelementIndex)
  const configuration = [
    {
      'formType': 'input',
      'name': 'elements[' + index + '][icon]',
      'type': 'text',
      'label': 'headline',
      'value': (newElement === false ? (element.icon !== undefined ? element.icon : '') : ''),
    },
    {
      'formType': 'input',
      'name': 'elements[' + index + '][template]',
      'type': 'hidden',
      'value': 'Elements/slider_start.html',
    }
  ];

  let newConfiguration = configuration;
  let Status;
  if (element.visible === 1) {
    Status = 'elementActive';
  } if (element.visible === 0) {
    Status = 'elementDeactive';
  } else if (newElement === true) {
    Status = 'elementActive';
  }
  return (
    <>
      <Box id={'slider_start' + index} data-appendcontainer={(newElement === false && element.appendto !== null && element.appendto !== undefined && element.appendto !== '' ?  element.appendto : 'false')}>
        {WebBuilderElementsHead('slider_start', 'slider_start', '', Status, index, 'elementButton', 'Text',
          modalShow, setModalShow, modalData, setModalData, newElement, element, content, setElements, Store, '', '', element.id)}

        <Row className={'boxShadowCard card bbunset'}   style={{width: '100%',background:'transparent',height:'1px'}} >
          <div id={'Teaser'}>slider_start</div>
          <Col>slider_start</Col>
          <Col className={'accordion-body element-body'}>
            {PielersForm(base(element,i,newElement,newelementIndex))}
            {/* <Tabs defaultActiveKey="Einstellungen" id="uncontrolled-tab-example" className="mb-3" key={'tabindex'}>
              <Tab eventKey="Einstellungen" title={<IntlMessage Store={Store} messageId="settings.settings.header" locale={'de'} />} key={'Einstellungen'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
                {PielersForm(basesettings(element,i,newElement,newelementIndex,Store))}
                {PielersForm(newConfiguration)}
              </Tab>
            </Tabs>*/}

          </Col>
          <div id={'settingstext' + index + 'slider_start'} className={'settingsWebBuilderModul '} style={{ display: 'none' }}>
            {PielersForm(basesettings(element, i, newElement, newelementIndex, Store))}
            <h3>Modul</h3>
            {PielersForm(newConfiguration)}
          </div>
          {(newElement === false ? CopyElements(element.id, 'slider_start', Store) : <></>)}



        </Row>
        <Row id={'appendCarouseltItemStart'+index} data-appendtab={'true'} data-appentclass={element.class} >
          <Tabs className="mb-5" key={'tabindex'} style={{marginBottom:'80px !important',marginLeft:'10px'}}>

          </Tabs>
        </Row>

        <Row id={'InjectSliderEnd'+index} style={{display:'none'}} >

        </Row>
      </Box>
    </>
  )


}

export default slider_startWebBuilder;
