import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import CreateMatomoIframe from "../../core/CreateMatomoIframe";
import WidgetURLMapping from "../../core/WidgetURLMapping";
import React from "react";


const Content = () => {
    return(
        <>
            <Tabs defaultActiveKey="content_name" id="uncontrolled-tab-example" className="mb-3 tabMatomo" key={'tabindex'}>
                <Tab eventKey="content_name" title={"Inhaltsname"} key={'content_name'} id={'content_name'} className={"tabsMatomo"}>
                    <CreateMatomoIframe
                        headline={'Inhaltsname'}
                        width={"100%"}
                        height={"600"}
                        url={WidgetURLMapping({name:'content name'})}
                        scrolling={"yes"}
                        id={'content1'}
                    />
                </Tab>
                <Tab eventKey="content_part" title={"Inhaltsteil"} key={'content_part'} id={'content_part'} className={"tabsMatomo"}>
                    <CreateMatomoIframe
                        headline={'Inhaltsteil'}
                        width={"100%"}
                        height={"600"}
                        url={WidgetURLMapping({name:'content part'})}
                        scrolling={"yes"}
                        id={'content2'}
                    />
                </Tab>
            </Tabs>

        </>
    )
}

export default Content;