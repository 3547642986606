import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import React from "react";
import {ApiURL} from "../../../../core/component/const/ApiURL";
// @ts-ignore
import {Notification} from "create-notifications/notfication";


const Signin = (props:{value:any}) => {
    const send = (e:any) => {
        e.preventDefault();
        let data = new FormData(e.target);
        let value = Object.fromEntries(data.entries());
        let raw = JSON.stringify(value);
        let root = ApiURL();
        let requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: raw,
        };

        fetch(root+"conference/user/register/", requestOptions)
            .then(response => response.text())
            .then(result => afterSend('success'))
            .catch(error => afterSend('error'));
    }

    const afterSend = (state:any) => {
        if(state === 'success') {
            Notification({rewrite:true,landingpages:'/',type:'success', mode:'classic', title:{text:'Success'}, text:{text: 'Saved successfully'}, insert:'center right',duration:4})
        } else if(state === 'error'){
            Notification({rewrite:true,landingpages:'/',type:'danger', mode:'classic', title:{text:'Error'}, text:{text: 'Sorry, an error has occurred'}, insert:'center right',duration:4})
        }
    }
    return (
        <>
            <div className="Login"  style={{paddingBottom: '2em'}}>
                <div style={{textAlign:'center'}}><img src={process.env.REACT_APP_LOGO_IMG} alt={''} style={{maxHeight:'120px'}}/></div>
                <br />
                <Form onSubmit={(e)=> send(e)}>
                    <Form.Group className="mb-3" controlId="email">
                        <Form.Label className={'text-white'}>E-Mail:</Form.Label>
                        <Form.Control type="email" name={'email'} required={true}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="password">
                        <Form.Label className={'text-white'}>Password:</Form.Label>
                        <Form.Control type="password" name={'password'} required={true}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="username">
                        <Form.Label className={'text-white'}>Username:</Form.Label>
                        <Form.Control type="text" name={'username'} required={true}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="first_name">
                        <Form.Label className={'text-white'}>First Name:</Form.Label>
                        <Form.Control type="text" name={'first_name'} required={true}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="last_name">
                        <Form.Label className={'text-white'}>Last Name:</Form.Label>
                        <Form.Control type="text" name={'last_name'} required={true}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="discr">
                        <Form.Control type="hidden" name={'discr'} defaultValue={'user'}/>
                    </Form.Group>
                    <Button variant="success" type="submit">Send</Button>
                </Form>
                {(process.env.REACT_APP_Register !== undefined ? <p className={'logintext undertext'}>Dont`t have an account? <a href={"/Signin"}>Register now</a> | forgot Password?</p> : <></>)}
                {(process.env.REACT_APP_PARTNER_LOGO !== undefined ? <div ><img src={process.env.REACT_APP_PARTNER_LOGO} alt={''}/></div> : <></>)}
            </div>
        </>
    )
}

export default Signin;
