import React, {useRef, useState} from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Button from 'react-bootstrap/Button';
import {
  useNavigate,
} from 'react-router-dom';
import PielersForm from '../../../../../../@WUM/core/form-serilizer';
import {saveDataIndividualPut} from '../../../../../../@WUM/core/RestFullApi/ApiHook';
import {error} from '../../../../../../@WUM/core/component/const/error.services';
import {_handleValidateShipping} from '../../../../../../@WUM/core/component/const/_handleValidateShipping';
import {handleSubmitForm} from '../../../../../../@WUM/core/component/const/handleSubmitForm';
import {SunEditorConfig} from '../../../../../../@WUM/core/component/const/SunEditorConfig';
import {Form} from 'react-bootstrap';
import {ResponseAllMerchant} from '../../../API/response/member/member';
import {memberApiType} from '../../../API/request/member/memberApi.interface';
import {ResponseAllShipingTypes, ResponseAllUnit} from '../../../API/response/product/Product';
import {ShippingApiType} from '../../../API/request/Product/ShippingApi.interface';
import {allUnitApiType} from '../../../API/request/unit/allUnitApi.interface';
import IntlMessage, {IntlMessageValue} from "../../../../../../@WUM/core/component/const/IntlMessage";
import SunEditorBig from '../../../../../../@WUM/core/component/const/SunEditorBig';
import {DummyUser} from "../../../../../../@WUM/core/dummy/user";
import {useSelector} from "react-redux";



const ProductNew = (value: any) => {

  const user = DummyUser();
  const debug = (AllMerchant: memberApiType[] | boolean, Shipping: ShippingApiType[], Unit: allUnitApiType[]) => {}
  const history = useNavigate();
  const AllMerchant =  ResponseAllMerchant();
  const Shipping =  ResponseAllShipingTypes();
  const Unit =  ResponseAllUnit();
  const Store = useSelector((state: any) => state)
  // user.merchantID !== null
  debug(AllMerchant,Shipping,Unit)


  const [validated, setValidated] = useState(false);

  const arti = [
    {
      'formType': 'header',
      'value': <IntlMessage Store={Store} messageId="disclosure.general" />,
      'type': 'h5',
    },
    {
      'formType': 'select',
      'label': <IntlMessage Store={Store} messageId="common.status" />,
      'name': 'status',
      'required': true,
      'selected': '',
      'options': [{
        'label': '',
        'value': '',
      },
        {
          'label': IntlMessageValue("de","sell_on.plattform",Store),
          'value': '1'
        },
        {
          'label': IntlMessageValue("de","take_off.plattform",Store),
          'value': '0'
        }]

    },
    {
      'formType': 'input',
      'label': <IntlMessage Store={Store} messageId="article.name" />,
      'type': 'text',
      'placeholder': IntlMessageValue("de","article.name.provide",Store),
      'name': 'Artikelname',
      'required': true,
      'id': 'Artikelname',
      'value':  '',
    },
    {
      'formType': 'input',
      'label': <IntlMessage Store={Store} messageId="article.no" />,
      'type': 'text',
      'placeholder': IntlMessageValue("de","article.no.provide",Store),
      'required': true,
      'name': 'Artikelnummer',
      'id': 'Artikelnummer',
      'value':  '',
    },
    {
      'formType': 'fetchselect',
      'local': 'MerchantAll',
      'valueState': 'id',
      'beginn': 'true',
      'start': 0,
      'type': 'MerchantAll',
      'onClick_id': 'Roles',
      'nonetype': true,
      'required': true,
      'labelState': 'company',
      'name': 'merchant_id',
      'id': 'merchant_id',
      'disabled': (user?.merchant_id !== null ? true : false),
      'selected': (user?.merchant_id !== null ? user?.merchant_id : ''),
      'label': <IntlMessage Store={Store} messageId="role.provider" />
    },{
      'formType': 'fetchselect',
      'local': 'allTaxon',
      'valueState': 'translatable_id',
      'beginn': 'true',
      'required': true,
      'nonetype': true,
      'start': 0,
      'labelState': 'name',
      'selected': '',
      'label': <IntlMessage Store={Store} messageId="category.main" />
    },
    {
      'formType': 'fetchselect',
      'local': 'allTaxon',
      'valueState': 'translatable_id',
      'beginn': 'true',
      'start': 0,
      'required': true,
      'nonetype': true,
      'type': 'taxon',
      'onClick_id': 'taxon',
      'labelState': 'name',
      'selected': '',
      'label': <IntlMessage Store={Store} messageId="category.provider.add" />
    },
    {
      'formType': 'holder',
      'ressource': '',
      'type': 'taxon'
    },
    {
      'formType': 'checkbox',
      'label': <IntlMessage Store={Store} messageId="is_grower" />,
      'name': 'isGrower',
      'visible': '1',
    },
    {
      'formType': 'header',
      'value': <IntlMessage Store={Store} messageId="stock.management" />,
      'type': 'h5',
    },
    {
      'formType': 'select',
      'label': <IntlMessage Store={Store} messageId="stock.keeping" />,
      'name': 'variant[lagerhaltung]',
      'required': true,
      'selected':  '0',
      'onclick': 'stock',
      'options': [{
        'label': '',
        'value': '',
      },
        {
          'label': IntlMessageValue("de","common.deactivated",Store),
          'value': '0'
        },
        {
          'label': IntlMessageValue("de","common.activated",Store),
          'value': '1'
        }]

    },
    { 'formType': 'input',
      'label': <IntlMessage Store={Store} messageId="stock.on_hand" />,
      'type': 'onclickHidden',
      'containerID': 'stockContainer',
      'placeholder': IntlMessageValue("de","stock.on_hand.placeholder",Store),
      'name': 'on_hand',
      'id': 'on_hand',
      'value': 0,
    },
    {
      'formType': 'header',
      'value': <IntlMessage Store={Store} messageId="price.info" />,
      'type': 'h5',
    },
    {
      'formType': 'multiply',
      'cols': [
        { 'formType': 'input',
          'label': <IntlMessage Store={Store} messageId="article.price.euro" />,
          'type': 'text',
          'required': true,
          'placeholder': IntlMessageValue("de","article.price",Store),
          'name': 'variant[price]',
          'id': 'variant[price]',
          'legende': <IntlMessage Store={Store} messageId="price.format.legend" />,
          'value': '',
        },
        {
          'formType': 'fetchselect',
          'local': 'Tax',
          'valueState': 'id',
          'beginn': 'true',
          'start': 0,
          'name': 'variant[tax]',
          'type': 'Tax',
          'nonetype': true,
          'required': true,
          'onClick_id': 'Tax',
          'labelState': 'name',
          'selected': '',
          'label': <IntlMessage Store={Store} messageId="tax.vat" />
        },
      ]
    },

    {
      'formType': 'multiply',
      'cols': [
        { 'formType': 'input',
          'label': <IntlMessage Store={Store} messageId="sale.volume" />,
          'type': 'text',
          'required': true,
          'placeholder': IntlMessageValue("de","sale.volume",Store),
          'name': 'variant[menge]',
          'id': 'variant[menge]',
          'value': '',
        },
        {
          'formType': 'fetchselect',
          'local': 'Unit',
          'valueState': 'code',
          'beginn': 'true',
          'start': 0,
          'nonetype': true,
          'required': true,
          'name': 'variant[type]',
          'type': 'type',
          'onClick_id': 'Unit',
          'labelState': 'name',
          'selected':  '',
          'label': <IntlMessage Store={Store} messageId="unit" />
        },
      ]
    },
    {
      'formType': 'header',
      'value': <IntlMessage Store={Store} messageId="shipping.info" />,
      'type': 'h5',
    },
    {
      'formType': 'fetchselect',
      'local': 'Shipping',
      'valueState': 'id',
      'beginn': 'true',
      'start': 0,
      'type': 'Shipping',
      'onClick_id': 'Shipping',
      'labelState': 'name',
      'selected': '',
      'label': <IntlMessage Store={Store} messageId="shipping.add" />
    },
    {
      'formType': 'holder',
      'ressource':  '',
      'type': 'Shipping'
    },
    {
      'formType': 'header',
      'value': <IntlMessage Store={Store} messageId="product.info" />,
      'type': 'h5',
    },
    SunEditorBig(false,'Kurzbeschreibung',true,{'lang': 'de','height': '400px;','config': SunEditorConfig()},'Kurzbeschreibung',<IntlMessage Store={Store} messageId="common.short_description" />),
    SunEditorBig(false,'Beschreibung',true,{'lang': 'de','height': '400px;','config': SunEditorConfig()},'Beschreibung',<IntlMessage Store={Store} messageId="common.description" />),
  ];

  const forms = useRef(null)
  const submit = () => {
    let Dataform = forms.current

    let check = _handleValidateShipping();

    if(check === true) {
      if (Dataform !== undefined && Dataform !== null) {
        const data = new FormData(Dataform)

       /* var merchandID = $('#merchant_id').val() as string
        data.append('merchant_id', merchandID);  */

        saveDataIndividualPut('/Product/Hauptattribute/', data)
          .then((data: any) => {
            //notify_save();
            //history(-1);
            setTimeout(() => window.location.href = '/Product/Detail/'+data.productID, 1000);
          })
          .catch(e => {
            error(e);
          });
      }
    } else {
      alert(<IntlMessage Store={Store} messageId="shipping.price.invalid" />)
    }
  }
  return (
    <div>
      <Form ref={forms} noValidate validated={validated} onSubmit={(e) => handleSubmitForm(e, submit, setValidated, 0)}>
        <Tabs defaultActiveKey="Hauptartikel" id="uncontrolled-tab-example" className="mb-3" key={'tabindex'}>
          <Tab eventKey="Hauptartikel" title={<IntlMessage Store={Store} messageId="article.main.create" />} key={'allgemein'} id={'allgemein'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
            {PielersForm(arti,null,null,Store)}
          </Tab>
        </Tabs>
        <div className={'footerBox'} style={{backgroundColor: 'white', padding: '15px'}}>
          <Button variant='light' style={{float: 'left', marginLeft: '15px'}} onClick={() => history(-1)}><IntlMessage Store={Store} messageId="common.back"/></Button>
          <Button type={'submit'} style={{float: 'right', marginRight: '15px'}} variant="success" ><IntlMessage Store={Store} messageId="common.save" /></Button>
          <br style={{clear: 'both'}} />
        </div>
      </Form>
    </div>
  );
}


export default ProductNew;
