import IntlMessage from "../../../../../../../@WUM/core/component/const/IntlMessage";
import React from "react";
import SunEditorBig from '../../../../../../../@WUM/core/component/const/SunEditorBig';
import {SunEditorConfig} from '../../../../../../../@WUM/core/component/const/SunEditorConfig';


function configuration(conf: string,response:any,Store:any) {

  const information = [
   {
      'formType': 'input',
      'type': 'hidden',
      'name': 'merchant_id',
      'value': response.merchant_id,
    },{
      'formType': 'header',
      'value': <IntlMessage Store={Store} messageId="profile.header" />,
    },
    {
    'formType': 'input',
    'label': <IntlMessage Store={Store} messageId="profile.name.label" />,
    'type': 'text',
    'placeholder': "lang[common.company.names]",
      'name': 'merchant[company]',
    'value': response.merchant.company,
  },
    SunEditorBig(response.translation,'shortDescription',false,{'lang': 'de','height': '150px;','config': SunEditorConfig()},'translations[shortDescription]',<IntlMessage Store={Store} messageId="profile.short_description.label" />),
    SunEditorBig(response.translation,'description',false,{'lang': 'de','height': '150px;','config': SunEditorConfig()},'translations[description]',<IntlMessage Store={Store} messageId="profile.description.label" />),
    {
      'formType': 'header',
      'value': <IntlMessage Store={Store} messageId="profile.contact" />,
      'name': 'merchant[email]',
    },
    {
      'formType': 'input',
      'label': <IntlMessage Store={Store} messageId="common.email" />,
      'type': 'email',
      'name': 'merchant[email]',
      'placeholder': "lang[common.email]",
      'value': response.merchant.email,
    },
    {
      'formType': 'input',
      'label': <IntlMessage Store={Store} messageId="common.webpage" />,
      'type': 'text',
      'placeholder': "lang[common.webpage]",
      'name': 'merchant[website]',
      'value': response.merchant.website,
    },
    {
      'formType': 'input',
      'label': <IntlMessage Store={Store} messageId="profile.contact.phone" />,
      'type': 'tel',
      'placeholder': "lang[common.phone_number]",
      'name': 'address[phone_number]',
      'value': response.address.phone_number,
    },
    SunEditorBig(response.merchant,'opening_hours',false,{'lang': 'de','height': '150px;','config': SunEditorConfig()},'merchant[opening_hours]',<IntlMessage Store={Store} messageId="profile.opening_hours" />),
    {
      'formType': 'header',
      'value': <IntlMessage Store={Store} messageId="common.imprint_infos" />,
    },
    {
      'formType': 'input',
      'label': <IntlMessage Store={Store} messageId="profile.imprint.link.label" />,
      'name': 'merchant[imprintWebsite]',
      'type': 'text',
      'placeholder': "lang[common.imprint]",
      'value': response.merchant.imprint_website,
    },
    {
      'formType': 'header',
      'value': 'oder',
    },
    SunEditorBig(response.merchant,'imprint_text',false,{'lang': 'de','height': '250px;','config': SunEditorConfig()},'merchant[imprintText]',<IntlMessage Store={Store} messageId="profile.imprint.text.label" />),
    {
      'formType': 'header',
      'value': <IntlMessage Store={Store} messageId="common.agb" />,
    },
    SunEditorBig(response.merchant,'agb_text',false,{'lang': 'de','height': '250px;','config': SunEditorConfig()},'merchant[agbText]',<IntlMessage Store={Store} messageId="profile.agb.text.label" />),
    {
      'formType': 'header',
      'value': <IntlMessage Store={Store} messageId="common.privacy_policy" />,
    },
    SunEditorBig(response.merchant,'dsi_text',false,{'lang': 'de','height': '250px;','config': SunEditorConfig()},'merchant[dsiText]',<IntlMessage Store={Store} messageId="profile.privacy_policy.text.label" />),
    {
      'formType': 'header',
      'value': <IntlMessage Store={Store} messageId="common.cancellation_policy" />,
    },
    SunEditorBig(response.merchant,'rofw_text',false,{'lang': 'de','height': '250px;','config': SunEditorConfig()},'merchant[rofwText]',<IntlMessage Store={Store} messageId="profile.cancellation_policy_text.label" />),
  ];



  if(conf === 'information') {
    return information;
  }

}

export default configuration;
