import {siteApiType} from "./siteApi.interface";
import {requests} from '../../../../../../@WUM/core/RestFullApi/api';
import {siteApiDetailType} from "./siteApiDetail.interface";

/**
 * Sends the call to the FULL REST API with the methods GET, POST, PUT
 * @namespace example
 * @see {@link siteApiType}
 * @prop {any} limit Indicates the current page
 * @prop {any} searchValue Submits search filters
 * @prop {any} post Indicates POST
 * @prop {any} id Transmits the ID of the target element
 * @example
 * getPosts: (limit: any, searchValue: any): Promise<blogApiType[]> => requests.get(`Content/Blog/${limit}/${searchValue}`),
 * @author Marcel Ugurcu
 * @version 0.0.1
 */
export const site = {
    getPosts: (limit: any, searchValue: any): Promise<siteApiType[]> => requests.get(`page/all/${limit}/${searchValue}`),
    //getPosts: (): Promise<siteApiType[]> => requests.get(`page/`),
    getAPost: (id: string): Promise<siteApiDetailType[]> => requests.get(`page/${id}/`),
    createPost: (post: any, id: number): Promise<siteApiDetailType> =>
        requests.put(`/page/insert/${id}/`, post),
    updatePost: (post: any, id: number): Promise<siteApiDetailType> =>
        requests.post(`page/update/${id}/`, JSON.stringify(post)),
    deletePost: (id: string): Promise<void> => requests.delete(`Singlepage/on/delete/${id}/`),
};