import React from "react";


interface TemplateProviderDefaultProps {
    routing: any;
}


const TemplateProviderDefault = (props:TemplateProviderDefaultProps) => {
    return (
        <>
            {props.routing}
    </>
    )
}

export default TemplateProviderDefault;