import React, {useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import ContentSwitch from "../../../@WUM/core/component/const/ContentSwitch";
import {saveDataIndividualGet} from '../../../@WUM/core/RestFullApi/ApiHook';
import {error} from '../../../@WUM/core/component/const/error.services';
import {removeUseLocalStorage} from '../../../@WUM/core/component/const/useLocalStoreage';
import {SpinnerJSX} from '../../../@WUM/core/component/const/Spinner';
import IntlMessage, {IntlMessageValue} from '../../../@WUM/core/component/const/IntlMessage';
import {ResponseCalender, ResponseCalenderDelete} from '../API/response/calender/calender';
import {useSelector} from "react-redux";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import SearchJSX from "../../../@WUM/core/component/const/SearchJSX";

interface NotificationsProps {
}

const PageOverview = (props:{value:any}) => {

  const Store = useSelector((state: any) => state)
  const {page, deleteID} = useParams<{page: string, deleteID: string}>();
  removeUseLocalStorage('seo_title');
  removeUseLocalStorage('seo_description');



  const history = useNavigate();
  /**
   * Neuladen der Seite
   */
  const routeChange = (paths:string) => {
    let path = paths;
    history(path);
  };
  let limit: any;
  if(deleteID) {
    ResponseCalenderDelete(deleteID);
    routeChange('/Calender/overview/'+page)
  }
  if (typeof page === "string") {
    limit = (parseFloat(page) * 10) - 10;
  }
  const destination = 'Calender';
  const namespace_search = "events_searchValue";
  const [searchValue, setSearchValue] = useState((localStorage.getItem(namespace_search) !== null ? localStorage.getItem(namespace_search) : ''));
  const [search, setSearch] = useState<boolean>(false)
  const response = ResponseCalender(limit,searchValue);
  const [modalShow, setModalShow] = React.useState(false);
  const [modalData, setModalData] = React.useState([]);
  const [mode, setMode] = useState('cart');
  const [parent_id, setparent_id] = useState(false);
  const row:any = [];
  if(parent_id === false) {
    localStorage.removeItem('fetchSelect');
    const getPagesSelect = () => {
      saveDataIndividualGet('/Content/Pages/Select/0/')
        .then((data: any) => {
            row.push(data);
          localStorage.setItem('fetchSelect', JSON.stringify(row));

        })
        .catch(e => {
          error(e);
        });
    }
    getPagesSelect();
    setparent_id(true);
  }

  const SearchConfig = [{
    namespace:namespace_search,
    destination: destination,
    input:[
      {id:'search_name',translation: 'common.search_for_title',param: 'title',method: 'like'},
    ],
    button: ['common.search','common.del.search','common.create_new_page']
  }]


  const CardsConfiguration = {
    'data': {
      'ressource': response,
      'indiBild': true,
      'img': 'cdn_url',
      'status': 'visible',
      'statusActive': <IntlMessage messageId="common.activated"  Store={Store}/>,
      'statusDeactive': <IntlMessage messageId="common.deactivated"  Store={Store}/>,
      'link': '',
      'prelink': 'http://myhomice-f2.pielers.de/produkte/',
      'title': 'title',
      'descriptionShow': true,
      'description': 'shortDescription',
      'parentShow': true,
      'parent': 'parent.translations.title',
      'router': 'id',
      'preroute': '/Calender/Detail/',
      'edit': <IntlMessage messageId="common.edit_page"  Store={Store}/>,
      'delete': true,
      'Dontvisited': true,
      'deleteLink': '/Calender/Delete/'+page+'/[ID]'
    }
  }

  const Configuration = {
    'tableBody': {
      'data': response,
      'dataID': 'id',
      'underdata': true,
      'keys': ['visible', 'title', 'linkTitle', 'eventdate','taxon_id'],
      'type': ['status', 'string', 'string', 'string','string'],
    },
    'tableHeader': ['status',<IntlMessage messageId="comment.event.name"  Store={Store}/>, <IntlMessage messageId="comment.event.link.title"  Store={Store}/>,<IntlMessage messageId="comment.event.begin"  Store={Store}/>,<IntlMessage messageId="comment.event.category.id"  Store={Store}/>],
    'tableHeaderWidth': ['auto', '30%', '30%', '30%','30%'],
    'tablecolor': ['light'],
    'menu': {
      'data': response,
      'menuDisable': false,
      'menutriggerDelete': true,
      'menuItem': [<IntlMessage messageId="common.edit"  Store={Store}/>, <IntlMessage messageId="common.delete"  Store={Store}/>],
      'menuroute': ['/Calender/Detail', '/Calender/Delete/'+page+'/[ID]'],
      'menuParams': ['','id', 'id'],
      'menuIcons': ['pencil', 'Delete'],
    },

  };
  if (response.length > 0) {

    return (
      <><SearchJSX setSearchValue={setSearchValue} Store={Store} SearchConfig={SearchConfig} />
        { <ContentSwitch pagenew={'new'} destinationnew={'Calender'} tonew={'new'} text={<IntlMessage Store={Store} locale={'de'} messageId={'omment.event.new'} />}
                         response={response} CardsConfiguration={CardsConfiguration} mode={mode}
                         Configuration={Configuration} setMode={setMode}  destination={destination} page={page}
                         to={'overview'} setSearchValues={setSearchValue} setSearchs={setSearch} search={false}
                         modalShow={modalShow} setModalShow={setModalShow} modalData={modalData} setModalData={setModalData} SearchParam={'?translation.title'}
                         searchfield={false} allTaxon={[]} _handleKeyFilters={[]} _handleEnter={[]} filter={true} stop={false} Store={Store}/>}
      </>
    );
  } else {
    localStorage.setItem('searchEnterInput', '');
      return (
        <><SpinnerJSX messageId={'common.loading'}  Store={Store}/></>
      );
  }

};


export default PageOverview;

