import text from "./pageFormElements/text";
import label from "./pageFormElements/label";
import headline from "./pageFormElements/headline";
import ds from "./pageFormElements/ds";
import de from "./pageFormElements/de";
import fs from "./pageFormElements/fs";
import fe from "./pageFormElements/fe";
import se from "./pageFormElements/se";
import ss from "./pageFormElements/ss";
import option from "./pageFormElements/option";
import checkbox from "./pageFormElements/checkbox";
import textarea from "./pageFormElements/textarea";
import input from "./pageFormElements/input";
import radio from "./pageFormElements/radio";
import button from "./pageFormElements/button";
import fileupload from "./pageFormElements/upload";


function renderFormSwitch(data: any, i: number, newElement:any = false,newelementIndex:any = 0,
                      modalShow: boolean, setModalShow: (value: (((prevState: boolean) => boolean) | boolean)) => void, modalData: any[],
                      setModalData: (value: (((prevState: any[]) => any[]) | any[])) => void,setdeleteElement:any = false,setElements:any = [],Store:any,galerie?:any,
                      galerieItems?:any)
{
    let type ;

    if(data[i].type === 'h1' || data[i].type === 'h2' || data[i].type === 'h3'
        || data[i].type === 'h4' || data[i].type === 'h5' || data[i].type === 'h6' ||
    data[i].type === 'h7' || data[i].type === 'h8' || data[i].type === 'h9'
    || data[i].type === 'h10' || data[i].type === 'h11' || data[i].type === 'h12') {
        type = 'headline';
    } else {
        type = data[i].type;
    }

    switch(type) {
        case 'headline':
            return headline(data[i], i, newElement, newelementIndex, modalShow, setModalShow, modalData, setModalData, setdeleteElement, setElements, Store, galerie, galerieItems);
       case 'text':
            return text(data[i], i, newElement, newelementIndex, modalShow, setModalShow, modalData, setModalData, setdeleteElement, setElements, Store, galerie, galerieItems);
        case 'label':
            return label(data[i], i, newElement, newelementIndex, modalShow, setModalShow, modalData, setModalData, setdeleteElement, setElements, Store, galerie, galerieItems);
        case 'div_start':
            return ds(data[i], i, newElement, newelementIndex, modalShow, setModalShow, modalData, setModalData, setdeleteElement, setElements, Store);
        case 'div_end':
            return de(data[i], i, newElement, newelementIndex, modalShow, setModalShow, modalData, setModalData, setdeleteElement, setElements, Store);
        case 'fieldset_start':
            return fs(data[i], i, newElement, newelementIndex, modalShow, setModalShow, modalData, setModalData, setdeleteElement, setElements, Store);
        case 'fieldset_end':
            return fe(data[i], i, newElement, newelementIndex, modalShow, setModalShow, modalData, setModalData, setdeleteElement, setElements, Store);
        case 'select_start':
            return ss(data[i], i, newElement, newelementIndex, modalShow, setModalShow, modalData, setModalData, setdeleteElement, setElements, Store);
        case 'select_end':
            return se(data[i], i, newElement, newelementIndex, modalShow, setModalShow, modalData, setModalData, setdeleteElement, setElements, Store);
        case 'checkbox':
            return checkbox(data[i], i, newElement, newelementIndex, modalShow, setModalShow, modalData, setModalData, setdeleteElement, setElements, Store);
        case 'textarea':
            return textarea(data[i], i, newElement, newelementIndex, modalShow, setModalShow, modalData, setModalData, setdeleteElement, setElements, Store);
        case 'option':
            return option(data[i], i, newElement, newelementIndex, modalShow, setModalShow, modalData, setModalData, setdeleteElement, setElements, Store);
        case 'input':
            return input(data[i], i, newElement, newelementIndex, modalShow, setModalShow, modalData, setModalData, setdeleteElement, setElements, Store);
        case 'fileupload':
            return fileupload(data[i], i, newElement, newelementIndex, modalShow, setModalShow, modalData, setModalData, setdeleteElement, setElements, Store);
        case 'radio':
            return radio(data[i], i, newElement, newelementIndex, modalShow, setModalShow, modalData, setModalData, setdeleteElement, setElements, Store);
        case 'button':
            return button(data[i], i, newElement, newelementIndex, modalShow, setModalShow, modalData, setModalData, setdeleteElement, setElements, Store);

    }
}

export default renderFormSwitch;
