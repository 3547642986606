import React, {useState} from 'react';
import {
  ResponseAllShipingTypes,
  ResponseAllTax,
  ResponseallTaxon, ResponseAllUnit,
  ResponseLimitSearch, ResponseLimitSearchMerchant,
} from '../../../API/response/product/Product';
import {SpinnerJSX} from '../../../../../../@WUM/core/component/const/Spinner';
import {useParams} from 'react-router-dom';
import ContentSwitch from '../../../../../../@WUM/core/component/const/ContentSwitch';
import {TaxApiType} from '../../../API/request/Product/TaxApi.interface';
import {allTaxonApiType} from '../../../API/request/Product/allTacxonApi.interface';
import {ShippingApiType} from '../../../API/request/Product/ShippingApi.interface';
import {ResponseAllMerchant} from '../../../API/response/member/member';
import {memberApiType} from '../../../API/request/member/memberApi.interface';
import {allUnitApiType} from '../../../API/request/unit/allUnitApi.interface';
import IntlMessage from '../../../../../../@WUM/core/component/const/IntlMessage';
import {DummyUser} from "../../../../../../@WUM/core/dummy/user";
import {useSelector} from "react-redux";

interface NotificationsProps {
  value:any
}

const ProductOverview: React.FC<NotificationsProps> = (value: any) => {
  const user = DummyUser();
  localStorage.removeItem('arcticle');
  const Store = useSelector((state: any) => state)
  const forbidden = (search:any) => {
    if (user !== null) {
      for (let u = 0; u < user.role.length; u++) {
        if (user.role[u] === search) {
          return true;
        } else {

        }
      }
    }
  }
  const {page} = useParams<{page: string}>();
  let limit: any;



  const debug = (allTaxon: allTaxonApiType[] | boolean, tax: TaxApiType[], Shipping: ShippingApiType[], AllMerchant: memberApiType[] | boolean, Unit: allUnitApiType[]) => {
    return;
  }
  const Shipping =  ResponseAllShipingTypes();
  const allTaxon = ResponseallTaxon();
  const tax =  ResponseAllTax();
  const AllMerchant =  ResponseAllMerchant();
  const Unit =  ResponseAllUnit();
  debug(allTaxon,tax,Shipping,AllMerchant,Unit)
  if (typeof page === "string") {
    limit = (parseFloat(page) * 8) - 8;
  }
  const [searchValue, setSearchValue] = useState('');
  const [search, setSearch] = useState<boolean>(false)
  const [mode, setMode] = useState('cart');
  let response;
  if(user !== null && user !== undefined && (forbidden('merchant') === true || forbidden('merchant_ma') === true)) {
    if(user.merchant_id !== null) {
      response = ResponseLimitSearchMerchant(limit,user.merchant_id,searchValue);
    } else {
      response = ResponseLimitSearch(limit,searchValue);
    }
  } else {
      response = ResponseLimitSearch(limit,searchValue);
  }



  const destination = 'Product';


  let link;
  if(process.env.REACT_APP_MODE === 'LIVE') {
    link = process.env.REACT_APP_PREVIEW_LINK_LIVE;
  } else if(process.env.REACT_APP_MODE === 'DEVELOPMENT'){
    link = process.env.REACT_APP_PREVIEW_LINK_DEVELOPMENT;
  } else {
    link = process.env.REACT_APP_PREVIEW_LINK_LOCAL;
  }


  const CardsConfiguration = {
    'data': {
      'ressource': response,
      'img': 'img',
      'status': 'enabled',
      'imgproduct':true,
      'cdnlink':true,
      'indiBild':true,
      'statusActive': <IntlMessage Store={Store} messageId="available" />,
      'statusDeactive': <IntlMessage Store={Store} messageId="available.not" />,
      'link': 'translation.slug',
      'prelink': link+'/produkte/[param]/',
      'prelinksearch': 'slug',
      'showMerchant': false,
      'merchant': 'merchant.company',
      'title': 'name',
      'description': 'translation.short_description',
      'noDescription': true,
      'router': 'id',
      'preroute': '/Product/Detail/',
      'product': false,
      'edit': <IntlMessage Store={Store} messageId="product.edit" />,
      'delete': false,
      'deleteLink': ''
    }
  }

  const Configuration = {
    'tableBody': {
      'data': response,
      'dataID': 'id',
      'underdata': true,
      'keys': ['code', 'name', 'slug', 'created_at', 'enabled'],
      'type': ['string', 'string', 'string', 'date', 'status|available|available.not'],
    },
    'tableHeader': [<IntlMessage Store={Store} messageId="article.no" />, <IntlMessage Store={Store} messageId="article.name" />,  <IntlMessage Store={Store} messageId="common.slug" />, <IntlMessage Store={Store} messageId="created_at" />, <IntlMessage Store={Store} messageId="available" />],
    'tableHeaderWidth': ['15%', '40%',  '30%', '15%'],
    'tablecolor': ['light'],
    'menu': {
      'data': response,
      'menuDisable': false,
      'menuItem': [<IntlMessage Store={Store} messageId="common.display" />, <IntlMessage Store={Store} messageId="common.edit" />, <IntlMessage Store={Store} messageId="common.delete" />],
      'menuroute': ['/Product/Show', '/Product/Detail', '/Product/Delete'],
      'menuParams': [false, 'id', 'id'],
      'menuIcons': ['eye', 'pencil', 'Delete'],
    },

  };


    if (response.length > 0) {

      return (
        <>
          <ContentSwitch pagenew={'new'} destinationnew={'Product'} tonew={'new'} text={<IntlMessage Store={Store} messageId="product.create" />}
                         response={response} CardsConfiguration={CardsConfiguration} mode={mode}
                         Configuration={Configuration} setMode={setMode} destination={destination} page={page}
                         to={'overview'} setSearchValues={setSearchValue} setSearchs={setSearch} search={search}
                         modalShow={''} setModalShow={''} modalData={''} setModalData={''}
                         searchfield={[]} allTaxon={[]} _handleKeyFilters={[]} _handleEnter={[]} filter={false} Store={Store} SearchParam={'?name'}/>
        </>
      )
    } else {
      localStorage.setItem('searchEnterInput','');
      return (
        <><SpinnerJSX messageId={'common.loading'} Store={Store} /></>
      );
    }

}

export default ProductOverview;




