
const items = (form: any) => {

  let FormDatas = [];
  let inputs = form.elements;
  // Iterate over the form controlsc
  for (let i = 0; i < inputs.length; i++) {
    // Disable all form controls
    if (inputs[i].hasAttribute('name')) {
      if (!inputs[i].getAttribute('name').includes('elements') && !inputs[i].getAttribute('name').includes('[')) {
        FormDatas.push(inputs[i].getAttribute('name'));
      }
    }
  }

  let mapsdata = [];

  for (let f = 0; f < FormDatas.length; f++) {
    if (!FormDatas[f].includes('elements') && !FormDatas[f].includes('[')) {
      mapsdata.push(FormDatas[f]);
    }
  }

  return mapsdata;
};

export default items;