import Tab from "react-bootstrap/Tab";
import {Row, Tabs} from "react-bootstrap";
import PielersForm from "../../../../@WUM/core/form-serilizer";
import {dynamikForm} from "./dynamikForm";
import {TabsGeneral} from "../configuration/tabsConfig/General/tabsGeneral";
import React from "react";

export const dynamikTabs = (data:any,Store:any,response:any) => {
    let Holder = [];

    for(let x=0;x<data.length;x++) {
        let tabsHolder = []
        if (data[x] === undefined ) {

        } else {
            if(data[x].tabs !== undefined) {

                for(let tab=0;tab<data[x].tabs.tab.length;tab++) {
                    tabsHolder.push(
                        <Tab eventKey={data[x].tabs.tab[tab].eventkey} title={data[x].tabs.tab[tab].title} key={data[x].tabs.tab[tab].key}
                             style={{backgroundColor: "White", padding: "15px", marginTop: "-15px"}}>
                            <Row>{PielersForm(dynamikForm(data[x].tabs.tab[tab].content, Store, response))}</Row>
                        </Tab>
                    )
                }


                Holder.push(
                    <Tab eventKey={data[x].tabs.eventkey} title={data[x].tabs.title} key={data[x].tabs.key}>
                        <Tabs defaultActiveKey={data[x].tabs.defaultActiveKey} id={data[x].tabs.defaultActiveKey+"-tab-example"} className="mb-3" key={'tabindex'}>
                            {tabsHolder}
                        </Tabs >
                    </Tab>
                )
            } else {
                Holder.push(

                    <Tab eventKey={data[x].eventkey} title={data[x].title} key={data[x].key}
                         style={{backgroundColor: "White", padding: "15px", marginTop: "-15px"}}>
                        <Row>{PielersForm(dynamikForm(data[x].content, Store, response))}</Row>
                    </Tab>

                )
            }

        }
    }


    return (<>

        <Tabs defaultActiveKey="General" id="General-tab-example" className="mb-3" key={'tabindex'}>
            <Tab eventKey="General" title={'General'} key={'General'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
                {PielersForm(TabsGeneral('', 0, Store, response))}
            </Tab>
            {Holder}
        </Tabs>

    </>);

}

