

export const DummyUser = () => {
    return {
        "last_login": null,
        "username": "Florian Meier",
        "is_staff": false,
        "is_active": true,
        "date_joined": "2021-10-06T06:41:23.749000Z",
        "channel_id": null,
        "discr": "admin",
        "merchant_id": null,
        "email": "f.meier@pielers.de",
        "first_name": "Florian",
        "id": 1030,
        "last_name": "Meier",
        "locale_code": "de_DE",
        "password": "ouafEpPRyBwGq40q3KwSm7ViqfZaA/XeXLXN42QEBcjT20vtz7SFGP7DIBZ5LSEGh76zoUmA2dxCDbCCmrJrFw==",
        "salt": "6SI1GEWBOH4C55MLIWZSG60B827SLQ3",
        "password_reset_token": null,
        "password_requested_at": null,
        "role": [
            "user",
            "superadmin"
        ],
        "is_superuser": 1,
        "isDeleted": 0,
        "groups": [],
        "user_permissions": []
    };

}