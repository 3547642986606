import {Row} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import CreateMatomoIframe from "../../core/CreateMatomoIframe";
import WidgetURLMapping from "../../core/WidgetURLMapping";
import React from "react";


const Device = (props:{Store:any}) => {

    return(
        <>
            <Row>
                <Col>
                    <CreateMatomoIframe
                        headline={'Gerätetyp'}
                        width={"100%"}
                        height={"550"}
                        url={WidgetURLMapping({name:'device type'})}
                        scrolling={"yes"}
                        id={'D1'}
                        Store={props.Store}
                    />
                    <CreateMatomoIframe
                        headline={'Gerätemarke'}
                        width={"100%"}
                        height={"200"}
                        url={WidgetURLMapping({name:'device brand'})}
                        scrolling={"yes"}
                        id={'D2'}
                        Store={props.Store}
                    />
                </Col>
                <Col>
                    <CreateMatomoIframe
                        headline={'Gerätemodell'}
                        width={"100%"}
                        height={"300"}
                        url={WidgetURLMapping({name:'device model'})}
                        scrolling={"yes"}
                        id={'D3'}
                        Store={props.Store}
                    />
                    <CreateMatomoIframe
                        headline={'Bildschirmauflösungen'}
                        width={"100%"}
                        height={"300"}
                        url={WidgetURLMapping({name:'screen resolutions'})}
                        scrolling={"yes"}
                        id={'D4'}
                        Store={props.Store}
                    />
                </Col>
            </Row>
        </>
    )
}

export default Device;
