import DynamikOverview from "../../dynamikModul/Modules/Pages/overview";
import React from "react";
import {ResponseOverview, ResponseDelete, ResponseDetail,ResponseLegalforms} from "./API/response/Type/customizer";
import DynamikDeleted from "../../dynamikModul/Modules/Pages/deleted";
import DynamikDetail from "../../dynamikModul/Modules/Pages/detail";
import DynamikNew from "../../dynamikModul/Modules/Pages/new";
import CardJSX from "./configuration/card";
import SearchConfig from "./configuration/SearchConfig";
import {destination, link} from "./helper/const";
import table from "./configuration/table";
import Forms from "./configuration/Form"

export const GermanBusinessPagesConfig = (value:any) =>{


    return [
        {
            path: '/businessgerman/overview/:page',
            elements: <DynamikOverview value={value} table={table} destination={destination} state={'businessgerman'} SearchConfig={SearchConfig(destination)}
                                       CardsConfiguration={CardJSX(link,destination)} response={ResponseOverview} buttonText={'Dt. Unternehmen '} />,
            roles: ['superadmin','admin']
        },
        {
            path: '/businessgerman/Delete/:page/:deleteID',
            elements: <DynamikDeleted value={value} reponse={ResponseDelete} namespace={destination}  />,
            roles: ['superadmin','admin']
        },
        {
            path: '/businessgerman/new/new',
            elements: <DynamikNew value={value} forms={Forms} addedForm={ResponseLegalforms} namespace={'/'+destination+'/overview/1'} saveRoute={'company/german/0/'}/>,
            roles: ['superadmin','admin']
        },
        {
            path: '/businessgerman/new/:page',
            elements: <DynamikNew value={value} forms={Forms} addedForm={ResponseLegalforms} namespace={'/'+destination+'/overview/1'} saveRoute={'company/german/0/'}/>,
            roles: ['superadmin','admin']
        },
        {
            path: '/businessgerman/Detail/:id',
            elements: <DynamikDetail value={value} forms={Forms} addedForm={ResponseLegalforms}  response={ResponseDetail} saveRoute={'company/german/'}/>,
            roles: ['superadmin','admin']
        }
    ];
}

export const GermanBusinessMenuConfig = (value:any) =>{
    return [
        {
            name: 'Dt. Unternehmen',
            route: '/businessgerman/overview/1',
            icon: 'business',
            roles: ['superadmin','admin']
        },
    ];
}

export const GermanBusinessDasboard = () => [
    {
        title: 'Dt. Unternehmen',
        href: '/businessgerman/overview/1',
        undertitle:'Backoffice',
        icon: 'business',
        text: 'Dt. Unternehmen',
        roles: ['superadmin','admin']
    }
]
