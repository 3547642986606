import React from 'react';
import MonitoringOverview from "./Pages/overview";
import MonitoringSystemAPIOverview from "./Pages/MonitoringSystemAPIOverview";
import MonitoringSystemAPIOutbound from "./Pages/MonitoringSystemAPIOutbound";

export const MonitoringPagesConfig = (value: any) => {

    return [
        {
            path: '/Monitoring/overview/:page',
            elements: <MonitoringOverview value={value}/>,
            roles: ['superadmin']
        },
        {
            path: '/Monitoring/API/overview/:page',
            elements: <MonitoringSystemAPIOverview values={value}/>,
            roles: ['superadmin']
        },
        {
            path: '/Monitoring/email/overview/:page',
            elements: <MonitoringSystemAPIOutbound value={value}/>,
            roles: ['superadmin']
        },
    ];
}

export const MonitoringMenuConfig = (value: any) => {
    return [
        {
            name: 'Dashboard',
            route: '/Monitoring/overview/1',
            icon: 'dashboard',
            roles: ['superadmin']
        },
        {
            name: 'Monitoring',
            route: '/Monitoring/API/overview/1',
            icon: 'api',
            roles: ['superadmin']
        }
    ]
}
