import React from 'react';
import PageOverview from "./Pages/Pageoverview";
import PageDetail from "./Pages/PageDetail";
import PageNew from "./Pages/new";
import CalenderDeleted from "./Pages/deleted"

export const CalenderPagesConfig = (value:any) => {
  return [
    {
    path: '/Calender/overview/:page',
    roles: ['superadmin','admin','ContentCreator'],
    elements: <PageOverview value={value}/>,
  },
  {
    path: '/Calender/Detail/:id',
    roles: ['superadmin','admin','ContentCreator'],
    elements: <PageDetail value={value}/>,
  },
  {
    roles: ['superadmin','admin','ContentCreator'],
    path: '/Calender/Delete/:page/:deleteID',
    elements: <CalenderDeleted value={value}/>,
  },
  {
    path: '/Calender/new/:page',
    roles: ['superadmin','admin','ContentCreator'],
    elements: <PageNew value={value}/>,
  }
  ];
}
export const CalenderMenuConfig = (value:any) => {
  return [
    {
      header: 'Veranstaltungskalender',
      icon: 'event',
      roles: ['superadmin','admin','ContentCreator'],
      menuitem: [
        {
          name: 'Kalender',
          route: '/Calender/overview/1',
          icon: 'calendar_today',
          roles: ['superadmin','admin','ContentCreator']
        }
      ]
    },
  ];
}
