import React from "react";
import CreateMatomoWidget from "./CreateMatomoWidget";
import LoadIframe from "./loadIframe";
import {SpinnerJSX} from "../../../../@WUM/core/component/const/Spinner";

interface CreateMatomoIframeProps {
    url: string | undefined;
    width: string;
    height: string;
    scrolling: string;
    headline: string|any;
    id?:string;
    Store?:any
}

const CreateMatomoIframe = (props: CreateMatomoIframeProps) => {

    return (
        <>
            <div className={'ContentWrapper IframeMatomo'}>
                <div id="widgetIframe">
                    <h2>{props.headline}</h2>
                    <div className="container">
                        <SpinnerJSX class="loading" id={"loading"+props.id} messageId={'common.loading'} display={'inline-block'} Store={props.Store}/>
                        <iframe id={"frame"+props.id} style={{opacity: '0',display:'none'}} title={props.headline} width={props.width}
                                height={props.height}
                                src={CreateMatomoWidget({Widget: props.url})}
                                scrolling={props.scrolling} frameBorder="0"
                                onLoad={()=> LoadIframe({IframeID:"frame"+props.id,LoadingID:"loading"+props.id})}/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CreateMatomoIframe;
