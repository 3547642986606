import CreateMatomoIframe from "../../core/CreateMatomoIframe";
import WidgetURLMapping from "../../core/WidgetURLMapping";
import React from "react";


const Campaigns = () => {
    return(
        <>
            <CreateMatomoIframe
                headline={'Kampagnen'}
                width={"100%"}
                height={"600"}
                url={WidgetURLMapping({name:'campaigns'})}
                scrolling={"yes"}
                id={'c1'}
            />
        </>
    )
}

export default Campaigns;