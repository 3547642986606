import Pielersserialize from '../helper/fields';
import excape from '../helper/excape';

const serializeItem = (map: any,form: any) => {
  let data = excape(Pielersserialize(map, form));

  return data;
}

export default serializeItem;
