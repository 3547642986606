
const CardJSX = (link:any,destination:any) => {
  return {
    'data': {
      'ressource': '',
      'dontStatus': true,
      'img': '',
      'status': 'visible',
      'statusActive': '',
      'statusDeactive': '',
      'link': 'translation.de.slug',
      'prelink': link+'/magazin/[param]/',
      'prelinksearch': 'translation.de.slug',
      'title': 'companyName',
      'descriptionShow': false,
      'description': 'city',
      'AddressDontShowGF': true,
      'AddressShow': true,
      'Address': [{
        'street': 'street',
        'plz': 'postcode',
        'city': 'city',
        'HRB': 'hrb',
        'gffirstname':'first_name',
        'gflastname':'last_name'
      }],
      'router': 'id',
      'preroute': '/'+destination+'/Detail/',
      'edit': '',
      'delete': true,
      'Dontvisited': true,
      'deleteLink': ''
    }
  }
}

export default CardJSX;
