/**
 * Config für den Suneditor
 * @constructor
 */
export const SunEditorConfig = (mode:string = 'complex') => {

    const basic = [
        ["font", "fontSize"],
        ["fontColor"],
        ["horizontalRule"],
        ["link", "imageGallery"],
    ];

    const complex = [
        ["undo", "redo"],
        ["font", "fontSize", "formatBlock"],
        ["paragraphStyle","blockquote","bold", "underline", "italic"],
        ["strike", "subscript", "superscript"],
        ["removeFormat"],
        ["fontColor", "hiliteColor","textStyle"],
        ["outdent", "indent"],
        ["align", "horizontalRule", "list","lineHeight", "table"],
        ["link", "imageGallery", "video"],
        ["fullScreen", "showBlocks", "codeView"],
        ["preview", "template"]
    ];

    const formatting = [
        ["undo", "redo"],
        ["bold", "underline", "italic", "strike", "subscript", "superscript"],
        ["removeFormat"],
        ["outdent", "indent"],
        ["fullScreen", "showBlocks", "codeView"],
        ["preview", "print"],
    ];



    switch(mode) {
        case 'basic':
            return basic
        case 'complex':
            return complex
        case 'formatting':
            return formatting

    }
}

