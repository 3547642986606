import SunEditorBig from "../../../../../../@WUM/core/component/const/SunEditorBig";


function configuration(conf: string, response: any, newElement: any = false) {

  let config = [
    {
      'formType': 'multiply',
      'cols': [
        {
          'formType': 'input',
          'label': 'locale',
          'type': 'text',
          'name': 'locale',
          'id': 'locale',
          'readonly':(newElement === false ? (response[0] !== undefined ? response[0].locale : false)  : false),
          'value': (newElement === false ? (response[0] !== undefined ? response[0].locale : '')  : ''),
        },
        {
          'formType': 'input',
          'type': 'text',
          'label': 'name',
          'name': 'name',
          'readonly':(newElement === false ? (response[0] !== undefined ? response[0].locale : false)  : false),
          'value': (newElement === false ? (response[0] !== undefined ? response[0].name : '') : ''),
        },
      ],
    },
    SunEditorBig((newElement === false   ? response[0] : ''),'value',newElement,[],
        'value','value','','','','','classic','200'),
    {
      'formType': 'input',
      'type': 'hidden',
      'name': 'id',
      'value': (newElement === false ? response[0].id : 'null'),
    }
  ];

  return config;

}

export default configuration;
