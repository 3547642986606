/**
 * Erstellt den Header
 * @param name
 * @param nameID
 * @param Status
 * @param index
 * @param type
 * @param typeName
 * @param modalShow
 * @param setModalShow
 * @param modalData
 * @param setModalData
 * @param newElement
 * @param element
 * @param content
 * @param setElements
 * @constructor
 */
import {Accordion, Col, Row, Button} from "react-bootstrap";
import deleteElements from "../../form-serilizer/elements/helper/deleteElements";

export const ElementsHead  = (name:any,nameID:any,preview:any,Status:any,index:any,type:any,
                              typeName:any,modalShow:any,setModalShow:any,modalData:any,
                              setModalData:any,newElement:any,element:any,content:any = false,setElements:any = [],Store:any,isimgPreview:any =false,isVideoPreview:any =false,elementId:any = 0) => {
    let Margin;
    if(newElement === false) {
        Margin = '-6px';
    } else {
        Margin = '6px';
    }

    const openMoadl= () => {
        let modal = document.getElementById('ModalCopyElements'+elementId) as HTMLDivElement
        modal.style.display = "block";
    }

    let previewHolder;
    if(isimgPreview === true) {
        preview = (<><img src={preview} width={'65'} style={{marginLeft:'10px'}}/></>)
    } else if(isVideoPreview === true) {
        preview = (<><video id="image_title_image_4" width="250" height="250" controls={true} style={{marginLeft:'10px'}}>
            <source src={preview} type="video/mp4" />
                Your browser does not support the video tag.</video>
        </>)
    } else {
        preview = (<><span style={{marginLeft:'10px'}} dangerouslySetInnerHTML={{ __html: preview.substr(0, 100)}} /></>)
    }

    return (<><Row>
        <Col style={{width: '60px', maxWidth: '60px'}}>
            <h3 className={Status} style={{height:'100%',marginTop: '0px',padding:'0.08em 0em',width:'60px',textAlign:'center'}}>
        <span className="material-icons" style={{fontSize: 'xxx-large',marginTop: Margin,color:'black'}}>
     open_with
</span></h3></Col><Col>
        <Accordion.Button style={{background:'#f9fafb',border:'1px solid black'}}>
            <h3>{name}</h3>{(elementId !== 0 ? <Button variant={'info'} className={'elementButton'} onClick={()=> openMoadl()} style={{right: '90px'}}>Kopieren</Button> : <></>)}
            {preview}
            {deleteElements(nameID.toLowerCase()+''+index,type,typeName,modalShow, setModalShow,modalData, setModalData,(newElement === false ? element.id : 0 ),content,setElements,Store)}
        </Accordion.Button>

    </Col>
        {/* <Col style={{width: '50px', maxWidth: '50px',marginTop: '10px'}}>
      {deleteElements(nameID.toLowerCase()+''+index,type,typeName,modalShow, setModalShow,modalData, setModalData,(newElement === false ? element.id : 0 ),content,setElements)}
    </Col>*/}
    </Row></>)
}

export default ElementsHead;
