import React from 'react';
import {Accordion, Tabs} from 'react-bootstrap';
import PielersForm from '../../index';
import IntlMessage, {IntlMessageValue} from "../../../component/const/IntlMessage";
import {baseBootstrapForms, baseForms} from "./base";
import Tab from "react-bootstrap/Tab";
import MergeTwoObjecttoArray from "../../../component/const/MergeTwoObjecttoArray";
import FormsHead from "../../../component/const/FormsHead";

function headline(forms: any, i: any, newElement: any = false, newelementIndex: any = 0,
              modalShow: any,
              setModalShow: any,
              modalData: any,
              setModalData: any,
              content: any = [], setElements: any = [], Store: any, galerie?: any,
              galerieItems?: any) {

    const index = (newElement === false ? i : newelementIndex)
    const headlines = [1,2,3,4,5,6].map(n => ({
        'label': IntlMessageValue('de',"module.headline_n",Store,"replace","[n]",n),
        'value': 'h'+n,
    }));
    const fake_headlines = [1,2,3,4,5,6].map(n => ({
        'label': IntlMessageValue('de',"module.fake_headline_n",Store,"replace","[n]",n),
        'value': 'h'+(n+6),
    }));

    let options = MergeTwoObjecttoArray(headlines,fake_headlines)
    const configuration = [
        {
            'formType': 'select',
            'label': <IntlMessage Store={Store} messageId="module.headline_type" />,
            'name': 'form[' + index + '][type]',
            'selected': (newElement === false ? forms.type : ''),
            'options': options,
        },
        {
            'formType': 'input',
            'label': 'Text',
            'name': 'form[' + index + '][text]',
            'type': 'text',
            'value': (newElement === false ? forms.text: ''),
        },{
            'formType': 'input',
            'name': 'form[' + index + '][value]',
            'type': 'hidden',
            'value': (newElement === false ? forms.value : ''),
        },

    ]
    let newConfiguration = configuration;


    let Status;
    if(forms.visible === 1) {
        Status = 'elementActive';
    } if(forms.visible === 0){
        Status = 'elementDeactive';
    } else if(newElement === true) {
        Status = 'elementActive';
    }
    return (
        <>
            <Accordion.Item eventKey={index} id={'text'+index}   style={{marginBottom: '10px'}}>
                {FormsHead(IntlMessageValue('de',"form.elements.headline",Store),'headline',(newElement === false ? forms.text: ''),Status,index,'elementButton','headline',
                    modalShow,setModalShow,modalData, setModalData,newElement,forms,content,setElements,Store)}
                <Accordion.Body>
                    <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3" key={'tabindex'}>
                        <Tab eventKey="home" title={<IntlMessage Store={Store} messageId="common.general" />} key={'allgemein'} id={'allgemein'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
                            {PielersForm(newConfiguration)}
                        </Tab>
                        <Tab eventKey="Bootstrap" title={'Bootstrap'} key={'Bootstrap'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
                            {PielersForm(baseBootstrapForms(forms,i,newElement,newelementIndex,Store))}
                        </Tab>
                        <Tab eventKey="Einstellungen" title={<IntlMessage Store={Store} messageId="settings.settings.header" locale={'de'} />} key={'Einstellungen'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
                            {PielersForm(baseForms(forms,i,newElement,newelementIndex,Store))}
                        </Tab>
                    </Tabs>
                </Accordion.Body>
            </Accordion.Item>

        </>
    )


}

export default headline;
