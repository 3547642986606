
const CardJSX = (link:any,destination:any) => {
  return {
    "data": {
      "ressource": "",
      "dontStatus": true,
      "img": "",
      "status": "visible",
      "statusActive": "",
      "statusDeactive": "",
      "link": "translation.de.slug",
      "prelink": link + "/magazin/[param]/",
      "prelinksearch": "translation.de.slug",
      "title": "company",
      "descriptionShow": false,
      "description": "city",
      "AddressShow": true,
      "Address": [{
        "street": "street",
        "plz": "postcode",
        "city": "city",
        "HRB": "hrb",
        "gffirstname": "gffirstname",
        "gflastname": "gflastname"
      }],
      "router": "id",
      "preroute": "/" + destination + "/Detail/",
      "edit": "",
      "delete": true,
      "Dontvisited": true,
      "deleteLink": ""
    }
  }
}

export default CardJSX;
