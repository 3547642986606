import {Element, Translation} from '../../API/request/site/templateApiDetail.interface';

export interface config {
  title: any,
  visible: number,
  id:any,
  identifier: string,
  type: string,
  layout: string,
  position: any,
  share_title: string,
  share_description: string,
  seo_title: string,
  seo_description: string,
  menu_event: string,
  seo_keywords: string,
  seo_index: string,
  parent: any;
  elements: Element[]
  translation: Translation;
}

export function output(Data: any) {
  let back = {
    title: Data.title,
    id:Data.id,
    visible: Data.visible,
    identifier: Data.identifier,
    type: Data.type,
    layout: Data.layout,
    parent: Data.parent_id,
    position: Data.position,
    share_title: Data.share_title,
    share_description: Data.share_description,
    seo_title: Data.seo_title,
    seo_description: Data.seo_description,
    seo_keywords: Data.seo_keywords,
    menu_event: Data.menu_event,
    seo_index: Data.seo_index,
    elements: Data.elements,
    translation: Data.translation


  }

  return back;
}
