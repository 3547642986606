
const CardJSX = (link:any,destination:any) => {
  return {
    'data': {
      'ressource': '',
      'dontStatus': true,
      'img': '',
      'status': 'visible',
      'statusActive': '',
      'statusDeactive': '',
      'link': 'translation.de.slug',
      'prelink': link+'/magazin/[param]/',
      'prelinksearch': 'translation.de.slug',
      'title': 'token',
      'descriptionShow': true,
      'description': 'refferal_date',
      'router': 'id',
      'showreferer': true,
      'referer_label': 'Referer',
      'referer_firstname': 'referer.customer.firstName',
      'referer_lastname':  'referer.customer.lastName',
      'refer_user_label': 'User',
      'referer_user_firstname': 'user.customer.firstName',
      'referer_user_lastname':  'user.customer.lastName',
      'preroute': '/'+destination+'/Detail/',
      'edit': '',
      'delete': true,
      'Dontvisited': true,
      'deleteLink': ''
    }
  }
}

export default CardJSX;
