import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {isEmptyArray} from 'formik';
import {SpinnerJSX} from '../components/Spinner';
import Alllabel from '../../API/alllabel';
import Labeloverview from '../components/Labeloverview';
import LabelEditProps from '../../Interface/LabelEdit.interface';
import {useSelector} from "react-redux";



const LabelEdit = (props:LabelEditProps) => {
  let labels = Alllabel();
    const Store = useSelector((state: any) => state)
  if( labels !== undefined && !isEmptyArray(labels)) {
  return (
    <>
      <Row>
          <Col md={12} sm={12} xl={12} xxl={12} lg={12} className={'ContentWrapper'}>
          <Labeloverview label={labels} Store={Store} />
          </Col>
    </Row>
  </>)
  } else {
    return (<><SpinnerJSX messageId={'common.loading'} /></>)
  }
}

export default LabelEdit;
