import IntlMessage from "../../../../../../../@WUM/core/component/const/IntlMessage";
import React from "react";


function configuration(conf: string,response:any,Store:any) {

  const information = [
    {
      'formType': 'fetchselect',
      'local': 'allTaxon',
      'valueState': 'translatable_id',
      'beginn': 'true',
      'start': 0,
      'type': 'taxonMerchant',
      'onClick_id': 'taxon',
      'onClickBeginn': response.taxons.length,
      'labelState': 'name',
      'selected': '',
      'label': <IntlMessage Store={Store} messageId="category.provider.add" />
    },
    {
      'formType': 'holder',
      'ressource': response.taxons,
      'value': '',
      'type': 'taxonMerchant'
    },

  ];



  if(conf === 'information') {
    return information;
  }

}

export default configuration;
