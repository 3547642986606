import {LanguageSwitcherValue} from '../components/LanguageSwitcher';
import {isEmptyArray} from 'formik';
import HelperRowOptionProps from '../../Interface/HelperRowOption.interface';



  
  export const HelperRowOption = (props:HelperRowOptionProps) => {
    if (props.row !== undefined && !isEmptyArray(props.row)) {
      let OptionSwimlane = [];
  
      for (let x = 0; x < props.row.length; x++) {
        OptionSwimlane.push(<>
          <option
            value={props.row[x].id}
            selected={(props.selected !== undefined && props.selected === props.row[x].name ? true : false)}
          >{LanguageSwitcherValue('board.' + props.row[x].name.toLowerCase() + '')}</option>
        </>)
      }
  
      return (
        <>
          {OptionSwimlane}
        </>
      )
    } else {
      return (
          <></>
      )
    }
  }

  export default HelperRowOption;