import React, {useRef, useState} from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Button from 'react-bootstrap/Button';
import {
    useNavigate,
    useParams,
} from 'react-router-dom';
import configuration from './configuration/Page/configuration';
import Spinner from 'react-bootstrap/Spinner';
import {notify_save} from '../../../../../@WUM/core/component/const/notification.service';
import {error} from '../../../../../@WUM/core/component/const/error.services';
import {handleSubmitForm} from '../../../../../@WUM/core/component/const/handleSubmitForm';
import {Form} from 'react-bootstrap';
import IntlMessage from '../../../../../@WUM/core/component/const/IntlMessage';
import {useSelector} from "react-redux";
import {saveDataIndividualPut} from "../../../../../@WUM/core/component/const/saveData";
import {ResponseformularSettings} from "../../API/response/formular/formular";
import FormMaker from "../../../../../@WUM/core/form-serilizer";



const FormDetail = (value:any) => {

    const { id } = useParams<{ id: string }>();
    const response2 = ResponseformularSettings(id);
    const history = useNavigate();
    const Store = useSelector((state: any) => state)
    /**
     * Neuladen der Seite
     */
    const routeChange = (path:string) => {
        let paths = path;
        history(paths);
    };
    const [validated, setValidated] = useState(false);


    const forms = useRef(null)
    const submit = () => {



        let Dataform = forms.current
        if (Dataform !== undefined && Dataform !== null) {
            const data = new FormData(Dataform)
            let object:any = {};
            data.forEach((value, key) => {
                if(!Reflect.has(object, key)){
                    object[key] = value;
                    return;
                }
                if(!Array.isArray(object[key])){
                    object[key] = [object[key]];
                }
                object[key].push(value);
            });
            let json = JSON.stringify(object);

            saveDataIndividualPut('formular/save/', json)
                .then((data: any) => {
                    notify_save(Store);
                    setTimeout(() => window.location.reload(), 3000);
                })
                .catch(e => {
                    error(e);
                });
        }

    }

    if (response2.length > 0) {
        return (
            <div>
                <Form autoComplete="none" ref={forms}  id={'form001'} noValidate validated={validated} onSubmit={(e) => handleSubmitForm(e, submit, setValidated, 0)}>
                    <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3" key={'tabindex'}>
                        <Tab eventKey="home" title={<IntlMessage Store={Store} messageId="common.general" />} key={'Allgemein'} id={'Allgemein'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
                            {FormMaker(configuration('dataColOne', response2, false,Store))}
                        </Tab>
                    </Tabs>
                    <div style={{backgroundColor: 'white', padding: '15px'}}>
                        <Button variant='light' style={{float: 'left', marginLeft: '15px'}} onClick={() => history(-1)}><IntlMessage Store={Store} messageId="common.back"/></Button>
                        <Button variant='success'
                                style={{float: 'right', marginRight: '15px'}}
                                type={'submit'}
                        >
                            <IntlMessage Store={Store} messageId="common.save" />
                        </Button>
                        <br style={{clear: 'both'}} />
                    </div>
                </Form>
            </div>
        );
    } else {
        return (
            <><Spinner animation="border" role="status">
                <span className="visually-hidden"><IntlMessage Store={Store} messageId="common.loading" /></span>
            </Spinner></>
        )
    }
}


export default FormDetail;
