import {formularApiType} from './formularApi.interface';
import {requests} from '../../../../../../@WUM/core/RestFullApi/api';
import {formularApiDetail, formularsendApiDetail, MenuApiDetail} from './formularApiDetail.interface';

/**
 * Sends the call to the FULL REST API with the methods GET, POST, PUT
 * @namespace blog
 * @see {@link blogApiType}
 * @see {@link blogApiDetail}
 * @prop {any} limit Indicates the current page
 * @prop {any} searchValue Submits search filters
 * @prop {any} post Indicates POST
 * @prop {any} id Transmits the ID of the target element
 * @example
 * getPosts: (limit: any, searchValue: any): Promise<blogApiType[]> => requests.get(`Content/Blog/${limit}/${searchValue}`),
 * @author Marcel Ugurcu
 * @version 0.0.1
 */
export const menu = {
  getPosts: (): Promise<formularApiType[]> => requests.get(`menu/all/`),
  //getPosts: (): Promise<blogApiType[]> => requests.get(`Content/Blog/`),
  getPostsALL: (): Promise<formularApiType[]> => requests.get(`formular/All/`),
  getAPost: (id: string | undefined): Promise<formularApiDetail[]> => requests.get(`formular/Single/${id}/`),
  getAPostSettings: (id: string | undefined): Promise<formularApiDetail[]> => requests.get(`formular/settings/Single/${id}/`),
  createPost: (post: formularApiType, id: number): Promise<formularApiType> =>
    requests.put(`/formular/Single/${id}/`, post),
  updatePost: (post: any): Promise<MenuApiDetail> =>
    requests.post(`menu/save/`, post),
  getPostsFormularSave: (post: any, id: number): Promise<formularsendApiDetail> =>
      requests.post(`menu/save/`, post),
  deletePost: (id: string): Promise<void> => requests.delete(`SingleBlog/on/delete/${id}/`),
  getALLTypes: (): Promise<formularApiType[]> => requests.get(`allType/`),
};



