import CreateMatomoIframe from "../../core/CreateMatomoIframe";
import WidgetURLMapping from "../../core/WidgetURLMapping";
import React from "react";


const SocialNetworks = () => {
    return(
        <>
            <CreateMatomoIframe
                headline={'Soziale Netzwerke'}
                width={"100%"}
                height={"600"}
                url={WidgetURLMapping({name:'Social networks'})}
                scrolling={"yes"}
                id={'sn1'}
            />
        </>
    )
}

export default SocialNetworks;