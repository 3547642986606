import {useEffect, useState} from 'react';
import {error} from '../../../../../@WUM/core/component/const/error.services';
import {allLogingApiType} from '../../request/login/allLogingApi.interface';
import {Loging} from '../../request/login/loging';
import {checkLogin} from "../../../../../@WUM/templates/default/Auth/service/AuthService";


export const Response = (limit:any,searchValue:any) => {
  const [posts, setPosts] = useState<allLogingApiType[]>([]);
  const [isError, setIsError] = useState<boolean>(false);
  useEffect(() => {
    (async () => {
      await Loging.getallLoging(limit,searchValue)
    .then((data) => {
      checkLogin(data);
      setPosts(data);
    })
    .catch((err) => {
      error(err);
      setIsError(true);
    });
    })()}, [limit,searchValue])


  return isError ? (isError ) : (posts);

}
