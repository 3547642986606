import {TabsSlider} from "./Slider/tabsSlider";
import {TabsM22} from "./m22/tabsm22";
import {TabsM02} from "./m02/tabsm02";
import {TabsHeadline} from "./Headline/tabsHeadline";

export const TabsModule = {
    tabs:{
        defaultActiveKey: 'Slider',
        eventkey: 'Module',
        key: 'Module',
        title: 'Module',
        tab: [TabsSlider,TabsM22,TabsM02,TabsHeadline]
    }
}
