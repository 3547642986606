import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import {handleSucces} from './HandleSucces';
import React from 'react';

/**
 * @typedef newEleProps
 * @prop {any} history Übergibt das history Object
 * @prop {any} page Gibt an welche Page
 * @prop {any} destination Wo wir uns befinden
 * @prop {any} to Wohin geroutet werden soll
 * @prop {any} text Text des elements
 * @author Marcel Ugurcu
 * @version 0.0.1
 */
interface newEleProps {
  history: any,
  page: any,
  destination: any,
  to: any,
  text: any,
}

/**
 *
 * @param {newEleProps} props Übergabe Werte für Preperatoren, Language id
 * @constructor
 * @returns Button gibt einen Button für den success Fall zurück
 * @author Marcel Ugurcu
 * @version 0.0.1
 * Example for Configuration
 * @example
 * <NewButton history={props.history} page={props.pagenew} destination={props.destinationnew} to={props.tonew} text={props.text} />
 *
 */
const NewButton : React.FC<newEleProps> = (props) => {
  return(<><Col>
  <Button variant='success'
                 style={{float: 'right', marginRight: '15px',maxWidth: '250px'}}
                 type={'button'}
                 onClick={() => handleSucces(props.page,props.history,props.destination,props.to)}
    >
      {props.text}
    </Button>
    </Col>
    </>
  )
}

export default NewButton;