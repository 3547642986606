import { ProductDataAction } from '../ActionType'
import { Dispatch } from 'redux'
import { fetchDataApi } from '../../../@WUM/core/RestFullApi/ApiHook'
//import { Product } from '../../../modules/CMS/Blog/API/request/Product/Product'
/**
 * @typedef Product Init
 * @author IOTA IT
 * @version 0.0.1
 */
export const ProductRequestInit = () => {
  return {
    type: ProductDataAction.PRODUCT_INIT,
  }
}
/**
 * @typedef Product success method
 * @returns product
 * @param {any} data
 * @author IOTA IT
 * @version 0.0.1
 */
export const ProductRequestSuccess = (data: any) => {
  return {
    type: ProductDataAction.PRODUCT_SUCCESS,
    data: data,
  }
}/**
 * @typedef Product variant
 * @returns 
 * @param {any} variant
 * @author IOTA IT
 * @version 0.0.1
 */
export const ProductVariant = (variant: any) => {
  return {
    type: ProductDataAction.PRODUCT_VARIANT,
    variant: variant,
  }
}/**
 * @typedef Product Data sheet
 * @param {any} datasheet
 * @author IOTA IT
 * @version 0.0.1
 */
export const ProductDatasheet = (datasheet: any) => {
  return {
    type: ProductDataAction.PRODUCT_DATASHEET,
    datasheet: datasheet,
  }
}/**
 * @typedef Product success method
 * @param {any} shipping
 * @author IOTA IT
 * @version 0.0.1
 */
export const ProductShipping = (shipping: any) => {
  return {
    type: ProductDataAction.PRODUCT_SHIPPING,
    shipping: shipping,
  }
}/**
 * @typedef Product Tax
 * @param {any} tax
 * @author IOTA IT
 * @version 0.0.1
 */
export const ProductTax = (tax: any) => {
  return {
    type: ProductDataAction.PRODUCT_PRODUCT_TAX,
    tax: tax,
  }
}/**
 * @typedef ProductAllTaxon
 * @param {any} : tax
 * @type any
 * @author IOTA IT
 * @version 0.0.1
 */
export const ProductAllTaxon = (taxon: any) => {
  return {
    type: ProductDataAction.PRODUCT_ALL_TAXON,
    taxon: taxon,
  }
}/**
 * @typedef all Product
 * @param {any} product
 * @author IOTA IT
 * @version 0.0.1
 */
export const AllProduct = (product: any) => {
  return {
    type: ProductDataAction.PRODUCT_ALL_PRODUCT,
    product: product,
  }
}/**
 * @typedef ProductImage
 * @param {any} image
 * @type any
 * @author IOTA IT
 * @version 0.0.1
 */
export const ProductImage = (image: any) => {
  return {
    type: ProductDataAction.PRODUCT_IMAGES,
    image: image,
  }
}/**
 * @typedef ProductArticle
 * @param {any} article
 * @author IOTA IT
 * @version 0.0.1
 */
export const ProductArticle = (article: any) => {
  return {
    type: ProductDataAction.PRODUCT_ARTICLE,
    article: article,
  }
}/**
 * @typedef ProductDimension
 * @param data : any
 * @author IOTA IT
 * @version 0.0.1
 */
export const ProductDimension = (dimension: any) => {
  return {
    type: ProductDataAction.PRODUCT_DIMENSIONS,
    dimension: dimension,
  }
}
/**
 * @typedef ProductRequestFail
 * @returns object
 * @param {any} error
 * @author IOTA IT
 * @version 0.0.1
 */

export const ProductRequestFail = (error: any) => {
  return {
    type: ProductDataAction.PRODUCT_FAIL,
    error: error,
  }
}

/**
 * @typedef DataAction
 * @dispatch error
 * @dispatch data
 * @author IOTA IT
 * @version 0.0.1
 */

export const getProduct = () => {
  return (dispatch: Dispatch) => {
    dispatch(ProductRequestInit())
    fetchDataApi('')
      .then((res: any) => {
        dispatch(ProductRequestSuccess(res))
      })
      .catch((err: any) => {
        dispatch(ProductRequestFail(err))
      })
  }
}

export const getProductVariant = (data: any) => {
  return (dispatch: Dispatch) => {
    dispatch(ProductRequestInit())

    dispatch(ProductVariant(data))
  }
}
export const getProductDatasheet = (data: any) => {
  return (dispatch: Dispatch) => {
    dispatch(ProductDatasheet(data))
  }
}
export const getProductShipping = (data: any) => {
  //console.log(data);

  return (dispatch: Dispatch) => {
    dispatch(ProductShipping(data))
  }
}
export const getProductTax = (data: any) => {
  return (dispatch: Dispatch) => {
    dispatch(ProductTax(data))
  }
}
export const getAllTaxon = (data: any) => {
  return (dispatch: Dispatch) => {
    dispatch(ProductVariant(data))
  }
}
export const getAllProduct = (data: any) => {
  return (dispatch: Dispatch) => {
    dispatch(ProductRequestInit())
    dispatch(AllProduct(data))
    // Product.getAllProducts().then((res) => {

    // })
  }
}
export const getProductImage = (data: any) => {
  return (dispatch: Dispatch) => {
    dispatch(ProductImage(data))
  }
}
export const getProductArticle = (data: any) => {
  return (dispatch: Dispatch) => {
    dispatch(ProductArticle(data))
  }
}
export const getProductDimensions = (data: any) => {
  return (dispatch: Dispatch) => {
    dispatch(ProductDimension(data))
  }
}
