import React from 'react';
import PielersForm from '../../index';
import {basesettings} from './base';
import IntlMessage from "../../../component/const/IntlMessage";
import CopyElements from "../../../component/const/CopyElements";
import WebBuilderElementsHead from "../../../component/const/WebBuilderElementsHead";
import Col from 'react-bootstrap/Col';
import Row from "react-bootstrap/Row";
import Box from '@mui/material/Box';
import {clearCol} from "../../../component/core/elements/content";

function dividerWebBuilder(data: any, element: any, i: any, newElement: any = false, newelementIndex: any = 0, modalShow: boolean,
                 setModalShow: (value: (((prevState: boolean) => boolean) | boolean)) => void, modalData: any[],
                 setModalData: (value: (((prevState: any[]) => any[]) | any[])) => void,
                 content:any = [],setElements:any = [],Store:any) {

  const index = (newElement === false ? i : newelementIndex)

  const configuration = [
    {
      'formType': 'divider'
    },
    {
      'formType': 'input',
      'name': 'elements[' + index + '][type]',
      'type': 'hidden',
      'value': element.type,
    },
    {
      'formType': 'input',
      'name': 'elements[' + index + '][id]',
      'type': 'hidden',
      'value': element.id,
    },
    {
      'formType': 'input',
      'name': 'elements[' + index + '][new]',
      'type': 'hidden',
      'value': (newElement === false ? false : true),
    },
    {
      'formType': 'input',
      'name': 'elements[' + index + '][position]',
      'type': 'hidden',
      'value': (newElement === false ? element.position : index),
    },
    {
      'formType': 'input',
      'name': 'elements[' + index + '][updated_at]',
      'type': 'hidden',
      'value': element.updated_at,
    },
    {
      'formType': 'input',
      'name': 'elements[' + index + '][type_id]',
      'type': 'hidden',
      'value': (newElement === false ? element.type_id : window.location.pathname.split('/').pop()),
    },
    {
      'formType': 'input',
      'name': 'elements[' + index + '][template]',
      'type': 'hidden',
      'value': 'Elements/divider.html',
    }
    ]

  let newConfiguration = configuration;

  let Status;
  if(element.visible === 1) {
    Status = 'elementActive';
  } if(element.visible === 0){
    Status = 'elementDeactive';
  } else if(newElement === true) {
    Status = 'elementActive';
  }
  return (
    <>
    <Box id={'divider'+index} data-appendcontainer={(newElement === false && element.appendto !== null && element.appendto !== undefined && element.appendto !== '' ?  element.appendto : 'false')} >
    {WebBuilderElementsHead(<IntlMessage Store={Store} messageId="module.divider" />,'divider','',Status,index,'elementButton','Text',
            modalShow,setModalShow,modalData, setModalData,newElement,element,content,setElements,Store,'','',element.id)}
      <div id={'preview-container_divider'+index}  className={'preview-container '+Status} style={{display: (newElement === false ? 'block' : 'none')}}>
        {(newElement === false ?
            <span dangerouslySetInnerHTML={{ __html: element.previews }} /> : <></>)}
      </div>
      <Row id={'mein_divider'+index} className={'boxShadowCard card ElementBorder'} style={{width: '100%',margin:'0px 0px 10px 0px',border: '0',boxShadow: 'unset'}}>

        <Col  className={'element-body'}>

          <div id={'edit_divider'+index} className={'boxShadowCard card '+Status} style={{display:(newElement === false ? 'none' : 'block'),padding:'15px',width: '100%',marginBottom: '20px'}}>
            <div id={'Teaser'}>divider</div>
            <Col style={{marginBottom: '10px',marginTop:'10px'}}><IntlMessage Store={Store} messageId="module.divider" /></Col>
          {PielersForm(newConfiguration)}
        </div>
          </Col>
        <div id={'settingstext'+index+'divider'} className={'settingsWebBuilderModul '} style={{display:'none'}}>

          {PielersForm(basesettings(element,i,newElement,newelementIndex,Store))}
        </div>
          {(newElement === false ? CopyElements(element.id,<IntlMessage messageId="module.divider"  Store={Store}/>,Store) : <></>)}

        </Row>
        </Box>
    </>
  )


}

export default dividerWebBuilder;
