
export const TabsM02 = {
    eventkey: 'm02',
    key: 'm02',
    title: 'Three-Steps-Module',
    content: [
        {
            label: "m02.m02-cta-bg",
            discr: 'm02-cta-bg',
            size: [
                3,
                3,
                3
            ],
            type: 'text'
        },
        {
            label: "m02.m02-cta-color",
            discr: 'm02-cta-color',
            size: [
                3,
                3,
                3
            ],
            type: 'color'
        },
        {
            label: "m02.m02-cta-font-weight",
            discr: 'm02-cta-font-weight',
            size: [
                3,
                3,
                3
            ],
            type: 'text'
        },
        {
            label: "m02.m02-cta-font-size",
            discr: 'm02-cta-font-size',
            size: [
                3,
                3,
                3
            ],
            type: 'text'
        },
        {
            label: "m02.m02-cta-bg-hover",
            discr: 'm02-cta-bg-hover',
            size: [
                3,
                3,
                3
            ],
            type: 'color'
        },
        {
            label: "m02.m02-cta-color-hover",
            discr: 'm02-cta-color-hover',
            size: [
                3,
                3,
                3
            ],
            type: 'color'
        }
    ]
}
