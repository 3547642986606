import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import React, {useRef} from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import PielersForm from '../../../../../../../@WUM/core/form-serilizer';
import {saveDataIndividualPut} from '../../../../../../../@WUM/core/RestFullApi/ApiHook';
import {notify_saveProduct} from '../../../../../../../@WUM/core/component/const/notification.service';
import {error} from '../../../../../../../@WUM/core/component/const/error.services';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import IntlMessage from '../../../../../../../@WUM/core/component/const/IntlMessage';

export const setDataSheet = (setModalDataSheetShow: (value: (((prevState: boolean) => boolean) | boolean)) => void) => {
  setModalDataSheetShow(true)
}

export const modalDataSheet = (modalDataSheetShow: boolean, setModalDataSheetShow: (value: (((prevState: boolean) => boolean) | boolean)) => void,saveDataSheet:any,DataSheetValue:any,id:any,Store:any) =>{

  let DataDataSheet: any = DataSheetValue;

  return(<>
      <DataSheetModal
        show={modalDataSheetShow}
        data={DataDataSheet}
        fetchId={id}
        onHide={() => setModalDataSheetShow(false)}
        saveDataSheet = {saveDataSheet}
        Store={Store}
      />
    </>
  )
}

const DataSheetModal = (props:any,) => {
  let datasheet:any = JSON.parse(localStorage.getItem('datasheet') as string);

  let component = [];

  let ergonomie;

  if(process.env.REACT_APP_CHANNEL === 'MYHOMICE') {
    ergonomie = (<>
      <Tab eventKey="ergonomie" title={<IntlMessage Store={props.Store} messageId="touchpoint.myhomice.datasheet.index" />}
           key={'ergonomie'} id={'ergonomie'} style={{backgroundColor: "White", padding: "15px", marginTop: "-15px"}}>
        <Row><Col>{PielersForm(datasheet?.ergonomie)}</Col>
          <Col>
            <h4><IntlMessage Store={props.Store} messageId="touchpoint.myhomice.datasheet.title" /></h4>
            <p><IntlMessage Store={props.Store} messageId="touchpoint.myhomice.datasheet.description" /></p>
            <Button variant={'info'} type={'button'}
                    href={'https://www.myhomice.de/fuer-nutzer/myhomice-ergonomie-index/'} target={'_blank'}>Mehr
              Informationen</Button>
          </Col></Row>
      </Tab>
    </>)
  }



  if(process.env.REACT_APP_AGRICA === 'True') {
    component.push(
      <>
        <Tabs defaultActiveKey="criteria" id="uncontrolled-tab-example" className="mb-3" key={'tabindex'}>
        <Tab eventKey="criteria" title={<IntlMessage Store={props.Store} messageId="criteria.form" />} key={'criteria'} id={'criteria'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
          <h3><IntlMessage Store={props.Store} messageId="criteria.form" /></h3>
          {PielersForm(datasheet?.criteria)}
        </Tab>
        <Tab eventKey="allergens" title={<IntlMessage Store={props.Store} messageId="allergens.form" />} key={'allergens'} id={'allergens'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
          <h3><IntlMessage Store={props.Store} messageId="allergens.form" /></h3>
          {PielersForm(datasheet?.allergens)}
        </Tab>
        <Tab eventKey="sealsPielers" title={<IntlMessage Store={props.Store} messageId="sealsPielers.form" />} key={'sealsPielers'} id={'sealsPielers'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
          <h3><IntlMessage Store={props.Store} messageId="sealsPielers.form" /></h3>
          {PielersForm(datasheet?.sealsPielers)}
        </Tab>
        <Tab eventKey="shippingPielers" title={<IntlMessage Store={props.Store} messageId="shippingPielers.form" />} key={'shippingPielers'} id={'shippingPielers'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
          <Row>
            <Col>
              <h3><IntlMessage Store={props.Store} messageId="shippingtypePielers.form" /></h3>
              {PielersForm(datasheet?.shippingtypePielers)}
            </Col>
            <Col>
              <h3><IntlMessage Store={props.Store} messageId="packaging.form" /></h3>
              {PielersForm(datasheet?.packaging)}</Col>
          </Row>
          <Row>
            <Col>
              <h3><IntlMessage Store={props.Store} messageId="storagePielers.form" /></h3>
              {PielersForm(datasheet?.storagePielers)}</Col>
            <Col>
              <h3><IntlMessage Store={props.Store} messageId="delivery.time" /></h3>
              {PielersForm(datasheet?.shippingTime)}</Col>
          </Row>

        </Tab>
        <Tab eventKey="origins1" title={<IntlMessage Store={props.Store} messageId="common.origin" />} key={'origins1'} id={'origins1'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
          <h3><IntlMessage Store={props.Store} messageId="common.origin" /></h3>
          {PielersForm(datasheet?.origins)}
        </Tab>
          <Tab eventKey="rigonalBrand" title={<IntlMessage Store={props.Store} messageId="rigonalBrand.origin" />} key={'rigonalBrand'} id={'rigonalBrand'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
            <h3><IntlMessage Store={props.Store} messageId="rigonalBrand.origin" /></h3>
            {PielersForm(datasheet?.rigonalBrand)}
          </Tab>
        <Tab eventKey="other" title={<IntlMessage Store={props.Store} messageId="other.form" />} key={'other'} id={'other'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
          <Row>
            <Col>
              <h3><IntlMessage Store={props.Store} messageId="portion.form" /></h3>
              {PielersForm(datasheet?.portion)}</Col>
            <Col>
              <h3><IntlMessage Store={props.Store} messageId="formsOfNutrition.form" /></h3>
              {PielersForm(datasheet?.formsOfNutrition)}</Col>
          </Row>
        </Tab>
        </Tabs>
      </>
    )
  } else {
    component.push(
      <>
        <Tabs defaultActiveKey="seals" id="uncontrolled-tab-example" className="mb-3" key={'tabindex'}>
        <Tab eventKey="seals" title={<IntlMessage Store={props.Store} messageId="seals" />} key={'seals'} id={'seals'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
          {PielersForm(datasheet?.seals)}
        </Tab>
          {ergonomie}
        <Tab eventKey="shippingType" title={<IntlMessage Store={props.Store} messageId="shipping.form" />} key={'shippingType'} id={'shippingType'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
          {PielersForm(datasheet?.shippingType)}
        </Tab>
        <Tab eventKey="shippingTime" title={<IntlMessage Store={props.Store} messageId="delivery.time" />} key={'shippingTime'} id={'shippingTime'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
          {PielersForm(datasheet?.shippingTime)}
        </Tab>
        <Tab eventKey="montage" title={<IntlMessage Store={props.Store} messageId="assamble.skill" />} key={'montage'} id={'montage'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
          {PielersForm(datasheet?.montage)}
        </Tab>
        <Tab eventKey="origins" title={<IntlMessage Store={props.Store} messageId="common.origin" />} key={'origins'} id={'origins'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
          {PielersForm(datasheet?.origins)}
        </Tab>
        </Tabs>
      </>
    )
  }

    const form = useRef(null)
    const submited = (e: { preventDefault: () => void; }) => {
      e.preventDefault()
      let Dataform = form.current
      if(Dataform !== undefined && Dataform !== null) {
        const data = new FormData(Dataform)
        saveDataIndividualPut('/Product/Datenblatt/' + props.fetchId + '/', data)
          .then((data: any) => {
            notify_saveProduct(props.Store);
            setTimeout(() => window.location.reload(), 3000);
          })
          .catch(e => {
            error(e);
            setTimeout(() => window.location.reload(), 3000);
          });
      }
      props.onHide();
     /* window.location.reload()*/
    }
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    ><form ref={form} onSubmit={submited} >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <IntlMessage Store={props.Store} messageId="datasheet.edit" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={'modalBody'}>

          {component}

        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}><IntlMessage Store={props.Store} messageId="common.close" /></Button>
        <Button type={'submit'} className={'float-end'} variant="success" ><IntlMessage Store={props.Store} messageId="common.save" /></Button>
      </Modal.Footer>
    </form>
    </Modal>
  );
};



