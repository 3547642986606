import React from 'react';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Spinner from 'react-bootstrap/Spinner'
import PielersForm from '../../../form-serilizer'
import configuration from '../configuration/home';
//import {AuthUser} from '../../../../../types/models/AuthUser';
import IntlMessage from '../../const/IntlMessage';
import ElementsHomeInterface from "../interface/ElementsHome.interface";

function home(props:ElementsHomeInterface){

    let Store = props.Store;
  if(props.response === 'new') {
    return (
      <>
        {/*configuration('titel',[],true,Store)*/}
        {/*{configuration('description', [], true,Store)}*/}
        <hr key={'hrone'}/>
       {/* <Row className="g-2" key={'spalte'}>
          <Col md key={'colone'}>*/}
            {PielersForm(configuration('dataColOne', [], true,Store))}
        {/*  </Col>
          <Col md key={'coltwo'}>*/}
            {PielersForm(configuration('dataColtwo', [], true,Store))}
        {/*  </Col>*/}
       {/* </Row>*/}
        <hr key={'hrtwo'}/>
      </>
    )
  } else
  if(props.response !== undefined && props.response.hasOwnProperty('translation')) {
    return (
      <>
        {/*configuration('titel', props.response, false,Store)*/}
        {/*{configuration('description', props.response, false,Store)}*/}
        <hr key={'hrone'}/>
       {/* <Row className="g-2" key={'spalte'}>
          <Col md key={'colone'}>*/}
            {PielersForm(configuration('dataColOne', props.response, false,Store))}
        {/*  </Col>
          <Col md key={'coltwo'}>*/}
            {PielersForm(configuration('dataColtwo', props.response, false,Store))}
        {/*  </Col>
        </Row>*/}
        <hr key={'hrtwo'}/>
      </>
    )
  } else {
    return (
      <><Spinner animation="border" role="status">
        <span className="visually-hidden"><IntlMessage Store={Store} messageId="common.loading" /></span>
      </Spinner></>
    )
  }
}

export default home;
