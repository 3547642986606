import React from 'react';
import PielersForm from '../../index'
import {base, basesettings} from './base';
import IntlMessage, {IntlMessageValue} from '../../../component/const/IntlMessage';
import CopyElements from "../../../component/const/CopyElements";
import WebBuilderElementsHead from "../../../component/const/WebBuilderElementsHead";
import Col from 'react-bootstrap/Col';
import Row from "react-bootstrap/Row";
import Box from '@mui/material/Box';
import {clearCol} from "../../../component/core/elements/content";

function cardWebBuilder(data: any, element:any,i:any, newElement:any = false,newelementIndex:any = 0,modalShow: boolean,
               setModalShow: (value: (((prevState: boolean) => boolean) | boolean)) => void, modalData: any[],
               setModalData: (value: (((prevState: any[]) => any[]) | any[])) => void,
               content:any = [],setElements:any = [],Store:any,galerie?:any,
    galerieItems?:any) {

  const index = (newElement === false ? i : newelementIndex)

  const configuration = [
    {
      'formType': 'multiply',
      'cols': [
        {
          'formType': 'image',
          'altLink': true,
          'id': 'image_title_image_'+index,
          'path': (newElement === false ? (element.translation !== undefined ? (element.translation.de.media !== null ? element.translation.de.media.cdn_url : '') : '') : ''),
          'width': '44',
          'height': '44',
          'class': 'elementsIMg',
          'size': {
            'xl': 2,
            'md': 2,
            'sm': 2,
          }
        },
        {
          'formType': 'formFile',
          'onclick': true,
          'id': 'image_titleFormField'+index,
          'onclick_context': (newElement === false ? element.context : 'pielers_element_title_image'),
          'Gallery': galerie,
          'galerieItems': galerieItems,
          'expandfile':index+'galerie',
          'experiment': true,
          'append': 'elements[' + index + '][translation][image_id]',
          'src': 'image_title_image_'+index,
          'size': {
            'xl': 10,
            'md': 10,
            'sm': 10,
          }
        },
      ],
    },
    {
      'formType': 'input',
      'name': 'elements[' + index + '][translation][image_id]',
      'id': 'elements[' + index + '][translation][image_id]',
      'visible': true,
      'label': 'imageID',
      'type': 'text',
      'placeholder': 'imageID',
      'value': (newElement === false ? (element.translation !== undefined ? element.translation.de.image_id : '') : ''),
    },
    { 'formType': 'input',
      'label': 'image_alt',
      'type': 'text',
      'placeholder': 'image_alt',
      'name': 'elements['+index+'][translation][image_alt]',
      'value': (newElement === false ? (element.translation !== undefined ? element.translation.de.image_alt : '' ) : '' ),
    },
    { 'formType': 'input',
      'label': 'headline',
      'type': 'text',
      'placeholder': 'headline',
      'name': 'elements['+index+'][translation][headline]',
      'value': (newElement === false ? (element.translation !== undefined ? element.translation.de.headline : '' ) : '' ),
    },
    { 'formType': 'input',
      'label': 'text',
      'type': 'text',
      'placeholder': 'text',
      'name': 'elements['+index+'][translation][text]',
      'value': (newElement === false ? (element.translation !== undefined ? element.translation.de.text : '' ) : '' ),
    },
    { 'formType': 'input',
      'label': 'link',
      'type': 'text',
      'placeholder': 'link',
      'name': 'elements['+index+'][translation][link]',
      'value': (newElement === false ? (element.translation !== undefined ? element.translation.de.link : '' ) : '' ),
    },
    {
      'formType': 'input',
      'name': 'elements[' + index + '][template]',
      'type': 'hidden',
      'value': 'Elements/card.html',
    }


    ];

  let newConfiguration = configuration.concat(base(element,i,newElement,newelementIndex));

  let Status;
  if(element.visible === 1) {
    Status = 'elementActive';
  } if(element.visible === 0){
    Status = 'elementDeactive';
  } else if(newElement === true) {
    Status = 'elementActive';
  }
  return (
    <>
    <Box id={'card'+index} data-appendcontainer={(newElement === false && element.appendto !== null && element.appendto !== undefined && element.appendto !== '' ?  element.appendto : 'false')} >
    {WebBuilderElementsHead('Card Module','card',(newElement === false ? (element.translation !== undefined ? (element.translation.de.media !== null ? element.translation.de.media.cdn_url : '') : '') : ''),Status,index,'elementButton',<IntlMessage Store={Store} messageId="content.element.image.type" />,
            modalShow,setModalShow,modalData, setModalData,newElement,element,content,setElements,Store,true,'',element.id)}
      <div id={'preview-container_card'+index}  className={'preview-container '+Status} style={{display: (newElement === false ? 'block' : 'none')}}>
        {(newElement === false ?
            <span dangerouslySetInnerHTML={{ __html: element.previews }} /> : <></>)}

      </div>
      <Row id={'mein_card'+index} className={'boxShadowCard card ElementBorder'} style={{width: '100%',margin:'0px 0px 10px 0px',border: '0',boxShadow: 'unset'}}>


        <Col  className={'accordion-body element-body'}>

          <div id={'edit_card'+index} className={'boxShadowCard card '+Status} style={{display:(newElement === false ? 'none' : 'block'),padding:'15px',width: '100%',marginBottom: '20px'}}>
            <div id={'Teaser'}>image</div>
            <Col style={{marginBottom: '10px',marginTop:'10px'}}>Card Module</Col>
        {PielersForm(newConfiguration)}

          </div>
          </Col>
        <div id={'settingstext'+index+'card'} className={'settingsWebBuilderModul '} style={{display:'none'}}>

          {PielersForm(basesettings(element,i,newElement,newelementIndex,Store))}
        </div>
        {(newElement === false ? CopyElements(element.id,<IntlMessage messageId="module.image"  Store={Store}/>,Store) : <></>)}

      </Row>
      </Box>
    </>
  )


}

export default cardWebBuilder;
