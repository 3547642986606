import Modal from 'react-bootstrap/Modal';
import WYSIWYGindi from '../../../../../../../@WUM/core/form-serilizer/elements/WYSIWYGindi';
import Button from 'react-bootstrap/Button';
import React from 'react';
import IntlMessage from '../../../../../../../@WUM/core/component/const/IntlMessage';

const _handleKeyDown = (values:any) => {
  const value = values;
  localStorage.setItem('Dimensions', value);
};

export const setDimensions = (setModalDimensionsShow: (value: (((prevState: boolean) => boolean) | boolean)) => void) => {
  setModalDimensionsShow(true)
}

export const setterDimensions = (DimensionsValue: string | undefined, setDimensionsValue: (arg0: any) => void, response: { translation: {nutrient: any; }; }[]) => {
  if(DimensionsValue === null || DimensionsValue === undefined) {


    if(response[0].translation.nutrient === null  && localStorage.getItem('Dimensions') !== null && JSON.parse(localStorage.getItem('Dimensions') as string)?.abmessungen !== null) {
      setDimensionsValue((JSON.parse(localStorage.getItem('Dimensions') as string)?.abmessungen));
    } else {
      setDimensionsValue((response[0].translation.nutrient));
    }

  }
}

export const modalDimensions = (modalDimensionsShow: boolean, setModalDimensionsShow: (value: (((prevState: boolean) => boolean) | boolean)) => void,saveDimensions:any,DimensionsValue:any,Store:any) =>{

  let DataDimensions: any = DimensionsValue;

  return(<>
      <DimensionsModal
        show={modalDimensionsShow}
        data={DataDimensions}
        onHide={() => setModalDimensionsShow(false)}
        saveDimensions = {saveDimensions}
        _handleKeyDown = {_handleKeyDown}
        Store={Store}
      />
    </>
  )
}


const DimensionsModal = (props:any,) => {
  let label;
  if(process.env.REACT_APP_AGRICA === 'True') {
    label = <IntlMessage Store={props.Store} messageId="common.nutritional.values" />;
  } else {
    label =  <IntlMessage Store={props.Store} messageId="materials.edit" />;
  }
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {label}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {WYSIWYGindi(undefined,0,{'name': 'hallo','lang': 'de','value': props.data,'height':'400px'},props._handleKeyDown)}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}><IntlMessage Store={props.StoreStore} messageId="common.close" /></Button>
        <Button type={'button'} className={'float-end'} variant="success"  onClick={() => props.saveDimensions()}><IntlMessage Store={props.StoreStore} messageId="common.save" /></Button>
      </Modal.Footer>
    </Modal>
  );
};



