import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React from "react";
import Form from "react-bootstrap/Form";
import InputGroup from 'react-bootstrap/InputGroup'
const Forms = (response:any = [],mode:any='new') => {


    return(
        <>
            <h3>Referal</h3>
            <h4>Referer</h4>
            <hr/>
            <Row>
                <Col>
                    <Form.Group className="mb-3" controlId="username">
                        <Form.Label>Username:</Form.Label>
                        <Form.Control type="text" readOnly={true} defaultValue={(mode === 'new' ? '' : response[0].user.username)}/>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3" controlId="street">
                        <Form.Label>Straße:</Form.Label>
                        <Form.Control type="text" readOnly={true}  defaultValue={(mode === 'new' ? '' : response[0].referer.address[0].street)}/>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3" controlId="postcode">
                        <Form.Label>Postleitzahl:</Form.Label>
                        <Form.Control type="text" readOnly={true}  defaultValue={(mode === 'new' ? '' : response[0].referer.address[0].postcode)}/>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3" controlId="operationDate">
                        <Form.Label>Stadt:</Form.Label>
                        <Form.Control type="text" readOnly={true}  defaultValue={(mode === 'new' ? '' : response[0].referer.address[0].city)}/>
                    </Form.Group>
                </Col>
            </Row>
            <h4>user</h4>
            <hr/>
            <Row>
                <Col>
                    <Form.Group className="mb-3" controlId="username">
                        <Form.Label>Username:</Form.Label>
                        <Form.Control type="text"  readOnly={true}  defaultValue={(mode === 'new' ? '' : response[0].user.username)}/>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3" controlId="street">
                        <Form.Label>Straße:</Form.Label>
                        <Form.Control type="text" readOnly={true}  defaultValue={(mode === 'new' ? '' : response[0].user.address[0].street)}/>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3" controlId="postcode">
                        <Form.Label>Postleitzahl:</Form.Label>
                        <Form.Control type="text" readOnly={true}  defaultValue={(mode === 'new' ? '' : response[0].user.address[0].postcode)}/>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3" controlId="operationDate">
                        <Form.Label>Stadt:</Form.Label>
                        <Form.Control type="text" readOnly={true} defaultValue={(mode === 'new' ? '' : response[0].user.address[0].city)}/>
                    </Form.Group>
                </Col>
            </Row>
            <h4>Provision</h4>
            <hr/>
            <Row>
                <Col>
                    <Form.Group className="mb-3" controlId="provision">
                        <Form.Label>Provision:</Form.Label>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1">%</InputGroup.Text>
                        <Form.Control type="number" name={'provision'} required={true} defaultValue={(mode === 'new' ? '' : response[0].provision)}/>
                        </InputGroup>
                    </Form.Group>
                </Col>
            </Row>
        </>
    )
}

export default Forms;


