import {MemberMenuConfig, MemberPagesConfig} from "../modules/settings/Member";
import {PromoMenuConfig, PromoPagesConfig} from "../modules/settings/promo";
import {CMSMenuConfig} from "../modules/CMS";
import {MatomoMenuConfig} from "../modules/matomo/modules";
import {TicketMenuConfig} from "../modules/ticketsystem/modules";
import {ShopMenuConfig} from "../modules/shop";
import {monitoringMenuConfig} from "../modules/monitoring/modules";
import {SettingsMenuConfig} from "../modules/settings";
import {AuthPagesConfig} from "../@WUM/templates/default/Auth/modules";
import {CMSRouteConfig} from "../modules/CMS";
import {MatomoPagesConfig} from "../modules/matomo/modules";
import {TicketPagesConfig} from "../modules/ticketsystem/modules";
import {monitoringPagesConfig} from "../modules/monitoring/modules";
import {ShopRouteConfig} from "../modules/shop";
import {SettingsRouteConfig} from "../modules/settings";
import {
    GermanBusinessDasboard,
    GermanBusinessMenuConfig,
    GermanBusinessPagesConfig
} from "../modules/backoffice/germanbusiness";
import {
    ForeignBusinessDasboard,
    ForeignBusinessMenuConfig,
    ForeignBusinessPagesConfig
} from "../modules/backoffice/foreignbusiness";
import {SRODasboard, SROMenuConfig, SROPagesConfig} from "../modules/backoffice/sro";
import {EwivDasboard, EwivMenuConfig, EwivPagesConfig} from "../modules/backoffice/ewiv";
import {CustomerDasboard, CustomerMenuConfig, CustomerPagesConfig} from "../modules/backoffice/customer";
import {DashbaordMenuConfig, DashbaordPagesConfig} from "../modules/CMS/dashboard/modules";
import {ReferalDasboard, ReferalMenuConfig, ReferalPagesConfig} from "../modules/backoffice/referal";
const components = {
    'MemberMenuConfig': MemberMenuConfig,
    'PromoMenuConfig': PromoMenuConfig,
    'MemberPagesConfig': MemberPagesConfig,
    'PromoPagesConfig': PromoPagesConfig,
    //'CMSMenuConfig': CMSMenuConfig,
    'MatomoMenuConfig':MatomoMenuConfig,
    'TicketMenuConfig':TicketMenuConfig,
    'ShopMenuConfig':ShopMenuConfig,
    'monitoringMenuConfig':monitoringMenuConfig,
    'SettingsMenuConfig':SettingsMenuConfig,
    'AuthPagesConfig':AuthPagesConfig,
    //'CMSRouteConfig':CMSRouteConfig,
    'MatomoPagesConfig':MatomoPagesConfig,
    'TicketPagesConfig':TicketPagesConfig,
    'monitoringPagesConfig':monitoringPagesConfig,
    'ShopRouteConfig':ShopRouteConfig,
    'SettingsRouteConfig':SettingsRouteConfig,
    'GermanBusinessPagesConfig':GermanBusinessPagesConfig,
    'GermanBusinessMenuConfig':GermanBusinessMenuConfig,
    'ForeignBusinessPagesConfig':ForeignBusinessPagesConfig,
    'ForeignBusinessMenuConfig':ForeignBusinessMenuConfig,
    'SROPagesConfig':SROPagesConfig,
    'SROMenuConfig':SROMenuConfig,
    'EwivPagesConfig':EwivPagesConfig,
    'EwivMenuConfig':EwivMenuConfig,
    'CustomerPagesConfig':CustomerPagesConfig,
    'CustomerMenuConfig':CustomerMenuConfig,
    'CustomerDasboard':CustomerDasboard,
    'GermanBusinessDasboard':GermanBusinessDasboard,
    'ForeignBusinessDasboard':ForeignBusinessDasboard,
    'SRODasboard':SRODasboard,
    'EwivDasboard':EwivDasboard,
    'ReferalPagesConfig':ReferalPagesConfig,
    'ReferalMenuConfig':ReferalMenuConfig,
    'ReferalDasboard':ReferalDasboard
    //'DashbaordPagesConfig':DashbaordPagesConfig,
    //'DashbaordMenuConfig':DashbaordMenuConfig

};

export default components;
