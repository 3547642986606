import {RegisterSitemap} from "../../../../Register/RegisterSitemap";
import {HelpDeskMenuConfig} from "../../../helpdesk/modules/register";

const SidebarItems = (value:any) => {

    if(process.env.REACT_APP_HELPDESK_EDIT !== undefined) {
        return [
            ...RegisterSitemap(value),
            ...HelpDeskMenuConfig(value)
        ];
    } else {
        return [
            ...RegisterSitemap(value)
        ];
    }

}

export default SidebarItems;
