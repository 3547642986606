import React from 'react';
import IntlMessage from '../../../../../../../@WUM/core/component/const/IntlMessage';
import {SpinnerJSX} from '../../../../../../../@WUM/core/component/const/Spinner';
import {dateFormat} from '../../../../../../../@WUM/core/component/const/dateFormat';
import {formatDate} from '../../../../../../../@WUM/core/component/const/formatDate';
import {currencyFormat} from '../../../../../../../@WUM/core/component/const/currencyFormat';
import Button from 'react-bootstrap/Button';
import Download from '../../../../../../../@WUM/core/component/const/download';

/**
 * Interface für Invoice Detail View
 * @prop {any} response2
 * @prop {any} history
 * @prop {any} loadItems
 * @prop {any} setLoadItems
 */
interface homeInvoiceProps {
  response2: any,
  history:any,
  loadItems:any,
  setLoadItems:any,
    Store:any

}

/**
 *
 * @param {homeInvoiceProps} props Übergabe Parameter zum Bilden der View
 * @constructor
 */
const HomeInvoice: React.FC<homeInvoiceProps> = (props) => {

  let currency = 'EUR';
  let total = 0;
  let mwst = 0;
  let row = [];
  let headline;
  if (props.response2.items !== undefined && props.response2.items.length > 0) {
    for (let x = 0; x < props.response2.items.length; x++) {
      total = total + props.response2.items[x].total;
      mwst = mwst + (props.response2.items[x].tax);
      row.push(<>
        <tr>
          <td style={{paddingTop: '10px', paddingBottom: '10px'}}>{x + 1}</td>
          <td style={{paddingTop: '10px', paddingBottom: '10px'}}>
            <h6>{props.response2.items[x].name}</h6>{props.response2.items[x].description}</td>
          <td style={{paddingTop: '10px', paddingBottom: '10px'}}>{props.response2.items[x].tax_rate * 100} %</td>
          <td style={{
            paddingTop: '10px',
            paddingBottom: '10px',
            textAlign: 'right',
          }}>{currencyFormat((props.response2.items[x].total / 100), currency)}</td>
        </tr>
      </>);
    }

    let pdf = (<></>);
    if (props.response2.pdf !== undefined && props.response2.pdf !== null) {
      if (props.response2.pdf.cdn_url !== undefined && props.response2.pdf.cdn_url !== null) {
        pdf = (<>
          <Download pdfId={props.response2.pdf_id} name={props.response2.pdf.name} />
        </>);
      }
    }

    let invoiceHead : any = [{
        'reversal_order_invoice': {
          text: 'Stornorechnung'
        },
        'merchant_order_invoice': {
          text: 'Rechnung'
        },
        'provision_operator_invoice': {
          text: 'Rechnung'
        },
        'operator_shipping_invoice': {
          text: 'Rechnung'
        },
        'provision_invoice': {
          text: 'Rechnung'
        },
        'discount_order_invoice': {
          text: 'Abrechnung'
        },
    },
      ];
  if(invoiceHead[0][props.response2.discr] === undefined) {
    headline = <IntlMessage Store={props.Store} messageId='common.invoice' />;
  } else {
    headline = invoiceHead[0][props.response2.discr].text;
  }


    if(headline === undefined) {
      headline = <IntlMessage Store={props.Store} messageId='common.invoice' />;
    }

    let netto = (total - mwst) / 100;

    return (
      <div key={'head'} style={{backgroundColor: '#FFFFFF', padding: '20px'}}>
        <Button variant='light' style={{float: 'left', marginLeft: '15px'}}
                onClick={() => props.history.goBack()}><IntlMessage Store={props.Store} messageId='common.back' /></Button>
        <div style={{clear: 'both'}}></div>
        <hr />
        <br />
        <br />
        <p>{props.response2.sender_company} | {props.response2.sender_street_address} | {props.response2.sender_zip}</p>
        <br />
        <h6>{props.response2.firstname} {props.response2.lastname}</h6>
        <h6>{props.response2.street_address}</h6>
        <h6>{props.response2.zip}</h6>
        <br />
        <br />
        <h3>{headline}</h3>
        <br />
        <div>
          <p className={'float-start'}>{formatDate(props.response2.date,true)}</p>
          <p className={'float-end'}><IntlMessage Store={props.Store} messageId='invoice.no' />: {props.response2.number}</p>
        </div>
        <div style={{clear: 'both'}}></div>
        <table style={{width: '100%'}}>
          <thead>
          <tr style={{borderBottom: '1px solid #ccc7d1'}}>
            <th style={{width: '5%', paddingTop: '10px', paddingBottom: '10px'}}>#</th>
            <th style={{width: '85%', paddingTop: '10px', paddingBottom: '10px'}}><IntlMessage Store={props.Store} messageId='common.name' /></th>
            <th style={{width: '5%', paddingTop: '10px', paddingBottom: '10px'}}><IntlMessage Store={props.Store} messageId='tax.vat.short' /></th>
            <th style={{width: '5%', textAlign: 'right', paddingTop: '10px', paddingBottom: '10px'}}><IntlMessage Store={props.Store} messageId='common.price' /></th>
          </tr>
          </thead>
          <tbody>
          {row}
          <tr style={{borderTop: '1px solid #ccc7d1'}}>

            <td colSpan={3}
                style={{textAlign: 'right', paddingRight: '10px', paddingTop: '10px', paddingBottom: '10px'}}><h6
              style={{margin: '0px'}}><IntlMessage Store={props.Store} messageId='invoice.netto' /></h6></td>
            <td style={{
              textAlign: 'right',
              paddingTop: '10px',
              paddingBottom: '10px',
            }}>{currencyFormat(netto, currency)}</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td style={{
              borderTop: '1px solid #ccc7d1',
              textAlign: 'right',
              paddingRight: '10px',
              paddingTop: '10px',
              paddingBottom: '10px',
            }}><h6 style={{margin: '0px'}}><IntlMessage Store={props.Store} messageId='tax.vat.short' /></h6></td>
            <td style={{
              borderTop: '1px solid #ccc7d1',
              textAlign: 'right',
              paddingTop: '10px',
              paddingBottom: '10px',
            }}>{currencyFormat((mwst / 100), currency)}</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td style={{
              borderTop: '5px double #ccc7d1',
              borderBottom: '1px solid #ccc7d1',
              textAlign: 'right',
              paddingRight: '10px',
            }}>
              <h6 style={{margin: '0px', paddingTop: '10px', paddingBottom: '10px'}}><IntlMessage Store={props.Store} messageId='common.total' /></h6></td>
            <td style={{
              borderTop: '5px double #ccc7d1',
              borderBottom: '1px solid #ccc7d1',
              textAlign: 'right',
              paddingTop: '10px',
              paddingBottom: '10px',
            }}>
              {currencyFormat((total / 100), currency)}</td>
          </tr>
          </tbody>
        </table>
        <br />
        <br />
        <hr />
        <div className={'float-end'}>
          <h4><IntlMessage Store={props.Store} messageId='download.documents' /></h4>
          <div style={{textAlign: 'right'}}>
            {pdf}
          </div>
          <div style={{clear: 'both'}}></div>
        </div>
          <div style={{clear: 'both'}}></div>
      </div>

    );
  } else {
    if (props.response2.items !== undefined && props.response2.items.length > 0) {
      props.setLoadItems(true);
    }
    return (
      <><SpinnerJSX messageId={'common.loading'} Store={props.Store} /></>
    );
  }
}

export default HomeInvoice;
