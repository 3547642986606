import React, {useState} from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Button from 'react-bootstrap/Button';
import {
  useNavigate,
} from 'react-router-dom';
import home from '../../../../../@WUM/core/component/core/elements/home';
import Content from '../../../../../@WUM/core/component/core/elements/content';
import seo from '../../../../../@WUM/core/component/core/elements/seo';
import share from '../../../../../@WUM/core/component/core/elements/share';
import {blogApiDetail} from '../../API/request/blog/blogApiDetail.interface';
import {init2} from "../../../../../@WUM/core/form-serilizer/seriliaze/Formserialize";
import {configBlog, outputBlog}from '../configuration/mapping';
import {blog} from '../../API/request/blog/blog';
import {notify_save} from '../../../../../@WUM/core/component/const/notification.service';
import {error} from '../../../../../@WUM/core/component/const/error.services';
import {Form} from 'react-bootstrap';
import {handleSubmitForm} from "../../../../../@WUM/core/component/const/handleSubmitForm";
import {useLocalStorage} from "../../../../../@WUM/core/component/const/useLocalStoreage";
import IntlMessage from "../../../../../@WUM/core/component/const/IntlMessage";
import {useSelector} from "react-redux";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
//import {useAuthUser} from '../../../../../@crema/utility/AppHooks';

/**
 * Give View NewBlog for Content Blog
 * @memberOf blog
 * @author Marcel Ugurcu
 * @version 0.0.1
 */
const BlogNew = (props:{value:any}) => {

  //const user = 1;
  const history = useNavigate();

  const Store = useSelector((state: any) => state)

  const [currentTutorial, setCurrentTutorial] = useState<blogApiDetail[]>([]);
  const [element,setElement] = useState([]);
  const [modalShow, setModalShow] = React.useState(false);
  const [modalData, setModalData] = React.useState([]);

  const [validated, setValidated] = useState(false);
  const [seo_title, setseo_title] = useLocalStorage("seo_title", "");
  const [seo_description, setseo_description] = useLocalStorage("seo_description", "");
  const [Index, setIndex] = React.useState(0);
  /**
   * Sendet Formular Daten an die API
   * @param status
   * @param id
   */
  const updateData = (status: boolean, id: any,form:any) => {

    /*
     *  Wichtig zum Rausholen der Datan aus Formular und senden
     *  Das Interface muss immer angepasst werden
     */
    let Data = init2(form);

    let reauestData: blogApiDetail[] &
        configBlog
      = Object.assign({}, currentTutorial,
        outputBlog(Data));


    /*
     *  Senden der Erhaltenen Daten an das Frontend
     */
    blog.updatePost(reauestData, id)
      .then(response => {

        setCurrentTutorial({...currentTutorial});
        notify_save(Store);
        setTimeout(() => window.location.href = '/Blog/1', 3000);
      })
      .catch(e => {
        error(e);
      });
  };

  return (
    <div>
      <Form  id={'form001'} className={'formControl'} noValidate validated={validated} onSubmit={(e) => handleSubmitForm(e, updateData, setValidated, 0)}>
        <Row>


          <Col  style={{display:'block',minWidth:'260px',maxWidth:'260px'}}>

            <div className={'settingsContent'}>
              <div id={'home'} className={'pl-3 pr-3'}>
                {home({response:'new',Store:Store})}
              </div>
              <div id={'seo'} className={'pl-3 pr-3'}>
                {seo({response:'new',seo_title:seo_title,setseo_title:setseo_title,seo_description:seo_description,setseo_description:setseo_description,Store:Store})}
              </div>
              <div id={'share'} className={'pl-3 pr-3'}>
                {share({response:'new',Store:Store})}
              </div>
            </div></Col>
          <Col >
            {Content({response:'new', elements:element, setElements:setElement, modalShow:modalShow,
              setModalShow:setModalShow, modalData:modalData, setModalData:setModalData,setdeleteElement:false,Index:Index,setIndex:setIndex,Store:Store})}</Col>
        </Row>
        <Row className={'ContentButton'}>
          <Row className={'m-0'} style={{height: '100%'}}>
            <Col className={'mt-2 text-center'}>
              <Button variant='light' style={{float: 'left', marginLeft: '15px'}} onClick={() => history(-1)}><IntlMessage Store={Store} messageId="common.back"/></Button>
            </Col>
            <Col className={'mt-2  text-center'} >
              <Button variant='success'
                      style={{float: 'right', marginRight: '15px'}}
                      type={'submit'}
              >
                {<IntlMessage Store={Store} messageId="common.save" />}
              </Button>
            </Col>
          </Row>
        </Row>

        <div style={{backgroundColor: 'white', padding: '15px'}}>

          <br style={{clear: 'both'}} />
        </div>
      </Form>
    </div>
  );
}


export default BlogNew;
