
export const H5 = {
    eventkey: 'H5',
    key: 'H5',
    title: 'H5',
    content: [
        {
            label: "headline.h5.font-size-h5",
            discr: 'font-size-h5',
            size: [
                3,
                3,
                3
            ],
            type: 'text'
        },
        {
            label: "headline.h5.font-weight-h5",
            discr: 'font-weight-h5',
            size: [
                3,
                3,
                3
            ],
            type: 'text'
        },
        {
            label: "headline.h5.font-color-h5",
            discr: 'font-color-h5',
            size: [
                3,
                3,
                3
            ],
            type: 'text'
        }
    ]
}
