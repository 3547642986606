
import React from "react";
import '../css/matomo.css'
import VisitorTabBuild from "../core/VisitorTabBuild";
import VisitorConfig from "../configuration/visitor/VisitorConfig";
import SwitchSearch from "../core/SwitchSearch";
import {useSelector} from "react-redux";


const Visitor = (props:{value:any}) => {

    const [Period, setPeriod] = React.useState([localStorage.getItem('period')]);
    const [Date, setDate] = React.useState([localStorage.getItem('sdate')+','+localStorage.getItem('edate')]);
    const Store = useSelector((state: any) => state)
    //console.log(Period);
    //console.log(Date);
    return (
        <>
            <SwitchSearch setDate={setDate} setPeriod={setPeriod} Store={Store} />
            <VisitorTabBuild config={VisitorConfig(Store)} />
        </>
    )
}


export default Visitor;
