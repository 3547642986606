import {configAxios, httpClient, httpClientToken, httpClientWithAuth} from './api';
import {errorFetch} from '../component/const/error.services';
import {notify_save} from '../component/const/notification.service';

let api_URL;
if(process.env.REACT_APP_MODE === 'LOCAL'){
    api_URL = process.env.REACT_APP_API_URL_LOCAL;
} else if (process.env.REACT_APP_MODE === 'DEVELOPMENT'){
    api_URL = process.env.REACT_APP_API_URL_DEVELOPMENT;
} else if(process.env.REACT_APP_MODE === 'LIVE') {
    api_URL = process.env.REACT_APP_API_URL_LIVE;
}  else if (process.env.REACT_APP_MODE === 'KLIMABAUHELD') {
    api_URL = process.env.REACT_APP_API_URL_KLIMABAUHELD;
}
export const apiRoot = api_URL
export const apiLogin = apiRoot+"/token/";
export const apiLog = apiRoot+"/log/";
export const apiTokenVerify = apiRoot+"/token/user/";
export const fetchDataApi = (url:string,Seterror:boolean = true) => {
    /* console.log('fetchDataApi', url);*/
    return new Promise((resolve, reject) => {
        (process.env.REACT_APP_AUTH !== undefined ?httpClientWithAuth : httpClient)
            .get(url,configAxios)
            .then(({data}) => {
                return resolve(data);
            })
            .catch(function (error) {
                if(Seterror === true) {
                    errorFetch(error);
                    return reject(error);
                } else {
                    return;
                }

            });
        return Promise.resolve();
    });
};

export const fetchDataApiUser = (url:string,token:any,raw:any) => {
    /* console.log('fetchDataApi', url);*/
    return new Promise((resolve, reject) => {
        (process.env.REACT_APP_AUTH !== undefined ?httpClientWithAuth : httpClient)
            .post(url,raw,{ headers: {"Authorization" : `Bearer ${token}`} })
            .then(({data}) => {
                return resolve(data);
            })
            .catch(function (error) {
                errorFetch(error);
                return reject(error);
            });
        return Promise.resolve();
    });
};


export const fetchDatasApi = (url:string) => {
    (process.env.REACT_APP_AUTH !== undefined ?httpClientWithAuth : httpClient)
        .get(url)
        .then(({data}) => {return data})
};

export const saveDataApi = (url:string, payload:any) => {
    /* console.log('url, payload', url, payload);*/
    return new Promise((resolve, reject) => {
        (process.env.REACT_APP_AUTH !== undefined ?httpClientWithAuth : httpClient)
            .post(url, payload,configAxios)
            .then(({data}) => {
                return resolve(data);
            })
            .catch(function (error) {
                errorFetch(error);
                return reject(error);
            });
        return Promise.resolve();
    });
};

export const saveDataApiToken = (url:string, payload:any) => {
    /* console.log('url, payload', url, payload);*/
    return new Promise((resolve, reject) => {
        (process.env.REACT_APP_AUTH !== undefined ?httpClientWithAuth : httpClientToken)
            .post(url, payload)
            .then(({data}) => {
                return resolve(data);
            })
            .catch(function (error) {
                errorFetch(error);
                return reject(error);
            });
        return Promise.resolve();
    });
};



export const saveDataOrdersStatus = (id:any) => {
    /* console.log('url, payload', url, payload);*/
    return new Promise((resolve, reject) => {
        (process.env.REACT_APP_AUTH !== undefined ?httpClientWithAuth : httpClient)
            .put(`/Orders/Order/cancel/${id}/`,)
            .then(({data}) => {
                return resolve(data);
            })
            .catch(function (error) {
                errorFetch(error);
                return reject(error);
            });
        return Promise.resolve();
    });
};

export const saveDataIndividualPut = (link: string, data: any) => {
    /* console.log('url, payload', url, payload);*/
    return new Promise((resolve, reject) => {
        (process.env.REACT_APP_AUTH !== undefined ?httpClientWithAuth : httpClient)
            .put(link,data,configAxios)
            .then(({data}) => {
                notify_save();
                return resolve(data);
            })
            .catch(function (error) {
                errorFetch(error);
                return reject(error);
            });
        return Promise.resolve();
    });
};

export const saveDataIndividualGet = (link: string) => {
    /* console.log('url, payload', url, payload);*/
    return new Promise((resolve, reject) => {
        (process.env.REACT_APP_AUTH !== undefined ?httpClientWithAuth : httpClient)
            .get(link,configAxios)
            .then(({data}) => {
                return resolve(data);
            })
            .catch(function (error) {
                errorFetch(error);
                return reject(error);
            });
        return Promise.resolve();
    });
};

export const updateDataApi = (url:string, payload:any) => {
    return new Promise((resolve, reject) => {
        (process.env.REACT_APP_AUTH !== undefined ?httpClientWithAuth : httpClient)
            .put(url, payload,configAxios)
            .then(({data}) => {
                return resolve(data);
            })
            .catch(function (error) {
                errorFetch(error);
                return reject(error);
            });
        return Promise.resolve();
    });
};
export const deleteDataApi = (url:string) => {
    return new Promise((resolve, reject) => {
        (process.env.REACT_APP_AUTH !== undefined ?httpClientWithAuth : httpClient)
            .delete(url,configAxios)
            .then(({data}) => {
                return resolve(data);
            })
            .catch(function (error) {
                errorFetch(error);
                return reject(error);
            });
        return Promise.resolve();
    });
};
