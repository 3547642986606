import Button from "react-bootstrap/Button";
import IntlMessage from "../../../../@WUM/core/component/const/IntlMessage";
import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {Form} from 'react-bootstrap';
import {SpinnerJSX} from '../../../../@WUM/core/component/const/Spinner';
import {ApiURL} from "../../../../@WUM/core/component/const/ApiURL";
// @ts-ignore
import {Notification} from "create-notifications/notfication";

const DynamikDetail = (props:{value:any,forms?:any,response?:any,saveRoute?:any,addedForm?:any,paramsAddedForm?:any,addedForm2?:any,paramsAddedForm2?:any}) => {
    const { id } = useParams<{ id: string }>();
    const history = useNavigate();
    const Store = useSelector((state: any) => state)
    const response2 = props.response(id);
    const addedF = (props.addedForm !== undefined && props.addedForm !== null && props.paramsAddedForm !== undefined && props.paramsAddedForm!== null ? props.addedForm(id) : (props.addedForm !== undefined && props.addedForm !== null ? props.addedForm() : ''  ));
    const addedF2 = (props.addedForm2 !== undefined && props.addedForm2 !== null && props.paramsAddedForm2 !== undefined && props.paramsAddedForm2 !== null ? props.addedForm2(id) : (props.addedForm2 !== undefined && props.addedForm2 !== null ? props.addedForm2() : ''));
    if (response2.length > 0) {

        const send = (e:any) => {
            e.preventDefault();
            let data = new FormData(e.target);
            let value = Object.fromEntries(data.entries());
            let raw = JSON.stringify(value);
            let root = ApiURL();
            let requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token') as string)
                },
                body: raw,
            };
            fetch(root+""+props.saveRoute+""+id+"/", requestOptions)
                .then(response => response.text())
                .then(result => afterSend('success'))
                .catch(error => afterSend('error'));
        }

        const afterSend = (state:any) => {
            if(state === 'success') {
                Notification({reload:true,type:'success', mode:'classic', title:{text:'Success'}, text:{text: 'Saved successfully'}, insert:'center right',duration:4})
            } else if(state === 'error'){
                Notification({reload:true,type:'danger', mode:'classic', title:{text:'Error'}, text:{text: 'Sorry, an error has occurred'}, insert:'center right',duration:4})
            }
        }

    return (
        <>
        <div style={{backgroundColor: 'white', padding: '15px'}}>
            <Form id={'form001'} onSubmit={(e)=> send(e)}>
                {(props.addedForm !== undefined && props.addedForm !== null && props.addedForm2 !== undefined && props.addedForm2 !== null ?
                    props.forms(response2,'Detail',addedF,addedF2) :(props.addedForm !== undefined && props.addedForm !== null ? props.forms(response2,'Detail',addedF) : props.forms(response2,'Detail')))}
            <div style={{backgroundColor: 'white', padding: '15px'}}>
                <Button variant='light' style={{float: 'left', marginLeft: '15px'}} onClick={() => history(-1)}><IntlMessage Store={Store} messageId="common.back"/></Button>
                <Button variant='success'
                        style={{float: 'right', marginRight: '15px'}}
                        type={'submit'}
                        id={'sendButton'}
                >
                    <IntlMessage Store={Store} messageId="common.save" />
                </Button>
                <br style={{clear: 'both'}} />
            </div>
        </Form>
        </div>
        </>
    )
} else {
    localStorage.setItem('searchEnterInput','');
    return (
        <><SpinnerJSX messageId={'common.loading'} Store={Store} /></>
    );
}
}

export default DynamikDetail;

