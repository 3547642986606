import Button from 'react-bootstrap/Button';
import React from 'react';
import LanguageSwitcher from './LanguageSwitcher';
import '../../css/header.css';


const Header = () =>  {

  return (
    <>
      <div className={'MainBackground colorpadtop'}>
        <Button variant='success'
                className="borifloat"
                type={'button'}
                href={'/ticket/new/Issue'}
        >
          <LanguageSwitcher messageId={'new.issue'} />
        </Button>
        <div style={{clear:'both'}}></div>
      </div>
    </>
  )
}

export default Header