import React from 'react';
import TableCell from '@material-ui/core/TableCell';
function fetchLocal(text: number, id: any, index: number, VisitData: any, VisitDataDel: any, Store: any, Rows: any, i: any, tableBodyPositionElement: any){




  let value = JSON.parse(localStorage.getItem(Rows.split('|')[1]) as string);



  let valReturn;
  let controll = false;

  for(let x=0;x<value.length;x++) {
    if(text === 0) {
      text = parseInt(Rows.split('|')[3]);
    }

    if(value[x].id === text) {
      valReturn = value[x][Rows.split('|')[2]]
      controll = true;
    }
  }

  if(controll === false) {
    valReturn = '-- keine Zuordnung -- sonstige'
  }


  return (
    <><TableCell style={{textAlign:tableBodyPositionElement}}  key={"td"+id+ "_" +index} data-param={VisitData} data-param-del={VisitDataDel}  data-id={id} id={"td"+id+ "_" +index}>{valReturn}</TableCell>
    </>
  );

}

export default fetchLocal;
