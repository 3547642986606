
export const Header = {
    eventkey: 'header',
    key: 'header',
    title: 'header',
    content: [
        {
            label: "header.header-hero-font-weight",
            discr: 'header-hero-font-weight',
            size: [
                3,
                3,
                3
            ],
            type: 'text'
        },
        {
            label: "header.hero-font-size",
            discr: 'hero-font-size',
            size: [
                3,
                3,
                3
            ],
            type: 'text'
        },
        {
            label: "header.hero-font-size-h1",
            discr: 'hero-font-size-h1',
            size: [
                3,
                3,
                3
            ],
            type: 'text'
        },
        {
            label: "header.hero-font-weight-h1",
            discr: 'hero-font-weight-h1',
            size: [
                3,
                3,
                3
            ],
            type: 'text'
        },
        {
            label: "header.hero-font-family",
            discr: 'hero-font-family',
            size: [
                3,
                3,
                3
            ],
            type: 'text'
        }]
}
