/**
 * Prüft ob ShippingPrice korreckt angegeben wurde und wen nicht wird es korigiert
 * @author Marcel Ugurcu
 * @version 0.0.1
 */
export const _handleValidateShipping = () => {
    if(document.getElementById('shippingprice') !== null  && document.getElementById('shippingprice') !== undefined){
        let shippingprice = (document.getElementById('shippingpriceInput') as HTMLInputElement).value
        let result = shippingprice.match(",");
        if(result === null){
            let modifierResult;
            if(shippingprice === '') {
                modifierResult = '0,00';
            } else {
                modifierResult = shippingprice+',00';
            }
            (document.getElementById('shippingpriceInput') as HTMLInputElement).value = modifierResult;
            return false;
        }
        return true;
    } else {
        return true;
    }
}