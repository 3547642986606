import {DynamikModulsActionType} from "../ActionType";
import {Dispatch} from "redux";


/**
 * @typedef Locale Init
 * @returns noting
 * @author IOTA IT
 * @version 0.0.1
 */
export const DynamikModulsRequestInit = () => {
    return {
        type: DynamikModulsActionType.DynamikModuls_INIT,
    }
}

export const DynamikModulsSuccess = (PagesModuls: any,MenuModuls:any,Dashboard:any) => {

    return {
        type: DynamikModulsActionType.DynamikModuls_INIT,
        PagesModuls: PagesModuls,
        MenuModuls:MenuModuls,
        Dashboard:Dashboard,
    }
}

export const getDynamikModuls = (PagesModuls: any[], MenuModuls: any, Dashboard: any) => {

    return (dispatch: Dispatch) => {
        dispatch(DynamikModulsSuccess(
            PagesModuls,
            MenuModuls,
            Dashboard
        ))
    }
}


