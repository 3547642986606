import createFields from "../configuration/fields";
import {FieldsMap} from "../configuration/FieldsMap";
import {apiRoot} from "../../../../@WUM/core/RestFullApi/ApiHook";
import {notify_save} from "../../../../@WUM/core/component/const/notification.service";
import {error} from "../../../../@WUM/core/component/const/error.services";

/**
 * Sendet Formular Daten an die API
 * @param status
 * @param id
 */
export const save = (template:any,Store:any,routeChange:any) => {



    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer "+localStorage.getItem('token'));
    myHeaders.append("Content-Type", "application/json");


    var raw = createFields(FieldsMap,template);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
    };


    fetch(apiRoot + "template/css/Single/0/", requestOptions)
        .then(response => response.text())
        .then(result => {
            notify_save(Store);
            routeChange();
        })
        .catch(e => {
            error(e)
        });
};
