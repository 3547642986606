import {useEffect, useState} from 'react';
import {error} from '../../../../../../@WUM/core/component/const/error.services';
import {checkLogin} from "../../../../../../@WUM/templates/default/Auth/service/AuthService";
import {config} from "../../request/config/config";
import {ConfigApiType} from "../../request/config/ConfigApi.interface.";


export const ResponseConfigAll = (limit: any, searchValue: any) => {
  const [posts, setPosts] = useState<ConfigApiType[]>([]);
  const [isError, setIsError] = useState<boolean>(false);
  useEffect(() => {
    (async () => {
      await config.getPosts(limit,searchValue)
    .then((data) => {
      checkLogin(data);
      setPosts(data);
    })
    .catch((err) => {
      error(err);
      setIsError(true);
    });
    })()}, [limit,searchValue])


  return isError ? (isError ) : (posts);

}


