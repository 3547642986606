import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import Button from "react-bootstrap/Button";
import {ApiRoot} from "../../RestFullApi/api";
import IntlMessage from "../../component/const/IntlMessage";
function downloadPDF(text: string, data: any, index: number, VisitData: any, VisitDataDel: any, Store: any, Rows: any, i: any, tableBodyPositionElement: any){


    if(data[Rows.split('|')[2]] !== null && data[Rows.split('|')[2]] !== undefined && data[Rows.split('|')[2]].includes(' ')) {
        let start = (data[Rows.split('|')[2]] !== null ? data[Rows.split('|')[2]].split(' ')[0] : '')
        let end = (data[Rows.split('|')[3]] !== null ? data[Rows.split('|')[3]].split(' ')[1] : '')



        return (
            <><TableCell style={{textAlign:tableBodyPositionElement}}  key={"td" + i + "_" + index}>
                <Button
                    href={ApiRoot() + 'v2/invoice/pdf/' + data[Rows.split('|')[1]] + '/' + start + '/' + end + '/' + data[Rows.split('|')[4]] + '/' + data[Rows.split('|')[5]] + '/'}
                    variant="success" target={'new'}><IntlMessage Store={Store} messageId={'PDF'}/></Button>
            </TableCell>
            </>
        );

    } else {
        return (
            <><TableCell key={"td" + i + "_" + index}>
            </TableCell>
            </>
        );
    }

}

export default downloadPDF;
