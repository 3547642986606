const SearchConfig = (destination:any) => {

    return [{
        namespace: destination+"_searchValue",
        destination: destination,
        input:[
            {id:'search_name',translation: 'Nach Unternehmensnamen suchen',param: 'company',method: 'like'}
        ],
        button: ['common.search','common.del.search','Neues Ausl. Unternehmen anlegen']
    }]
}

export default SearchConfig;
