import ApiURL from '../modules/components/ApiURL';
import getToken from '../modules/components/Token';
import ReactDOM from 'react-dom';
import React from 'react';
import {dateFormat} from "../modules/components/dateFormat";
import '../css/SaveComments.css';
import {notify_save} from "../../../@WUM/core/component/const/notification.service";

const saveComments = (e: any, issueID: any,Store:any) => {

  e.preventDefault();
  let data = e.currentTarget

if(data[2].value !== '') {

  var myHeaders = new Headers();

  myHeaders.append("Authorization", 'Bearer ' + getToken());

  var formdata = new FormData(data);

  var raw = JSON.stringify({
    "User": formdata.get('User'),
    "translateable_id": formdata.get('translateable_id'),
    "text": formdata.get('text')
  });

  let requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token') as string)
    },
    body: raw
  };

  fetch(ApiURL() + "comment/insert/1/", requestOptions)
    .then(response => response.text())
    .then(result => newComment(result,Store))
    .catch(error => console.log('error', error));

  data.children[3].children[0].children[2].children[2].children[0].innerHTML = '';
  data[2].value = '';

  const createComment = (data: any) => {

    let Result = JSON.parse(data.item);

    return (
      <>
        <div className="grayborder15">
          <h5>{Result.User} vom: {dateFormat(Result.date,'de','us',true,true,Store)}</h5>
          <p dangerouslySetInnerHTML={{__html: Result.text}} />
        </div>
      </>
    )
  }

  const newComment = (result: any,Store:any) => {
    let newElement = createComment({'item': result});
    let temp = document.createElement('div');
    ReactDOM.render(newElement, temp);
    let ele = document.getElementById('commentHolder') as HTMLDivElement;
    ele.appendChild(temp)
    notify_save(Store);
  }
} else {
  alert('Bitte schreiben Sie ein Kommentar bevor sie Speichern')
  return (<></>)
}
}

export default saveComments;
