import {useEffect, useState} from "react";
import {authApiDetailType} from "../request/authApiDetailType";
import {dynamik} from "../request/dynamik";
import * as actionsdynamikModuls
    from "../../../../templates/default/Auth/store/actions/dynamikModuls/DynamikModulsAction";
import {error} from "../../../component/const/error.services";


export const ResponseGetModuls = (dispatch:any) => {
    const [posts, setPosts] = useState<authApiDetailType[]>();
    const [isError, setIsError] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            await dynamik.getAPostSitemap()
                .then((data) => {
                    let PagesConfig = [];
                    let MenuConfig = [];
                    let Dashboard = [];

                    let transformresult:any = data


                    for(let x=0;x<transformresult.moduls.length;x++) {
                        if(transformresult.moduls[x].type === 'PAGES_CONFIG') {
                            PagesConfig.push(transformresult.moduls[x])
                        }else if(transformresult.moduls[x].type === 'DASHBOARD') {
                            Dashboard.push(transformresult.moduls[x])
                        }
                    }

                    for(let s=0;s<transformresult.sitemap.length;s++) {

                        MenuConfig.push(transformresult.sitemap[s])

                    }

                    dispatch(actionsdynamikModuls.getDynamikModuls(
                        PagesConfig,
                        MenuConfig,
                        Dashboard
                    ))
                    setPosts(data);

                })
                .catch((err) => {
                    error(err);
                    setIsError(true);
                });
        })()}, [])


    return isError ? (posts ) : (posts);

}

export const ResponseGetSitemap = (PagesConfig:any,Dashboard:any,dispatch:any,) => {
    const [posts, setPosts] = useState<authApiDetailType[]>();
    const [isError, setIsError] = useState<boolean>(false);
    useEffect(() => {
        (async () => {
            await dynamik.getAPostSitemap()
                .then((data) => {
                    let MenuConfig = [];

                    let transformresult:any = data


                    for(let x=0;x<transformresult.length;x++) {

                        MenuConfig.push(transformresult[x])

                    }

                    dispatch(actionsdynamikModuls.getDynamikModuls(
                        PagesConfig,
                        MenuConfig,
                        Dashboard

                    ))
                    setPosts(data);
                })
                .catch((err) => {
                    error(err);
                    setIsError(true);
                });
        })()}, [])


    return isError ? (posts ) : (posts);

}
