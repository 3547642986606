import React, {useState} from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Button from 'react-bootstrap/Button';
import {
  useNavigate,
} from 'react-router-dom';
import home from './elements/home';
import {calenderApiDetail} from '../API/request/calender/calenderApiDetail.interface';
import {init2} from "../../../@WUM/core/form-serilizer/seriliaze/Formserialize";
import {config, output} from './configuration/mapping';
import {notify_save} from '../../../@WUM/core/component/const/notification.service';
import {error} from '../../../@WUM/core/component/const/error.services';
import {btn_back} from '../../../@WUM/core/component/const/btn_back';
import {handleSubmitForm} from '../../../@WUM/core/component/const/handleSubmitForm';
import {Form} from 'react-bootstrap';
import IntlMessage from '../../../@WUM/core/component/const/IntlMessage';
import {calender} from '../API/request/calender/calender';
import {useSelector} from "react-redux";



const PageNew = (props:{value:any}) => {

  const Store = useSelector((state: any) => state)
  const history = useNavigate();

  const [currentTutorial, setCurrentTutorial] = useState<calenderApiDetail[]>([]);
/*  const [element,setElement] = useState([]);
  const [modalShow, setModalShow] = React.useState(false);
  const [modalData, setModalData] = React.useState([]);*/

  const [validated, setValidated] = useState(false);
/*  const [seo_title, setseo_title] = useLocalStorage("seo_title", "");
  const [seo_description, setseo_description] = useLocalStorage("seo_description", "");*/
  /**
   * Sendet Formular Daten an die API
   * @param status
   * @param id
   * @param form
   */
  const updateData = (status: boolean, id: any = 0,form:any) => {

    /*
     *  Wichtig zum Rausholen der Datan aus Formular und senden
     *  Das Interface muss immer angepasst werden
     */
    let Data = init2(form);

    let reauestData: calenderApiDetail[] &
      config
      = Object.assign({}, currentTutorial,
      output(Data));


    /*
     *  Senden der Erhaltenen Daten an das Frontend
     */
    calender.updatePostNew(reauestData, id)
      .then(response => {
        setCurrentTutorial({...currentTutorial});
        notify_save();
        history(-1);
      })
      .catch(e => {
        error(e);
      });

  };


  return (
    <div>
      <Form  id={'form001'} className={'eventsCalender'} noValidate validated={validated} onSubmit={(e) => handleSubmitForm(e, updateData, setValidated, 0)}>
        <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3" key={'tabindex'}>
          <Tab eventKey="home" title={<IntlMessage messageId="common.general"  Store={Store}/>} key={'allgemein'} id={'allgemein'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
          {home('new',Store)}
          </Tab>
        </Tabs>
        <div style={{backgroundColor: 'white', padding: '15px'}}>
          {btn_back(history,'common.abort','',Store)}
          <Button variant='success'
                  style={{float: 'right', marginRight: '15px'}}
                  type={'submit'}
          >
            <IntlMessage messageId="common.save" Store={Store} />
          </Button>
          <br style={{clear: 'both'}} />
        </div>
      </Form>
    </div>
  );
}


export default PageNew;
