import React from 'react';
import PielersForm from '../../index';
import {base, basesettings} from './base';
import CopyElements from "../../../component/const/CopyElements";
import WebBuilderElementsHead from "../../../component/const/WebBuilderElementsHead";
import Col from 'react-bootstrap/Col';
import Row from "react-bootstrap/Row";
import Box from '@mui/material/Box';
import IntlMessage from "../../../component/const/IntlMessage";

function magazinWebBuilder(data: any, element: any, i: any, newElement: any = false, newelementIndex: any = 0, modalShow: boolean,
                 setModalShow: (value: (((prevState: boolean) => boolean) | boolean)) => void, modalData: any[],
                 setModalData: (value: (((prevState: any[]) => any[]) | any[])) => void,
                 content:any = [],setElements:any = [],Store:any) {

  const index = (newElement === false ? i : newelementIndex)

  const configuration = [
    {
      'formType': 'sitemap'
    },
    {
      'formType': 'input',
      'name': 'elements[' + index + '][template]',
      'type': 'hidden',
      'value': 'Elements/magazin.html',
    }
    ]

  let newConfiguration = configuration;

  let Status;
  if(element.visible === 1) {
    Status = 'elementActive';
  } if(element.visible === 0){
    Status = 'elementDeactive';
  } else if(newElement === true) {
    Status = 'elementActive';
  }
  return (
    <>
    <Box id={'magazin'+index} data-appendcontainer={(newElement === false && element.appendto !== null && element.appendto !== undefined && element.appendto !== '' ?  element.appendto : 'false')} >
    {WebBuilderElementsHead('Magazin','magazin','',Status,index,'elementButton','Text',
            modalShow,setModalShow,modalData, setModalData,newElement,element,content,setElements,Store,'','',element.id)}
      <div id={'preview-container_magazin' + index} className={'preview-container '+Status} style={{display: (newElement === false ? 'block' : 'none')}}>
        {(newElement === false ?

            <span dangerouslySetInnerHTML={{ __html: element.previews }} />

            : <></>)}
      </div>
      <Row id={'mein_magazin'+index} className={'boxShadowCard card ElementBorder'} style={{width: '100%',margin:'0px 0px 10px 0px',border: '0',boxShadow: 'unset' }}>
        <Col  className={'element-body'}>
          <div id={'edit_magazin' + index} className={'boxShadowCard card '+Status} style={{display:(newElement === false ? 'none' : 'block'),padding:'15px',width: '100%',marginBottom: '20px'}}>
            <Col style={{marginBottom: '10px',marginTop:'10px'}}>Magazin</Col>
            <div id={'Teaser'}>image_title</div>
            {PielersForm(base(element,i,newElement,newelementIndex))}
          </div>
        </Col>
      <div id={'settingstext'+index+'magazin'} className={'settingsWebBuilderModul '} style={{display:'none'}}>
        {PielersForm(basesettings(element,i,newElement,newelementIndex,Store))}
        <h3>Modul</h3>
        {PielersForm(newConfiguration)}
      </div>
          {(newElement === false ? CopyElements(element.id,'Magazin',Store) : <></>)}

        </Row>
        </Box>
    </>
  )


}

export default magazinWebBuilder;
