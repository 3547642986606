import IntlMessage from '../../../../../../core/component/const/IntlMessage';
import React from "react";

/**
 *
 * @memberOf blog
 * @prop conf
 * @prop response
 * @prop page
 * @author Marcel Ugurcu
 * @version 0.0.1
 */
function configuration(conf: string,response:any,page:any,Store:any) {

  return {
    'tableBody': {
      'data': response,
      'dataID': 'id',
      'visitMenu': 'translation.slug',
      'underdata': true,
      'keys': ['id', 'name', 'locale', 'cat_id','parent'],
      'type': ['string', 'string', 'string', 'string', 'string'],
    },
    'tableHeader': ['ID', 'Name', 'Locale', 'Category ID','Parent ID'],
    'tableHeaderWidth': ['auto', '30%', '30%', '30%','30%'],
    'tablecolor': ['light'],
    'menu': {
      'data': response,
      'menuDisable': false,
      'menutriggerDelete': true,
      'menuItem': [<IntlMessage Store={Store} messageId="common.display" />, <IntlMessage Store={Store} messageId="common.edit" />, <IntlMessage Store={Store} messageId="common.delete" />],
      'menuroute': ['http://myhomice-f2.pielers.de/magazin/[param]', '/helpdesk/faq/Detail', '/helpdesk/faq/Delete/'+page+'/[ID]'],
      'menuParams': ['translation.slug', 'id', 'id'],
      'menuIcons': ['eye','pencil','Delete'],
    },

  }
}

export default configuration;
