import React
//{useState}
  from 'react';
import PielersTable from '../../../../../../@WUM/core/tablemaker';
import {SpinnerJSX} from '../../../../../../@WUM/core/component/const/Spinner';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import {handleSucces} from '../../../../../../@WUM/core/component/const/HandleSucces';
import PaginationJSX from '../../../../../../@WUM/core/form-serilizer/elements/pagination';
import IntlMessage from '../../../../../../@WUM/core/component/const/IntlMessage';
/*import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';*/
import {ResponsePromotion} from '../../../API/response/orders/promotion';
import {useSelector} from "react-redux";
interface NotificationsProps {
  value:any
}

const Promotionoverview: React.FC<NotificationsProps> = (value:any) => {
  const Store = useSelector((state: any) => state)
  const {page} = useParams<{page: string}>();
  let limit: any;
  //const [searchValue, setSearchValue] = useState('');
  if (typeof page === "string") {
    limit = (parseFloat(page) - 1) * 10;
  }

  const response = ResponsePromotion(limit,'');
  const history = useNavigate();
  const destination = 'Promotion';

  const Configuration = {
    'tableBody': {
      'data': response,
      'dataID': 'id',
      'underdata': true,
      'keys': ['created_at','id', 'coupon.code','state','Order.payment_state','detail.code','customer.fullname','Order.price'],
      'type': ['dateInvoice','fillNumber', 'string','state_promotion','payment_promotion_state','string','string','summe'],
    },
    'tableHeader': ['Bestelldatum','Bestellnummer', 'Gutscheinname','Status','Bezahlstatus','Mitarbeitercode','Mitarbeiter','Summe'],
    'tableHeaderWidth': ['10%', '10%', '10%','14%','14%','14%','14%','14%'],
    'tablecolor': ['light'],
    'menu': {
      'data': response,
      'menuDisable': false,
      'menuItem': [<IntlMessage Store={Store} messageId="display.order" />],
      'menuroute': ['/Promotion/Detail'],
      'menuParams': [false, 'id', 'id'],
      'menuIcons': ['eye'],
    },

  };

  /*let filterOption = [];
  let MerchantAll: any = JSON.parse(localStorage.getItem('MerchantAll') as string)
  for(let x = 0;x<MerchantAll.length;x++){
    filterOption.push(<><option value={MerchantAll[x].id}>{MerchantAll[x].name}</option></>)
  }*/

    /**
     * Sendet Filter an die API
     * @param {event} e Event des HTML Element
     * @author Marcel Ugurcu
     * @version 0.0.1
     */
/*    const _handleKeyFilter = function(e: any) {
      if(e.target.value !== '0') {
        const value = e.target.value;

        setSearchValue('?merchantID=' + value);
      }
    };


  let filter = (<>
    <Form.Select aria-label="Filter" onChange={_handleKeyFilter}>
      <option value={'0'}></option>
      {filterOption}
    </Form.Select>
  </>);*/

  if (response.length > 0) {
    return (
      <>
        {/*<div style={{backgroundColor: 'white', padding: '15px'}}>
          <Row className="g-2" key={'spalte'}>
            <Col><p>Nach Anbieter Filtern</p></Col>
            <Col md key={'colone'}>
              {filter}
            </Col>
            <Col><p>Nach Kunden Filtern</p></Col>
            <Col><p>Cooming soon</p></Col>
          </Row>
        </div>*/}
        <div className={'site'}>

        <PielersTable Configuration={Configuration} />
          <PaginationJSX response={response} history={history} to={'overview'} handleSuccess={handleSucces} destination={destination} />
        </div>
      </>
    );
  } else {
    return (
      <><SpinnerJSX messageId={'common.loading'} Store={Store} /></>
    );
  }

};


export default Promotionoverview;
/*function show () {

  const spinnerElement = document.getElementsByClassName('spinner');
  const siteElement = document.getElementsByClassName('site');
  spinnerElement[0].setAttribute('style','display:none');
  siteElement[0].setAttribute('style','display:block');
}*/
