export const  link = () => {
    let links;
    if(process.env.REACT_APP_MODE === 'LIVE') {
        links = process.env.REACT_APP_PREVIEW_LINK_LIVE;
    } else if(process.env.REACT_APP_MODE === 'DEVELOPMENT'){
        links = process.env.REACT_APP_PREVIEW_LINK_DEVELOPMENT;
    } else {
        links = process.env.REACT_APP_PREVIEW_LINK_LOCAL;
    }
    return links;
};

export const destination = 'foreignbusiness';
