import React from 'react';
import {LanguageSwitcherValue} from "../../../core/components/LanguageSwitcher";
import InvoiceOverview from "./Pages/overview";
import InvoiceDetail from "./Pages/Detail"

export const InvoicePagesConfig = (value: any) => {

    return [

        {
            path: '/Invoice/overview/:page',
            elements: <InvoiceOverview value={value}/>,
            roles: ['superadmin','admin','operator','merchant','merchant_ma']
        },
        {
            path: '/Invoice/Detail/:id',
            elements: <InvoiceDetail value={value}/>,
            roles: ['superadmin','admin','operator','merchant','merchant_ma']
        },
    ];
}

export const InvoiceMenuConfig = (value: any) => {
    return [
        {
            name: 'Rechnungen anzeigen',
            route: '/Invoice/overview/1',
            icon: 'payments',
            roles: ['superadmin','admin','operator','merchant','merchant_ma']
        }
    ]
}
