import getToken from '../modules/components/Token';
import ApiURL from '../modules/components/ApiURL';
import ReactDOM from 'react-dom';
import React from 'react';
import GetAvatarView from '../modules/components/getAvatarView';
import {
  notify_del_element,
  notify_save,
  notify_save_intvall_reload
} from "../../../@WUM/core/component/const/notification.service";
export const delComment= (e: any,translateable_id:any, id: any,Store:any,issueID:any) => {
  e.preventDefault();
  let data = translateable_id
  var myHeaders = new Headers();


  myHeaders.append("Authorization", 'Bearer ' + getToken());
  var formdata = new FormData();
  formdata.append("translateable_id", data);
  formdata.append("id", id);
  var raw = JSON.stringify({
    "translateable_id": formdata.get('translateable_id'),
    "id": formdata.get('id'),
  });
  let requestOptions = {
    method: 'DELETE',
    headers: myHeaders,
    body: raw
  };

  fetch(ApiURL() + "delete/comment/0/", requestOptions)
    .then(response => response.text())
    .then(result => (newComment(result)))
    .catch(error => console.log('error', error));


  const newComment = (result:any) => {

    notify_save_intvall_reload(Store);
    setTimeout(() => window.location.replace('/ticket/issue/'+issueID), 3000);
    }
}
