
import React from "react";

const open = (id:any) => {



    let All = document.querySelectorAll('.Form');

    for(let a=0;a<All.length;a++) {
        let Single = All[a] as HTMLDivElement;
        if(Single.id === id) {
            if(!Single.className.includes('hidden')) {
                Single.classList.add('hidden');
            } else {
                Single.classList.remove('hidden');
            }
        } else {
            Single.classList.remove('hidden');

            Single.classList.add('hidden');
        }

    }
}


export default open;
