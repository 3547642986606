import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React from "react";
import Form from "react-bootstrap/Form";
import Download, {buildDoc, buildDownload} from "../../../../@WUM/core/component/const/download";
import Tab from "react-bootstrap/Tab";
import Tabs from 'react-bootstrap/Tabs';
import Image from "react-bootstrap/Image";
import FormUploadFile from "../../../../@WUM/core/form-serilizer/elements/FormUploadFile";
import InputGroup from "react-bootstrap/InputGroup";

const Forms = (response:any = [],mode:any='new',fetch:any=[]) => {

    let Legalforms = fetch;

    if (Legalforms.length > 0) {
        return (
            <> <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3" key={'tabindex'}>
                <Tab eventKey="home" title={'Allgemein'} key={'allgemein'} id={'allgemein'} >
                    <h2>Allgemein</h2>
                <h3>Geschäftsführer</h3>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="gffirstname">
                            <Form.Label>Vorname:</Form.Label>
                            <Form.Control type="text" name={'gffirstname'}
                                          defaultValue={(mode === 'new' ? '' : response[0].gffirstname)}/>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3" controlId="gflastname">
                            <Form.Label>Nachname</Form.Label>
                            <Form.Control type="text" name={'gflastname'}
                                          defaultValue={(mode === 'new' ? '' : response[0].gflastname)}/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="company">
                            <Form.Label>Firmenname:</Form.Label>
                            <Form.Control type="text" name={'company'}
                                          defaultValue={(mode === 'new' ? '' : response[0].company)}/>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3" controlId="form">
                            <Form.Label>Unternehmensform</Form.Label>
                            <Form.Select aria-label="Default select example" name={'form'}
                                         defaultValue={(mode === 'new' ? '' : response[0].form)}>
                                {buildLegalforms(Legalforms)}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="hrb">
                            <Form.Label>Handelsregister:</Form.Label>
                            <Form.Control type="text" name={'hrb'}
                                          defaultValue={(mode === 'new' ? '' : response[0].hrb)}/>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3" controlId="hrbnr">
                            <Form.Label>Handelsregisternummer</Form.Label>
                            <Form.Control type="text" name={'hrbnr'}
                                          defaultValue={(mode === 'new' ? '' : response[0].hrbnr)}/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="taxnumber">
                            <Form.Label>Steuernummer:</Form.Label>
                            <Form.Control type="text" name={'taxnumber'}
                                          defaultValue={(mode === 'new' ? '' : response[0].taxnumber)}/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="street">
                            <Form.Label>Straße:</Form.Label>
                            <Form.Control type="text" name={'street'}
                                          defaultValue={(mode === 'new' ? '' : response[0].street)}/>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3" controlId="postcode">
                            <Form.Label>Postleitzahl</Form.Label>
                            <Form.Control type="text" name={'postcode'}
                                          defaultValue={(mode === 'new' ? '' : response[0].postcode)}/>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3" controlId="city">
                            <Form.Label>Ort</Form.Label>
                            <Form.Control type="text" name={'city'}
                                          defaultValue={(mode === 'new' ? '' : response[0].city)}/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="buisness">
                            <Form.Label>Welche Tätigkeit/ Aufgaben erfüllt Ihr deutsches Unternehmen? </Form.Label>
                            <Form.Control as="textarea" name={'buisness'} rows={5}
                                          defaultValue={(mode === 'new' ? '' : response[0].buisness)}/>
                        </Form.Group>
                    </Col>
                </Row>
                </Tab>
                <Tab eventKey="Dokumente" title={'Dokumente'} key={'Dokumente'} id={'Dokumente'} >
                    <h2>Dokumente</h2>
                    {(mode === 'new' ? <></> : <Row>
                        <Col className={'col-6'}><br/><Form.Group className="mb-3" controlId="form">
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1">Art auswählen:</InputGroup.Text>
                            <Form.Select id={'field_context'} aria-label="Default select example" name={'form'} onClick={(e) => unlock(e,'germanupload') }>
                                <option />
                                <option value={'doc'}>Dokument</option>
                                <option value={'hr'}>Handelsregister</option>
                                <option value={'privacy'}>Datenschutz</option>
                                <option value={'service'}>Dienstleistungauftrag</option>
                                <option value={'perso'}>Personalausweis</option>
                                <option value={'extra'}>Extra</option>
                            </Form.Select>
                            </InputGroup>
                        </Form.Group></Col>
                        <Col className={'col-6'}> <FormUploadFile data={{
                            'formType': 'formFileBucket',
                            'onclick': true,
                            'onclick_context': 'pielers_element_text_image',
                            'append': 'preview_media',
                            'experiment':true,
                            'multi': false,
                            'fieldcontext': 'field_context',
                            'src':'ewiv',
                            'addedRoute': 'ewiv/',
                            'Buckettype':true,
                            'send_id': response[0].id,
                            'id':'germanupload',
                            'url': 'german/',
                            'type': 'file',
                            'size': {
                                'xl': 10,
                                'md': 10,
                                'sm': 10,
                            }
                        }} Store={'de'} /></Col>
                    </Row>)}
                    {(mode !== 'new' ? (response[0].medias !== null && response[0].medias !== undefined ? buildDoc(response[0].medias) : <></>) : <></>)}
                </Tab>
            </Tabs>

            </>
        )
    } else {
        return (<></>)
    }

}
export default Forms;

const buildLegalforms = (Legalforms:any) => {
    let option = [];
    for(let x=0;x<Legalforms.length;x++) {
        option.push(
            <>
                <option value={Legalforms[x].key}>{Legalforms[x].value}</option>
            </>
        )
    }

    return option;
}

const unlock = (e:any,name:any) => {
    let fileField = document.getElementById(name) as HTMLInputElement;
    if(e.target.value !== undefined && e.target.value !== null && e.target.value !== '') {
        fileField.removeAttribute('disabled')
    } else {
        fileField.setAttribute('disabled','true')
    }
}

