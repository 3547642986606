import DynamikOverview from "../../dynamikModul/Modules/Pages/overview";
import React from "react";
import {ResponseDelete, ResponseOverview, ResponseDetail, ResponseCountry} from "./API/response/Type/customizer";
import DynamikDeleted from "../../dynamikModul/Modules/Pages/deleted";
import DynamikDetail from "../../dynamikModul/Modules/Pages/detail";
import DynamikNew from "../../dynamikModul/Modules/Pages/new";
import CardJSX from "./configuration/card";
import SearchConfig from "./configuration/SearchConfig";
import {destination, link} from "./helper/const";
import table from "./configuration/table";
import Forms from "./configuration/Form"



export const ForeignBusinessPagesConfig = (value:any) =>{
    return [
        {
            path: '/foreignbusiness/overview/:page',
            elements: <DynamikOverview value={value} table={table} destination={destination} state={'foreignbusiness'} SearchConfig={SearchConfig(destination)}
                                       CardsConfiguration={CardJSX(link,destination)} response={ResponseOverview} buttonText={'Ausl. Unternehmen '} />,
            roles: ['superadmin','admin']
        },
        {
            path: '/foreignbusiness/Delete/:page/:deleteID',
            elements: <DynamikDeleted value={value} reponse={ResponseDelete} namespace={destination}  />,
            roles: ['superadmin','admin']
        },
        {
            path: '/foreignbusiness/new/new',
            elements: <DynamikNew value={value} forms={Forms} addedForm={ResponseCountry} namespace={'/'+destination+'/overview/1'} saveRoute={'company/foreign/0/'}/>,
            roles: ['superadmin','admin']
        },
        {
            path: '/foreignbusiness/new/:page',
            elements: <DynamikNew value={value} forms={Forms} addedForm={ResponseCountry} namespace={'/'+destination+'/overview/1'} saveRoute={'company/foreign/0/'}/>,
            roles: ['superadmin','admin']
        },
        {
            path: '/foreignbusiness/Detail/:id',
            elements: <DynamikDetail value={value} forms={Forms} addedForm={ResponseCountry} response={ResponseDetail} saveRoute={'company/foreign/'}/>,
            roles: ['superadmin','admin']
        }
    ];
}

export const ForeignBusinessMenuConfig = (value:any) =>{
    return [
        {
            name: 'Ausl. Unternehmen',
            route: '/foreignbusiness/overview/1',
            icon: 'business',
            roles: ['superadmin','admin']
        },
    ];
}
export const ForeignBusinessDasboard = () => [
    {
        title: 'Ausl. Unternehmen',
        href: '/foreignbusiness/overview/1',
        undertitle:'Backoffice',
        icon: 'business',
        text: 'Ausl. Unternehmen',
        roles: ['superadmin','admin']
    }
]



