
import React from 'react';
import TableCell from '@material-ui/core/TableCell';
function euro(text: string, id: any, index: number, data: any, VisitData: any, VisitDataDel: boolean, Store: any, tableBodyPositionElement: any){


    let summe = parseInt(text);
    let ConvertSum = Intl.NumberFormat('de-DE', {style: 'currency',currency: 'EUR', minimumFractionDigits: 2}).format(summe);
    return (
        <><TableCell style={{textAlign:tableBodyPositionElement}}  key={"td"+id+ "_" +index} data-param={VisitData} data-param-del={VisitDataDel} data-id={id} id={"td"+id+ "_" +index}>{ConvertSum}</TableCell>
        </>
);

}

export default euro;
