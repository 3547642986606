import TemplateProviderDefault from "./default/index";
import Routing from "../core/routing/routes";
import routingConfig from "../templates/default/config/route";
import React from "react";


const TemplateRegister = () => {

    const [value, setValue] = React.useState(process.env.REACT_APP_DEFAULT_LANG);


    return [
        {
            template: 'default',
            component: <TemplateProviderDefault routing={<Routing setValue={setValue} value={value} config={routingConfig(value)} /> } />
        }
    ]
}

export default TemplateRegister;
