import React, {useState} from 'react';
import { ResponseAll} from "../../../api/response/faq";
import {SpinnerJSX} from '../../../../core/component/const/Spinner';
import configuration from '../configuration/Page/faq/overview';
import {useNavigate, useParams} from 'react-router-dom';
import IntlMessage, {IntlMessageValue} from '../../../../core/component/const/IntlMessage';
import ContentSwitch from "../../../../core/component/const/ContentSwitch";
import SearchJSX from "../../../../core/component/const/SearchJSX";
import {useSelector} from 'react-redux'
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
/**
 * Give View Overview for Content Blog
 * @memberOf blog
 * @prop page
 * @prop deleteID
 * @author Marcel Ugurcu
 * @version 0.0.1
 */
const FAQOverview = (props:{value:any}) => {




  const {page,deleteID} = useParams<{page: string,deleteID: string}>();
  const Store = useSelector((state: any) => state)



  const history = useNavigate();
  /**
   * Neuladen der Seite
   */
  const routeChange = (paths:string) => {
    history(-1);
  };
  let limit: any;
  if(deleteID) {
    //ResponseDelete(deleteID);
    routeChange('/helpdesk/faq/overview/1')
  }

  if (typeof page === "string") {
    limit = (parseFloat(page) * 10) - 10;
  }

  const destination = 'helpdesk/faq';
  const namespace_search = "faq_searchValue";
  const [searchValue, setSearchValue] = useState((localStorage.getItem(namespace_search) !== null ? localStorage.getItem(namespace_search) : ''));
  const [search, setSearch] = useState<boolean>(false)
  const response = ResponseAll(limit,searchValue);
  const [modalShow, setModalShow] = React.useState(false);
  const [modalData, setModalData] = React.useState([]);
  const Configuration = configuration('',response,page,Store);

  const [mode, setMode] = useState('cart');



  let link;
  if(process.env.REACT_APP_MODE === 'LIVE') {
    link = process.env.REACT_APP_PREVIEW_LINK_LIVE;
  } else if(process.env.REACT_APP_MODE === 'DEVELOPMENT'){
    link = process.env.REACT_APP_PREVIEW_LINK_DEVELOPMENT;
  } else {
    link = process.env.REACT_APP_PREVIEW_LINK_LOCAL;
  }

  const SearchConfig = [{
    namespace:namespace_search,
    destination: destination,
    input:[
      {id:'search_name',translation: 'common.search_for_title',param: 'translation.title',method: 'like'},
      {id:'search_title',translation: 'common.date',param: 'filter',method: '=',individual:<Form.Group as={Col} controlId={'search_title'}
                                                                                                              name={'search_title'}>
          <Form.Label>Nach Titel Sortieren</Form.Label>
          <Form.Select  aria-label="Filter" defaultValue={(localStorage.getItem(namespace_search + '_search_title') as string)}>
            <option />
          <option value={'title'}>{IntlMessageValue('de','searchadded.title.up',Store)}</option>
          <option value={'-title'}>{IntlMessageValue('de','searchadded.title.down',Store)}</option>
        </Form.Select> </Form.Group>}
    ],
    button: ['common.search','common.del.search','Neuen FAQ anlegen']
  }]


  const CardsConfiguration = {
    data: {
      ressource: response,
      img: '',
      status: 'status',
      statusActive: <IntlMessage Store={Store} messageId='common.activated' />,
      statusDeactive: <IntlMessage Store={Store} messageId='common.deactivated' />,
      link: '',
      prelink: 'http://myhomice-f2.pielers.de/produkte/',
      title: 'name',
      descriptionShow: false,
      description: 'translation.de.description',
      parentShow: true,
      parent: 'parent',
      router: 'id',
      preroute: '/helpdesk/faq/Detail/',
      edit: <IntlMessage Store={Store} messageId='common.edit_page' />,
      delete: true,
      Dontvisited: true,
      deleteLink: '/helpdesk/faq/Delete/' + page + '/[ID]',
    },
  }


  let NewPage = (page === undefined ? 1:page);
  if (response.length > 0) {
    return (
        <> <SearchJSX  setSearchValue={setSearchValue} Store={Store} SearchConfig={SearchConfig} />
       { <ContentSwitch pagenew={'new'} destinationnew={'helpdesk/faq'} tonew={'new'} text={'FAQ '}
                       response={response} CardsConfiguration={CardsConfiguration} mode={mode}
                       Configuration={Configuration} setMode={setMode}  destination={destination} page={NewPage}
                       to={'overview'} setSearchValues={setSearchValue} setSearchs={setSearch} search={false}
                       modalShow={modalShow} setModalShow={setModalShow} modalData={modalData} setModalData={setModalData} SearchParam={'?translation.title'}
                       searchfield={false} allTaxon={[]} _handleKeyFilters={[]} _handleEnter={[]} filter={true} stop={false} Store={Store}/>}
  </>

    )
  } else {

        return (
          <><SpinnerJSX messageId={'common.loading'} Store={Store} /></>
        );
      }

};


export default FAQOverview;



