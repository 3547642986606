import React, {useState} from 'react';
import {Response, ResponseAuthor, ResponseDelete} from '../../API/response/blog/blog';
import {SpinnerJSX} from '../../../../@WUM/core/component/const/Spinner';
import configuration from '../configuration/Page/overview';
import {useNavigate, useParams} from 'react-router-dom';
import {blogApiDetail} from '../../API/request/blog/blogApiDetail.interface';
import {removeUseLocalStorage} from "../../../../@WUM/core/component/const/useLocalStoreage";
import IntlMessage, {IntlMessageValue} from '../../../../@WUM/core/component/const/IntlMessage';
import {ResponseallProducts, ResponseallTaxon} from '../../API/response/product/Product';
import {allTaxonApiType} from '../../API/request/Product/allTacxonApi.interface';
import ContentSwitch from "../../../../@WUM/core/component/const/ContentSwitch";
import {useSelector} from 'react-redux'
import { createElement } from 'react';
import DynamikComponents from "../../dynamik/components";
/**
 * Give View Overview for Content Blog
 * @memberOf blog
 * @prop page
 * @prop deleteID
 * @author Marcel Ugurcu
 * @version 0.0.1
 */
const DynamikOverview = (props:{value:any,destination:any,SearchConfig:any,CardsConfiguration:any,response:any,buttonText:any,state:any,table?:any,nonewButton?:any}) => {


    const {page,deleteID} = useParams<{page: string,deleteID: string}>();
    const Store = useSelector((state: any) => state)
    removeUseLocalStorage('seo_title');
    removeUseLocalStorage('seo_description');



    const history = useNavigate();
    /**
     * Neuladen der Seite
     */
    const routeChange = (paths:string) => {
        history(-1);
    };
    let limit: any;
    if(deleteID) {
        ResponseDelete(deleteID);
        routeChange('/'+props.destination+'/overview/1')
    }

    if (typeof page === "string") {
        limit = (parseFloat(page) * 10) - 10;
    }
    ResponseallProducts();
    const destination = props.destination;
    const namespace_search = props.destination+"_searchValue";
    const [searchValue, setSearchValue] = useState((localStorage.getItem(namespace_search) !== null ? localStorage.getItem(namespace_search) : ''));
    const [search, setSearch] = useState<boolean>(false)
    const response = props.response(limit,searchValue,props.state);
    const [modalShow, setModalShow] = React.useState(false);
    const [modalData, setModalData] = React.useState([]);
    const Configuration = props.table('',response,page,Store);

    const [mode, setMode] = useState('cart');



    const SearchConfig = props.SearchConfig

    let CardsConfiguration = props.CardsConfiguration;
    CardsConfiguration.data.ressource = response
    CardsConfiguration.data.statusActive = <IntlMessage Store={Store} messageId="common.activated" />
    CardsConfiguration.data.statusDeactive = <IntlMessage Store={Store} messageId="common.deactivated" />
    CardsConfiguration.data.edit = <IntlMessage Store={Store} messageId={props.buttonText} />
    CardsConfiguration.data.deleteLink = '/'+destination+'/Delete/'+page+'/[ID]'

    let NewPage = (page === undefined ? 1:page);
    if (response.length > 0) {

        return (
            <> {createElement(DynamikComponents['SearchJSX'],{noNewButton:props.nonewButton,setSearchValue:setSearchValue,Store:Store,SearchConfig:SearchConfig})}
                { <ContentSwitch pagenew={'new'} destinationnew={props.destination} tonew={'new'} text={
                    createElement(DynamikComponents['IntlMessage'],{Store:Store,locale:'de',messageId:'blog.new.post'})}
                                 response={response} CardsConfiguration={CardsConfiguration} mode={mode}
                                 Configuration={Configuration} setMode={setMode}  destination={destination} page={NewPage}
                                 to={'overview'} setSearchValues={setSearchValue} setSearchs={setSearch} search={false}
                                 modalShow={modalShow} setModalShow={setModalShow} modalData={modalData} setModalData={setModalData} SearchParam={'?translation.title'}
                                 searchfield={false} allTaxon={[]} _handleKeyFilters={[]} _handleEnter={[]} filter={true} stop={false} Store={Store}/>}
            </>

        )
    } else {
        localStorage.setItem('searchEnterInput','');
        return (
            <><SpinnerJSX messageId={'common.loading'} Store={Store} /></>
        );
    }

};


export default DynamikOverview;



