import Form from "react-bootstrap/Form";
import React, {useEffect} from "react";
import {SunEditorConfig} from "../../../../../@WUM/core/component/const/SunEditorConfig";
import SunEditor, {buttonList} from "suneditor-react";
import {apiRoot} from "../../../../../@WUM/core/RestFullApi/ApiHook";


const setForm = (x:any,l:any,value:any,newForm:any=false,AllPages:any,settypes: (value: (((prevState: string) => string) | string)) => void,types:any) => {


    let optionALL = [];

    for(let all = 0;all<AllPages.length;all++) {
        optionALL.push(
            <option value={AllPages[all].id}>{AllPages[all].title}</option>
        )
    }

    /*let checkType = (newForm === true ? (document.getElementById(types) as HTMLSelectElement)?.value : value.type)*/

    let checkType = '';
    if(newForm !== false) {
        checkType = types
    } else {
        checkType = value.type;
    }



    return (
        <>
            {(newForm === true ?
                   <></>
                    : <></>

            )}
            <Form.Group className={"mb-3 hidden"} controlId="appendto">
            <Form.Control type="text" name={"Menu["+x+"][Items]["+l+"][item][appendto]"} defaultValue={(newForm === false ? value.appendto : '' )} />
            <Form.Control type="text" name={"Menu["+x+"][Items]["+l+"][item][id]"} defaultValue={(newForm === false ? value.id : '' )} />
            </Form.Group>
            <Form.Group className={"mb-3 "+(mappingType(checkType,newForm).includes('position') ? '' : 'hidden')} controlId="position">
                <Form.Label className={(mappingType(checkType,newForm).includes('position') ? '' : 'hidden')}>position</Form.Label>
                <Form.Control type={(mappingType(checkType,newForm).includes('position') ? 'text' : 'hidden')} name={"Menu["+x+"][Items]["+l+"][item][position]"} defaultValue={(newForm === false ? value.position : '' )} />
            </Form.Group>
            <Form.Group className={"mb-3 "+(mappingType(checkType,newForm).includes('page_id') ? '' : 'hidden')} controlId="page_id">
                <Form.Label className={(mappingType(checkType,newForm).includes('position') ? '' : 'hidden')}>page_id</Form.Label>
                {/*<Form.Control type={(mappingType(value.type).includes('page_id') ? 'text' : 'hidden')} name={"Menu["+x+"][Items]["+l+"][item][page_id]"} defaultValue={(newForm === false ? value.page_id : '' )} />*/}
                <Form.Select  name={"Menu["+x+"][Items]["+l+"][item][page_id]"} defaultValue={(newForm === false ? value.page_id : '' )}>
                    <option></option>
                    {optionALL}
                </Form.Select>
            </Form.Group>
            <Form.Group className={"mb-3 "+(mappingType(checkType,newForm).includes('type') ? '' : 'hidden')} controlId="type">
                <Form.Label>type</Form.Label>
                <Form.Control type={(mappingType(checkType,newForm).includes('type') ? 'text' : 'hidden')} name={"Menu["+x+"][Items]["+l+"][item][type]"} defaultValue={(newForm === false ? value.type : types )} />
            </Form.Group>
            <Form.Group className={"mb-3 "+(mappingType(checkType,newForm).includes('html') ? '' : 'hidden')} controlId="html">
                <Form.Label className={(mappingType(checkType,newForm).includes('html') ? '' : 'hidden')}>html</Form.Label>
                <SunEditor name={"Menu["+x+"][Items]["+l+"][item][html]"} lang={'de'}
                           defaultValue={(newForm === false ? value.html : '' )} key={'Sun' + x}
                           height={'250px'}
                           setOptions={{
                               "toolbarContainer": "#custom_toolbar",
                               "imageWidth": "150px",
                               "imageHeight": "150px",
                               "imageGalleryUrl": apiRoot+"MediaSunEditor/",
                               "imageGalleryHeader": {
                                   "Authorization": "Bearer "+localStorage.getItem('token')
                               },

                               buttonList: SunEditorConfig('complex'),
                               attributesWhitelist:
                                   {
                                       div:'id|class',
                                       p: 'id|class',
                                       h: 'id|class',
                                       span: 'id|class'
                                   },
                               "linkNoPrefix": true,
                           }}/>
              </Form.Group>
            <Form.Group className={"mb-3 "+(mappingType(checkType,newForm).includes('link') ? '' : 'hidden')} controlId="link">
                <Form.Label className={(mappingType(checkType,newForm).includes('link') ? '' : 'hidden')}>link</Form.Label>
                <Form.Control type={(mappingType(checkType,newForm).includes('link') ? 'text' : 'hidden')} name={"Menu["+x+"][Items]["+l+"][item][link]"} defaultValue={(newForm === false ? value.link : '' )} />
            </Form.Group>
            <Form.Group className={"mb-3 "+(mappingType(checkType,newForm).includes('target') ? '' : 'hidden')} controlId="target">
                <Form.Label>target</Form.Label>
                <Form.Control type={(mappingType(checkType,newForm).includes('target') ? 'text' : 'hidden')} name={"Menu["+x+"][Items]["+l+"][item][target]"} defaultValue={(newForm === false ? value.target : '' )} />
            </Form.Group>
            <Form.Group className={"mb-3 "+(mappingType(checkType,newForm).includes('parent') ? '' : 'hidden')} controlId="parent">
                <Form.Label className={(mappingType(checkType,newForm).includes('parent') ? '' : 'hidden')}>parent</Form.Label>
                <Form.Control type={(mappingType(checkType,newForm).includes('parent') ? 'text' : 'hidden')} name={"Menu["+x+"][Items]["+l+"][item][parent]"} defaultValue={(newForm === false ? value.parent : '' )} />
            </Form.Group>
            <Form.Group className={"mb-3 " +(mappingType(checkType,newForm).includes('visible') ? '' : 'hidden')} controlId="visible">
                <Form.Label className={(mappingType(checkType,newForm).includes('visible') ? '' : ' hidden')}>visible</Form.Label>
                <Form.Select  name={"Menu["+x+"][Items]["+l+"][item][visible]"} defaultValue={(newForm === false ? value.visible : '' )}>
                    <option></option>
                    <option value={1}>Active</option>
                    <option value={0}>Deactive</option>
                </Form.Select>
            </Form.Group>
            <Form.Group  controlId="class" className={"mb-3 "+(mappingType(checkType,newForm).includes('class') ? '' : 'hidden')}>
                <Form.Label className={(mappingType(checkType,newForm).includes('class') ? '' : 'hidden')}>class</Form.Label>
                <Form.Control type={(mappingType(checkType,newForm).includes('class') ? 'text' : 'hidden')} name={"Menu["+x+"][Items]["+l+"][item][class]"} defaultValue={(newForm === false ? value.class : '' )} />

            </Form.Group>
        </>
    )
}

export default setForm;

const mappingType = (type:any,newForm:any=false) => {

    let map = '';
    if(newForm === true) {
        console.log(type);
    }

    switch(type) {
        case 'page':
            map = 'page_id visible class'
            break;
        case 'html':
            map = 'html visible class'
            break;

        case 'link':
            map = 'link visible target class'
            break;
    }

    return map;


}
