import WidgetURLMapping from "../../core/WidgetURLMapping";
import CreateMatomoIframe from "../../core/CreateMatomoIframe";
import React from "react";


const Site = () => {

    return(
        <>
            <CreateMatomoIframe
                headline={'Seiten'}
                width={"100%"}
                height={"600"}
                url={WidgetURLMapping({name:'sites'})}
                scrolling={"yes"}
                id={'site1'}
            />
        </>
    )

}

export default Site;