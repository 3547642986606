import {ResponsePromoDetail} from "../../API/response/promo";
import {useParams} from "react-router-dom";
import {SpinnerJSX} from "../../../../../@WUM/core/component/const/Spinner";
import React from "react";
import {Row,Col} from "react-bootstrap";
import '../../css/promo.css';
import {useSelector} from "react-redux";

const PromoDetail = (props: { value: any }) => {

    const {id} = useParams<{ id: string }>();
    const response = ResponsePromoDetail(id);
    const Store = useSelector((state: any) => state)

    if (response.length > 0) {
        return (
            <>
                <div key={'head'}>
                    <div className={'MainBackground'} style={{backgroundColor: 'white', padding: '15px'}}
                         key={'button'}>
                        <Row>
                            <Col>Username: {response[0].userName}</Col>
                            <Col>AccessToken: {response[0].accessToken}</Col>
                            <Col>Zugeordnete Files: {response[0].files.length}</Col>
                        </Row>
                        <br/>
                        <div>{BuildFile(response[0].files)}
                        <p style={{clear:'both'}}></p>
                        </div>
                    </div>
                </div>
            </>
        )
    } else {

        return (
            <><SpinnerJSX messageId={'common.loading'} Store={Store}/></>
        );
    }


}

export default PromoDetail;


const BuildFile = (files: any) => {

    let file = [];

    for (let x = 0; x < files.length; x++) {
        file.push(<>
            <div className={'videoWrapper'}>
            <p><strong>{files[x].file.fileName}</strong></p>
                <video controls
                       src={(process.env.REACT_APP_MODE === 'LOCAL' ? process.env.REACT_APP_CDN_URL_LOCAL : process.env.REACT_APP_CDN_URL)+'/'+process.env.REACT_APP_CDN_CLIENT+'/' + files[x].file.fileDestination + '' + files[x].file.fileName}
                       width={500}
                       poster={(process.env.REACT_APP_MODE === 'LOCAL' ? process.env.REACT_APP_CDN_URL_LOCAL : process.env.REACT_APP_CDN_URL)+'/'+process.env.REACT_APP_CDN_CLIENT+'/' + files[x].file.fileDestination + '' + files[x].file.thumbnail}
                />
            </div>
        </>)
    }


    return file;
}
