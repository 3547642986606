import {PageActionType} from '../ActionType'
import {Dispatch} from 'redux'
import {site} from "../../../modules/CMS/Pages/API/request/site/site"
import {checkLogin} from "../../../@WUM/templates/default/Auth/service/AuthService";

/**
 * @typedef Product Init
 * @returns noting
 * @author IOTA IT
 * @version 0.0.1
 */
export const PageRequestInit = () => {
    return {
        type: PageActionType.PAGE_INIT,
    }
}
/**
 * @typedef Product success method
 * @returns array
 * @param data : any
 * @type any
 * @author IOTA IT
 * @version 0.0.1
 */
export const PageRequestSuccess = (data: any) => {
    return {
        type: PageActionType.PAGE_SUCCESS,
        data: data,
    }
}
/**
 * @typedef Product failed method
 * @returns object
 * @param error
 * @author IOTA IT
 * @version 0.0.1
 */

export const PageRequestFail = (error: any) => {
    return {
        type: PageActionType.PAGE_FAIL,
        error: error,
    }
}

/**
 * @typedef DataAction
 * @dispatch error
 * @dispatch data
 * @author IOTA IT
 * @version 0.0.1
 */

// get all post pages

export const getPostPages = (limit: any, searchValue: string) => {
    return (dispatch: Dispatch) => {
        dispatch(PageRequestInit())
        site.getPosts(limit, searchValue)
            .then((data) => {
                checkLogin(data)
                dispatch(PageRequestSuccess(data))
            })
            .catch((err) => {
                dispatch(PageRequestFail(err))
            })
    }
}

// delete post by id

export const PageDeleteSuccess = (data: any) => {
    return {
        type: PageActionType.PAGE_DELETE_SUCCESS,
        data: data,
    }
}

export const deletePagesByID = (id: string) => {
    return (dispatch: Dispatch) => {
        dispatch(PageRequestInit())
        site.deletePost(id)
            .then((data) => {
                checkLogin(data)
                dispatch(PageDeleteSuccess(true))
            })
            .catch((err) => {
                dispatch(PageRequestFail(err))
            })
    }
}

// single page details

export const SinglePageSuccess = (data: any) => {
    return {
        type: PageActionType.PAGE_SINGLE_DATA,
        data: data,
    }
}

export const singlePageDetailsID = (id: string) => {
    return (dispatch: Dispatch) => {
        dispatch(PageRequestInit())
        site.getAPost(id)
            .then((data) => {
                checkLogin(data)
                dispatch(SinglePageSuccess(data))
            })
            .catch((err) => {
                dispatch(PageRequestFail(err))
            })
    }
}

// data clear after delete pages from redux

export const ClearSuccess = () => {
    return {
        type: PageActionType.PAGE_CLEAR_DATA,
    }
}

export const clearPageData = () => {
    return (dispatch: Dispatch) => {
        dispatch(ClearSuccess());
    };
};
