import React, {useState} from 'react';
import {SpinnerJSX} from '../../../../../@WUM/core/component/const/Spinner';
import configuration from './configuration/Page/overview';
import {useNavigate, useParams} from 'react-router-dom';
import {removeUseLocalStorage} from "../../../../../@WUM/core/component/const/useLocalStoreage";
import TableMaker from "../../../../../@WUM/core/tablemaker";
import {useSelector} from "react-redux";
import SearchJSX from "../../../../../@WUM/core/component/const/SearchJSX";
import {Responseformular, ResponseformularDelete} from "../../API/response/formular/formular";

/**
 * @typedef CustomizerOverviewProps
 * @prop {any} value
 */

interface CustomizerOverviewProps {
  value: any;
}

/**
 * Give View Overview for Content Blog
 * @memberOf blog
 * @prop page
 * @prop deleteID
 * @author Marcel Ugurcu
 * @version 0.0.1
 */

const FormularOverview = (props: CustomizerOverviewProps) => {
  const Store = useSelector((state: any) => state)
  const {page,deleteID} = useParams<{page: string,deleteID: string}>();
  removeUseLocalStorage('seo_title');
  removeUseLocalStorage('seo_description');

  const history = useNavigate();
  /**
   * Neuladen der Seite
   */
  const routeChange = (paths:string) => {
    let path = paths;
    history(path);
  };
  if(deleteID) {
    ResponseformularDelete(deleteID);
    routeChange('/customizer/overview/1')
  }

  let limit: any;
  if (typeof page === "string") {
    limit = (parseFloat(page) * 10) - 10;
  } else {
    limit = 0;
  }
  const namespace_search = "formular_searchValue";
  const [searchValue, setSearchValue] = useState((localStorage.getItem(namespace_search) !== null ? localStorage.getItem(namespace_search) : ''));
  const destination = 'formular'
  const response = Responseformular(limit,searchValue);
  const Configuration = configuration('',response,page,Store);

  const SearchConfig = [{
    namespace:namespace_search,
    destination: destination,
    input:[
      {id:'search_name',translation: 'common.search_for_title',param: 'name',method: 'like'},
    ],
    button: ['common.search','common.del.search','form.button'],
  }]

  if (response.length > 0) {
    return (
        <>
          <div className="white-pad-15">
            <SearchJSX  setSearchValue={setSearchValue} Store={Store} SearchConfig={SearchConfig} />

            <TableMaker Configuration={Configuration}/>
          </div>

        </>

    )
  } else {
    localStorage.setItem('searchEnterInput','');
    return (
        <><SpinnerJSX messageId={'common.loading'} Store={Store} /></>
    );
  }

};


export default FormularOverview;



