
import {MonitoringMenuConfig, MonitoringPagesConfig} from "./monitoring";

export const monitoringPagesConfig = (value: any) => {

    return [
        ...MonitoringPagesConfig(value)
    ];
}

export const monitoringMenuConfig = (value:any) => {

    return [
        {
            header: 'Monitoring',
            icon: 'monitor_heart',
            roles: ['superadmin'],
            menuitem: [
                ...MonitoringMenuConfig(value)
            ]
        }
    ];
}

export const monitoring = () => [
    {
        title: 'Dashboard',
        href: '/Monitoring/overview/1',
        undertitle:'Monitoring',
        icon: 'dashboard',
        text: 'monitoring.dashboard.description',
        roles: ['superadmin']
    },
    {
        title: 'Monitoring',
        href: '/Monitoring/API/overview/1',
        undertitle:'Monitoring',
        icon: 'api',
        text: 'monitoring.monitoring.description',
        roles: ['superadmin']
    }
]
