import React, {useState} from "react";
import ApiURL from "../../../../../modules/ticketsystem/modules/components/ApiURL";
import getToken from "../../../../../modules/ticketsystem/modules/components/Token";
import {checkLogin} from "../../../../templates/default/Auth/service/AuthService";
import {SpinnerJSX} from "../../../../core/component/const/Spinner";
import {destroyArticle} from "../faq/faq";


const initialState = {
    id:0,
    Store:null
}

interface interfaceArticle {
    id:any,
    Store?:any,
}

const Article = (props:interfaceArticle=initialState) => {

    const SingleArticle = () => {
        const [Article, setArticle] = useState([]); // Array instead of object

        React.useEffect(() => {
            let root = ApiURL();
            const fetchData = async () => {
                const result = await fetch(root + 'Helpdesk/faq/show/'+props.id+'/ ', {
                    method: "GET",
                    headers: {
                        'Authorization': 'Bearer ' + getToken()
                    }
                })
                    .then(response => response.json())
                    .then(response => {
                        checkLogin(response);
                        localStorage.setItem('articleHelpDesk',JSON.stringify(response))
                        return response;
                    })
                    .catch(err => console.error(err));

                setArticle(result)
            }

            fetchData();
        }, [])

        return Article;
    }
    SingleArticle();

    if(localStorage.getItem('articleHelpDesk') !== null && JSON.parse(localStorage.getItem('articleHelpDesk') as string)?.content !== null) {
        return (
            <>
                {buildArticle(JSON.parse(localStorage.getItem('articleHelpDesk') as string))}

            </>
        )
    } else {
        return(<><div className="help-desk__article entered"><SpinnerJSX messageId={'common.loading'} Store={props.Store} /></div></>);
    }


}

export default Article;


const buildArticle = (response:any) => {

        return (
            <>
                <button className="help-desk__back" onClick={() => destroyArticle()}>
                    <span className="material-icons text-white">
                        arrow_back_ios
                    </span>
                </button>
                <div className="help-desk__article entered" dangerouslySetInnerHTML={{__html: response.content}}/>

            </>
        )

}
