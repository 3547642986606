import React, {useState
   } from 'react';
import {SpinnerJSX} from '../../../../@WUM/core/component/const/Spinner';
import {useNavigate,
  useParams
} from 'react-router-dom';
import ContentSwitch from '../../../../@WUM/core/component/const/ContentSwitch';
import {ResponseallTaxLimit, ResponseTaxDelete} from '../../../shop/products/API/response/product/Product';
import IntlMessage from '../../../../@WUM/core/component/const/IntlMessage';
import {useSelector} from "react-redux";
import {ResponseRewrite, ResponseRewriteDelete} from "../API/response/rewrite";
import PielersTable from "../../../../@WUM/core/tablemaker";
import PaginationJSX from "../../../../@WUM/core/form-serilizer/elements/pagination";
import {handleSucces} from "../../../../@WUM/core/component/const/HandleSucces";
import {notify_save} from "../../../../@WUM/core/component/const/notification.service";



const RewriteDeleted = (value:any) => {
    const Store = useSelector((state: any) => state)
    const {page, deleteID} = useParams<{ page: string, deleteID: string }>();
    ResponseRewriteDelete(deleteID);
    let landingpages = '/rewrite/overview/1'
    notify_save(Store);
    setTimeout(() => window.location.href = landingpages, 3000);
    return (<></>);

}
export default RewriteDeleted;



