import React from 'react';
import PielersForm from '../../index'
import {base, basesettings} from './base';
import IntlMessage, {IntlMessageValue} from '../../../component/const/IntlMessage';
import SunEditorBig from '../../../component/const/SunEditorBig';
import CopyElements from "../../../component/const/CopyElements";
import Col from 'react-bootstrap/Col';
import Row from "react-bootstrap/Row";
import WebBuilderElementsHead, {
  Settingsprepare,
  showEditPreview
} from "../../../component/const/WebBuilderElementsHead";
import Box from '@mui/material/Box';
import {clearCol} from "../../../component/core/elements/content";

function textWebBuilder(data: any, element:any,i:any, newElement:any = false,newelementIndex:any = 0,modalShow: boolean,
              setModalShow: (value: (((prevState: boolean) => boolean) | boolean)) => void, modalData: any[],
              setModalData: (value: (((prevState: any[]) => any[]) | any[])) => void,
              content:any = [],setElements:any = [],Store:any) {

  const index = (newElement === false ? i : newelementIndex)

  const configuration = [
    SunEditorBig((newElement === false && element.translation !== undefined  ? element.translation.de : ''),'text',newElement,data,
        'elements[' + index + '][translation][text]',''),
    {
      'formType': 'input',
      'name': 'elements[' + index + '][template]',
      'type': 'hidden',
      'value': 'Elements/text.html',
    },
    ];

  let newConfiguration = configuration.concat(base(element,i,newElement,newelementIndex));

  let Status;
  if(element.visible === 1) {
    Status = 'elementActive';
  } if(element.visible === 0){
    Status = 'elementDeactive';
  } else if(newElement === true) {
    Status = 'elementActive';
  }

  return (
    <>
    <Box id={'text'+index}
         style={{border:'4px solid transparent'}}
         data-appendcontainer={(newElement === false && element.appendto !== null && element.appendto !== undefined && element.appendto !== '' ?  element.appendto : 'false')} >
    {WebBuilderElementsHead(<IntlMessage Store={Store} messageId="module.text" />,'text',(newElement === false && element.translation !== undefined  ? element.translation.de.text : ''),Status,index,'elementButton',<IntlMessage Store={Store} messageId="module.text" />,
            modalShow,setModalShow,modalData, setModalData,newElement,element,content,setElements,Store,'','',element.id)}
      <div id={'preview-container_text'+index}  className={'preview-container '+Status} style={{display: (newElement === false ? 'block' : 'none')}} >
        {(newElement === false ?
            <span dangerouslySetInnerHTML={{ __html: element.previews }} />
            : <></>)}
      </div>
        <Row  id={'mein_text'+index} className={'boxShadowCard card ElementBorder'}   style={{width: '100%',margin:'0px 0px 10px 0px',border: '0',boxShadow: 'unset'}}>

          <Col  className={' element-body'}>

            <div id={'edit_text'+index} className={'boxShadowCard card '+Status} style={{display:(newElement === false ? 'none' : 'block'),padding:'15px',width: '100%',marginBottom: '20px'}}>
              <div id={'Teaser'}>Text</div>
              <Col style={{marginBottom: '10px',marginTop:'10px'}}><IntlMessage Store={Store} messageId="module.text" /><br /></Col>
            {PielersForm(newConfiguration)}
            {/*<Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3" key={'tabindex'}>
              <Tab eventKey="home" title={<IntlMessage Store={Store} messageId="common.general" />} key={'allgemein'} id={'allgemein'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
                {PielersForm(newConfiguration)}
              </Tab>
              <Tab eventKey="Einstellungen" title={<IntlMessage Store={Store} messageId="settings.settings.header" locale={'de'} />} key={'Einstellungen'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
                {PielersForm(basesettings(element,i,newElement,newelementIndex,Store))}
              </Tab>
            </Tabs>*/}

            </div>
          </Col>
          <div id={'settingstext'+index+'text'} className={'settingsWebBuilderModul '} style={{display:'none'}}>

            {PielersForm(basesettings(element,i,newElement,newelementIndex,Store))}
          </div>
          {(newElement === false ? CopyElements(element.id,<IntlMessage messageId="module.text"  Store={Store}/>,Store) : <></>)}

        </Row>
        </Box>
    </>
  )


}

export default textWebBuilder;
