
export interface config {
  discr: string;
  merchant_id?: any;
  email: string;
  first_name: string;
  last_name: string;
  locale_code: string;
  password: string;
  username: string;
  is_active: boolean;
  controllpw: string;
}

export function output(Data: any) {
  let back = {
    last_name: Data.last_name,
    first_name: Data.first_name,
    email: Data.email,
    discr: Data.discr,
    username: Data.username,
    merchant_id: Data.merchant_id,
    password: Data.password,
    is_active: Data.is_active,
    locale_code: Data.locale_code,
    controllpw: Data.controllpw,
  }

  return back;
}