import {Rate} from '../../../../shop/products/API/request/Product/TaxApiDetail.interface';
import {Data} from "../../API/request/customTypesAPI.interface.";

export interface config {
  id: number;
  data: any;
}

export function output(Data: any) {
  let back = {
    id: Data.id,
    data: Data.data


  }

  return back;
}
