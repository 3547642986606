import React from 'react';
import Login from "./Login";
import Logout from "./Logout";
import Signin from "./Signin";



export const AuthPagesConfig = (value:any) =>{

  return [
    {
      path: '/login',
      elements: <Login value={value}/>,
      roles: ['superadmin','admin','author','contentcreator','operator','merchant','merchant_ma']
    },
    {
      path: '/logout',
      elements: <Logout value={value}/>,
      roles: ['superadmin','admin','author','contentcreator','operator','merchant','merchant_ma']
    },
    {
      path: '/Signin',
      elements: <Signin value={value}/>,
      roles: ['superadmin','admin','author','contentcreator','operator','merchant','merchant_ma']
    }
  ];
}

export const AuthMenuConfig = (value:any) =>{
  return [
    {
      name: "Beiträge",
      route: '/Blog',
      icon: 'post_add',
      roles: ['superadmin','admin','author','contentcreator','operator','merchant','merchant_ma']
    },
  ];
}
