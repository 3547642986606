import CreateMatomoIframe from "../../core/CreateMatomoIframe";
import WidgetURLMapping from "../../core/WidgetURLMapping";
import React from "react";

const Performance = () => {
    return(
        <>
            <CreateMatomoIframe
                headline={'Veränderung der Seitenleistungsmetriken'}
                width={"100%"}
                height={"600"}
                url={WidgetURLMapping({name:'Change in page performance metrics'})}
                scrolling={"yes"}
                id={'pc1'}
            />
           {/* <CreateMatomoIframe
                headline={'Leistungsübersicht'}
                width={"100%"}
                height={"600"}
                url={WidgetURLMapping({name:'Performance overview'})}
                scrolling={"yes"}
                id={'pc2'}
            />*/}
            <CreateMatomoIframe
                headline={'Seiten URL'}
                width={"100%"}
                height={"600"}
                url={WidgetURLMapping({name:'sites'})}
                scrolling={"yes"}
                id={'pc3'}
            />
            <CreateMatomoIframe
                headline={'Seitentitel'}
                width={"100%"}
                height={"600"}
                url={WidgetURLMapping({name:'page title'})}
                scrolling={"yes"}
                id={'pc4'}
            />
        </>
    )
}

export default Performance;