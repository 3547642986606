import React from 'react';
function sitemap() {

    return (
      <>
        <hr />
      </>
    );

}

export default sitemap;
