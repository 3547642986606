import Col from "react-bootstrap/Col";
import CreateMatomoIframe from "../../core/CreateMatomoIframe";
import WidgetURLMapping from "../../core/WidgetURLMapping";
import {Row} from "react-bootstrap";
import React from "react";


const Time = (props:{Store:any}) => {
    return(
        <>
            <Row>
                <Col>
                    <CreateMatomoIframe
                        headline={'Besuche nach lokaler Zeit'}
                        width={"100%"}
                        height={"350"}
                        url={WidgetURLMapping({name:'Visits according to local time'})}
                        scrolling={"yes"}
                        id={'time1'}
                        Store={props.Store}
                    />
                </Col>
                <Col>
                    <CreateMatomoIframe
                        headline={'Besuche nach Server-Zeit'}
                        width={"100%"}
                        height={"350"}
                        url={WidgetURLMapping({name:'Visits by server time'})}
                        scrolling={"yes"}
                        id={'time2'}
                        Store={props.Store}
                    />
                </Col>
            </Row>
        </>
    )

}

export default Time;
