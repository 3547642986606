import Badge from 'react-bootstrap/Badge';
import React from 'react';
import LabelProps from '../../Interface/label.interface';


const Label = (props:LabelProps) => {

  return (
    <>
      <Badge pill={true} className={'m-1'} bg={props.bg}>{props.text}</Badge>
    </>

  );

};

export default Label;