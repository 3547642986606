import React from 'react';
import PielersForm from '../../index'
import {base, basesettings} from './base';
import IntlMessage, {IntlMessageValue} from '../../../component/const/IntlMessage';
import SunEditorBig from '../../../component/const/SunEditorBig';
import CopyElements from "../../../component/const/CopyElements";
import Col from 'react-bootstrap/Col';
import Row from "react-bootstrap/Row";
import WebBuilderElementsHead from "../../../component/const/WebBuilderElementsHead";
import Box from '@mui/material/Box';

function text_imageWebBuilder(data: any, element: any, i: any, newElement: any = false, newelementIndex: any = 0, modalShow: boolean,
                    setModalShow: (value: (((prevState: boolean) => boolean) | boolean)) => void, modalData: any[],
                    setModalData: (value: (((prevState: any[]) => any[]) | any[])) => void,
                    content:any = [],setElements:any = [],Store:any,galerie?:any,
    galerieItems?:any) {

  const index = (newElement === false ? i : newelementIndex)

  const configuration = [
    SunEditorBig(element.translation,'text',newElement,data,'elements[' + index + '][translation][text]',IntlMessageValue('de','common.edit_text',Store)), {
      'formType': 'select',
      'label': <IntlMessage Store={Store} messageId="common.target" />,
      'name': 'elements['+index+'][translation][image_position]',
      'selected': (newElement === false ? (element.translation !== undefined ? element.translation.image_position: '' )  : ''),
      'options': [
        {
          'label': IntlMessageValue("de","common.left",Store),
          'value': 'links',
        },
        {
          'label': IntlMessageValue("de","common.right",Store),
          'value': 'rechts',
        },
      ]

    },
    {
      'formType': 'multiply',
      'cols': [
        {
          'formType': 'image',
          'altLink': true,
          'id': 'text_image_'+index,
          'path': (newElement === false ? (element.media !== undefined && element.media !== null ? element.media.cdn_url : ''): ''),
          'width': '44',
          'height': '44',
          'class': 'elementsIMg',
          'size': {
            'xl': 2,
            'md': 2,
            'sm': 2,
          }
        },
        {
          'formType': 'formFile',
          'onclick': true,
          'id': 'image_titleFormField'+index,
          'onclick_context': (newElement === false ? element.context : 'marketplace_api'),
          'Gallery': galerie,
          'galerieItems': galerieItems,
          'expandfile':index+'TextImage',
          'append': 'elements[' + index + '][translation][image_id]',
          'src': 'text_image_'+index,
          'experiment': true,
          'size': {
            'xl': 10,
            'md': 10,
            'sm': 10,
          }
        },
      ],
    },{
      'formType': 'input',
      'name': 'elements[' + index + '][translation][image_id]',
      'id': 'elements[' + index + '][translation][image_id]',
      'visible': true,
      'label': 'imageID',
      'type': 'text',
      'placeholder': 'imageID',
      'value': (newElement === false ? (element.translation !== undefined ? element.translation.image_id : '') : ''),
    },]

  let newConfiguration = configuration.concat(base(element,i,newElement,newelementIndex));
  let Status;
  if(element.visible === 1) {
    Status = 'elementActive';
  } if(element.visible === 0){
    Status = 'elementDeactive';
  } else if(newElement === true) {
    Status = 'elementActive';
  }
  return (
    <>
    <Box id={'text_image'+index} data-appendcontainer={(newElement === false && element.appendto !== null && element.appendto !== undefined && element.appendto !== '' ?  element.appendto : 'false')} >
    {WebBuilderElementsHead(<IntlMessage Store={Store} messageId="module.text_with_image2s" />,'text_image',(newElement === false && element.translation !== undefined  ? element.translation.de.text : ''),Status,index,'elementButton',<IntlMessage Store={Store} messageId="module.text_with_image2" />,
            modalShow,setModalShow,modalData, setModalData,newElement,element,content,setElements,Store,'','',element.id)}
      <div id={'preview-container_text_image' + index} className={'preview-container '+Status} style={{display: (newElement === false ? 'block' : 'none')}}>
        {(newElement === false ?
            <span dangerouslySetInnerHTML={{ __html: element.previews }} />
            : <></>)}
      </div>
       <Row id={'mein_text_image'+index} className={'boxShadowCard card ElementBorder'}   style={{ width: '100%',margin:'0px 0px 10px 0px',border: '0',boxShadow: 'unset' }}>
           <Col  className={'element-body'}>
             <div id={'edit_module_text_image'+index} className={'boxShadowCard card '+Status} style={{display:(newElement === false ? 'none' : 'block'),padding:'15px',width: '100%',marginBottom: '20px'}}>
               <div id={'Teaser'}>text_image</div>
               <Col style={{marginBottom: '10px'}}><IntlMessage Store={Store} messageId="module.text_with_image2s" /></Col>
               {PielersForm(newConfiguration)}

             </div>

          </Col>
        <div id={'settingstext'+index+'text_image'} className={'settingsWebBuilderModul '} style={{display:'none'}}>

          {PielersForm(basesettings(element,i,newElement,newelementIndex,Store))}
        </div>
          {(newElement === false ? CopyElements(element.id,<IntlMessage messageId="module.text_with_image2"  Store={Store}/>,Store) : <></>)}

        </Row>
        </Box>
    </>
  )


}

export default text_imageWebBuilder;
