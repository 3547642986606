import CreateMatomoIframe from "../../core/CreateMatomoIframe";
import WidgetURLMapping from "../../core/WidgetURLMapping";
import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";


const Events = () =>{
    return(
        <>
            <Tabs defaultActiveKey="event_categories" id="uncontrolled-tab-example" className="mb-3 tabMatomo" key={'tabindex'}>
                <Tab eventKey="event_categories" title={"Ereigniskategorien"} key={'event_categories'} id={'event_categories'} className={"tabsMatomo"}>
                    <CreateMatomoIframe
                        headline={'Ereigniskategorien'}
                        width={"100%"}
                        height={"600"}
                        url={WidgetURLMapping({name:'event categories'})}
                        scrolling={"yes"}
                        id={'events1'}
                    />
                </Tab>
                <Tab eventKey="event_actions" title={"Ereignisaktionen"} key={'event_actions'} id={'event_actions'} className={"tabsMatomo"}>
                    <CreateMatomoIframe
                        headline={'Ereignisaktionen'}
                        width={"100%"}
                        height={"600"}
                        url={WidgetURLMapping({name:'event actions'})}
                        scrolling={"yes"}
                        id={'events2'}
                    />
                </Tab>
                <Tab eventKey="event_names" title={"Ereignisnamen"} key={'event_names'} id={'event_names'} className={"tabsMatomo"}>
                    <CreateMatomoIframe
                        headline={'Ereignisnamen'}
                        width={"100%"}
                        height={"600"}
                        url={WidgetURLMapping({name:'event names'})}
                        scrolling={"yes"}
                        id={'events3'}
                    />
                </Tab>
            </Tabs>

        </>
    )
}

export default Events;