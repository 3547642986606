import React from 'react';
import PielersForm from '../../index'
import {Tabs,Button} from 'react-bootstrap';
import {base, basesettings} from './base';
import IntlMessage, {IntlMessageValue} from '../../../component/const/IntlMessage';
import Tab from "react-bootstrap/Tab";
import ReactDOM from "react-dom";
import SunEditorBig from "../../../component/const/SunEditorBig";
import CopyElements from "../../../component/const/CopyElements";
import Col from 'react-bootstrap/Col';
import Row from "react-bootstrap/Row";
import WebBuilderElementsHead, {
  Settingsprepare,
  showEditPreview
} from "../../../component/const/WebBuilderElementsHead";
import Box from '@mui/material/Box';
import {clearCol} from "../../../component/core/elements/content";

function accordionWebBuilder(data: any, element:any,i:any, newElement:any = false,newelementIndex:any = 0,modalShow: boolean,
              setModalShow: (value: (((prevState: boolean) => boolean) | boolean)) => void, modalData: any[],
              setModalData: (value: (((prevState: any[]) => any[]) | any[])) => void,
              content:any = [],setElements:any = [],Store:any) {

  const index = (newElement === false ? i : newelementIndex)

  const configuration = [
    {
      'formType': 'input',
      'label': 'Accordion Name',
      'name': 'elements[' + index + '][translation][name]',
      'type': 'text',
      'value': (newElement === false ? (element.translation !== undefined ? element.translation.de.name : '') : ''),
    },
    {
      'formType': 'input',
      'name': 'elements[' + index + '][template]',
      'type': 'hidden',
      'value': 'Elements/accordion.html',
    },
    ];

  let newConfiguration = configuration;

  let Status;
  if(element.visible === 1) {
    Status = 'elementActive';
  } if(element.visible === 0){
    Status = 'elementDeactive';
  } else if(newElement === true) {
    Status = 'elementActive';
  }

  function JSONNewAppend() {

    let JsonP = JSON.parse((document.getElementById('json' + index) as HTMLInputElement).value);

    console.log(JsonP.item);

    let newPart = {
      'headline': '',
      'content':'',
      'id': null,
      'parent': 0,
      'locale': 'de',
    };

    let tmpItem = [];

    for(let x=0;x<JsonP.item.length;x++) {
      tmpItem.push(JsonP.item[x])
    }
    tmpItem.push(newPart);

    JsonP.item = tmpItem;

    (document.getElementById('json' + index) as HTMLInputElement).value = JSON.stringify(JsonP);

    let conf = [
      {
        'formType': 'multiplyinRow',
        'singelCol':true,
        'singleNew':true,
        'RowId':'accordion_'+(JsonP.item.length -1)+'_'+index,
        'cols': [{ 'formType': 'input',
          'label': <IntlMessage Store={Store} messageId="common.headline" />,
          'type': 'text',
          'placeholder': IntlMessageValue("de","common.headline",Store),
          'value':  '',
          'jsonID':'json' + index,
          'id':'headline'+(JsonP.item.length -1)+'var_' + index,
          'appendJson': (JsonP.item.length -1),
          'appendJsonField': 'headline',
        },
          SunEditorBig('','content',true,data,
              'content'+(JsonP.item.length -1)+'var_' + index,IntlMessageValue('de', 'common.edit_text',Store),'json' + index,'content'+(JsonP.item.length -1)+'var_' + index,(JsonP.item.length -1),'content'),
          {
            'formType': 'button',
            'label': 'Item entfernen',
            'variants': 'danger',
            'removeID':(JsonP.item.length -1),
            'removeIndex':index,
            'namespace':'accordion_',
            'jsonID':'json' + index,
            'size': {
              'xl': 2,
              'md': 2,
              'sm': 2,
            }
          },
        ]
      }
    ]

    console.log(JsonP);
    let newElement = <>{PielersForm(conf)}</>;
    let temp = document.createElement('div');
    temp.classList.add("col-md-4")
    temp.classList.add("mb-3")
    temp.id = 'accordion_'+(JsonP.item.length -1)+'_'+index
    ReactDOM.render(newElement, temp);
    let ele =  document.getElementById('accordionRow'+index) as HTMLDivElement;
    if(ele !== null) {
      ele.appendChild(temp)
    }
  }

  let accordion;
  if(newElement === false) {
    accordion = buildAccordion((newElement === false ? element.translation.de : {}), (newElement === false ? element.translation.de.name : ''), (newElement === false ? 'accordion_' + element.div_id + '_' + element.id : 'accordion_0_0'));
  }

  return (
    <>
        <Box id={'accordion'+index}
             style={{border:'4px solid transparent'}}
             data-appendcontainer={(newElement === false && element.appendto !== null && element.appendto !== undefined && element.appendto !== '' ?  element.appendto : 'false')}>
        {WebBuilderElementsHead(<IntlMessage Store={Store} messageId="module.accordion" />,'accordion',(newElement === false ? (element.translation !== undefined ? element.translation.de.name : '') : ''),Status,index,'elementButton',<IntlMessage Store={Store} messageId="module.accordion" />,
            modalShow,setModalShow,modalData, setModalData,newElement,element,content,setElements,Store,'','',element.id)}
          <div id={'preview-container_accordion'+index} className={'preview-container '+Status} style={{display: (newElement === false ? 'block' : 'none')}}>
            <div id={"accordion_"+(element.div_id === null ? '' : element.div.id)+"_"+element.id }
                 className={ clearCol(element.class) }>
            {accordion}
            </div>
          </div>
      <Row  id={'mein_accordion'+index} className={'boxShadowCard card ElementBorder'}   style={{width: '100%',margin:'0px 0px 10px 0px',border: '0',boxShadow: 'unset'}}>



        <Col  className={' element-body'}>
          {PielersForm(base(element,i,newElement,newelementIndex))}

          <div id={'edit_accordion'+index} className={'boxShadowCard card '+Status} style={{display:(newElement === false ? 'none' : 'block'),padding:'15px',width: '100%',marginBottom: '20px'}}>
            <Col style={{marginBottom: '10px',marginTop:'10px'}}><IntlMessage Store={Store} messageId="module.accordion" /></Col>
            <div id={'Teaser'}>carouselItem_end</div>
          <Button variant={'success'} onClick={()=>JSONNewAppend()} className={'mb-3'} >Neuen Item hinzufügen</Button><br />
          <Row id={"accordionRow"+index}>{buildItem(element,index,newElement,data,Store)}</Row>
          {buildJson(element,index,newElement,data,Store)}

          </div>
        </Col>
        <div id={'settingstext'+index+'accordion'} className={'settingsWebBuilderModul '} style={{display:'none'}}>
              {PielersForm(basesettings(element,i,newElement,newelementIndex,Store))}
              {PielersForm(newConfiguration)}
        </div>
        {(newElement === false ? CopyElements(element.id,<IntlMessage messageId="module.accordion"  Store={Store}/>,Store) : <></>)}

      </Row>
      </Box>

    </>
  )
}

export default accordionWebBuilder;

const buildJson = (element:any,index:any,newElement:any,data:any,Store:any ) => {

  let Items = [];
  if (newElement === false) {
    let elementItem = element.translation.de.item;
    let defaultJson = {"item": elementItem}
  Items.push({
    'formType': 'textarea',
    'label': 'json',
    'nochar':'no',
    'id':'json' + index,
    'name': 'elements[' + index + '][translation][ItemString]',
    'type': 'textarea',
    'typeFormat': 'json',
    'placeholder': "lang[common.insert_headline]",
    'value': defaultJson,
  });

  let newConfiguration = Items;

  return PielersForm(newConfiguration)


} else {
  Items.push({
    'formType': 'input',
    'label': 'json',
    'id':'json' + index,
    'name': 'elements[' + index + '][translation][ItemString]',
    'type': 'hidden',
    'placeholder': "lang[common.insert_headline]",
    'value': '{"item":[]}',
  });

  let newConfiguration = Items;

  return PielersForm(newConfiguration)
}
}

const buildItem = (element:any,index:any,newElement:any,data:any,Store:any ) => {

  let Items = [];
  if (newElement === false) {
    let elementItem = element.translation.de.item;

    //let defaultJson = {"item": elementItem}




    if (elementItem !== undefined) {

      for (let x = 0; x < elementItem.length; x++) {
        Items.push(
            {
              'formType': 'multiplyinRow',
              'RowId':'accordion_'+x+'_'+index,
              'singelCol':true,
              'class':'mb-3',
              'cols': [
                 { 'formType': 'input',
                'label': <IntlMessage Store={Store} messageId="common.headline" />,
                'type': 'text',
                'placeholder': IntlMessageValue("de","common.headline",Store),
                'jsonID':'json' + index,
                'id':'headline'+x+'var_' + index,
                'appendJson': x,
                'appendJsonField': 'headline',
                'value': (newElement === false ? elementItem[x].headline : ''),
              },
                SunEditorBig((newElement === false ? elementItem[x] : ''),'content',newElement,data,
                    'content'+x+'var_' + index,IntlMessageValue('de', 'common.edit_text',Store),'json' + index,'content'+x+'var_' + index,x,'content'),
                {
                  'formType': 'button',
                  'label': 'Item entfernen',
                  'variants': 'danger',
                  'removeID':x,
                  'removeIndex':index,
                  'namespace':'accordion_',
                  'jsonID':'json' + index,
                  'size': {
                    'xl': 2,
                    'md': 2,
                    'sm': 2,
                  }
                },
              ]
            }
        )

      }
    }

      let newConfiguration = Items;

      return PielersForm(newConfiguration)


  } else {
   return;
  }

}


const buildAccordion = (slidesItems:any, name:any, Appendid:any) => {



  let slides = slidesItems;

  let row = [];

  let body = [];



  for (let x = 0; x < slides.item.length; x++) {

    let namespace = 'flush-collapse_'+Appendid+'_' + name + '_'+x;

    row.push(<><div id={"accordion-item_"+Appendid+"_" + name + "_"+x+""} className="accordion-item">
        <h2 id={"accordion-header_"+Appendid+"_" + name + "_"+x+""} className="accordion-header">
        <button onClick={()=> openAccordion(namespace,"flush-collapse_"+Appendid+"_" + name + "_",slides.item.length)} id={"accordion-button_"+Appendid+"_" + name + "_"+x+""} className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
        data-bs-target={"#flush-collapse_"+Appendid+"_" + name + "_"+x+""} aria-expanded="false" aria-controls={"flush-collapse_"+Appendid+"_" + name + "_"+x+""} >
          {slides.item[x].headline}
        </button>
        </h2>
        <div style={{display:'none'}} id={"flush-collapse_"+Appendid+"_" + name + "_"+x+""} className="accordion-collapse collapse" aria-labelledby={"accordion-header_"+Appendid+"_" + name + "_"+x+""} data-bs-parent={"#accordionFlush"+Appendid+"_" + name + ""}>
        <div id={"accordion-body_"+Appendid+"_" + name + "_"+x+""} className="accordion-body" dangerouslySetInnerHTML={{__html: slides.item[x].content.replaceAll('\\"','"')}}/>
        </div></div></>);
  }

  body.push(<><div className="accordion accordion-flush" id={"accordionFlush"+Appendid+"_" + name + ""}>{row}</div></>);



  return body;
}


const openAccordion = (name:any,namespace:any,lenght:any) => {

  for(let x=0;x<lenght;x++){
    if(name !== namespace+x) {
      let modal = document.getElementById(namespace + x) as HTMLDivElement
      modal.style.display = 'none'
    }
  }


  let modal = document.getElementById(name) as HTMLDivElement
  if (modal.style.display === 'none') {
    modal.style.display = 'block'
  } else {
    modal.style.display = 'none'
  }
}
