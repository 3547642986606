import React from 'react';
import {LanguageSwitcherValue} from "../../../core/components/LanguageSwitcher";
import ProductOverview from "./Pages/Productoverview";
import ProductDetail from "./Pages/ProductDetail";
import ProductNew from "./Pages/new";

export const ProductPagesConfig = (value: any) => {

  return [
      {
        path: '/Product/overview/:page',
        elements: <ProductOverview value={value} />,
          roles: ['superadmin','admin','operator','merchant','merchant_ma']
      },
      {
        path: '/Product/Detail/:id',
        elements: <ProductDetail value={value} />,
          roles: ['superadmin','admin','operator','merchant','merchant_ma']
      },
      {
        path: '/Product/Show/:id',
        elements: <ProductDetail value={value} />,
          roles: ['superadmin','admin','operator','merchant','merchant_ma']
      },
      {
        path: '/Product/Delete/:id',
        elements: <ProductDetail value={value} />,
          roles: ['superadmin','admin','operator','merchant','merchant_ma']
      },
      {
        path: '/Product/new/new',
        elements: <ProductNew value={value} />,
          roles: ['superadmin','admin','operator','merchant','merchant_ma']
      },
];
}

export const ProductMenuConfig = (value: any) => {
  return [
    {
      name: LanguageSwitcherValue("shop.product.header"),
      route: '/Product/overview/1',
      icon: 'shopping_bag',
        roles: ['superadmin','admin','operator','merchant','merchant_ma']
    }
  ]
}

export const product = () => {

    switch (process.env.REACT_APP_MODE_PLATTFORM) {
        case 'SHOP':
        case 'SHOPwithMatomo':
            return [
                {
                    title: 'shop.product.header',
                    href: '/Product/overview/1',
                    undertitle:'Shop',
                    icon: 'shopping_bag',
                    text: 'shop.produkte.tooltip.content',
                    roles: ['superadmin','admin','operator','merchant','merchant_ma']
                },
                {
                    title: 'shop.orders.header',
                    href: '/Orders/overview/1',
                    undertitle:'Shop',
                    icon: 'monetization_on',
                    text: 'shop.bestellungen.tooltip.content',
                    roles: ['superadmin','admin','operator','merchant','merchant_ma']
                },
                {
                    title: 'shop.promotion.header',
                    href: '/Promotion/overview/1',
                    undertitle:'Shop',
                    icon: 'redeem',
                    text: 'shop.gutscheine.tooltip.content',
                    roles: ['superadmin','admin','operator','merchant','merchant_ma']
                },
                {
                    title: 'Rechnungen',
                    href: '/Invoice/overview/1',
                    undertitle:'Shop',
                    icon: 'payments',
                    text: 'Rechnungen',
                    roles: ['superadmin','admin','operator','merchant','merchant_ma']
                },
                {
                    title: 'Profil',
                    href: '/merchant/Detail/'+process.env.REACT_APP_MERCHANT_PROFIL,
                    undertitle:'Shop',
                    icon: 'storefront',
                    text: 'shop.anbieter.tooltip.content',
                    roles: ['superadmin','admin','operator','merchant','merchant_ma']
                }
            ]
        case 'ProductCatalog':
        case 'ProductCatalogwithMatomo':
            return [
                {
                    title: 'shop.product.header',
                    href: '/Product/overview/1',
                    undertitle:'Shop',
                    icon: 'shopping_bag',
                    text: 'shop.produkte.tooltip.content',
                    roles: ['superadmin','admin','operator','merchant','merchant_ma']
                },
                {
                    title: 'Profil',
                    href: '/merchant/Detail/'+process.env.REACT_APP_MERCHANT_PROFIL,
                    undertitle:'Shop',
                    icon: 'storefront',
                    text: 'shop.anbieter.tooltip.content',
                    roles: ['superadmin','admin','operator','merchant','merchant_ma']
                }
            ]
    }
}
