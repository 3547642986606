import React from "react";
import IntlMessage, {IntlMessageValue} from "../../../component/const/IntlMessage";

export function baseForms(forms: any, i: any, newElement:any = false,newelementIndex:any = 0,Store:any): Array<any> {
  const index = (newElement === false ? i : newelementIndex)
  return [
    {
      'formType': 'input',
      'name': 'form[' + index + '][id]',
      'type': 'hidden',
      'value': (newElement === false ? forms.id : 0),
    },
    {
      'formType': 'input',
      'name': 'form[' + index + '][new]',
      'type': 'hidden',
      'value': (newElement === false ? false : true),
    },{
      'formType': 'input',
      'name': 'form[' + index + '][name]',
      'label': IntlMessageValue('de',"common.form.elements.element_name",Store),
      'legende':IntlMessageValue('de',"common.form.elements.element_name.legende",Store),
      'type': 'text',
      'value': (newElement === false ? forms.name : ''),
    },{
      'formType': 'input',
      'name': 'form[' + index + '][elementid]',
      'label': IntlMessageValue('de',"common.form.elements.element_id",Store),
      'legende':IntlMessageValue('de',"common.form.elements.element_id.legende",Store),
      'type': 'text',
      'value': (newElement === false ? forms.elementid : ''),
    },
    {
      'formType': 'input',
      'name': 'form[' + index + '][class]',
      'label': IntlMessageValue('de',"common.form.elements.element_class",Store),
      'legende':IntlMessageValue('de',"common.form.elements.element_class.legende",Store),
      'type': 'text',
      'value': (newElement === false ? forms.class : ''),
    },{
      'formType': 'input',
      'name': 'form[' + index + '][position]',
      'type': 'hidden',
      'value': (newElement === false ? forms.position : index),
    },{
      'formType': 'input',
      'name': 'form[' + index + '][formname]',
      'type': 'hidden',
      'value': (newElement === false ? window.location.pathname.split('/').pop() : window.location.pathname.split('/').pop()),
    },{
      'formType': 'select',
      'label': <IntlMessage Store={Store} messageId="common.status" />,
      'br': true,
      'name': 'form[' + index + '][visible]',
      'selected': (newElement === false ? forms.visible : 1),
      'options': [
        {
          'label': IntlMessageValue('de',"common.activated",Store),
          'value': '1'
        },
        {
          'label': IntlMessageValue('de',"common.deactivated",Store),
          'value': '0'
        }]

    },
    /*{
      'formType': 'select',
      'label': 'Innerhalb eines Div',
      'br': true,
      'hidden':true,
      'name': 'form[' + index + '][indiv]',
      'selected': (newElement === false ? forms.indiv : 0),
      'options': [
        {
          'label': IntlMessageValue('de',"common.deactivated",Store),
          'value': '0'
        },   {
          'label': IntlMessageValue('de',"common.activated",Store),
          'value': '1'
        }]

    },*/
  ];
}

export function baseBootstrapForms(forms: any, i: any, newElement:any = false,newelementIndex:any = 0,Store:any): Array<any> {
  const index = (newElement === false ? i : newelementIndex)
  return [
    {
      'formType': 'select',
      'label': IntlMessageValue('de',"common.form.elements.row_start",Store),
      'legende': IntlMessageValue('de',"common.form.elements.row_start.legende",Store),
      'br': true,
      'name': 'form[' + index + '][isRowStart]',
      'selected': (newElement === false ? forms.isRowStart : 0),
      'options': [
        {
          'label': IntlMessageValue('de',"common.deactivated",Store),
          'value': '0'
        },   {
          'label': IntlMessageValue('de',"common.activated",Store),
          'value': '1'
        }]

    },{
      'formType': 'select',
      'label': IntlMessageValue('de',"common.form.elements.col",Store),
      'legende': IntlMessageValue('de',"common.form.elements.col.legende",Store),
      'br': true,
      'name': 'form[' + index + '][isCol]',
      'selected': (newElement === false ? forms.isCol : 0),
      'options': [
        {
          'label': IntlMessageValue('de',"common.deactivated",Store),
          'value': '0'
        },   {
          'label': IntlMessageValue('de',"common.activated",Store),
          'value': '1'
        }]

    },{
      'formType': 'select',
      'label': IntlMessageValue('de',"common.form.elements.cols",Store),
      'legende': IntlMessageValue('de',"common.form.elements.cols.legende",Store),
      'br': true,
      'name': 'form[' + index + '][cols]',
      'selected': (newElement === false ? forms.cols : ''),
      'options': [
        {
          'label': '',
          'value': ''
        },
        {
          'label': 2,
          'value': 'col-2'
        },   {
          'label': 4,
          'value': 'col-4'
        },{
          'label': 6,
          'value': 'col-6'
        },   {
          'label': 8,
          'value': 'col-8'
        },{
          'label': 10,
          'value': 'col-10'
        },   {
          'label': 12,
          'value': 'col-12'
        }]

    },{
      'formType': 'select',
      'label': IntlMessageValue('de',"common.form.elements.row_end",Store),
      'legende': IntlMessageValue('de',"common.form.elements.row_end.legende",Store),
      'br': true,
      'name': 'form[' + index + '][isRowEnd]',
      'selected': (newElement === false ? forms.isRowEnd : 0),
      'options': [
        {
          'label': IntlMessageValue('de',"common.deactivated",Store),
          'value': '0'
        },   {
          'label': IntlMessageValue('de',"common.activated",Store),
          'value': '1'
        }]

    },

  ]
}
