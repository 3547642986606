import {requests} from '../../../../../../@WUM/core/RestFullApi/api';
import {memberApiType} from './memberApi.interface';


export const member = {
  getPosts: (limit:string,search:any): Promise<memberApiType[]> => requests.get(`User/All/${limit}/${search}`),
  getPostsRoles: (): Promise<memberApiType[]> => requests.get(`User/Roles/`),
  getPostsMerchant: (): Promise<memberApiType[]> => requests.get(`Merchants/fetch/all/`),
  getAPost: (id: string): Promise<memberApiType[]> => requests.get(`User/${id}/`),
  createPost: (post: memberApiType): Promise<memberApiType> =>
    requests.post('posts', post),
  updatePost: (post: any, id: number): Promise<memberApiType> =>
    requests.put(`User/${id}/`, post),
  deletePost: (id: any): Promise<void> => requests.delete(`User/${id}/`),
};



