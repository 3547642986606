
import {requests} from '../../../../../@WUM/core/RestFullApi/api';
import {allLogingApiType} from './allLogingApi.interface';


export const Loging = {
  getallLoging: (limit:any,searchValue:any): Promise<allLogingApiType[]> => requests.get(`Monitoring/${limit}/${searchValue}`),
  getallTaxonTree: (): Promise<allLogingApiType[]> => requests.get(`/api/TaxonTree/`),
  getAPost: (id: string): Promise<allLogingApiType[]> =>  requests.get(`SingleTaxon/${id}/`),
  updatePost: (post: any, id: number): Promise<allLogingApiType> =>
    requests.put(`SingleTaxon/${id}/`, post),
  updatePostNew: (post: any, id: number): Promise<allLogingApiType> =>
    requests.put(`SingleTaxon/${id}/new`, post),
};



