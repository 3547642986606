import React from 'react';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import {ResponseDetail
} from '../../../API/response/orders/orders';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {SpinnerJSX} from '../../../../../../@WUM/core/component/const/Spinner';
import Button from 'react-bootstrap/Button';
import {saveDataIndividualPut} from '../../../../../../@WUM/core/RestFullApi/ApiHook';
import {notify_saveProduct} from '../../../../../../@WUM/core/component/const/notification.service';
import {error} from '../../../../../../@WUM/core/component/const/error.services';
import IntlMessage from '../../../../../../@WUM/core/component/const/IntlMessage';
import Download from '../../../../../../@WUM/core/component/const/download';
import Paper from '@material-ui/core/Paper';
import Form from 'react-bootstrap/Form';
import {displayElement} from "../../../../../../@WUM/core/component/const/displayElement";
import {useSelector} from "react-redux";

const OrdersDetail = (value:any) => {

  const {id} = useParams<{id: string}>();
  const history = useNavigate();
  const response2= ResponseDetail(id);
  const [cancelState, setcancelState] = React.useState(false);
  const [cancelID, setCancelID] = React.useState(0);
  if(cancelState !== false && cancelID !== 0) {
    setcancelState(false);
    setCancelID(0);
  }
  const Store = useSelector((state: any) => state)

  const setter = (setcancelState:any,y:any,data:any,setCancelID:any) =>{
    let ID = data[0].OrderID;
    let message = (document.getElementById('message') as HTMLTextAreaElement).value
    if(message === '') {
      alert('Sie müssen eine Nachricht eingeben');
      return;
    }
    setCancelID(ID);
    setcancelState(true);
    saveDataIndividualPut('/Orders/Order/cancel/'+ID+'/',{message:message})
      .then((data:any) => {
        setCancelID(0);
        setcancelState(false);
        notify_saveProduct(Store);
        setTimeout(() => window.location.reload(), 3000);
      })
      .catch(e => {
        error(e);
      });
  }

  const sum = (currency: string, wert: number) => {
    let summe;
    summe = wert / 100;

    let ConvertSum = Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 2,
    }).format(summe);
    return ConvertSum;

  }

  const total = (unit: number, quantity: number, total: number, tax:boolean = false,now:any = false) => {

    if (now === true) {
      if(tax === false) {
        return sum('EUR', total);
      } else {
        let tax = total / 119 * 19;
        return sum('EUR', tax);
      }
    }

    let totalTMP = unit * quantity;

    if (totalTMP !== total) {
      if(tax === false) {
        return sum('EUR', totalTMP);
      } else {
        let tax = totalTMP / 119 * 19;
        return sum('EUR', tax);;
      }

    } else {
      if(tax === false) {
        return sum('EUR', total);
      } else {
        let tax = total / 119 * 19;
        return sum('EUR', tax);;
      }
    }

  }

  const tax = (sum: number) => {
    let tax = sum / 119 * 19;
    return tax;

  }

  const item = (data: any,merchant:any,y:any,ItemHolderbill:any,cancelState:any,setcancelState:any,setCancelID:any) => {

    let cancel;
    let cancelButton;
    if(data[0].Parent.state === 'cancelled') {
      cancel = (<><IntlMessage Store={Store} messageId="common.cancelled"/></>);
      cancelButton = (<></>)
    } else {
      cancel = (<><Button className={'float-end'} key={'b'+y} id={'b'+y} variant='danger' type={'button'} onClick={() => setter(setcancelState,y,data,setCancelID)}><IntlMessage Store={Store} messageId="common.cancel_order"/></Button></>);
      cancelButton =  (<><Button key={'b'+y} id={'b'+y} variant='danger' type={'button'} onClick={() => displayElement('ContentStorno','block')}><IntlMessage Store={Store} messageId="common.cancel_order"/></Button></>);
    }

    const setItems = (data:any) => {

      let ItemHolder = [];
      for(let I=0;I < data.length; I++) {
        ItemHolder.push(<><hr /><Row>
          <Col xl={5}>{data[I].VariantTranlsation.name} ({data[I].Variant.code})</Col>
          <Col xl={3}><IntlMessage Store={Store} messageId="common.amount"/>: {data[I].Product.quantity}</Col>
          <Col xl={2}>{sum('EUR', data[I].Product.unit_price)}</Col>
          <Col xl={2}>{total(data[I].Product.unit_price, data[I].Product.quantity, data[I].Product.total)}</Col>
        </Row></>)
      }
      return ItemHolder;
    }

    return (
      <>
        <Row>

          <Col xl={7} className={'mb-4'}>
            <h2>#{data[0].Parent.number} -{merchant}</h2>
            <br />
            <h3><IntlMessage Store={Store} messageId="common.package"/> #{y + 1}</h3>

            {setItems(data)}
            <hr />
            <br /><h3><IntlMessage Store={Store} messageId="common.actions"/></h3><br />
            {cancelButton}
            <Paper id={'ContentStorno'} className={'modalviewContent'} style={{display:'none',width:'450px',padding:'10px',height:'auto',overflow:'hidden',left:'25%',zIndex:1}}>
              <Row style={{padding: '10px'}}>
                <Form.Group className="mb-3" controlId={"Form.ControlTextarea"}
                            key={"Form.ControlTextarea"}>
                  <Form.Label>Stornierungsgrund</Form.Label>
                  <Form.Control name={'message'} id={'message'} as={'textarea'} rows={10} maxLength={500} />
                </Form.Group>
              </Row>
              <hr />
              <div>
                <br />
                <Button type={'button'} variant={'primary'}
                        onClick={() => displayElement('ContentStorno','none')}
                >
                  Schließen</Button>
                {cancel}
                <div style={{clear:'both'}} />
              </div>
            </Paper>
          </Col>
          <Col xl={5}>{ItemHolderbill[y]}</Col>
        </Row>
        <hr style={{
          color: '#000000',
          backgroundColor: '#000000',
          height: 5
        }}/>
      </>
    );
  }

  const itembill = (data: any) => {

    const tmpsumm = (data:any) => {
      let itemHoldertmp = [];
      for(let tmp=0;tmp< data.length;tmp++) {
        let tmp_summ = data[tmp].Product.total * data[tmp].Product.quantity;
        itemHoldertmp.push(tmp_summ);
      }
      let summ:number = 0;
      for(let Item=0;Item< itemHoldertmp.length; Item++) {

        summ += itemHoldertmp[Item];
      }

      return summ;
    }

    const tmpShippingSum = (total:any,shipping:any) => {
      let newTotal = total+shipping;

      return newTotal;
    }
    let pdf = (<></>);
    if(data[0].pdf !== undefined && data[0].pdf !== null) {
      if (data[0].pdf.cdn_url !== undefined && data[0].pdf.cdn_url !== null) {
        pdf = (<><Download pdfId={data[0].pdf.id} name={data[0].pdf.name} /></>);
      }
    }

    let shippingstate: any = {
      'ready': {
        'label': <IntlMessage Store={Store} messageId="common.ready_for_shipment" />,
        'color': 'warning',
        'text': 'dark'
      },
      'shipped': {
        'label': <IntlMessage Store={Store} messageId="common.sent" />,
        'color': 'success',
        'text': 'dark'
      },
      'partially_shipped':{
        'label': <IntlMessage Store={Store} messageId="common.sent_partly" />,
        'color': 'warning',
        'text': 'dark'
      },
      'cancelled':{
        'label': <IntlMessage Store={Store} messageId="common.cancelled" />,
        'color': 'danger',
        'text': 'dark'
      },
      'addressed':{
        'label': <IntlMessage Store={Store} messageId="common.cancelled" />,
        'color': 'danger',
        'text': 'dark'
      },'created': {
        'label': <IntlMessage Store={Store} messageId="common.created_delivery" />,
        'color': 'success',
        'text': 'light'
      }
    };

    let Orderstate: any = {
      'new': {
        'label': <IntlMessage Store={Store} messageId="common.new_order" />,
        'color': 'warning'
      },
      'hidden': {
        'label': <IntlMessage Store={Store} messageId="common.new_order" />,
        'color': 'warning'
      },
      'fulfilled': {
        'label': <IntlMessage Store={Store} messageId="common.completed" />,
        'color': 'success'
      },
      'cancelled':{
        'label': <IntlMessage Store={Store} messageId="common.cancelled" />,
        'color': 'danger'
      },
      'addressed':{
        'label': <IntlMessage Store={Store} messageId="common.cancelled" />,
        'color': 'danger'
      }
    };

   let OrderstateButton = (
     <Button variant={Orderstate[data[0].Parent.state].color} type={'button'} className={'disabled'}>{Orderstate[data[0].Parent.state].label}</Button>
   )

    let paymentstateButton = (
      <Button variant={shippingstate[data[0].Parent.shipping_state].color} type={'button'} className={'disabled'}>{shippingstate[data[0].Parent.shipping_state].label}</Button>
    )

    return (
      <><Row>
        <Col>
          <Row style={{marginBottom: '10px',textAlign:'right'}}>

            <Col>{OrderstateButton}</Col>
            <Col>{paymentstateButton}</Col>
          </Row>
          <div style={{
            background: '#f2f2f2',
            borderRadius: '3px',
            marginBottom: '20px',
            padding: '20px',
            position: 'relative',
          }}>

            <Row>
              <Col><IntlMessage Store={Store} messageId="invoice.subTotal" /></Col>
              <Col> {total(data.unit_price, data.quantity, tmpsumm(data), false,true)}</Col>
            </Row>
            <hr />
            <Row>
              <Col><IntlMessage Store={Store} messageId="common.customer_discount" /></Col>
              <Col>- {Intl.NumberFormat('de-DE', {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: 2,
              }).format((data[0].Parent.coupon_amount / 100))}</Col>
            </Row>
            <hr />
            <Row>
              <Col><IntlMessage Store={Store} messageId="common.operator_credit" /></Col>
              <Col>{Intl.NumberFormat('de-DE', {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: 2,
              }).format((data[0].Parent.coupon_amount / 100))}</Col>
            </Row>
            <hr />
            <Row>
              <Col><IntlMessage Store={Store} messageId="common.shipping.costs" /></Col>
              <Col>{total(data[0].Shippingcost.shippingcost , 1, data[0].Shippingcost.shippingcost, false,true)}</Col>
            </Row>
            <hr />
            <Row>
              <Col><IntlMessage Store={Store} messageId="common.taxes_included" /></Col>
              <Col>{total(data.unit_price, data.quantity, tmpsumm(data), true,true)}</Col>
            </Row>
            <hr />
            <Row>
              <Col><IntlMessage Store={Store} messageId="common.price.total" /></Col>
              <Col>{total(data.unit_price, data.quantity, tmpShippingSum(tmpsumm(data),data[0].Shippingcost.shippingcost), false,true)}</Col>
            </Row>
          </div>
          <h2><IntlMessage Store={Store} messageId="common.files" /></h2>
          <div style={{
            background: '#f2f2f2',
            borderRadius: '3px',
            marginBottom: '20px',
            padding: '20px',
            position: 'relative',
          }}>
            {pdf}
          </div>
        </Col>
      </Row>
      </>
    )
  }


  let ItemHolderbill = [];
  if (response2?.Order.merchant !== undefined) {
    for (let x = 0; x < response2?.Order.merchant.length; x++) {
      let singleItemHolder = [];
      for (let y = 1; y < response2?.Order.Items[response2?.Order.merchant[x].merchant].length -1; y++) {
        singleItemHolder.push({'Product': response2?.Order.Items[response2?.Order.merchant[x].merchant][y].order,
          'Shippingcost':response2?.Order.Items[response2?.Order.merchant[x].merchant][response2?.Order.Items[response2?.Order.merchant[x].merchant].length-1],
          'Parent':response2?.Order.Items[response2?.Order.merchant[x].merchant][y].parent,
          'pdf': response2?.Order.Items[response2?.Order.merchant[x].merchant][y].pdf});
      }
      ItemHolderbill.push(itembill(singleItemHolder));
    }
  }
  let ItemHolder = [];
  if (response2?.Order.merchant !== undefined) {
    for (let x = 0; x < response2?.Order.merchant.length; x++) {
      let singleItemHolder = [];
      for (let y = 1; y < response2?.Order.Items[response2?.Order.merchant[x].merchant].length - 1; y++) {

        singleItemHolder.push({'Product': response2?.Order.Items[response2?.Order.merchant[x].merchant][y].order,
          'Productcode': response2?.Order.Items[response2?.Order.merchant[x].merchant][y].productData.code,
          'OrderID':  response2?.Order.Items[response2?.Order.merchant[x].merchant][0].OrderID,
          'Parent':response2?.Order.Items[response2?.Order.merchant[x].merchant][y].parent,
          'Variant':response2?.Order.Items[response2?.Order.merchant[x].merchant][y].vari,
          'VariantTranlsation':response2?.Order.Items[response2?.Order.merchant[x].merchant][y].vari_transation,
          'Shippingcost':response2?.Order.Items[response2?.Order.merchant[x].merchant][response2?.Order.Items[response2?.Order.merchant[x].merchant].length-1],
          'pdf': response2?.Order.Items[response2?.Order.merchant[x].merchant][y].pdf});
      }

      ItemHolder.push(item(singleItemHolder, response2?.Order.merchant[x].merchant,x,ItemHolderbill,cancelState,setcancelState,setCancelID));
    }

  }


  if (response2?.hasOwnProperty('Order') === true) {
    let total = response2?.Order.payment.amount + response2?.Order.shippingcost;

    return (
      <div key={'head'} style={{backgroundColor: '#FFFFFF', padding: '20px'}}>
        <Row><Col xl={10}><h2>#{response2?.Order.number} -Bestellung vom {response2?.Order.checkout_completed_at}</h2></Col>
          <Col xl={2}><Button variant='light' style={{float: 'left', marginLeft: '15px'}} className={'float-end'} onClick={() => history(-1)}><IntlMessage Store={Store} messageId="common.back"/></Button></Col></Row>
        <hr />
        <Row>
          <Col xl={8}>{ItemHolder}</Col>
          <Col xl={4}>
            <h2><IntlMessage Store={Store} messageId="common.customer" /></h2>
            <div style={{
              background: '#f2f2f2',
              borderRadius: '3px',
              marginBottom: '20px',
              padding: '20px',
              position: 'relative',
            }}>{response2?.Order.customer.email}</div>
            <br /><br />
            <h2><IntlMessage Store={Store} messageId="common.billing_address" /></h2>
            <div style={{
              background: '#f2f2f2',
              borderRadius: '3px',
              marginBottom: '20px',
              padding: '20px',
              position: 'relative',
            }}>
              {response2?.Order.billingAddress.first_name} {response2?.Order.billingAddress.last_name}<br />
              {response2?.Order.billingAddress.street}<br />
              {response2?.Order.billingAddress.country_code} {response2?.Order.billingAddress.postcode} {response2?.Order.billingAddress.city}<br />
              <strong><a href={'tel:+49'+response2?.Order.shippingAddresse.phone_number.slice(1)}>{response2?.Order.billingAddress.phone_number}</a></strong><br />
            </div>
            <br /><br />
            <h2><IntlMessage Store={Store} messageId="common.delivery_address" /></h2>
            <div style={{
              background: '#f2f2f2',
              borderRadius: '3px',
              marginBottom: '20px',
              padding: '20px',
              position: 'relative',
            }}>
              {response2?.Order.shippingAddresse.first_name} {response2?.Order.shippingAddresse.last_name}<br />
              {response2?.Order.shippingAddresse.street}<br />
              {response2?.Order.shippingAddresse.country_code} {response2?.Order.shippingAddresse.postcode} {response2?.Order.shippingAddresse.city}<br />
              <strong><a href={'tel:+49'+response2?.Order.shippingAddresse.phone_number.slice(1)}>{response2?.Order.shippingAddresse.phone_number}</a></strong><br />

            </div>
            <br /><br />
            <h2><IntlMessage Store={Store} messageId="customer.note" /></h2>
            <div style={{
              background: '#f2f2f2',
              borderRadius: '3px',
              marginBottom: '20px',
              padding: '20px',
              position: 'relative',
            }}>
              {response2?.Order.notes}
            </div>
            <br /><br />
            <h2><IntlMessage Store={Store} messageId="common.total" /></h2>
            <div style={{
              background: '#f2f2f2',
              borderRadius: '3px',
              marginBottom: '20px',
              padding: '20px',
              position: 'relative',
            }}>
              <Row>
                <Col><IntlMessage Store={Store} messageId="common.price.sub" /></Col>
                <Col> {sum(response2?.Order.payment.currency_code, response2?.Order.payment.amount)}</Col>
              </Row>
              <hr />
              <Row>
                <Col><IntlMessage Store={Store} messageId="common.customer_discount" /></Col>
                <Col>- {Intl.NumberFormat('de-DE', {
                  style: 'currency',
                  currency: 'EUR',
                  minimumFractionDigits: 2,
                }).format((response2?.Order.order_promotion_total / 100))}</Col>
              </Row>
              <hr />
              <Row>
                <Col><IntlMessage Store={Store} messageId="common.operator_credit" /></Col>
                <Col>{Intl.NumberFormat('de-DE', {
                  style: 'currency',
                  currency: 'EUR',
                  minimumFractionDigits: 2,
                }).format((response2?.Order.order_promotion_total / 100))}</Col>
              </Row>
              <hr />
              <Row>
                <Col><IntlMessage Store={Store} messageId="common.shipping.costs" /></Col>
                <Col>{sum(response2?.Order.payment.currency_code, response2?.Order.shippingcost)}</Col>
              </Row>
              <hr />
              <Row>
                <Col><IntlMessage Store={Store} messageId="common.taxes_included" /></Col>
                <Col>{sum(response2?.Order.payment.currency_code, tax(response2?.Order.payment.amount))}</Col>
              </Row>
              <hr />
              <Row>
                <Col><IntlMessage Store={Store} messageId="common.price.total" /></Col>
                <Col>{sum(response2?.Order.payment.currency_code, total)}</Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>

    );
  } else {
    return (
      <><SpinnerJSX messageId={'common.loading'} Store={Store} /></>
    );
  }
};


export default OrdersDetail;









