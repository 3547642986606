import React from "react";
import {switcher} from "../../index";
import Button from 'react-bootstrap/Button';

const IconMenu = () => {

    return(
        <>
            <div className="help-desk__widgets">
                <button className="help-desk__widget" onClick={() => switcher('faq','tutorials')}>
                    <div className="box">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                             viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                             strokeLinecap="round" strokeLinejoin="round">
                            <path d="M4 19.5A2.5 2.5 0 0 1 6.5 17H20"/>
                            <path
    d="M6.5 2H20v20H6.5A2.5 2.5 0 0 1 4 19.5v-15A2.5 2.5 0 0 1 6.5 2z"/>
                        </svg>
                    </div>
                    <p>FAQ</p></button>
                <button className="help-desk__widget" onClick={() => switcher('tutorials','faq')}>
                    <div className="box">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                             viewBox="0 0 24 24">
                            <circle cx="12" cy="12" r="10" fill="none" stroke="currentColor"
                                    strokeLinecap="round" strokeLinejoin="round"
                                    strokeWidth="2"/>
                            <polygon points="16.24 7.76 14.12 14.12 7.76 16.24 9.88 9.88 16.24 7.76"
                                     fill="none" stroke="currentColor" strokeLinecap="round"
                                     strokeLinejoin="round" strokeWidth="2"/>
                        </svg>
                    </div>
                    <p>Tutorials</p></button>
                <Button className="help-desk__widget" href={'/ticket/overview/:page'}>
                    <div className="box">
                        <span className="material-icons" aria-hidden="true">support</span>
                    </div>
                    <p>IT-Support</p></Button>
            </div>
        </>
    )
}

export default IconMenu;
