
import React, {useEffect, useState} from "react";
import {ResponseallType, Responseformular} from "../API/response/menu/formular";
import {SpinnerJSX} from "../../../../@WUM/core/component/const/Spinner";
import {useSelector} from "react-redux";
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ListGroup from 'react-bootstrap/ListGroup'
import Sortable from "sortablejs";
import Form from 'react-bootstrap/Form'
import open from "./helper/open";
import setForm from "./helper/setForm";
import addMenu from "./helper/addMenu";
import addform from "./helper/addform";
import {show, close, closeandChange, deleted, deletedMenu} from "./helper/switchdisplay";
import {init2} from "../../../../@WUM/core/form-serilizer/seriliaze/Formserialize";
import {blogApiDetail} from "../../Blog/API/request/blog/blogApiDetail.interface";
import {configBlog, outputBlog} from "../../Blog/modules/configuration/mapping";
import {blog} from "../../Blog/API/request/blog/blog";
import {notify_save} from "../../../../@WUM/core/component/const/notification.service";
import {error} from "../../../../@WUM/core/component/const/error.services";
import {handleSubmitForm} from "../../../../@WUM/core/component/const/handleSubmitForm";
import IntlMessage from "../../../../@WUM/core/component/const/IntlMessage";
import {useNavigate} from "react-router-dom";
import Badge from 'react-bootstrap/Badge'
import {menu} from "../API/request/menu/menu";
import {MenuApiDetail} from "../API/request/menu/formularApiDetail.interface";
import {configMenu, outputMenu} from "../API/request/menu/mapping";


const Menu = (props:{value: any;}) => {
    const Store = useSelector((state: any) => state)
    const response = Responseformular();
    const [currentTutorial, setCurrentTutorial] = useState<MenuApiDetail[]>([]);
    const [validated, setValidated] = useState(false);
    const history = useNavigate();
    const [types, settypes] = useState('');

    let AllPage = ResponseallType();

    /**
     * Sendet Formular Daten an die API
     * @param status
     * @param id
     */
    const updateData = (status: boolean, id: any,form:any) => {

        /*
         *  Wichtig zum Rausholen der Datan aus Formular und senden
         *  Das Interface muss immer angepasst werden
         */
        let Data = init2(form);

        console.log(Data)

        let reauestData: MenuApiDetail[] &
            configMenu
            = Object.assign({}, currentTutorial,
            outputMenu(Data));

        console.log(reauestData)

        /*
         *  Senden der Erhaltenen Daten an das Frontend
         */
        menu.updatePost(reauestData)
            .then(response => {

                setCurrentTutorial({...currentTutorial});
                notify_save(Store);
            })
            .catch(e => {
                error(e);
            });
    };


    useEffect(() => {
        var appendTo = document.querySelectorAll('[data-appendto]');

        for(let append=0;append<appendTo.length;append++){
            let appendField = appendTo[append] as HTMLDivElement;
            $('#'+appendField.id).appendTo('#'+(appendTo[append] as HTMLDivElement).dataset.appendto);
        }

       let addButton = (document.getElementById('addButton') as HTMLButtonElement)
        if(addButton !== null) {
            addButton.setAttribute('disabled','true')
            let Childsnested = $('#MenuHolder').find(".calcposition")
            for(let nested=0;nested<Childsnested.length;nested++) {
                let Childsfrom = $(Childsnested[nested] as HTMLDivElement).find(".list-group-item")
                for (let from = 0; from < Childsfrom.length; from++) {
                    let Childs = $(Childsfrom[from] as HTMLDivElement).find("input")

                    for (let fromi = 0; fromi < Childs.length; fromi++) {
                        let Name = (Childs[fromi] as HTMLDivElement).getAttribute('name') as string;
                        if(Name !== null && Name !== undefined) {
                            if (Name.includes('position')) {
                                (Childs[fromi] as HTMLInputElement).value = '' + from + '';
                                //console.log((Childs[fromi] as HTMLInputElement).value)
                            }

                            let Identifier = Name.split(']');

                            let MenuID = 'Menu[' + (Childsnested[nested] as HTMLDivElement).dataset.menuid + '';
                            if ((Childsnested[nested] as HTMLDivElement).dataset.parent !== null && (Childsnested[nested] as HTMLDivElement).dataset.parent !== undefined) {
                                if (Name.includes('parent')) {
                                    (Childs[fromi] as HTMLInputElement).value = '' + (Childsnested[nested] as HTMLDivElement).dataset.parent + '';
                                }
                            }
                            let ListID = '[' + from
                            let NewIdentifier;
                            let NewIdentifiers = '';
                            NewIdentifier = Name.replace(Identifier[2], ListID);

                            NewIdentifiers = NewIdentifier.replace(Identifier[0], MenuID);

                            //console.log('Name :'+Name+' newName: '+NewIdentifier + ' menuId: '+(Childsnested[nested] as HTMLDivElement).dataset.menuid + ' ListID: '+ListID);

                            (Childs[fromi] as HTMLDivElement).setAttribute('name', NewIdentifiers)
                        }
                    }

                }
                //console.log((Childsnested[nested] as HTMLDivElement))
            }
        }
        let nestedSortables = document.getElementsByClassName('nested-sortable');
        for (var i = 0; i < nestedSortables.length; i++) {
            new Sortable(nestedSortables[i] as HTMLElement, {
                group: 'nested',
                animation: 150,
                fallbackOnBody: true,
                swapThreshold: 0.65,
                onEnd: (evt) => {
                    let Childsnested = $('#MenuHolder').find(".calcposition")
                    for(let nested=0;nested<Childsnested.length;nested++) {
                        let Childsfrom = $(Childsnested[nested] as HTMLDivElement).find(".list-group-item")
                        for (let from = 0; from < Childsfrom.length; from++) {
                            let Childs = $(Childsfrom[from] as HTMLDivElement).find("input")

                            for (let fromi = 0; fromi < Childs.length; fromi++) {
                                let Name = (Childs[fromi] as HTMLDivElement).getAttribute('name') as string;
                                let appendTOs = (Childs[fromi] as HTMLDivElement).parentNode?.parentNode?.parentNode?.parentNode;
                                if(appendTOs !== null && appendTOs !== undefined) {

                                    let appendtoContainer = (appendTOs as HTMLDivElement).dataset.appendto;

                                    if(appendtoContainer !== undefined && appendtoContainer !== null) {

                                        if (Name.includes('appendto')) {
                                            (Childs[fromi] as HTMLInputElement).value = '' + (appendTOs as HTMLDivElement).id + '';
                                        }
                                    } else {
                                        if (Name.includes('appendto')) {
                                            (Childs[fromi] as HTMLInputElement).value = '';
                                        }
                                    }
                                }
                                if((Childsnested[nested] as HTMLDivElement).dataset.menusetid !== null && (Childsnested[nested] as HTMLDivElement).dataset.menusetid !== undefined) {
                                    if (Name.includes('menu_id')) {
                                        (Childs[fromi] as HTMLInputElement).value = '' + (Childsnested[nested] as HTMLDivElement).dataset.menusetid + '';
                                    }
                                }

                                if (Name.includes('position')) {
                                    (Childs[fromi] as HTMLInputElement).value = '' + from + '';
                                }

                                let Identifier = Name.split(']');

                                let MenuID = 'Menu[' + (Childsnested[nested] as HTMLDivElement).dataset.menuid + '';
                                if((Childsnested[nested] as HTMLDivElement).dataset.parent !== null && (Childsnested[nested] as HTMLDivElement).dataset.parent !== undefined) {
                                    if (Name.includes('parent')) {
                                        (Childs[fromi] as HTMLInputElement).value = '' + (Childsnested[nested] as HTMLDivElement).dataset.parent + '';
                                    }
                                }
                                let ListID = '[' + from
                                let NewIdentifier;
                                let NewIdentifiers =  '';

                                NewIdentifier = Name.replace(Identifier[2], ListID);

                                NewIdentifiers = NewIdentifier.replace(Identifier[0], MenuID);

                                //console.log('Name :'+Name+' newName: '+NewIdentifier + ' menuId: '+(Childsnested[nested] as HTMLDivElement).dataset.menuid + ' ListID: '+ListID);

                                (Childs[fromi] as HTMLDivElement).setAttribute('name', NewIdentifiers)
                            }

                        }
                        //console.log((Childsnested[nested] as HTMLDivElement))
                    }
                }
            });
        }

    });
    if(response.length > 0 && AllPage.length > 0) {

        let newMenu = response.length;

        let ButtonHolder = [];


        for(let x=0;x<response.length;x++) {
            let List = [];
            List.push(

            )



            if(response[x].items !== undefined) {
                for (let l = 0; l < response[x].items.length; l++) {
                    List.push(
                        <ListGroup.Item id={'lg'+x+l} data-appendto={response[x].items[l].item.appendto}>

                            <p id={'p'+x+''+l}  style={{paddingLeft:'25px'}}
                               >
                                <p className={'IconPlus float-end '} style={{left:'5px',top:'0px'}}>
                                <span className="material-icons text-white" onClick={(e)=>deleted('lg'+x+l,'group'+x,settypes)}>
                                delete
                            </span></p>
                                <p id={'p'+x+''+l+'titel'} style={{maxWidth: '180px'}} onClick={(e) => open(x + '-' + l + '-' + response[x].items[l].item.position)}>{response[x].items[l].item.name} </p>

                                    <p className={'float-end '} style={{right:'55px',top:'6px',position:'absolute'}}>
                                        {
                                            (response[x].items[l].item.type === 'page' ?
                                                <Badge pill  bg={'primary'}>
                                                    {response[x].items[l].item.type.toUpperCase()}
                                                </Badge>
                                                :
                                                (response[x].items[l].item.type === 'link' ?
                                                    <Badge pill  bg={'secondary'}>
                                                        {response[x].items[l].item.type.toUpperCase()}
                                                    </Badge>
                                                    :
                                                    (response[x].items[l].item.type === 'html' ?
                                                        <Badge pill  bg={'info'}>
                                                            {response[x].items[l].item.type.toUpperCase()}
                                                        </Badge>
                                                        :
                                                    <></>
                                                    )))
                                        }
                                    </p>
                                <p className={'IconPlus float-end '} style={{right:'15px',top:'0px'}}>
                                <span className="material-icons text-white" onClick={(e)=>show('p'+x+''+l,'name'+x+''+l)}>
                                edit_note
                            </span></p></p>
                            <div className="mb-3" style={{display:'none'}} id={'name'+x+''+l}>

                                <Form.Control type="hidden" name={"Menu["+x+"][Items]["+l+"][item]"} />
                                <Form.Control   onDoubleClick={(e) => open(x + '-' + l + '-' + response[x].items[l].item.position)}
                                               type="text" name={"Menu["+x+"][Items]["+l+"][item][name]"} id={'name'+x+''+l+'input'}
                                               defaultValue= {response[x].items[l].item.name}/>
                                <Form.Control type="text" name={"Menu["+x+"][Items]["+l+"][menu_id]"} defaultValue= {response[x].items[l].menu_id}/>
                                <Form.Control type="hidden" name={"Menu["+x+"][Items]["+l+"][item_id]"} defaultValue= {response[x].items[l].item_id}/>
                                <Form.Control type="hidden" name={"Menu["+x+"][Items]["+l+"][id]"} defaultValue= {response[x].items[l].id}/>
                                <p className={'IconPlus float-end '} style={{right:'15px',top:'7px'}}>
                                <span className="material-icons text-white"  onClick={(e)=>closeandChange('p'+x+''+l,'name'+x+''+l)}>
                                    close
                                </span>
                                </p>
                            </div>

                            <div id={x + '-' + l + '-' + response[x].items[l].item.position} className={'hidden Form'}>
                                {setForm(x, l,response[x].items[l].item,false,AllPage,settypes,types)}
                            </div>
                            {(response[x].items[l].item.type === 'page' ?
                            <ListGroup id={'group'+x+''+l} data-menusetid={response[x].id} data-menuid={x} data-appendto={true} data-parent={response[x].items[l].item.page_id} className={'nested-sortable  mb-3 dropZone'} style={{minHeight: '30px',marginLeft:'10px'}}>
                            </ListGroup>
                                : <></>)}
                        </ListGroup.Item>
                    )
                }
            }
            ButtonHolder.push(
                <Col className={'col-4 MenuItem p-0'} id={'Menu'+x}>
                    <Button variant="primary" style={{width:'100%'}} className={'rounded-0 border border-dark p-2'} id={'menuButton'+x}>
                        <span id={'menuButton'+x+'titel'} >{response[x].name}</span>

                        <p className={'IconPlus float-end '} style={{left:'15px',top:'0px'}}><span className="material-icons text-white" onClick={() => show('menuButton'+x,'menu'+x)} >
                                    edit
                    </span></p>
                        <p className={'IconPlus float-end '} style={{right:'0px',top:'0px'}}>
                                <span className="material-icons text-white" onClick={(e)=>deletedMenu('Menu'+x,settypes)}>
                                delete
                            </span></p>
                        <p className={'float-end '} style={{right:'35px',top:'2px',position:'absolute'}}>
                            <Form.Select id={'settype'+x} onChange={(e) => {
                                addform('group'+x,x,AllPage,settypes,e.target.value);
                                e.target.value = '';
                            }}>
                                <option></option>
                                <option value={'page'}>Page</option>
                                <option value={'link'}>Link</option>
                                <option value={'html'}>HTML</option>
                            </Form.Select>
                        </p>

                </Button>
                    <div id={'menu'+x} style={{display:'none'}}>
                    <Form.Control type="text" name={"Menu["+x+"][name]"} defaultValue={response[x].name} style={{backgroundColor: '#015b91',borderColor: '#015b91',color:'white'}}  id={'menu'+x+'input'}/>
                        <Form.Control   type="hidden" name={"Menu["+x+"][id]"} defaultValue= {response[x].id}/>
                        <p className={'IconPlus float-end '} style={{right:'10px',top:'0px'}}><span className="material-icons text-white" onClick={() => closeandChange('menuButton'+x,'menu'+x)} >
                                    close
                    </span></p>

                    </div>
                    <ListGroup id={'group'+x} className={'nested-sortable rounded-0 h95 calcposition'} style={{minHeight: '30px'}} data-menusetid={response[x].id} data-menuid={x}>
                        {List}
                    </ListGroup>
                </Col>

            )
        }

        return (
            <>
                <div className={'ContentWrapper h-100'}>
                    <Form  id={'form001'} className={'formControl'} noValidate validated={validated} onSubmit={(e) => handleSubmitForm(e, updateData, setValidated, 0)}>
                    <Row className={''}>
                        <Row className={'m-0'} style={{height: '100%'}}>
                            <Col className={''}>
                                <Button variant='light' style={{float: 'left', marginLeft: '15px'}} onClick={() => history(-1)}><IntlMessage Store={Store} messageId="common.back"/></Button>
                            </Col>
                            <Col>
                                <Button style={{float:'right',marginLeft:'10px'}} id={'addButton'}  onClick={()=>addMenu('MenuHolder','Menuname',newMenu,AllPage,settypes,types)}>
                                    Add
                                </Button>
                                <Form.Group className="mb-3"  controlId="Menuname" onChange={() => (document.getElementById('addButton') as HTMLButtonElement).removeAttribute('disabled')} style={{width:'250px',float:'right'}}>
                                    <Form.Control  type="text"   />
                                </Form.Group>

                            </Col>
                            <Col className={''} >
                                <Button variant='success'
                                        style={{float: 'right', marginRight: '15px'}}
                                        type={'submit'}
                                >
                                    {<IntlMessage Store={Store} messageId="common.save" />}
                                </Button>
                            </Col>
                        </Row>
                    </Row>


                    <Row id={'MenuHolder'}>
                        {ButtonHolder}
                    </Row>

                    </Form>
                </div>
            </>
        )
    } else {
        return (
            <><SpinnerJSX messageId={'common.loading'} Store={Store} /></>
        );
    }
}

export default Menu;



