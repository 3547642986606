import React from 'react';
import renderFormularSwitch from './register';
function Formular(data:any,modalShow: boolean = false, setModalShow:any = false, modalData:any = false,
                  setModalData:any = false ,newElement:any = false,newelementIndex:any = 0,setdeleteElement:any = false,setElements:any = [],Store:any,galerie?:any,
                  galerieItems?:any) {

    if(data !== undefined ) {
        if(data.length > 0) {
            const row = [];
            for (let i = 0; i < data.length; i++) {

                row.push(renderFormularSwitch(data,i,newElement,newelementIndex,modalShow, setModalShow,modalData, setModalData,setdeleteElement,setElements,Store,galerie,galerieItems));
            }
            return (
                <>
                    {row}
                </>
            )
        } else {
            return;
        } } else {
        return;
    }
}

export default Formular;
