
/**
 *
 * @memberOf blog
 * @typedef config
 * @prop {number} visible ID des Language Key
 * @prop {string} identifier Preperator Function name
 * @prop {number} discr Param for Preperator Function
 * @prop {string} layout Replace value for Preperator
 * @prop {string} position Replace value for Preperator
 * @prop {number} image_id Replace value for Preperator
 * @prop {number} author_id Param for Preperator Function
 * @prop {string} share_title Replace value for Preperator
 * @prop {string} share_description Replace value for Preperator
 * @prop {Element} elements Replace value for Preperator
 * @prop {Translations} translations Replace value for Preperator
 */
export interface config {
  id:number
  js: string,
  templateName: string
}

/**
 *
 * @memberOf blog
 * @prop Data
 */
export function output(Data: any) {
  let back = {
    id: Data.id,
    js: Data.js,
    templateName: Data.templateName


  }

  return back;
}
