import {DynamikModulsActionType} from "../../actions/ActionType";

const initialState = {
    loading: false,
    PagesModuls: [],
    MenuModuls: [],
    Dashboard: [],
}

type action = {
    type: string
    PagesModuls?: any
    MenuModuls?: any
    Dashboard?: any

}

const DynamikModulsReducer = (state = initialState,
                     action: action) => {
    switch (action.type) {
        case DynamikModulsActionType.DynamikModuls_INIT:
            return {
                ...state,
                PagesModuls: action.PagesModuls,
                MenuModuls: action.MenuModuls,
                Dashboard: action.Dashboard,
                loading: true,
            }
        default:
            return state
    }
}

export default DynamikModulsReducer;
