import {Button} from "react-bootstrap";
import IntlMessage from "../../const/IntlMessage";
import React from "react";
import FormFooterProps from "../interface/FormFooterProps.interface";



const FormFooter = (props:FormFooterProps) => {
    let link;
    if(process.env.REACT_APP_MODE === 'LIVE') {
        link = process.env.REACT_APP_PREVIEW_LINK_LIVE;
    } else if(process.env.REACT_APP_MODE === 'DEVELOPMENT'){
        link = process.env.REACT_APP_PREVIEW_LINK_DEVELOPMENT;
    } else {
        link = process.env.REACT_APP_PREVIEW_LINK_LOCAL;
    }
    return(
        <>
            <div id={'headerMCfooter'}>
                <Button id={'backContent'} variant='light' style={{float: 'left', marginLeft: '15px'}} onClick={() => removeMulti(props.history,props.back)}><IntlMessage Store={props.Store} messageId="common.back"/></Button>

                <Button variant='success'
                        style={{float: 'right', marginRight: '15px'}}
                        type={'submit'}
                        id={'btn_save'}
                >
                    <IntlMessage Store={props.Store} messageId="common.save" />
                </Button>
                {(props.nonPreview === undefined && props.response !== undefined  ? <Button id={'previewButton'} href={link+'/preview/type/'+(props.response !== undefined && props.response.hasOwnProperty('translation') && props.response.translation.de.slug !== null ? props.response.translation.de.slug.replaceAll('/','') : '')+'/'} variant={'info'} target={'_blank'} style={{float: 'right', marginRight: '15px'}}>Preview</Button> : <></>)}
                <br style={{clear: 'both'}} />

            </div>
        </>
    )
}

export default FormFooter;

const removeMulti = (history:any,back:any) => {
    removecssorjs(''+process.env.REACT_APP_FRONTEND_URL+'/templates/'+process.env.REACT_APP_TPL+'/css/customize.css',history,'css',back)
    removecssorjs(''+process.env.REACT_APP_FRONTEND_URL+'/templates/'+process.env.REACT_APP_TPL+'/css/bootstrap-icons.css',history,'css',back)
    removecssorjs('https://cdn.jsdelivr.net/npm/masonry-layout@4.2.2/dist/masonry.pkgd.min.js',history,'js',back)
}

export const removecssorjs = (index:any,history:any,type:any,back:any) => {
    if(type === 'css') {
        var linkNode =document.querySelectorAll('link[href*="'+index+'"]')[0]
        if(linkNode !== undefined && linkNode !== null) {
            linkNode.parentNode?.removeChild(linkNode);
        }
    } else if(type === 'js') {
        var JSNode =document.querySelectorAll('script[src*="'+index+'"]')[0]
        if(JSNode !== undefined && JSNode !== null) {
            JSNode.parentNode?.removeChild(JSNode);
        }
    }

if(back !== undefined) {
    window.location.href = back
} else {
    history(-1)
}


}
