import React, {useState
   } from 'react';
import {SpinnerJSX} from '../../../../@WUM/core/component/const/Spinner';
import {useNavigate,
  useParams
} from 'react-router-dom';
import IntlMessage, {IntlMessageValue} from '../../../../@WUM/core/component/const/IntlMessage';
import {useSelector} from "react-redux";
import {ResponseRewrite, ResponseRewriteDelete} from "../API/response/rewrite";
import PielersTable from "../../../../@WUM/core/tablemaker";
import PaginationJSX from "../../../../@WUM/core/form-serilizer/elements/pagination";
import {handleSucces} from "../../../../@WUM/core/component/const/HandleSucces";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import SearchJSX from "../../../../@WUM/core/component/const/SearchJSX";



const RewriteOverview = (value:any) => {

  const {page,deleteID} = useParams<{page: string,deleteID: string}>();
  let limit: any;
    if (typeof page === "string") {
        limit = (parseFloat(page) * 10) - 10;
    }

    const Store = useSelector((state: any) => state)
  const history = useNavigate();



  const debug = (page: string | undefined, modalShow: boolean, modalData: any[]) => {
    return;
  }
  const destination = 'rewrite';
  const namespace_search = "rewrite_searchValue";
  const [searchValue, setSearchValue] = useState((localStorage.getItem(namespace_search) !== null ? localStorage.getItem(namespace_search) : ''));
  const [search, setSearch] = useState<boolean>(false)
  const response = ResponseRewrite(limit,searchValue);
  const [modalShow, setModalShow] = React.useState(false);
  const [modalData, setModalData] = React.useState([]);
  debug(page,modalShow,modalData)



  const Configuration = {
    'tableBody': {
      'data': response,
      'dataID': 'id',
      'underdata': true,
      'keys': ['id','active', 'url', 'target','method'],
      'type': ['string', 'status', 'string', 'string', 'string'],
    },
    'tableHeader': ['ID','Status','Ausgangs-URL','Ziel-URL','Umleitungsmethode'],
    'tableHeaderWidth': ['10%', '10%', '35%','35%','10%'],
    'tablecolor': ['light'],
    'menu': {
      'data': response,
      'menuDisable': false,
      'menuItem': [<IntlMessage Store={Store} messageId="common.edit" />, <IntlMessage Store={Store} messageId="common.delete" />],
      'menuroute': ['/rewrite/Detail', '/rewrite/Delete/1'],
      'menuParams': ['id', 'id'],
      'menuIcons': ['pencil','Delete'],
    },

  };


  const SearchConfig = [{
    namespace:namespace_search,
    destination: destination,
    input:[
      {id:'search_name',translation: 'URL',param: 'url',method: 'like'},
      {id:'search_title',translation: 'Target',param: 'target',method: 'like'}
    ],
    button: ['common.search','common.del.search','Neue Regel erstellen']
  }]


  if ( response !== true && response.length > 0) {
    return (
      <>
        <SearchJSX setSearchValue={setSearchValue} Store={Store} SearchConfig={SearchConfig} />
          <PielersTable Configuration={Configuration} />
          <PaginationJSX response={response} history={history} to={'overview'} handleSuccess={handleSucces} destination={destination} />

  </>

    )
  } else {
    localStorage.setItem('searchEnterInput','');
        return (
          <><SpinnerJSX messageId={'common.loading'} Store={Store} /></>
        );
      }

};


export default RewriteOverview;



