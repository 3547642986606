import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import React from "react";
import TabsBuilderProps from "../interface/TabsBuilderProps.interface";
import Col from 'react-bootstrap/Col';
import Row from "react-bootstrap/Row";

const TabsBuilder = (props:TabsBuilderProps) => {

    let config = props.config[0];

    let settings: JSX.Element[] = [];
    let context: JSX.Element[] = [];
    for(let x = 0; x< config.tabs.length;x++){
        let nowTab = config.tabs[x];

        if(nowTab.eventKey !== 'content') {
            if(config.tabs[x].disabled !== undefined && config.tabs[x].disabled === 'true' ) {
                settings.push(
                    <><div id={nowTab.eventKey} className={'pl-3 pr-3'}>{nowTab.content}</div></>
                )
            } else{
                settings.push(
                    <><div id={nowTab.eventKey} className={'pl-3 pr-3'}>{nowTab.content}</div></>
                )
            }
        } else {
            if(config.tabs[x].disabled !== undefined && config.tabs[x].disabled === 'true' ) {
                context.push(
                    <>{nowTab.content}</>
                )
            } else{
                context.push(
                    <>{nowTab.content}</>
                )
            }
        }


    }


    return(
        <>
            <Row>


                <Col  style={{display:'block',minWidth:'260px',maxWidth:'260px'}}>

                   <div className={'settingsContent'}>
                    {settings}
                   </div></Col>
                <Col >

                    {context}</Col>




            </Row>
            <Row className={'ContentButton'}>
            <Row className={'m-0'} style={{height: '100%'}}>
                <Col className={'mt-2 text-center'} id={'injectback'} />
                <Col className={'mt-2  text-center'} id={'injectpreview'} />
                <Col className={'mt-2  text-center'} id={'injectsave'} />
            </Row>
            </Row>

        </>
    )

}

export default TabsBuilder;
