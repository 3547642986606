import {useEffect, useState} from 'react';
import {error} from '../../../../../@WUM/core/component/const/error.services';
import {checkLogin} from "../../../../../@WUM/templates/default/Auth/service/AuthService";
import {RewriteApiType} from '../request/rewriteAPI.interface.';
import {rewrite} from "../request/rewrite";

export const ResponseRewrite = (limit: any, searchValue: any) => {
  const [posts, setPosts] = useState<RewriteApiType[]>([]);
  const [isError, setIsError] = useState<boolean>(false);
  useEffect(() => {
    (async () => {
      await rewrite.getPosts(limit,searchValue)
    .then((data) => {
      checkLogin(data);
      setPosts(data);
    })
    .catch((err) => {
      error(err);
      setIsError(true);
    });
    })()}, [limit,searchValue])


  return isError ? (isError ) : (posts);

}

export const ResponseRewriteDelete = (id: any) => {
  const [isError, setIsError] = useState<boolean>(false);
  useEffect(() => {
    (async () => {
      await rewrite.deletePost(id)
        .then((data) => {
          return data;
        })
        .catch((err) => {
          error(err);
          setIsError(true);
        });
    })()}, [id])

  return isError ? (isError ) : (isError);

}


export const ResponseRewriteDetail = (id: string | undefined) => {
  const [posts, setPosts] = useState<RewriteApiType[]>([]);
  const [isError, setIsError] = useState<boolean>(false);
  useEffect(() => {
    (async () => {
      await rewrite.getAPost(id)
        .then((data) => {
          setPosts(data);
          //console.log(data);

        })
        .catch((err) => {
          error(err);
          setIsError(true);
        });
    })()}, [id])

  return isError ? (isError ) : (posts);

}
