const SunEditorBig = (element: any, key: any, newElement: any, data: any, name: any, label: any = '', jsonID: any = '', id: any = '', appendJson: any = '', appendJsonField: any = '', mode: any = 'classic',height:any = null) => {

    let SunEditor;

    if (newElement === false) {

        SunEditor =
            {
                'formType': 'suneditor',
                'data': data,
                'name': name,
                'label': (label !== '' ? label : ''),
                'value': '',
                'height': height,
                'mode': mode,
                'jsonID': jsonID,
                'id': id,
                'appendJson': appendJson,
                'appendJsonField': appendJsonField,
                'multiValue': {
                    'element': element,
                    'key': key,
                }
            }
    } else {
        SunEditor =
            {
                'formType': 'suneditor',
                'data': data,
                'name': name,
                'mode': mode,
                'jsonID': jsonID,
                'height': height,
                'id': id,
                'appendJson': appendJson,
                'appendJsonField': appendJsonField,
                'label': (label !== '' ? label : ''),
                'value': '',
            }
    }

    return SunEditor;

}

export default SunEditorBig;
