import React from 'react';
import TableCell from '@material-ui/core/TableCell';
function injection(text: string, id: any, index: number, VisitData: any, VisitDataDel: any, Store: any, tableBodyPositionElement: any){


  return (
    <><TableCell style={{textAlign:tableBodyPositionElement}}  key={"td"+id+ "_" +index} data-param={VisitData} data-param-del={VisitDataDel}
                 data-id={id} id={"td"+id+ "_" +index}><p dangerouslySetInnerHTML={{__html: text}} /></TableCell>
    </>
  );

}

export default injection;
