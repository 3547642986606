import {useEffect, useState} from 'react';
import {formularApiType} from '../../request/menu/formularApi.interface';
import {error} from "../../../../../../@WUM/core/component/const/error.services";
import {menu} from "../../request/menu/menu";

/**
 * Sends response to Rest API for Blog Overview
 * @memberOf blog
 * @prop limit
 * @prop searchValue
 * @see {@link blogApiType}
 * @author Marcel
 * Ugurcu
 * @version 0.0.1
 */
export const Responseformular = () => {
  const [posts, setPosts] = useState<formularApiType[]>([]);
  const [isError, setIsError] = useState<boolean>(false);
  useEffect(() => {
    (async () => {
      await menu.getPosts()
    .then((data) => {
      setPosts(data);
    })
    .catch((err) => {
      error(err);
      setIsError(true);
    });
    })()}, [])


  return isError ? (posts ) : (posts);

}

export const ResponseallType = () => {
  const [posts, setPosts] = useState<formularApiType[]>([]);
  const [isError, setIsError] = useState<boolean>(false);
  useEffect(() => {
    (async () => {
      await menu.getALLTypes()
          .then((data) => {
            setPosts(data);
          })
          .catch((err) => {
            error(err);
            setIsError(true);
          });
    })()}, [])


  return isError ? (posts ) : (posts);

}
