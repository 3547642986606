import React from 'react';
import PielersForm from '../../index'
import {base, basesettings} from './base';
import MergeTwoObjecttoArray from '../../../component/const/MergeTwoObjecttoArray';
import IntlMessage, {IntlMessageValue} from '../../../component/const/IntlMessage';
import SunEditorBig from '../../../component/const/SunEditorBig';
import CopyElements from "../../../component/const/CopyElements";
import Col from 'react-bootstrap/Col';
import Row from "react-bootstrap/Row";
import WebBuilderElementsHead from "../../../component/const/WebBuilderElementsHead";
import Box from '@mui/material/Box';

function text_blockerWebBuilder(data: any, element: any, i: any, newElement: any = false, newelementIndex: any = 0, modalShow: boolean,
                      setModalShow: (value: (((prevState: boolean) => boolean) | boolean)) => void, modalData: any[],
                      setModalData: (value: (((prevState: any[]) => any[]) | any[])) => void,
                      content:any = [],setElements:any = [],Store:any) {

  const index = (newElement === false ? i : newelementIndex)

  const fake_headlines = [1,2,3,4,5,6].map(n => ({
    'label': IntlMessageValue('de',"module.fake_headline_n",Store,"replace","[n]",n),
    'value': n
  }));
  const headlines = [1,2,3,4,5,6].map(n => ({
    'label': IntlMessageValue('de',"module.headline_n",Store,"replace","[n]",n),
    'value': n+6
  }))

  let options = MergeTwoObjecttoArray(fake_headlines,headlines)

  const configuration = [
    {
      'formType': 'select',
      'label': <IntlMessage Store={Store} messageId="module.headline_type" />,
      'legende': <IntlMessage Store={Store} messageId="module.headline_type.legend" />,
      'name': 'elements['+index+'][headline_type]',
      'selected': (newElement === false ? element.headline_type : ''),
      'options': options
    },{ 'formType': 'input',
      'label': <IntlMessage Store={Store} messageId="common.headline" />,
      'type': 'text',
      'placeholder': IntlMessageValue("de","common.headline",Store),
      'name': 'elements['+index+'][headline]',
      'value': (newElement === false ? (element.translation !== undefined ? element.translation.headline : '') : ''),
    },
    { 'formType': 'input',
      'label': <IntlMessage Store={Store} messageId="common.color" />,
      'type': 'color',
      'placeholder': IntlMessageValue("de","common.color",Store),
      'name': 'elements['+index+'][color]',
      'value': (newElement === false ? (element.translation !== undefined ? element.translation.color : '') : ''),
    },
    SunEditorBig(element.translation,'text',newElement,data,'elements[' + index + '][text]',IntlMessageValue('de','common.edit_text',Store))]

  let newConfiguration = configuration.concat(base(element,i,newElement,newelementIndex));
  let Status;
  if(element.visible === 1) {
    Status = 'elementActive';
  } if(element.visible === 0){
    Status = 'elementDeactive';
  } else if(newElement === true) {
    Status = 'elementActive';
  }
  return (
    <>
    <Box id={'text_blocker'+index} data-appendcontainer={(newElement === false && element.appendto !== null && element.appendto !== undefined && element.appendto !== '' ?  element.appendto : 'false')} >
    {WebBuilderElementsHead(<IntlMessage Store={Store} messageId="module.text_with_background" />,'text_blocker',(newElement === false ? (element.translation !== undefined ? element.translation.headline : '') : ''),Status,index,'elementButton',<IntlMessage Store={Store} messageId="module.text_with_background" />,
            modalShow,setModalShow,modalData, setModalData,newElement,element,content,setElements,Store,'','',element.id)}
      <div id={'preview-container_text_blocker'+index} className={'preview-container'}  style={{display: (newElement === false ? 'block' : 'none')}} >
        {(newElement === false ?
            <span dangerouslySetInnerHTML={{ __html: element.previews }} /> : <></>)}
      </div>
        <Row id={'mein_text_blocker'+index} className={'boxShadowCard card ElementBorder'} style={{width: '100%',margin:'0px 0px 10px 0px',border: '0',boxShadow: 'unset'}}>

          <Col  className={'element-body'}>
            <div id={'edit_module_text_blocker' + index}  style={{display:(newElement === false ? 'none' : 'block'),padding:'15px',width: '100%',marginBottom: '20px'}}>
              <div id={'Teaser'}>text_blocker</div>
              <Col style={{marginBottom: '10px',marginTop:'10px'}}><IntlMessage Store={Store} messageId="module.text_with_background" /></Col>
              {PielersForm(newConfiguration)}
            </div>
          </Col>
        <div id={'settingstext'+index+'text_blocker'} className={'settingsWebBuilderModul '} style={{display:'none'}}>

          {PielersForm(basesettings(element,i,newElement,newelementIndex,Store))}
        </div>
          {(newElement === false ? CopyElements(element.id,<IntlMessage messageId="module.text_with_background"  Store={Store}/>,Store) : <></>)}

        </Row>
        </Box>
    </>
  )


}

export default text_blockerWebBuilder;
