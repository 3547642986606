import React from 'react';
import {LanguageSwitcherValue} from "../../shop/core/components/LanguageSwitcher";

import PromoOverview from "./modules/Pages/PromoOverview";
import PromoDetail from "./modules/Pages/PromoDetail";

export const PromoPagesConfig = (value:any) => {

    return[
        {
            path: '/Promo/:page',
            elements: <PromoOverview value={value}/>,
            roles: ['superadmin','admin','operator']
        },
        {
            path: '/Promo/detail/:id',
            elements: <PromoDetail value={value}/>,
            roles: ['superadmin','admin','operator']
        },
    ];
}

export const PromoMenuConfig = (value:any) => {
    return [
        {
            name: LanguageSwitcherValue('common.settings.promo'),
            route: '/Promo/1',
            icon: 'savings',
            roles: ['superadmin','admin','operator']
        },
    ]
}
