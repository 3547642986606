import { PageActionType } from '../../Actions/ActionType'
const initialState = {
  loading: false,
  data: null,
  singlePage: [],
  deletePage: null,
  error: null,
}
type action = {
  type: string
  data?: any
  error?: any
}
const PageReducer = (state = initialState, action: action) => {
  switch (action.type) {
    case PageActionType.PAGE_INIT:
      return {
        ...state,
        loading: true,
      }
    case PageActionType.PAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      }
    case PageActionType.PAGE_SINGLE_DATA:
      return {
        ...state,
        loading: false,
        singlePage: action.data,
      }
    case PageActionType.PAGE_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        deletePage: action.data,
      }
    case PageActionType.PAGE_CLEAR_DATA:
      return {
        ...state,
        loading: false,
        deletePage: null,
      }
    case PageActionType.PAGE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    default:
      return state
  }
}

export default PageReducer
