import React, {useState} from 'react';
import {SpinnerJSX} from "../../../../../@WUM/core/component/const/Spinner";
import {useParams} from 'react-router-dom';
import ContentSwitch from "../../../../../@WUM/core/component/const/ContentSwitch";
import IntlMessage, {IntlMessageValue} from '../../../../../@WUM/core/component/const/IntlMessage';
import {Responsetextblocks} from '../../API/response/textblocks/textBlocks';
import {LanguageSwitcherValue} from "../../../../../@WUM/core/component/core/components/LanguageSwitcher";
import {useSelector} from "react-redux";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import SearchJSX from "../../../../../@WUM/core/component/const/SearchJSX";


const TextBlocksOverview = (props:{value:any}) => {

  const {page} = useParams<{page: string,deleteID: string}>();
  const Store = useSelector((state: any) => state)
  let limit: any;
  if (typeof page === "string") {
    limit = (parseFloat(page) * 10) - 10;
  }
  const destination = 'TextBlocks';
  const namespace_search = "TextBlocks_searchValue";
  const [searchValue, setSearchValue] = useState((localStorage.getItem(namespace_search) !== null ? localStorage.getItem(namespace_search) : ''));
  const [search, setSearch] = useState<boolean>(false)
  const response = Responsetextblocks(limit,searchValue);
  const [modalShow, setModalShow] = React.useState(false);
  const [modalData, setModalData] = React.useState([]);
  const debug = (searchValue: string|null, page: string | undefined, modalShow: any, modalData: any) => {
    return
  }
  debug(searchValue,page,modalShow,modalData)

  const [mode, setMode] = useState('cart');

  const SearchConfig = [{
    namespace:namespace_search,
    destination: destination,
    input:[
      {id:'search_name',translation: 'common.search_for_title',param: 'name',method: 'like'},
      {id:'search_title',translation: 'common.date',param: 'filter',method: '=',individual:<Form.Group as={Col} controlId={'search_title'}
                                                                                                       name={'search_title'}>
          <Form.Label>Nach Titel Sortieren</Form.Label>
          <Form.Select  aria-label="Filter" defaultValue={(localStorage.getItem(namespace_search + '_search_title') as string)}>
            <option />
            <option value={'title'}>{IntlMessageValue('de','searchadded.title.up',Store)}</option>
            <option value={'-title'}>{IntlMessageValue('de','searchadded.title.down',Store)}</option>
          </Form.Select> </Form.Group>}
    ],
    button: ['common.search','common.del.search','common.create_new_Texblock']
  }]

  const CardsConfiguration = {
    'data': {
      'ressource': response,
      'img': '',
      'dontStatus': true,
      'dontStatus_label': 'TextBlocks',
      'status': 'is_active',
      'statusActive': LanguageSwitcherValue("common.activated"),
      'statusDeactive': LanguageSwitcherValue("common.deactivated"),
      'link': 'slug',
      'prelink': '',
      'prelinksearch': 'translation.slug',
      'multiTitle': false,
      'title': 'name',
      'descriptionShow':true,
      'description': 'value',
      'router': 'id',
      'preroute': '/TextBlocks/Detail/',
      'edit': LanguageSwitcherValue("text.block.edit"),
      'delete': false,
      'deleteLink': '/TextBlocks/Delete/'+page+'/[ID]',
      'Dontvisited': true,
    }
  }

  const Configuration = {
    'tableBody': {
      'data': response,
      'dataID': 'id',
      'underdata': true,
      'keys': ['id' ,'name', 'value', 'locale'],
      'type': ['string', 'string', 'string', 'string'],
    },
    'tableHeader': [<IntlMessage Store={Store} messageId="common.id" />,
      <IntlMessage Store={Store} messageId="common.name" />,
      <IntlMessage Store={Store} messageId="common.value" />,
      <IntlMessage Store={Store} messageId="common.locale" />],
    'tableHeaderWidth': ['20%', '20%', '20%', '20%'],
    'tablecolor': ['light'],
    'menu': {
      'data': response,
      'menuDisable': false,
      'menuItem': [<IntlMessage Store={Store} messageId="common.edit" />, <IntlMessage Store={Store} messageId="common.delete" />],
      'menuroute': ['/TextBlocks/Detail', '/TextBlocks/Delete'],
      'menuParams': ['id', 'id'],
      'menuIcons': ['pencil','Delete'],
    },

  };

    let NewPage = (page === undefined ? 1:page);
  if ( response !== true && response.length > 0) {

    return (
      <><SearchJSX setSearchValue={setSearchValue} Store={Store} SearchConfig={SearchConfig} />
        <ContentSwitch pagenew={''} destinationnew={'TextBlocks'} tonew={'new'} text={<IntlMessage Store={Store} messageId="text.block.create" />}
                       response={response} CardsConfiguration={CardsConfiguration} mode={mode}
                       Configuration={Configuration} setMode={setMode}  destination={destination} page={NewPage}
                       to={'overview'} setSearchValues={setSearchValue} setSearchs={setSearch} search={search}
                       modalShow={modalShow} setModalShow={setModalShow} modalData={modalData} setModalData={setModalData}
                       searchfield={false} allTaxon={''} _handleKeyFilters={''} _handleEnter={''}
                       filter={false} merchantSelect={''} Store={Store}  />
  </>

    )
  } else {
    localStorage.setItem('searchInput','');
    localStorage.setItem('searchSelect','');
        return (
          <><SpinnerJSX messageId={'common.loading'} Store={Store} /></>
        );
      }

};


export default TextBlocksOverview;

